import React from "react";
import { Modal, Button, Icon } from "semantic-ui-react";
import FormCompany from "../forms/FormCompany";
import FormNewCompany from "../forms/FormNewCompany";

class CompanyModal extends React.Component {
  state = {
    part: 0,
    titlePart: "",
  };
  componentDidMount() {
    const { company } = this.props;
    if (this.props.showNavigation && company.id) {
      document.removeEventListener("keydown", () => { });
      
    }
  }

  save = () => {
    if (this.props.company.id) {
      this.props.onClickSave();
    } else {
      this.props.onClickAdd();
    }
  };
  back = () => {
    this.setState({
      part: this.state.part - 1,
    });
  };

  advance = () => {

    const validate = this.props.validate()
    const { part } = this.state;
    if (validate) {
      this.setState({
        part: part + 1,
      });
    }
  };

  render() {
    const {
      company,
      handleClose,
      onChange,
      onChecked,
      open,
      modalHeader = "",
      clients,
      groups,
      loading,
      errors,
      onSelectOrigin,
      onSelectSegment,
      data,
      onChangeState,
      generalError,
      cleanErrors,
      messageError,
      editCompany,
      setAddress,
      onSelectCode,
      handleExtraFields,
    } = this.props;

    const { part } = this.state;
    return (
      <Modal
        size={this.props.company.id ? "large" : "small"}
        closeIcon
        open={open}
        onClose={handleClose}
        dimmer="blurring"
        closeOnDimmerClick={false}
      >
        <Modal.Header>
          {part === 0 ? modalHeader : ""}
          {part === 1 ? "Localização" : ""}
          {part === 2 ? "Licenciamento" : ""}
          {part === 3 ? "Faturas" : ""}
          {part === 4 && this.props.company.id ? "Franquia Whatsapp" : ""}
          {part === 5 && this.props.company.id  ? "Webhook" : ""}
        </Modal.Header>

        <Modal.Content
          style={{
            minHeight: "450px",
          }}
        >
          <Modal.Description>
            {generalError && (
              <div className="errors-table">
                <Button
                  circular
                  basic
                  color="black"
                  icon="close"
                  className="button-close"
                  onClick={cleanErrors}
                />
                {generalError.email ? <p>{generalError.email}</p> : ""}
                {generalError.username ? <p>{generalError.username}</p> : ""}
                {generalError.password ? <p>{generalError.password}</p> : ""}
                {generalError.name ? <p>{generalError.name}</p> : ""}
                {generalError.cpf_cnpj ? <p>{generalError.cpf_cnpj}</p> : ""}
              </div>
            )}

            <div className="holder_company">
              <div className="form-company">
                {this.props.company.id ? (
                  <FormCompany
                    company={company}
                    clients={clients}
                    onChange={onChange}
                    onChecked={onChecked}
                    handleExtraFields={handleExtraFields}
                    groups={groups}
                    errors={errors}
                    messageError={messageError}
                    onChangeState={onChangeState}
                    data={data}
                    setAddress={setAddress}
                    onSelectCode={onSelectCode}
                    onSelectOrigin={onSelectOrigin}
                    onSelectSegment={onSelectSegment}
                    part={part}
                  />
                ) : (
                    <FormNewCompany
                      messageError={messageError}
                      handleExtraFields={handleExtraFields}
                      company={company}
                      onChange={onChange}
                      setAddress={setAddress}
                      onChecked={onChecked}
                      onChangeState={onChangeState}
                      data={data}
                      part={part}
                    />
                  )}
              </div>
            </div>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button.Group>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button.Or />

            <Button
              onClick={this.back}
              loading={loading}
              disabled={part === 0 ? true : loading}
              color="grey"
            >
              Voltar
                </Button>


            <Button.Or />

            <Button
              onClick={this.advance}
              loading={loading}
              disabled={loading || (this.props.company.id && part >= 5 || !this.props.company.id && part >= 3)}
              color="blue"
            >
              Avançar
            </Button>
            <Button.Or />
            <Button
              positive
              onClick={this.save}
              loading={loading}
              disabled={loading || (this.props.company.id && part < 5 || !this.props.company.id && part < 3)}
              color="blue"
            >
              Salvar
            </Button>
          </Button.Group>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default CompanyModal;
