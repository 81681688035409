import React, { Component } from "react";
import { connect } from "react-redux";
import api from "../../api/users";
import { bindActionCreators } from "redux";
import { Creators as UserActions } from "../../store/ducks/auth";
import { Dropdown } from "semantic-ui-react";
import AddUserModal from "./modal/AddUserModal";

class DropdownUsers extends Component {
  state = {
    user: {
      name: "",
      email: "",
      password: "",
      phone_number: "",
    },
    options: [],
    loading: false,
    search: "",
    openModal: false,
  };

  handleUserAddition = (e) => { };

  onSearchChange = (e, { searchQuery }) => {
    clearTimeout(this.timer);
    this.setState({ search: searchQuery });
    this.timer = setTimeout(this.onSearchUser, 300);
  };

  componentWillMount() {

    const company_id = this.props.company_id;
    if (!this.props.users || this.props.users <= 0) {
      const filter = JSON.stringify([["active", "=", 1]]);
      api.user.fetchAll({ take: 100, company_id, filter }).then((users) => {
        this.props.setUsers(users.data);

        this.setState({
          options: users.data.map((c) => ({
            key: c.id,
            value: c.id,
            text: `${c.name}`,
            image: { avatar: true, src: c.avatar },
          })),
          loading: false,
        });
      });
    } else {

      if (company_id) {
        const filter = JSON.stringify([["active", "=", 1]]);
        api.user.fetchAll({ take: 100, company_id, filter }).then((users) => {
          this.props.setUsers(users.data);

          this.setState({
            options: users.data.map((c) => ({
              key: c.id,
              value: c.id,
              text: `${c.name}`,
              image: { avatar: true, src: c.avatar },
            })),
            loading: false,
          });
        });
      } else {
        this.setState({
          options: this.props.users.map((c) => ({
            key: c.id,
            value: c.id,
            text: `${c.name}`,
            image: { avatar: true, src: c.avatar },
          })),
        });
      }
    }

  }

  componentDidMount() {
    const { options } = this.props;

    if (options) {
      this.setState({
        options: options.map((c) => ({
          key: c.id,
          value: c.id,
          text: `${c.name}`,
          image: { avatar: true, src: c.avatar },
        })),
      });
    }
  }

  onSearchUser = async () => {
    this.setState({ loading: true });
    const { search } = this.state;
    const filter = JSON.stringify([["active", "=", 1]]);
    const company_id = this.props.company_id;
    await api.user.fetchAll({ search, filter, company_id }).then((users) => {
      this.setState({
        options: this.state.options.concat(
          users.data
            .filter((el) => {
              return !this.state.options.some((a) => a.key === el.id);
            })
            .map((c) => ({
              key: c.id,
              value: c.id,
              text: `${c.name}`,
              image: { avatar: true, src: c.avatar },
            }))
        ),
        loading: false,
      });
    });
  };

  onChange = (e, { name, value }) => {
    this.setState((state) => ({
      user: { ...state.user, [name]: value },
    }));
  };

  cleanErrors = () => {
    this.setState({ errors: "" });
  };

  submit = () => {
    const { user } = this.state;

    this.setState({
      loading: true,
    });

    return api.user
      .submit(user)
      .then((data) => {
        this.setState((state) => ({
          options: [...state.options].concat({
            key: data.data.id,
            value: data.data.id,
            text: data.data.name,
          }),
          loading: false,
          user: {
            name: "",
            email: "",
            password: "",
            phone_number: "",
          },
          openModal: !state.openModal,
        }));

        this.props.onSelectUser(data.data.id);
      })
      .catch((err) => {
        this.setState({
          loading: false,
          errors: err.response,
        });
      });
  };

  toggle = (e, { name, value }) => {
    this.setState((state) => ({
      openModal: !state.openModal,
      user: {
        name: value,
        email: "",
        password: "",
        phone_number: "",
      },
    }));
  };

  render() {
    const { options, loading, openModal, user } = this.state;

    return (
      <div style={this.props.style || {}}>
        <Dropdown
          placeholder={this.props.placeholder != undefined ? this.props.placeholder :  "Pesquise um usuário"}
          fluid={this.props.fluid != undefined ? this.props.fluid :  true}
          search={this.props.search != undefined ? this.props.search :  true}
          selection={this.props.selection != undefined ? this.props.selection :  true}
          scrolling={this.props.scrolling != undefined ? this.props.scrolling :  true}
          multiple={this.props.multiple}
          allowAdditions={false}
          className="online"
          additionLabel="Adicionar: "
          name={this.props.name || "user_id"}
          width={this.props.width || "12"}
          value={this.props.user_id}
          onSearchChange={this.onSearchChange}
          onChange={(e, { value, options }) =>
            this.props.onSelectUser(value, options)
          }
          options={this.props.options && !options.length ? this.props.options.map(o => ({
            key: o.id,
            value: o.id,
            text: o.name,
          })) : options}
          onAddItem={this.toggle}
          closeOnChange
          loading={loading || this.props.loading}
          clearable={this.props.clearable || true}
        />
        <AddUserModal
          open={openModal}
          user={user}
          save={this.submit}
          toggle={this.toggle}
          onChange={this.onChange}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(UserActions, dispatch);

const mapStateToProps = ({ user }) => {
  return {
    users: user.users,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DropdownUsers);
