import React from "react";
import { Grid, Icon } from "semantic-ui-react";

export default () => (
  <Grid>
    <h2>Itens relacionado a conversa</h2>
    <p>
      Aqui vamos ver cinco itens, sim amiguinhos temos um que vai aparecer assim
      que você digitar algo e já vou dizer o porquê.
    </p>
    <p>
      <Icon color="grey" size="large" name="smile" />
      Enviar emoji, abrirá a aba de Emoji para que pssa enviar um Emoji
    </p>
    <p>
      <Icon color="grey" size="large" name="paperclip" />
      Anexar arquivo, de vídeo, documento, ou tirar uma foto da webCam
    </p>
    <p>
      <Icon color="grey" size="large" name="phone" rotated="clockwise" />
      selecionar de qual número será enviado, se tiver mais de um é comum querer
      ter um principal e outro como campanha, então aqui você poderá ter acesso
      aos números
    </p>
    <p>
      <Icon style={{ color: "green" }} size="large" name="microphone" />
      Gravação de áudio para enviar via plataforma
    </p>
    <p>
      <Icon color="grey" size="large" name="clock outline" />
      Agendar uma mensagem, pense comigo, você precisa Enviar uma mensagem na
      próxima semana a um cliente Você tem duas opções ou usa um despertador
      para lembrar ou nós do Monitchat adicionamos a solução , repare Que ao
      iniciar o texto aparece esse símbolo relógio uma opção de pré agendar a
      mensagem, legal né!
    </p>
    <p>
      <Icon color="grey" size="large" name="send" />
      enviar mensagem, ao clicar você envia a mensagem.
    </p>
  </Grid>
);
