import React from "react";
import { Grid } from "semantic-ui-react";

export default () => (
  <Grid>
    <Grid.Row>
      <h2>Palavras de Alerta</h2>
      <p>
        Aqui é onde se cadastra as palavras de alerta, além de não apresentar a
        palavra igual as palavras impróprias o administrador do sistema será
        notificado que o agente enviou ou recebeu essa palavra
      </p>
    </Grid.Row>
  </Grid>
);
