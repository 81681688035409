import React from "react";
import { Grid, Icon, List } from "semantic-ui-react";

export default () => (
  <Grid>
    <h2>Gerenciando a conversa</h2>
    <p>
      Nesta parte vamos vamos alguns gerenciamentos que poderá fazer para este
      contato:
    </p>
    <List as="ul" style={{ marginTop: 3, marginBottom: 40 }}>
      <List as="li">
        <Icon color="blue" size="large" name="edit" /> Clicando neste botão abre
        a edição do contato, lá poderá alterar o nome, a empresa, cadastrar um
        endereço ao contato, colocar CPF, bloquear contato, e bloquear contato
        de receber campanha
      </List>
      <List as="li">
        <Icon color="blue" size="large" name="archive" /> Clicando aqui você
        arquiva a conversa do contato, ela ficará disponível somente se
        pesquisar na parte superior desde menu
      </List>
      <List as="li">
        <Icon color="blue" size="large" name="money" /> Clicando neste botão
        você atrbui este contato a sua lista de carteira, todas as vezes que ele
        só ficará disponível para vizualização em sua tela ou usuário
        administrador. Lembrando que isso se tiver configurado para ser
        disponibilizado as conversas via Carteira ao invés por departamento
      </List>
      <List as="li">
        <Icon color="blue" size="large" name="user circle" /> Neste canto também
        fica o agente que está atendendo o contato, quando passa o mouse
        desaparece a foto deste agente
      </List>
      <List style={{ position: "relative" }} as="li">
        <span
          style={{
            marginBottom: 20,
            background: "#ef4747",
            borderRadius: "50%",
            fontSize: 9,
            width: 20,
            height: 20,
            fontSizeAdjust: 9,
            zIndex: -19,
            lineHeight: 20,
            position: "absolute",
          }}
          title="Mensagens novas"
        />
        <pre
          style={{
            marginTop: 0,
            font: "normal",
            fontFamily: "Nunito",
          }}
        >
          <strong style={{ color: "#fff", marginLeft: 6 }}>6</strong>{" "}
          {`    Mostramos uma notificação se de \nmensagem nova e fica conforme mostrado ao\n lado`}
        </pre>
      </List>
    </List>
  </Grid>
);
