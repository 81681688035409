import React, { Component } from "react";
import { connect } from "react-redux";
import { Icon, TextArea } from "semantic-ui-react";
import PerfectScrollbar from "react-perfect-scrollbar";

class WindowChat extends Component {
  state = {
    loading: true,
  };
  loadingTrue = () => {
    this.setState({
      loading: true,
    });
  };
  
  componentDidMount() {
    const { loading, loadingSwitch } = this.props;
    const body = document.querySelector(".message-body .scrollbar-container");
    const boxes = document.querySelector(".messages");
    if (body && boxes && loading) {
      body.scrollTo(0, boxes.scrollHeight);

      loadingSwitch();
    }
  }

  componentDidUpdate()
  {
    const window = document.getElementById(`internal-chat-${this.props.activeWindow}`);

    if(window)
    {
      window.focus();
    }
  }

  componentWillMount() { }
  render() {
    const {
      chats,
      active,
      activeWindow,
      closeWindow,
      minimizeWindow,
      enterMessage,
      sendMessage,
      message,
      loading,

      handleChange,
    } = this.props;

    return (
      <div className="holder_windows">
        <div className="chat_window">
          {chats
            .filter((c) => c.id)
            .map((c, i) => (
              <div
                className={activeWindow == i ? "window active" : "window"}
                id={`chat-window-${i * 1}`}
                key={`chat-window-${i * 1}`}
              >
                <div
                  className="header_chat_window"
                  onClick={() => {
                    active(i);
                    this.loadingTrue();
                  }}
                >
                  {c.avatar ? <img src={c.avatar} alt={c.name} /> : ""}

                  <span className="user_name">{c.name}</span>
                  {c.unread_messages > 0 ? <span className="unread">{c.unread_messages}</span> : null}
                  {document.querySelector(`#chat-window-${i * 1}`) && !document.querySelector(`#chat-window-${i * 1}`).classList.contains('active') ?
                  <Icon name="angle up" style={{zIndex: 1000}} onClick={() => {
                    document.querySelector(`#chat-window-${i * 1}`).classList.add('active')
                  }} />:
                  <Icon name="minus" style={{zIndex: 1000}} onClick={() => {
                    document.querySelector(`#chat-window-${i * 1}`).classList.remove('active')
                  }} />}
                  
                  <Icon name="close" onClick={() => closeWindow(c, i)} />
                </div>
                <div style={{backgroundColor: "#f9f6f2"}} className={loading ? "message-body" : "message-body"}>
                  <PerfectScrollbar
                    ref={(ref) => {
                      this._scrollBarRef = ref;
                    }}
                  >
                    <div
                      className="messages"
                    >
                      {c.chat.map((d) => (
                        <div
                          key={`chat-messages-${d.id}`}
                          className={
                            d.from === c.id
                              ? "message received"
                              : "message sended"
                          }
                        >

                          <span>{d.text}</span>
                          <span className="time">{d.created_at}</span>
                        </div>
                      ))}
                    </div>
                  </PerfectScrollbar>
                </div>
                <div className="input_box">
                  <TextArea
                    placeholder="Enviar mensagem"
                    name="message"
                    id={`internal-chat-${i}`}
                    onChange={handleChange}
                    value={message}
                    onKeyDown={(e) => enterMessage(e, c, i)}
                  />
                  <Icon
                    name="send"
                    onClick={() => sendMessage(c, i)}
                    className="send_message"
                  />
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return {
    user_id: user.user.id,
  };
};

export default connect(
  mapStateToProps,
  null
)(WindowChat);
