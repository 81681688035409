import React from "react";
import { Grid } from "semantic-ui-react";

export default () => (
  <Grid>
    <Grid.Row>
      <h2>Pausas do Agentes</h2>
      <p>
        <strong>
          Neste Menu ficará todos relatório de pausas dos agentes, vai ter aqui
          todas as pausas dos seus agentes.
        </strong>
      </p>
    </Grid.Row>
  </Grid>
);
