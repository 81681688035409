import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Icon } from "semantic-ui-react";

const MenuContent = ({ navLinks, conversationsLoaded }) => (
  <nav>
    <ul>
      {navLinks.map((navLink, i) => (
        <div key={i}>
          {navLink.url ? (
            <li key={`child-nav-link-${i * 1}`}>
              <Link
                to={navLink.url}
                target={navLink.parent ? "_parent" : "_self"}
              >
                {navLink.conversations && conversationsLoaded ? (
                  <span className="conversasAbertas">
                    {conversationsLoaded > 9 ? "10+" : conversationsLoaded}
                  </span>
                ) : (
                  ""
                )}
                <div className="center-translate">
                  <Icon name={navLink.icon} />
                  <span>{navLink.title}</span>
                </div>
              </Link>
            </li>
          ) : (
            ""
          )}
        </div>
      ))}
    </ul>
  </nav>
);

MenuContent.propTypes = {
  navLinks: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default MenuContent;
