import React from "react";
import { Grid, Button } from "semantic-ui-react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { Creators as TourActions } from "../../../store/ducks/tour";

function Introduction({ goTo }) {
  Introduction.propTypes = {
    goTo: PropTypes.func.isRequired,
  };

  return (
    <Grid>
      <Grid.Row>
        <h2>Introdução</h2>
      </Grid.Row>
      <Grid.Row>
        <p>
          A Tela inicial inicial é divida em algumas partes que são elas:{" "}
          <strong>
            Menu Lateral, Menu topo, e Relatórios, Atividades, Tarefas, gráfico
            de tickets{" "}
          </strong>{" "}
        </p>
        <p>
          {" "}
          Para Facilitar nós separamos essa Introdução em duas partes, uma Geral
          uma Detalhando cada parte da plataforma então se já passou pela
          indrodução geral e quer ir direto ao detalhamento click abaixo:
        </p>
        <Button
          style={{ marginBottom: "15px" }}
          fluid
          color="blue"
          onClick={() => goTo(13)}
        >
          Conhecer Menu Principal
        </Button>
        <Button
          style={{ marginBottom: "15px" }}
          fluid
          color="blue"
          onClick={() => goTo(8)}
        >
          Conhecer Menu Superior
        </Button>
        <p>
          <strong>
            Ou vamos inicar nossa experiêcia Geral!!! clique na seta abaixo ou
            use a seta do teclado para mover
          </strong>
        </p>
      </Grid.Row>
    </Grid>
  );
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(TourActions, dispatch);

const mapStateToProps = (state) => ({
  tour: state.tour,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Introduction);
