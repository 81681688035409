import React from "react";
import { Grid } from "semantic-ui-react";

export default () => (
  <Grid>
    <Grid.Row>
      <h2>Explicando os parâmentros</h2>

      <p>
        <strong>Solicitar Preenchimento do Ticket:</strong> Quando habilitado é
        obrigatório preencher os dados quando finaliza o Tickets, quando clicar
        em finalizar abrirá uma caixa pedindo as informação como departamento,
        categoria do atendimento e comentário do ticket.
      </p>
      <p>
        <strong>Restrição de Contatos á Usuários:</strong> aqui você terá duas
        opções de restrição por departamento, os tickets será seguimentados por
        departamento que os agentes foram cadastrados, todos do departamento
        verão os tickets que foram aribuidos ao departamento enquanto por
        carteira o usuário só verá os tickets das pessoas pertecentes a sua
        carteira e os novos se não tiver em nenhuma carteira
      </p>
      <p>
        <strong>Mensagem na Criação e de Agradecimento: </strong> Aqui só um
        detalhe que você pode usar as variáveis do sistema para ficar padrão
        como &#123;contact_name&#125; ou &#123;ticket_number&#125;
      </p>
      <p>
        <strong>Integração com Monitcall e Integração Intelliway:</strong> Se
        você possuir será configurado pra ti na primeira configuração da
        plataforma então também não é necessario mexer!
      </p>
    </Grid.Row>
  </Grid>
);
