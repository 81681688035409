import React from "react";
import { Grid } from "semantic-ui-react";

export default () => (
  <Grid>
    <Grid.Row>
      <h2>Agradecimento</h2>
      <p>Agradecemos a todos pelo momento dedicado a este tutorial e....</p>
      <p>
        Não esqueçam que em todo momento vocês contam com a nossa equipe que
        está inteiramente a disposição de vocês pelos contatos:
      </p>
      <p>
        <strong>Email:</strong> suporte@vipphone.com.br
      </p>
      <p>
        <strong>Telefone/Whatsapp:</strong> (27) 3010 - 9500
      </p>
      <span style={{ textAlign: "center", margin: "auto" }}>
        <h2>GRATIDÃO!</h2>
      </span>
      <p style={{ marginTop: 20 }}>
        <strong>
          Passe para o lado se quiser ver o Menu Principal novamente.
        </strong>
      </p>
    </Grid.Row>
  </Grid>
);
