import React from "react";

import {
  Wellcome,
  Introduction,
  MainMenu,
  TopMenu,
  Reports,
  Activities,
  Task,
  Tickets,
  DetailMainMenu,
  TopMenuDetailStepOne,
  TopMenuDetailStepTwo,
  TopMenuDetailStepThree,
  TopMenuDetailStepFour,
  TopMenuDetailStepFive,
} from "../../components/TourWizard/MainMenu/index";

import {
  ConversationStepOne,
  ConversationStepTwo,
  ConversationStepThree,
  ConversationStepFour,
  ConversationStepFive,
  ConversationStepSix,
  ConversationStepSeven,
  ConversationStepEight,
  ConversationStepNine,
  ConversationStepTen,
  ConversationStepEleven,
  ConversationStepTwelve,
  ConversationStepThirteen,
  ConversationStepFourteen,
  ConversationStepFifteen,
  ConversationStepSixteen,
  ConversationStepSeventeen,
  ConversationStepEighteen,
  ConversationStepNineteen,
} from "../../components/TourWizard/ConversationDetail";
import {
  RelationshipStepOne,
  RelationshipStepTwo,
  RelationshipStepThree,
} from "../../components/TourWizard/RelationshipDetail";
import {
  CampaignStepOne,
  CampaignStepTwo,
  CampaignStepThree,
  CampaignStepFour,
  CampaignStepFive,
  CampaignStepSix,
  CampaignStepSeven,
  CampaignStepEight,
  CampaignStepNine,
  CampaignStepTen,
  CampaignStepEleven,
  CampaignStepTwelve,
  CampaignStepFourteen,
} from "../../components/TourWizard/CampaignDetail";
import {
  TicketsStepOne,
  TicketsStepTwo,
  TicketsStepThree,
  TicketsStepFour,
  TicketsStepFive,
  TicketsStepSix,
  TicketsStepSeven,
  TicketsStepEight,
  TicketsStepNine,
  TicketsStepTen,
  TicketsStepEleven,
  TicketsStepTwelve,
  TicketsStepThirteen,
  TicketsStepFourteen,
} from "../../components/TourWizard/TicketsDetail";
import {
  ReportStepOne,
  ReportStepTwo,
  ReportStepThree,
  ReportStepFour,
  ReportStepFive,
  ReportStepSix,
  ReportStepSeven,
  ReportStepEight,
} from "../../components/TourWizard/ReportDetail";
import {
  ConfigurationsStepOne,
  ConfigurationsStepTwo,
  ConfigurationsStepThree,
  ConfigurationsStepFour,
  ConfigurationsStepFive,
  ConfigurationsStepSix,
  ConfigurationsStepSeven,
  ConfigurationsStepEight,
  ConfigurationsStepNine,
  ConfigurationsStepTen,
  ConfigurationsStepEleven,
  ConfigurationsStepTwelve,
  ConfigurationsStepThirteen,
  ConfigurationsStepFourteen,
  ConfigurationsStepFifteen,
  ConfigurationsStepSixteen,
  ConfigurationsStepSeventeen,
  ConfigurationsStepEighteen,
} from "../../components/TourWizard/ConfigurationsDetail";

import {
  CompanyStepOne,
  CompanyStepTwo,
  CompanyStepThree,
  CompanyStepFour,
  CompanyStepFive,
  CompanyStepSix,
  CompanyStepSeven,
  CompanyStepEight,
} from "../../components/TourWizard/CompanyDetail";

export const Types = {
  IS_OPEN: "tour/IS_OPEN",
  IS_CLOSE: "tour/IS_CLOSE",
  IS_OPEN_COMPANIES: "tour/IS_OPEN_COMPANIES",
  IS_OPEN_COMPANY: "tour/IS_OPEN_COMPANY",
  IS_OPEN_CONVERSATION: "tour/IS_OPEN_CONVERSATION",
  IS_OPEN_RELATIONSHIP: "tour/IS_OPEN_RELATIONSHIP",
  IS_OPEN_RELATIONSHIP_AGENTE: "tour/IS_OPEN_RELATIONSHIP_AGENTE",
  IS_OPEN_CAMPAIGN: "tour/IS_OPEN_CAMPAIGN",
  IS_OPEN_TICKETS: "tour/IS_OPEN_TICKETS",
  IS_OPEN_REPORT: "tour/IS_OPEN_REPORT",
  IS_OPEN_CONFIGURATIONS: "tour/IS_OPEN_CONFIGURATIONS",
  IS_OPEN_CONFIGURATIONS_AGENTE: "tour/IS_OPEN_CONFIGURATIONS_AGENTE",
  IS_OPEN_CONFIGURATIONS_SUPERVISOR: "tour/IS_OPEN_CONFIGURATIONS_SUPERVISOR",
};
const INITIAL_STATE = {
  isOpen: false,
  isOpenCompanies: false,
  isOpenConversation: false,
  isOpenRelationship: false,
  isOpenRelationshipAgente: false,
  isOpenCampaign: false,
  isOpenTickets: false,
  isOpenReport: false,
  isOpenCompany: false,
  isOpenConfigurations: false,
  isOpenConfigurationsAgente: false,
  isOpenConfigurationsSupervisor: false,
  mainSteps: [
    {
      selector: ".app",
      content: <Wellcome />,
    },
    {
      selector: ".app",
      content: (goTo) => <Introduction {...goTo} />,
    },
    {
      selector: ".holderNav",
      content: <MainMenu />,
    },
    {
      selector: ".botoes_topo",
      content: <TopMenu />,
    },
    {
      selector: ".holder_cards",
      content: <Reports />,
    },
    {
      selector: ".holder_atividades",
      content: <Activities />,
    },
    {
      selector: ".card_tarefas",
      content: <Task />,
    },
    {
      selector: ".chart_tickets",
      content: <Tickets />,
    },
    {
      selector: ".botoes_topo .rocket",
      content: <TopMenuDetailStepOne />,
    },
    {
      selector: ".botoes_topo .icone-add",
      content: <TopMenuDetailStepTwo />,
    },
    {
      selector: ".botoes_topo .calendar",
      content: <TopMenuDetailStepThree />,
    },
    {
      selector: ".botoes_topo .icone_celular",
      content: <TopMenuDetailStepFour />,
    },
    {
      selector: ".botoes_topo .foto-perfil",
      content: <TopMenuDetailStepFive />,
    },
    {
      selector: ".app",
      content: (goTo) => <DetailMainMenu {...goTo} />,
    },
  ],
  conversationSteps: [
    {
      selector: ".vazio .ui.green.button",
      content: <ConversationStepOne />,
    },
    {
      selector: ".conversasBusca",
      content: <ConversationStepTwo />,
    },
    {
      selector: ".busca",
      content: <ConversationStepThree />,
    },
    {
      selector: ".holder_card",
      content: <ConversationStepFour />,
    },
    {
      selector: ".fotoPerfil",
      content: <ConversationStepFive />,
    },
    {
      selector: ".resumo-conversa",
      content: <ConversationStepSix />,
    },
    {
      selector: ".icone_origem",
      content: <ConversationStepSeven />,
    },
    {
      selector: ".nome",
      content: <ConversationStepEight />,
    },
    {
      selector: ".icones_status",
      content: <ConversationStepNine />,
    },
    {
      selector: ".chart_holder",
      content: <ConversationStepTen />,
    },
    {
      selector: ".holder_icons",
      content: <ConversationStepEleven />,
    },
    {
      selector: ".info-conversa",
      content: <ConversationStepTwelve />,
    },
    {
      selector: "#conversation",
      content: <ConversationStepThirteen />,
    },
    {
      selector: ".topo-chat",
      content: <ConversationStepFourteen />,
    },
    {
      selector: ".QuickMessages",
      content: <ConversationStepFifteen />,
    },
    {
      selector: ".formchat",
      content: <ConversationStepSixteen />,
    },
    {
      selector: ".box-buttons-chat",
      content: <ConversationStepSeventeen />,
    },
    {
      selector: ".checkbox-enter",
      content: <ConversationStepEighteen />,
    },
    {
      selector: ".topoinfo",
      content: <ConversationStepNineteen />,
    },
    {
      selector: ".app",
      content: (goTo) => <DetailMainMenu {...goTo} />,
    },
  ],
  relationshipSteps: [
    {
      selector: ".CofigNav",
      content: <RelationshipStepOne />,
    },
    {
      selector: ".navRelationship:nth-child(1)",
      content: <RelationshipStepTwo />,
    },
    {
      selector: ".navRelationship:nth-child(2)",
      content: <RelationshipStepThree />,
    },
    {
      selector: ".app",
      content: (goTo) => <DetailMainMenu {...goTo} />,
    },
  ],
  relationshipStepsAgente: [
    {
      selector: ".CofigNav",
      content: <RelationshipStepOne />,
    },
    {
      selector: ".navRelationship:nth-child(2)",
      content: <RelationshipStepThree />,
    },
    {
      selector: ".app",
      content: (goTo) => <DetailMainMenu {...goTo} />,
    },
  ],
  campaignSteps: [
    {
      selector: ".tabela-padrao",
      content: <CampaignStepOne />,
    },
    {
      selector: ".tabela-padrao .actions",
      content: <CampaignStepTwo />,
    },
    {
      selector: ".tabela-padrao thead th:nth-child(1)",
      content: <CampaignStepThree />,
    },
    {
      selector: ".tabela-padrao thead th:nth-child(2)",
      content: <CampaignStepFour />,
    },
    {
      selector: ".tabela-padrao thead th:nth-child(3)",
      content: <CampaignStepFive />,
    },
    {
      selector: ".tabela-padrao thead th:nth-child(4)",
      content: <CampaignStepSix />,
    },
    {
      selector: ".tabela-padrao thead th:nth-child(5)",
      content: <CampaignStepSeven />,
    },
    {
      selector: ".tabela-padrao thead th:nth-child(6)",
      content: <CampaignStepEight />,
    },
    {
      selector: ".tabela-padrao thead th:nth-child(7)",
      content: <CampaignStepNine />,
    },
    {
      selector: ".tabela-padrao thead th:nth-child(8)",
      content: <CampaignStepTen />,
    },
    {
      selector: ".tabela-padrao thead th:nth-child(9)",
      content: <CampaignStepEleven />,
    },
    {
      selector: ".tabela-padrao thead th:nth-child(10)",
      content: <CampaignStepTwelve />,
    },
    {
      selector: ".tabela-padrao tbody",
      content: <CampaignStepFourteen />,
    },
    {
      selector: ".app",
      content: (goTo) => <DetailMainMenu {...goTo} />,
    },
  ],
  ticketsSteps: [
    {
      selector: ".CofigNav",
      content: <TicketsStepOne />,
    },
    {
      selector: ".tabela-padrao .actions",
      content: <TicketsStepTwo />,
    },
    {
      selector: ".tabela-padrao thead th:nth-child(1)",
      content: <TicketsStepThree />,
    },
    {
      selector: ".tabela-padrao thead th:nth-child(2)",
      content: <TicketsStepFour />,
    },
    {
      selector: ".tabela-padrao thead th:nth-child(3)",
      content: <TicketsStepFive />,
    },
    {
      selector: ".tabela-padrao thead th:nth-child(4)",
      content: <TicketsStepSix />,
    },
    {
      selector: ".tabela-padrao thead th:nth-child(5)",
      content: <TicketsStepSeven />,
    },
    {
      selector: ".tabela-padrao thead th:nth-child(6)",
      content: <TicketsStepEight />,
    },
    {
      selector: ".tabela-padrao thead th:nth-child(7)",
      content: <TicketsStepNine />,
    },
    {
      selector: ".tabela-padrao thead th:nth-child(8)",
      content: <TicketsStepTen />,
    },
    {
      selector: ".tabela-padrao thead th:nth-child(9)",
      content: <TicketsStepEleven />,
    },
    {
      selector: ".tabela-padrao thead th:nth-child(10)",
      content: <TicketsStepTwelve />,
    },
    {
      selector: ".tabela-padrao thead th:nth-child(11)",
      content: <TicketsStepThirteen />,
    },
    {
      selector: ".tabela-padrao tbody",
      content: <TicketsStepFourteen />,
    },
    {
      selector: ".app",
      content: (goTo) => <DetailMainMenu {...goTo} />,
    },
  ],
  reportSteps: [
    {
      selector: ".full",
      content: <ReportStepOne />,
    },
    {
      selector: ".report_card a",
      content: <ReportStepTwo />,
    },
    {
      selector: ".report_card a:nth-child(2)",
      content: <ReportStepThree />,
    },
    {
      selector: ".report_card a:nth-child(3)",
      content: <ReportStepFour />,
    },
    {
      selector: ".report_card a:nth-child(4)",
      content: <ReportStepFive />,
    },
    {
      selector: ".report_card a:nth-child(5)",
      content: <ReportStepSix />,
    },
    {
      selector: ".report_card a:nth-child(6)",
      content: <ReportStepSeven />,
    },
    {
      selector: ".report_card a:nth-child(7)",
      content: <ReportStepEight />,
    },
    {
      selector: ".app",
      content: (goTo) => <DetailMainMenu {...goTo} />,
    },
  ],
  companySteps: [
    {
      selector: ".holderPage",
      content: <CompanyStepOne />,
    },
    {
      selector: ".navCompanies ",
      content: <CompanyStepTwo />,
    },
    {
      selector: ".navCompanies li",
      content: <CompanyStepThree />,
    },
    {
      selector: ".navCompanies li:nth-child(2)",
      content: <CompanyStepFour />,
    },
    {
      selector: ".navCompanies:nth-child(2)",
      content: <CompanyStepFive />,
    },
    {
      selector: ".navCompanies:nth-child(2) li:nth-child(1)",
      content: <CompanyStepSix />,
    },
    {
      selector: ".navCompanies:nth-child(2) li:nth-child(2)",
      content: <CompanyStepSeven />,
    },
    {
      selector: ".app",
      content: <CompanyStepEight />,
    },
    {
      selector: ".app",
      content: (goTo) => <DetailMainMenu {...goTo} />,
    },
  ],
  configurationsSteps: [
    {
      selector: ".holderPage",
      content: <ConfigurationsStepOne />,
    },
    {
      selector: ".holderPage .navConfig",
      content: <ConfigurationsStepTwo />,
    },
    {
      selector: ".holderPage .navConfig li:nth-child(1)",
      content: <ConfigurationsStepThree />,
    },
    {
      selector: ".holderPage .navConfig li:nth-child(2)",
      content: <ConfigurationsStepFour />,
    },
    {
      selector: ".holderPage .navConfig li:nth-child(3)",
      content: <ConfigurationsStepSix />,
    },
    {
      selector: ".holderPage .navConfig li:nth-child(4)",
      content: <ConfigurationsStepSeven />,
    },
    {
      selector: ".holderPage .navConfig li:nth-child(5)",
      content: <ConfigurationsStepEight />,
    },
    {
      selector: ".holderPage .navConfig li:nth-child(6)",
      content: <ConfigurationsStepNine />,
    },
    {
      selector: ".holderPage .navConfig:nth-child(2) ",
      content: <ConfigurationsStepTen />,
    },
    {
      selector: ".holderPage .navConfig:nth-child(2) li:nth-child(1)",
      content: <ConfigurationsStepEleven />,
    },
    {
      selector: ".holderPage .navConfig:nth-child(2) li:nth-child(2)",
      content: <ConfigurationsStepTwelve />,
    },
    {
      selector: ".holderPage .navConfig:nth-child(2) li:nth-child(3)",
      content: <ConfigurationsStepThirteen />,
    },
    {
      selector: ".holderPage .navConfig:nth-child(2) li:nth-child(4)",
      content: <ConfigurationsStepFourteen />,
    },
    {
      selector: ".holderPage .navConfig:nth-child(3)",
      content: <ConfigurationsStepFifteen />,
    },
    {
      selector: ".holderPage .navConfig:nth-child(3) li",
      content: <ConfigurationsStepSixteen />,
    },
    {
      selector: ".holderPage .navConfig:nth-child(3) li",
      content: <ConfigurationsStepSeventeen />,
    },
    {
      selector: ".holderPage .navConfig:nth-child(4)",
      content: <ConfigurationsStepEighteen />,
    },
    {
      selector: ".app",
      content: (goTo) => <DetailMainMenu {...goTo} />,
    },
  ],
  configurationsStepsAgente: [
    {
      selector: ".holderPage",
      content: <ConfigurationsStepOne />,
    },
    {
      selector: ".holderPage .navConfig",
      content: <ConfigurationsStepTwo />,
    },
    {
      selector: ".holderPage .navConfig li:nth-child(1)",
      content: <ConfigurationsStepThree />,
    },
    {
      selector: ".holderPage .navConfig li:nth-child(2)",
      content: <ConfigurationsStepFour />,
    },
    {
      selector: ".holderPage .navConfig li:nth-child(3)",
      content: <ConfigurationsStepFive />,
    },
    {
      selector: ".holderPage .navConfig:nth-child(2) ",
      content: <ConfigurationsStepTen />,
    },
    {
      selector: ".holderPage .navConfig:nth-child(2) li:nth-child(1)",
      content: <ConfigurationsStepEleven />,
    },
    {
      selector: ".holderPage .navConfig:nth-child(2) li:nth-child(2)",
      content: <ConfigurationsStepTwelve />,
    },
    {
      selector: ".app",
      content: <CompanyStepEight />,
    },
    {
      selector: ".app",
      content: (goTo) => <DetailMainMenu {...goTo} />,
    },
  ],
  configurationsStepsSupervisor: [
    {
      selector: ".holderPage",
      content: <ConfigurationsStepOne />,
    },
    {
      selector: ".holderPage .navConfig",
      content: <ConfigurationsStepTwo />,
    },
    {
      selector: ".holderPage .navConfig li:nth-child(1)",
      content: <ConfigurationsStepThree />,
    },
    {
      selector: ".holderPage .navConfig li:nth-child(2)",
      content: <ConfigurationsStepFour />,
    },
    {
      selector: ".holderPage .navConfig li:nth-child(3)",
      content: <ConfigurationsStepFive />,
    },
    {
      selector: ".holderPage .navConfig li:nth-child(4)",
      content: <ConfigurationsStepSix />,
    },
    {
      selector: ".holderPage .navConfig li:nth-child(5)",
      content: <ConfigurationsStepSeven />,
    },
    {
      selector: ".holderPage .navConfig li:nth-child(6)",
      content: <ConfigurationsStepEight />,
    },
    {
      selector: ".holderPage .navConfig:nth-child(2) ",
      content: <ConfigurationsStepTen />,
    },
    {
      selector: ".holderPage .navConfig:nth-child(2) li:nth-child(1)",
      content: <ConfigurationsStepEleven />,
    },
    {
      selector: ".holderPage .navConfig:nth-child(2) li:nth-child(2)",
      content: <ConfigurationsStepTwelve />,
    },
    {
      selector: ".holderPage .navConfig:nth-child(2) li:nth-child(3)",
      content: <ConfigurationsStepThirteen />,
    },
    {
      selector: ".holderPage .navConfig:nth-child(2) li:nth-child(4)",
      content: <ConfigurationsStepFourteen />,
    },
    {
      selector: ".holderPage .navConfig:nth-child(3)",
      content: <ConfigurationsStepFifteen />,
    },
    {
      selector: ".holderPage .navConfig:nth-child(3) li",
      content: <ConfigurationsStepSixteen />,
    },
    {
      selector: ".holderPage .navConfig:nth-child(3) li",
      content: <ConfigurationsStepSeventeen />,
    },
    {
      selector: ".holderPage .navConfig:nth-child(4)",
      content: <ConfigurationsStepEighteen />,
    },
    {
      selector: ".app",
      content: <CompanyStepEight />,
    },
    {
      selector: ".app",
      content: (goTo) => <DetailMainMenu {...goTo} />,
    },
  ],
};

// reducer
export default function tour(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case Types.IS_OPEN:
      return {
        ...state,
        isOpenCompanies: false,
        isOpenConversation: false,
        isOpenRelationship: false,
        isOpenRelationshipAgente: false,
        isOpenCampaign: false,
        isOpenTickets: false,
        isOpenReport: false,
        isOpenCompany: false,
        isOpenConfigurations: false,
        isOpenConfigurationsAgente: false,
        isOpenConfigurationsSupervisor: false,
        isOpen: action.isOpen,
      };
    case Types.IS_CLOSE:
      return {
        ...state,
        isOpen: action.isOpen,
        isOpenCompanies: false,
        isOpenConversation: false,
        isOpenRelationship: false,
        isOpenRelationshipAgente: false,
        isOpenCampaign: false,
        isOpenTickets: false,
        isOpenReport: false,
        isOpenCompany: false,
        isOpenConfigurations: false,
        isOpenConfigurationsAgente: false,
        isOpenConfigurationsSupervisor: false,
      };
    case Types.IS_OPEN_COMPANIES:
      return {
        ...state,
        isOpen: false,
        isOpenConversation: false,
        isOpenRelationship: false,
        isOpenRelationshipAgente: false,
        isOpenCampaign: false,
        isOpenTickets: false,
        isOpenReport: false,
        isOpenCompany: false,
        isOpenConfigurations: false,
        isOpenConfigurationsAgente: false,
        isOpenConfigurationsSupervisor: false,
        isOpenCompanies: action.isOpenCompanies,
      };
    case Types.IS_OPEN_CONVERSATION:
      return {
        ...state,
        isOpen: false,
        isOpenCompanies: false,
        isOpenRelationship: false,
        isOpenRelationshipAgente: false,
        isOpenCampaign: false,
        isOpenTickets: false,
        isOpenReport: false,
        isOpenCompany: false,
        isOpenConfigurations: false,
        isOpenConfigurationsAgente: false,
        isOpenConfigurationsSupervisor: false,
        isOpenConversation: action.isOpenConversation,
      };
    case Types.IS_OPEN_RELATIONSHIP:
      return {
        ...state,
        isOpen: false,
        isOpenCompanies: false,
        isOpenConversation: false,
        isOpenRelationshipAgente: false,
        isOpenCampaign: false,
        isOpenTickets: false,
        isOpenReport: false,
        isOpenCompany: false,
        isOpenConfigurations: false,
        isOpenConfigurationsAgente: false,
        isOpenConfigurationsSupervisor: false,
        isOpenRelationship: action.isOpenRelationship,
      };
    case Types.IS_OPEN_RELATIONSHIP_AGENTE:
      return {
        ...state,
        isOpen: false,
        isOpenCompanies: false,
        isOpenConversation: false,
        isOpenRelationship: false,
        isOpenCampaign: false,
        isOpenTickets: false,
        isOpenReport: false,
        isOpenCompany: false,
        isOpenConfigurations: false,
        isOpenConfigurationsAgente: false,
        isOpenConfigurationsSupervisor: false,
        isOpenRelationshipAgente: action.isOpenRelationshipAgente,
      };
    case Types.IS_OPEN_CAMPAIGN:
      return {
        ...state,
        isOpen: false,
        isOpenCompanies: false,
        isOpenConversation: false,
        isOpenRelationship: false,
        isOpenRelationshipAgente: false,
        isOpenTickets: false,
        isOpenReport: false,
        isOpenCompany: false,
        isOpenConfigurations: false,
        isOpenConfigurationsAgente: false,
        isOpenConfigurationsSupervisor: false,
        isOpenCampaign: action.isOpenCampaign,
      };
    case Types.IS_OPEN_TICKETS:
      return {
        ...state,
        isOpen: false,
        isOpenCompanies: false,
        isOpenConversation: false,
        isOpenRelationship: false,
        isOpenRelationshipAgente: false,
        isOpenCampaign: false,
        isOpenReport: false,
        isOpenCompany: false,
        isOpenConfigurations: false,
        isOpenConfigurationsAgente: false,
        isOpenConfigurationsSupervisor: false,
        isOpenTickets: action.isOpenTickets,
      };
    case Types.IS_OPEN_REPORT:
      return {
        ...state,
        isOpen: false,
        isOpenCompanies: false,
        isOpenConversation: false,
        isOpenRelationship: false,
        isOpenRelationshipAgente: false,
        isOpenCampaign: false,
        isOpenTickets: false,
        isOpenCompany: false,
        isOpenConfigurations: false,
        isOpenConfigurationsAgente: false,
        isOpenConfigurationsSupervisor: false,
        isOpenReport: action.isOpenReport,
      };
    case Types.IS_OPEN_CONFIGURATIONS:
      return {
        ...state,
        isOpen: false,
        isOpenCompanies: false,
        isOpenConversation: false,
        isOpenRelationship: false,
        isOpenRelationshipAgente: false,
        isOpenCampaign: false,
        isOpenTickets: false,
        isOpenReport: false,
        isOpenCompany: false,
        isOpenConfigurationsAgente: false,
        isOpenConfigurationsSupervisor: false,
        isOpenConfigurations: action.isOpenConfigurations,
      };
    case Types.IS_OPEN_CONFIGURATIONS_AGENTE:
      return {
        ...state,
        isOpen: false,
        isOpenCompanies: false,
        isOpenConversation: false,
        isOpenRelationship: false,
        isOpenRelationshipAgente: false,
        isOpenCampaign: false,
        isOpenTickets: false,
        isOpenReport: false,
        isOpenCompany: false,
        isOpenConfigurations: false,
        isOpenConfigurationsSupervisor: false,
        isOpenConfigurationsAgente: action.isOpenConfigurationsAgente,
      };
    case Types.IS_OPEN_CONFIGURATIONS_SUPERVISOR:
      return {
        ...state,
        isOpen: false,
        isOpenCompanies: false,
        isOpenConversation: false,
        isOpenRelationship: false,
        isOpenRelationshipAgente: false,
        isOpenCampaign: false,
        isOpenTickets: false,
        isOpenReport: false,
        isOpenCompany: false,
        isOpenConfigurations: false,
        isOpenConfigurationsAgente: false,
        isOpenConfigurationsSupervisor: action.isOpenConfigurationsSupervisor,
      };
    case Types.IS_OPEN_COMPANY:
      return {
        ...state,
        isOpen: false,
        isOpenCompanies: false,
        isOpenConversation: false,
        isOpenRelationship: false,
        isOpenRelationshipAgente: false,
        isOpenCampaign: false,
        isOpenTickets: false,
        isOpenReport: false,
        isOpenConfigurations: false,
        isOpenConfigurationsAgente: false,
        isOpenConfigurationsSupervisor: false,
        isOpenCompany: action.isOpenCompany,
      };
    default:
      return state;
  }
}

export const Creators = {
  closeTour: () => (dispatch) => {
    dispatch({ type: Types.IS_CLOSE, isOpen: false });
  },
  openTour: () => (dispatch) => {
    dispatch({ type: Types.IS_OPEN, isOpen: true });
  },
  closeTourCompanies: () => (dispatch) => {
    dispatch({ type: Types.IS_OPEN_COMPANIES, isOpenCompanies: false });
  },
  openTourCompanies: () => (dispatch) => {
    dispatch({ type: Types.IS_OPEN_COMPANIES, isOpenCompanies: true });
  },
  closeTourConversation: () => (dispatch) => {
    dispatch({ type: Types.IS_OPEN_CONVERSATION, isOpenConversation: false });
  },
  openTourConversation: () => (dispatch) => {
    dispatch({ type: Types.IS_OPEN_CONVERSATION, isOpenConversation: true });
  },
  closeTourRelationship: () => (dispatch) => {
    dispatch({ type: Types.IS_OPEN_RELATIONSHIP, isOpenRelationship: false });
  },
  openTourRelationship: () => (dispatch) => {
    dispatch({ type: Types.IS_OPEN_RELATIONSHIP, isOpenRelationship: true });
  },
  closeTourRelationshipAgente: () => (dispatch) => {
    dispatch({
      type: Types.IS_OPEN_RELATIONSHIP_AGENTE,
      isOpenRelationshipAgente: false,
    });
  },
  openTourRelationshipAgente: () => (dispatch) => {
    dispatch({
      type: Types.IS_OPEN_RELATIONSHIP_AGENTE,
      isOpenRelationshipAgente: true,
    });
  },
  closeTourCampaign: () => (dispatch) => {
    dispatch({ type: Types.IS_OPEN_CAMPAIGN, isOpenCampaign: false });
  },
  openTourCampaign: () => (dispatch) => {
    dispatch({ type: Types.IS_OPEN_CAMPAIGN, isOpenCampaign: true });
  },
  closeTourTickets: () => (dispatch) => {
    dispatch({ type: Types.IS_OPEN_TICKETS, isOpenTickets: false });
  },
  openTourTickets: () => (dispatch) => {
    dispatch({ type: Types.IS_OPEN_TICKETS, isOpenTickets: true });
  },
  closeTourReport: () => (dispatch) => {
    dispatch({ type: Types.IS_OPEN_REPORT, isOpenReport: false });
  },
  openTourReport: () => (dispatch) => {
    dispatch({ type: Types.IS_OPEN_REPORT, isOpenReport: true });
  },
  closeTourConfigurations: () => (dispatch) => {
    dispatch({
      type: Types.IS_OPEN_CONFIGURATIONS,
      isOpenConfigurations: false,
    });
  },
  openTourConfigurations: () => (dispatch) => {
    dispatch({
      type: Types.IS_OPEN_CONFIGURATIONS,
      isOpenConfigurations: true,
    });
  },
  closeTourConfigurationsAgente: () => (dispatch) => {
    dispatch({
      type: Types.IS_OPEN_CONFIGURATIONS_AGENTE,
      isOpenConfigurationsAgente: false,
    });
  },
  openTourConfigurationsAgente: () => (dispatch) => {
    dispatch({
      type: Types.IS_OPEN_CONFIGURATIONS_AGENTE,
      isOpenConfigurationsAgente: true,
    });
  },
  closeTourConfigurationsSupervisor: () => (dispatch) => {
    dispatch({
      type: Types.IS_OPEN_CONFIGURATIONS_SUPERVISOR,
      isOpenConfigurationsSupervisor: false,
    });
  },
  openTourConfigurationsSupervisor: () => (dispatch) => {
    dispatch({
      type: Types.IS_OPEN_CONFIGURATIONS_SUPERVISOR,
      isOpenConfigurationsSupervisor: true,
    });
  },
  closeTourCompany: () => (dispatch) => {
    dispatch({
      type: Types.IS_OPEN_COMPANY,
      isOpenCompany: false,
    });
  },
  openTourCompany: () => (dispatch) => {
    dispatch({
      type: Types.IS_OPEN_COMPANY,
      isOpenCompany: true,
    });
  },
};
