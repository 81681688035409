import React from "react";
import { Grid, Icon } from "semantic-ui-react";

export default () => (
  <Grid>
    <h2>Origem do chamado</h2>
    <p>
      <Icon name="chat" color="green" size="large" /> Se a origem do ticket foi
      aberto pelo monitchat aparecerá este ícone
    </p>
    <p>
      <Icon name="whatsapp" color="green" size="large" /> Se a origem do ticket
      foi aberto pelo whatsapp, aparecerá o símbolo do whatsapp
    </p>
    <p>
      <Icon name="phone" color="black" rotated="clockwise" size="large" /> Se a
      origem do ticket foi aberto por telefone, aparecerá este símbolo do
      telefone. Detalhe que quando chega o contato por telefone aparece uma
      faixa vermelha debaixo desta conversa.
    </p>
  </Grid>
);
