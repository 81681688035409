import React from "react";
import { Grid } from "semantic-ui-react";

export default () => (
  <Grid>
    <Grid.Row>
      <h2>Sessões de Login</h2>
      <p>
        <strong>
          Neste Menu ficará todos relatório de sessões de login, os usuários que
          estão logando no sistema, junto com o seu horário e data.
        </strong>
      </p>
    </Grid.Row>
  </Grid>
);
