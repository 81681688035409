import React, { Component } from "react";
import api from "../../api/contact";
import { Dropdown } from "semantic-ui-react";
import AddContactModal from "./modal/AddContactModal";

class DropdownContacts extends Component {
  state = {
    contact: {
      name: "",
      phone_number: "",
    },
    options: [],
    loading: false,
    search: "",
    openModal: false,
  };

  onSearchChange = (e, { searchQuery }) => {
    clearTimeout(this.timer);
    this.setState({ search: searchQuery });
    this.timer = setTimeout(this.onSearchContact, 800);
  };

  componentDidUpdate(props, state) {
    if (this.props.options) {
      if (this.state.options.length == 0 && this.props.options.length > 0) {
        const { options } = this.props;

        if (options) {
          this.setState({
            options: options.map((c) => ({
              key: c.id || c.key,
              value: c.id || c.value,
              text: `${c.name} - ${c.phone_number}` || c.text,
              image: { avatar: true, src: c.avatar } || {avatar: true, src: c.avatar},
            })),
          });
        }
      }
    }
  }

  componentDidMount() {
    if (this.props.options) {
      this.setState({
        options: this.props.options.map((c) => ({
          key: c.id || c.key,
          value: c.id || c.key,
          text: c.text || `${c.name} - ${c.phone_number}`,
          image: c.image || { avatar: true, src: c.avatar },
        })),
      });
    }
  }

  onSearchContact = async () => {
    this.setState({ loading: true });
    const { search } = this.state;

    await api.contact.fetchAll({ search }).then((contacts) => {
      this.setState({
        options: this.state.options.concat(
          contacts.data
            .filter((el) => {
              return !this.state.options.some((a) => a.key === el.id);
            })
            .map((c) => ({
              key: c.id || c.key,
              value: c.id || c.key,
              text: c.text || `${c.name} - ${c.phone_number}`,
              image: c.image || { avatar: true, src: c.avatar },
            }))
        ),
        loading: false,
      });
    });
  };

  onChange = (e, { name, value }) => {
    this.setState((state) => ({
      contact: { ...state.contact, [name]: value },
    }));
  };

  cleanErrors = () => {
    this.setState({ errors: "" });
  };

  submit = () => {
    const { contact } = this.state;

    this.setState({
      loading: true,
    });

    return api.contact
      .submit(contact)
      .then((data) => {
        this.setState((state) => ({
          options: [...state.options].concat({
            key: data.data.id,
            value: data.data.id,
            text: data.data.name,
          }),
          loading: false,
          contact: {
            name: "",
            phone_number: "",
          },
          openModal: !state.openModal,
        }));
        this.props.onSelectContact(data.data.id);
      })
      .catch((err) => {
        this.setState({
          loading: false,
          errors: err.response,
        });
      });
  };

  toggle = (e, { name, value }) => {
    this.setState((state) => ({
      openModal: !state.openModal,
      contact: {
        name: value,
        phone_number: "",
      },
    }));
  };

  render() {
    const { options, loading, openModal, contact } = this.state;
    const { multiple, name, style = {} } = this.props;

    return (
      <div>
        <Dropdown
          placeholder="Pesquise um contato"
          fluid
          search
          selection
          scrolling
          allowAdditions
          multiple={multiple}
          additionLabel="Adicionar: "
          name={name}
          value={this.props.contact_id}
          onSearchChange={this.onSearchChange}
          onChange={(e, { value, name }) =>
            this.props.onSelectContact(value, this.state.options, {target: {name, value}})
          }
          style={style}
          options={options}
          onAddItem={this.toggle}
          closeOnChange
          loading={loading || this.props.loading}
          clearable={this.props.clearable || true}
        />
        <AddContactModal
          open={openModal}
          contact={contact}
          save={this.submit}
          toggle={this.toggle}
          onChange={this.onChange}
        />
      </div>
    );
  }
}

export default DropdownContacts;
