import React from "react";
import { Grid } from "semantic-ui-react";

export default () => (
  <Grid>
    <Grid.Row>
      <h2>Relatórios</h2>
      <p>
        <strong>
          Esta Área ainda está sendo contruída, então vou passar rapidinho só
          pra saber o que poderá ser visto por aqui.
        </strong>
      </p>
    </Grid.Row>
  </Grid>
);
