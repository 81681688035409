import React from "react";
import MainContainer from "../main-container/MainContainer";
import LoaderComponent from "../semantic/Loading";

const SplashScreen = () => {
  return (
    <MainContainer>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <LoaderComponent />
      </div>
    </MainContainer>
  );
};

export default SplashScreen;
