import axios from "../services/api";

export default {
	client: {
		fetchAll: params =>
			axios.get(`/client`, { params }).then(res => res.data),
		fetchClient: id => axios.get(`/client/${id}`).then(res => res.data),
		fetchData: params =>
			axios.get(`/client`, { params }).then(res => res.data.data),
		delete: id => axios.delete(`/client/${id}`).then(res => res.data),
		update: (id, client) =>
			axios.put(`/client/${id}`, client).then(res => res.data),
		submit: client => axios.post(`/client`, client).then(res => res.data)
	}
};
