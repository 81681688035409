import React, { Component } from "react";
import { Modal } from "semantic-ui-react";

class Support extends Component {
	render() {
		return (
			<Modal trigger={<a href="/#">Central de Suporte</a>}>
				<Modal.Header>Central de Suporte</Modal.Header>
				<Modal.Content>
					<Modal.Description>
						<div className="videos_tutoriais">
							<ul>
								<li>Conhecendo a Plataforma Monitchat</li>
								<li>Criando seus Usuários</li>
								<li>Conectando seu Whatsapp</li>
								<li>Configurando sua Plataforma</li>
								<li>Personalizando sua plataforma</li>
								<li>Meu primeiro ticket</li>
								<li>Atendendo um Ticket</li>
								<li>Comentando um ticket</li>
								<li>Encaminhando um ticket</li>
								<li>Criando uma Tarefa</li>
								<li>Criando um Ticket</li>
								<li>Criando um grupo de contatos</li>
								<li>Criando uma campanha</li>
								<li>Analisando os relatórios</li>
								<li>
									Analisando sua tela inicial (estatísticas)
								</li>
							</ul>
						</div>
					</Modal.Description>
				</Modal.Content>
			</Modal>
		);
	}
}

export default Support;
