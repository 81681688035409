import React, { Component } from "react";
import { Header, Modal } from "semantic-ui-react";

class PrivacyPolicy extends Component {
	render() {
		return (
			<Modal trigger={<a href="/#">Política de Privacidade</a>}>
				<Modal.Header>Política de Privacidade</Modal.Header>
				<Modal.Content>
					<Modal.Description>
						<Header>COMPARTILHAMENTO DE DADOS</Header>

						<p>
							Todos os dados inseridos em nossas páginas serão
							enviados de forma segura aos nossos bancos de dados
							instalados em servidores protegidos. Nenhuma
							informação inserida em nosso site será divulgada ou
							compartilhada com terceiros fora do Monitchat,
							exceto nos casos de requerimento de autoridades
							judiciais ou governamentais competentes para fins de
							investigações pessoais conduzidas por elas.
						</p>

						<Header>DADOS INSERIDOS NO SOFTWARE</Header>

						<p>
							Apenas usuários da empresa contratante poderão ter
							acesso aos dados. No caso da necessidade por parte
							de nossos especialistas em acessar dados dos nossos
							clientes para suporte e manutenção, isso somente
							será feito com devida autorização dos detentores dos
							dados.
						</p>

						<Header>SEGURANÇA DOS DADOS</Header>

						<p>
							Os dados informados em nosso site são armazenados em
							um banco de dados com acesso restrito aos nossos
							especialistas, que são obrigados a manter a
							confidencialidade das informações e não utilizá-las
							de forma indevida.
						</p>

						<Header>INTEGRAÇÃO COM GMAIL</Header>

						<p>
							Caso esta integração seja ativada, o sistema será
							capaz de enviar e-mails através de sua conta e
							acessar sua caixa de entrada. A primeira permissão,
							de envio, possibilita que o usuário componha a
							mensagem e possa enviá-la de dentro do sistema. A
							segunda permissão, de leitura, permite que o
							Monitchat identifique os e-mails recebidos a partir
							do momento da integração e armazene em nosso banco
							de dados o conteúdo das mensagens, bem como o
							identificador de cada anexo para que possam ser
							baixados pelo usuário diretamente através do
							sistema. As mensagens são armazenadas para que
							possam ser vinculadas ao histórico dos clientes,
							dependendo das configurações do usuário. Os dados
							obtidos com esta integração não serão divulgados nem
							acessados por terceiros. Não utilizamos os dados
							para nenhum tipo de uso comercial nem análise de
							comportamento. Os dados terão utilização exclusiva
							pessoal dentro do software.
						</p>

						<Header>INTEGRAÇÃO COM GOOGLE CALENDAR</Header>

						<p>
							Caso esta integração seja ativada, o sistema
							solicitará acesso à leitura e criação de eventos
							para que seu calendário seja sincronizado com o
							calendário da plataforma. Imediatamente após a
							ativação da integração, todos os eventos com datas
							futuras serão armazenados em nosso banco de dados
							para que possam ser exibidos no calendário. Cada
							evento criado no Google Calendar será armazenado
							como uma tarefa no Monitchat. O Monitchat analisará
							cada evento para identificar os participantes
							relacionados e assim poder vincular com o histórico
							de sua base de clientes. Cada tarefa criada no
							Monitchat também será criada como um evento no
							Google Calendar. Os contatos relacionados da tarefa
							poderão ser enviados como participantes do evento
							caso o usuário selecione esta opção. Os dados
							obtidos com esta integração não serão divulgados nem
							acessados por terceiros. Não utilizamos os dados
							para nenhum tipo de uso comercial nem análises de
							comportamento. Os dados terão utilização exclusiva
							pessoal dentro do software.
						</p>

						<Header>USO DAS INFORMAÇÕES COLETADAS</Header>

						<p>
							Acompanharemos os visitantes do nosso site, colhendo
							informações sobre páginas visitadas dentro dele e
							tempo gasto em cada uma delas. Essas informações
							aparecem para nós de forma anônima, ou seja, não
							sabemos quem são nossos visitantes. A intenção é
							analisar esses dados em busca de uma melhor
							experiência para o usuário em nosso site.
						</p>

						<Header>INFORMAÇÕES ADICIONAIS</Header>

						<p>
							Nunca enviaremos e-mails ou ligaremos pedindo aos
							nossos usuários informações sobre senha de acesso ao
							sistema ou informações sobre cartão de crédito ou
							qualquer outro meio de pagamento.
						</p>
					</Modal.Description>
				</Modal.Content>
			</Modal>
		);
	}
}

export default PrivacyPolicy;
