import React from "react";
import { Grid } from "semantic-ui-react";

export default () => (
  <Grid>
    <Grid.Row>
      <h2>Relacionamento</h2>
      <p>
        Veremos em relacionamentos, Contatos, Clientes, Grupo de Contatos e
        Contatos Restritos
      </p>{" "}
      <p>
        <strong>Contatos:</strong> Aqui onde mostra todos seus contatos
        cadastratos na base de dados.
      </p>
      <p>
        <strong>Clientes:</strong> Aqui aparecerá todos os clientes PJ que você
        cadastrou na base dedados
      </p>
      <p>
        <strong>Grupo de Contatos:</strong> Aparecerá os grupos de contatos,
        onde poderá cadastrar mais grupos ou ver os grupos existentes.
      </p>
      <p>
        <strong>Contatos Restritos:</strong> Aqui você cadastra contatos para
        bloquear eles, eles não poderão entrar em contato a menos que tire eles
        de contatos restritos.
      </p>
    </Grid.Row>
  </Grid>
);
