import React from "react";
import { Grid } from "semantic-ui-react";

export default () => (
  <Grid>
    <Grid.Row>
      <h2>Edição</h2>
      <p>
        Nesta coluna você poderá editar a campanha, se ela não tiver finalizada
        ou em andamento, excluir a campanha ou selecionar para excluir a
        campanha.
      </p>
    </Grid.Row>
  </Grid>
);
