import React from "react";
import { Modal, Button, Icon } from "semantic-ui-react";
import FormWebhook from "../forms/FormWebhook";

class WebhookModal extends React.Component {
  componentDidMount() {
    document.removeEventListener("keydown", () => { });
    
  }

  save = () => {
    if (this.props.webhook.id) {
      this.props.onClickSave();
    } else {
      this.props.onClickAdd();
    }
  };

  render() {
    const {
      webhook,
      handleClose,
      onChange,
      open,
      modalHeader = "",
      loading,
    } = this.props;

    return (
      <Modal
        size="large"
        closeIcon
        open={open}
        onClose={handleClose}
        dimmer="blurring"
        closeOnDimmerClick={false}
      >
        <Modal.Header>{modalHeader}</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <div className="form-webhook">
              <FormWebhook webhook={webhook} onChange={onChange} />
            </div>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button.Group>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button.Or />
            <Button
              positive
              onClick={this.save}
              loading={loading}
              disabled={loading}
            >
              Salvar
            </Button>
          </Button.Group>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default WebhookModal;
