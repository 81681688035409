import axios from "../services/api";

export default {
  invoice: {
    fetchAll: (params) =>
      axios.get(`/company-pricing-plan`, { params }).then((res) => res.data),
    fetchOrigin: (params) =>
      axios
        .get(`/origin-company-pricing-plan`, { params })
        .then((res) => res.data),
    fetchData: (params) =>
      axios
        .get(`/company-pricing-plan`, { params })
        .then((res) => res.data.data),
    fetchDataTake: (take = 100, skip = 0) =>
      axios
        .get(`/company-pricing-plan/?take=${take}&skip=${skip}`)
        .then((res) => res.data.data),
    delete: (id) =>
      axios.delete(`/company-pricing-plan/${id}`).then((res) => res.data),
    update: (id, invoice) =>
      axios.put(`/company-pricing-plan/${id}`, invoice).then((res) => res.data),
    submit: (invoice) =>
      axios.post(`/company-pricing-plan`, invoice).then((res) => res.data),
  },
};
