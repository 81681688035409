import React, { Component } from "react";
import { Dropdown } from "semantic-ui-react";

class DropdownInterruption extends Component {
	state = {
		interruption: {
			name: "",
			duration: ""
		},
		options: [],
		loading: false,
		search: "",
		openModal: false
	};

	handleInterruptionAddition = e => {};

	onChange = (e, { name, value }) => {
		this.setState(state => ({
			interruption: { ...state.interruption, [name]: value }
		}));
	};

	cleanErrors = () => {
		this.setState({ errors: "" });
	};

	toggle = (e, { name, value }) => {
		this.setState(state => ({
			openModal: !state.openModal,
			interruption: {
				name: value,
				phone_number: ""
			}
		}));
	};

	render() {
		const { loading } = this.state;
		const { name, interruptions } = this.props;

		return (
			<div>
				<Dropdown
					placeholder="Selecione um Motivo"
					fluid
					search
					selection
					scrolling
					additionLabel="Adicionar: "
					className="dropdown_interruption"
					name={name}
					value={this.props.interruption_id}
					onChange={(e, { value }) =>
						this.props.onSelectInterruption(value)
					}
					options={interruptions}
					closeOnChange
					loading={loading}
					clearable
				/>
			</div>
		);
	}
}

export default DropdownInterruption;
