import React from "react";
import { Grid } from "semantic-ui-react";

export default () => (
  <Grid>
    <Grid.Row>
      <h2>Tickets</h2>
      <p>Aqui vou te apresentar a página de tickets</p>
    </Grid.Row>
  </Grid>
);
