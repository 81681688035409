import React from "react";
import {
  Form,
  Input,
  Checkbox,
  TextArea,
  Icon,
  Loader,
  Grid,
  Progress,
  Label,
} from "semantic-ui-react";
import qrcode from "../../../assets/img/qrcode.jpg";

const FormSocialMedia = ({
  media,
  onChange,
  onChecked,
  createQrCode,
  loadingQrCode,
  account_number,
  error = null,
  qrCodeCreated,
  onChangeAccount = () => { },
  qrCodeImage,
  loginSuccess,
  qrCodeRead,
  phone_number,
}) => {
  return (
    <Form size="mini">
      {media.id || media.whatsapp_official_account ? (
        <Grid columns="equal">

          <Grid.Row>
            <Grid.Column>
              {media.whatsapp_official_account ? (

                <Form.Field
                  inline
                  autoComplete="off"
                  control={Input}
                  label="Número da conta:"
                  name="phone_number"
                  width="6"
                  onChange={onChange}
                  value={media.phone_number}
                />

              ) : (

                  <div>
                    <span className="numero_da_conta">{media.phone_number}</span>
                    <span
                      className="numero_da_conta"
                      name="phone_number"
                      onChange={onChange}
                    >
                      {phone_number}
                    </span>
                  </div>

                )}
            </Grid.Column>
            <Grid.Column >
              {media.whatsapp_official_account ? (
                <Form.Field
                  inline
                  autoComplete="off"
                  control={Input}
                  label="Api Key:"
                  name="whatsapp_account_key"
                  width="6"
                  onChange={onChange}
                  value={media.whatsapp_account_key}
                />
              ) : null}
            </Grid.Column>
          </Grid.Row>
          {media.whatsapp_official_account ? (<Grid.Row>
            <Grid.Column>

              <Form.Field
                fluid
                autoComplete="off"
                control={Input}
                label="Account Id: (para cadastro direto no facebook)"
                name="whatsapp_account_id"
                width="6"
                onChange={onChange}
                value={media.whatsapp_account_id}
              />

            </Grid.Column>
            <Grid.Column>

              <Form.Field
                inline
                autoComplete="off"
                control={Input}
                label="Business Id: (para cadastro direto no facebook)"
                name="whatsapp_business_account_id"
                width="6"
                onChange={onChange}
                value={media.whatsapp_business_account_id}
              />

            </Grid.Column>
          </Grid.Row>) : null}
          {media.whatsapp_official_account ? (<Grid.Row>
            <Grid.Column>

              <Form.Field
                autoComplete="off"
                control={Input}
                label="Id Projeto (360 Dialog)"
                name="project_id_360"
                width="6"
                onChange={onChange}
                value={media.project_id_360}
              />

            </Grid.Column>
            <Grid.Column>

              <Form.Field
                autoComplete="off"
                control={Input}
                label="Id Cliente (360 Dialog)"
                name="client_id_360"
                width="6"
                onChange={onChange}
                value={media.client_id_360}
              />

            </Grid.Column>
          </Grid.Row>) : null}
          {media.whatsapp_official_account ? (<Grid.Row>
            <Grid.Column>

              <Form.Field
                autoComplete="off"
                control={Input}
                label="Channel Id (360 Dialog)"
                name="channel_id"
                width="6"
                onChange={onChange}
                value={media.channel_id}
              />

            </Grid.Column>

            <Grid.Column>

              <Form.Field>
                <label>Uso da Franquia</label>
                <Progress
                  autoSuccess
                  label={`${media.current_percentual_usage} %`}
                  percent={media.current_percentual_usage}
                  title={`Consumo da franquia ${media.current_percentual_usage}`}
                  indicating
                  size="tiny"
                />
              </Form.Field>

            </Grid.Column>

          </Grid.Row>) : null}
          <Grid.Row>
            
              <Grid.Column>
                <Form.Field
                  autoComplete="off"
                  control={Input}
                  label="Nome do Assistente Virtual:"
                  name="virtual_assistant"
                  width="6"
                  onChange={onChange}
                  placeholder="Assistente Virtual"
                  value={media.virtual_assistant}
                />
              </Grid.Column>
              
              <Grid.Column>
                <Form.Field
                  autoComplete="off"
                  control={Input}
                  label="Franquia para marketing:"
                  name="marketing_quota"
                  width="6"
                  onChange={onChange}
                  placeholder="200"
                  value={media.marketing_quota}
                />
              </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Form.Field>
                <Checkbox
                  label="Resposta Automática"
                  name="auto_reply"
                  checked={
                    media.auto_reply === 1 || media.auto_reply === true
                      ? true
                      : false
                  }
                  onChange={onChecked}
                />
              </Form.Field>
            </Grid.Column>
            
            {media.whatsapp_official_account ? <Grid.Column>
              <Form.Field>
                <Checkbox
                  label="Cloud API"
                  name="whatsapp_cloud_api"
                  checked={
                    media.whatsapp_cloud_api === 1 || media.whatsapp_cloud_api === true
                      ? true
                      : false
                  }
                  onChange={onChecked}
                />
              </Form.Field>
            </Grid.Column> : null }

          </Grid.Row>
          {media.auto_reply ? (<Grid.Row>
            <Grid.Column>
              <Form.Field
                fluid
                control={TextArea}
                label="Texto para mensagem automática:"
                name="auto_reply_message"
                onChange={onChange}
                value={media.auto_reply_message}
              />

            </Grid.Column>
          </Grid.Row>) : null}
          <Grid.Row>
            <Grid.Column>
              <Form.Field>
                <Checkbox
                  label="Envio em Massa?"
                  name="can_spam"
                  checked={
                    media.can_spam === 1 || media.can_spam === true ? true : false
                  }
                  onChange={onChecked}
                />
              </Form.Field>
            </Grid.Column>
            <Grid.Column>
              <Form.Field>
                <Checkbox
                  label="Pode Responder?"
                  name="can_reply"
                  checked={
                    media.can_reply === 1 || media.can_reply === true ? true : false
                  }
                  onChange={onChecked}
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Form.Field>
                <Checkbox
                  label="Atendimento Automatizado?"
                  name="intelliway_integration"
                  checked={
                    media.intelliway_integration === 1 ||
                      media.intelliway_integration === true
                      ? true
                      : false
                  }
                  onChange={onChecked}
                />
              </Form.Field>
            </Grid.Column>
            <Grid.Column>
              <Form.Field>
                <Checkbox
                  label="Enviar email quando a conta perder autenticação?"
                  name="send_email_on_deauthentication"
                  checked={
                    media.send_email_on_deauthentication === 1 ||
                      media.send_email_on_deauthentication === true
                      ? true
                      : false
                  }
                  onChange={onChecked}
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>

        </Grid >

      ) : (

          <Form.Field
            autoComplete="off"
            control={Input}
            label="Número do Telefone:"
            name="account_number"
            width="6"
            placeholder="Ex: 552730109500"
            onChange={onChangeAccount}
            error={error && error.phone_number ? {

              content: error.phone_number,
              pointing: 'below',

            } : null}
            value={account_number}
          />

        )}
      {!media.whatsapp_official_account ? (
        media.id ? (
          <div className="holder_social" style={{ float: "left" }}>
            <div className="bloco_qrcode" style={{ margin: 0, marginTop: 10 }}>
              {loadingQrCode && (
                <div className="loading_qrcode">
                  <Loader active inline="centered" size="huge" />
                </div>
              )}
              <img
                src={qrCodeCreated ? qrCodeImage : qrcode}
                alt=""
                className={
                  !qrCodeCreated || loginSuccess
                    ? loadingQrCode
                      ? "apagado"
                      : "inativo"
                    : ""
                }
              />
              {media.connected === 1 ? (
                ""
              ) : (
                  <div>
                    {!qrCodeCreated && !loadingQrCode && (
                      <button onClick={createQrCode}>
                        <Icon name="redo alternate" color="white" />
                      Clique para carregar o código QR Code
                      </button>
                    )}
                  </div>
                )}

              {media.connected === 1 ? (
                <div className="conectado">
                  <svg
                    className="checkmark"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 52 52"
                  >
                    <circle
                      className="checkmark__circle"
                      cx="26"
                      cy="26"
                      r="25"
                      fill="none"
                    />
                    <path
                      className="checkmark__check"
                      fill="none"
                      d="M14.1 27.2l7.1 7.2 16.7-16.8"
                    />
                  </svg>
                </div>
              ) : (
                  ""
                )}
              {loginSuccess && (
                <div className="conectado">
                  <svg
                    className="checkmark"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 52 52"
                  >
                    <circle
                      className="checkmark__circle"
                      cx="26"
                      cy="26"
                      r="25"
                      fill="none"
                    />
                    <path
                      className="checkmark__check"
                      fill="none"
                      d="M14.1 27.2l7.1 7.2 16.7-16.8"
                    />
                  </svg>
                </div>
              )}
            </div>
          </div>
        ) : (
            <div className="holder_social full">
              <div className="bloco_qrcode">
                {loadingQrCode && (
                  <div className="loading_qrcode">
                    <Loader active inline="centered" size="huge" />
                  </div>
                )}

                {qrCodeRead ? (
                  <div className="loading_qrcode">
                    <Loader active inline="centered" size="huge" />
                  </div>
                ) : null}

                <img
                  src={qrCodeCreated ? qrCodeImage : qrcode}
                  alt=""
                  className={
                    qrCodeRead
                      ? "inativo"
                      : !qrCodeCreated || loginSuccess
                        ? loadingQrCode
                          ? "apagado"
                          : "inativo"
                        : ""
                  }
                />

                {!qrCodeRead
                  ? !qrCodeCreated &&
                  !loadingQrCode && (
                    <button onClick={createQrCode}>
                      <Icon name="redo alternate" color="white" />
                      Clique para carregar o código QR Code
                    </button>
                  )
                  : null}
                {loginSuccess && (
                  <div className="conectado">
                    <svg
                      className="checkmark"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 52 52"
                    >
                      <circle
                        className="checkmark__circle"
                        cx="26"
                        cy="26"
                        r="25"
                        fill="none"
                      />
                      <path
                        className="checkmark__check"
                        fill="none"
                        d="M14.1 27.2l7.1 7.2 16.7-16.8"
                      />
                    </svg>
                  </div>
                )}
              </div>
              {loadingQrCode ? (
                <div className="instrucoes">
                  <p>Gerando QrCode</p>
                  <ul>
                    <li>Aguarde vinte segundos para a conexão se estabelecer.</li>
                  </ul>
                </div>
              ) : !qrCodeRead ? (
                <div className="instrucoes">
                  <p>Autenticação Whatsapp</p>
                  <ul>
                    <li>Aguarde o código aparecer na tela.</li>
                    <li>Leia o código com o Whatsapp no seu celular.</li>
                    <li>Aguarde vinte segundos para a conexão se estabelecer.</li>
                  </ul>
                </div>
              ) : (
                    <div className="instrucoes">
                      <p>Carregando Mensagens</p>
                      <ul>
                        <li>Aguarde enquanto carregamos suas mensagens!</li>
                        <li>Esse processo podelevar alguns minutos.</li>
                        <li>Mantanha seu telefone conectado</li>
                      </ul>
                    </div>
                  )}
            </div>
          )
      ) : null}



    </Form >
  );
};

export default FormSocialMedia;
