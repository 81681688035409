import api from "../../api/social-media";

// Types declaration
export const Types = {
  IS_FETCHING: "social-media/IS_FETCHING",
  IS_FETCHED: "social-media/IS_FETCHED",
  FETCHED: "social-media/FETCHED",
  ON_ERROR: "social-media/ON_ERROR",
};

// Reducer
const INITIAL_STATE = {
  records: [],
  columns: {},
  clients: [],
  groups: [],
  order: {},
};

export default function conversation(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case Types.FETCHED:
      return {
        ...state,
        records: action.data.data,
        order: action.data.order,
        columns: action.data.columns,
        total_records: action.data.total_records,
        loading: false,
      };
    case Types.IS_FETCHING:
      return { ...state, loading: action.loading };
    case Types.IS_FETCHED:
      return {
        ...state,
        records: action.payload.data,
        order: action.payload.order,
        columns: action.payload.columns,
        total_records: action.payload.total_records,
        loading: false,
      };
    case Types.ON_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
}

// Actions
export const Creators = {
  accounts: (params) => (dispatch) => {
    dispatch({ type: Types.IS_FETCHING, loading: true });
    api.media
      .fetchAll()
      .then((res) => dispatch({ type: Types.IS_FETCHED, payload: res }))
      .catch((err) => dispatch(Creators.error(err.response)));
  },
  error: (data) => ({
    type: Types.ON_ERROR,
    error: data,
  }),
  fetchAll: () => (dispatch) =>
    api.media
      .fetchAll()
      .then((media) => dispatch({ type: Types.FETCHED, data: media }))
      .catch((err) => dispatch(Creators.error(err.response))),
};
