import React from "react";
import { Grid } from "semantic-ui-react";

export default () => (
  <Grid>
    <Grid.Row>
      <h2>Integrações</h2>
      <p>
        Aqui é onde se cadastra os parâmetros e servidores e cadastra o
        webhooks. no caso do servidor e webhooks são configurações do sistema
        não é necessário mexer.
      </p>
    </Grid.Row>
  </Grid>
);
