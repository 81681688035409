import React from "react";
import { Grid, Icon } from "semantic-ui-react";

export default () => (
  <Grid>
    <Grid.Row>
      <h2>Ações</h2>
      <p>Todas as ações possíveis nesta coluna são:</p>
      <p>
        <Icon name="comment" color="blue" /> Clicando aqui você verá os detalhes
        das mensagens, pra quem enviou e seu status, se está enviado, se está
        entregue e se foi lido, em caso de falha você poderá reeviar a mesnsagem
        para o contato que não foi entregue.
      </p>
      <p>
        <Icon name="copy" color="blue" /> Clicando aqui você faz uma cópia da
        campanha e já programa ela pra ocorrer na data selecionada
      </p>
      <p>
        <Icon name="edit" color="blue" /> Clicando aqui você edita a campanha,
        só da pra editar uma campanha que não foi inicializada
      </p>
      <p>
        <Icon name="trash" color="red" /> Clicando aqui você exclui a campanha
      </p>
    </Grid.Row>
  </Grid>
);
