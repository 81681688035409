import React from "react";
import { Grid } from "semantic-ui-react";

export default () => (
  <Grid>
    <h1>Mensagens rápidas</h1>
    <p>
      Não é difícil nos depararmos com mensagens padrões não é mesmo, então
      pensando nisso a nossa plataforma deixa você cadastrar essas mensagens que
      você teria que escrever e agora fica a um clique de distância!!!
    </p>
    <p>Fácil não, é só clicar no botão + e pronto terá acesso!</p>
    <p>
      Lá você vai poder colocar o título da mensagem, o texto e você poderá usar
      o conceito de variável que é alguns atalhos que nós da Vipphone preparamos
      com carinho pra vocês...
    </p>
    <p>
      A variável funciona da seguinte forma, você coloca sua mensagem do tipo
      "Bom dia <strong>variávelNomeContato</strong>, segue a informação
      desejada" e o sistema já vai entender que você quer enviar o nome do
      contato!!! incrível né!
    </p>
    <p>
      Como se declara a varável está na guia, tem um botão com todas elas e a
      forma que se declara.
    </p>
  </Grid>
);
