import React from "react";
import { Grid } from "semantic-ui-react";

export default () => (
  <Grid>
    <Grid.Row>
      <h2>Faturas</h2>
      <p>
        Aqui irá ficar as suas faturas em aberto e as já pagas com o status.
      </p>
    </Grid.Row>
  </Grid>
);
