import React from "react";
import { Grid } from "semantic-ui-react";

export default () => (
  <Grid>
    <Grid.Row>
      <h2>Financeiro</h2>
      <p>Aqui irá ficar as suas informações sobre o financeiro!</p>
    </Grid.Row>
  </Grid>
);
