import React from "react";
import { Grid } from "semantic-ui-react";

export default () => (
  <Grid>
    <Grid.Row>
      <h2>Campos de Formulário</h2>
      <p>
        Aqui irá cadastrar campos de formulário adicionais em cadastros pré
        existentes, por exemplo se quiser o site do cliente no cadastro do
        cliente, aqui você adiciona este campo.
      </p>
    </Grid.Row>
  </Grid>
);
