import React, { Component } from "react";
import { Link } from "react-router-dom";
import "react-dropdown/style.css";
import { mdiArrowRightThick, mdiMenu } from "@mdi/js";
import Icon from "@mdi/react";
import Perfil from "./Perfil";
import logo from "../../assets/img/logogreen.png";
import Icons from "./Icons";

function toggleClass() {
  const element = document.getElementsByName("chat");
  for (let i = 0; i < element.length; i++) {
    element[i].classList.toggle("open_chat");
  }
}
function screenResize() {
  const w = window.innerWidth;

  if (w <= 768) {
    const element = document.getElementsByName("MenuOffside");
    for (let i = 0; i < element.length; i += 1) {
      element[i].classList.toggle("off-side-mobile");
    }
  }
}

class Header extends Component {
  state = {
    contact: {},
    ticket: {},
    loading: false,
    showInfo: false,
  };

  render() {

    return (
      <header className="headerChat">
        <div className="openMenuFixed" onClick={screenResize}>
          <Icon path={mdiMenu} size={1} />
        </div>
        <Link to="/" className="logo-mobile">
          <img src={localStorage.getItem('logo_mobile')?localStorage.getItem('logo_mobile') :logo} alt="Logo Monitchat" />
        </Link>{" "}
        <div className="topo-conversa">
          <button className="voltar_chat" name="chat" onClick={toggleClass}>
            <Icon path={mdiArrowRightThick} size={1} vertical horizontal />
          </button>
        </div>
        <div className="topo-chat">
          <div className="botoes_topo">
            <Icons />
            <Perfil />
            <div className="holder-icons">{this.props.children}</div>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
