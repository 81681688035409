import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import api from "../../api/user"
import Header from "../header/Header";
import { Creators as AuthActions } from "../../store/ducks/auth";
import { Creators as AccountsActions } from "../../store/ducks/accounts";
import { Creators as TourActions } from "../../store/ducks/tour";
import MenuOffside from "../MenuOffside/MenuOffside";
import InternalChat from "../internal-chat/InternalChat";
import TaskEvent from "../events/TaskEvent";
import Tour from "reactour";
import Socket from "../../services/socket";
import UpdateStatus from "../header/UpdateStatus";

const MainContainer = ({ children, tour, closeTour, fetchAll, setHasNewUpdate }) => {

  const [intervalSeconds, setIntervalSeconds] = useState(45);

  useEffect(() => {
    const ping = () => {
      api.user.ping().then(res => {});
    };

    // Função para definir o intervalo
    const startInterval = () => {
      const intervalId = setInterval(() => {
        ping();

        const newIntervalSeconds = Math.floor(Math.random() * (60 - 45 + 1)) + 45;
        setIntervalSeconds(newIntervalSeconds);

        clearInterval(intervalId);
        startInterval();
      }, intervalSeconds * 1000);

      return intervalId;
    };

    const intervalId = startInterval();

    return () => clearInterval(intervalId);
  }, [intervalSeconds]);

  

  useEffect(() => {
    fetchAll();
    Socket.private(`new-version-released`).listen("NewVersionReleased", () => {
      setHasNewUpdate(true)
    });


  }, []);

  return (
    <div>
      <MenuOffside />
      <InternalChat />
      <Tour
        steps={tour.mainSteps}
        isOpen={tour.isOpen}
        onRequestClose={closeTour}
      />
      <div className="wrap_chat" name="MenuOffside">
        {/* <UpdateStatus /> */}
        <Header /> {children}
      </div>{" "}
      <TaskEvent />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.user.isAuthenticated,
    currentPause: state.user.currentPause,
    user: state.user,
    tour: state.tour,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { ...AuthActions, ...TourActions, ...AccountsActions },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MainContainer);
