import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route } from "react-router-dom";
import "semantic-ui-css/semantic.min.css";
import { Provider } from "react-redux";
import jwt from "jsonwebtoken";
import App from "./App";
import { Creators as AuthActions } from "./store/ducks/auth";
import store from "./store/store";
import LoaderComponent from "./components/semantic/Loading";

require("dotenv").config();

if (localStorage.token) {
  try {
    store.dispatch(AuthActions.setCurrentUser(jwt.decode(localStorage.token)));
  } catch (err) {
    localStorage.removeItem("token");
    store.dispatch(AuthActions.userLoggedOut());
    window.location.reload();
  }
} else {
  localStorage.removeItem("token");
  store.dispatch(AuthActions.userLoggedOut());
}

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <Suspense
        fallback={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 10,
              paddingTop: 10,
              alignSelf: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <LoaderComponent />
          </div>
        }
      >
        <Route component={App} />
      </Suspense>
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);
