import React from "react";
import { Grid } from "semantic-ui-react";

export default () => (
  <Grid>
    <Grid.Row>
      <h2>Início</h2>
      <p>Nesta coluna você verá a data e a hora que criou o ticket</p>
    </Grid.Row>
  </Grid>
);
