import React, { Component } from "react";
import "react-dropdown/style.css";
import {Icon} from "semantic-ui-react";

class UpdateStatus extends Component {
  state = {
    contact: {},
    ticket: {},
    loading: false,
    showInfo: false,
  };

  render() {
    return (
      <header className="headerChat" style={{zIndex: 100}}>
        <div className="topo-chat">
          <div className="botoes_topo" style={{cursor: "pointer"}}>
            <Icon name="close"></Icon>
          </div>
        </div>
      </header>
    );
  }
}

export default UpdateStatus;
