import React from "react";
import { Grid } from "semantic-ui-react";

export default () => (
  <Grid>
    <Grid.Row>
      <h2>Atendimento por Robô</h2>
      <p>
        <strong>
          Neste Menu ficará todos relatório de do atendimento do Robô, quantos
          chamados ele atendeu, pra qual departamentos foram os chamados.
        </strong>
      </p>
    </Grid.Row>
  </Grid>
);
