import axios from "../services/api";

export default {
	ticketcategory: {
		fetchAll: params =>
			axios.get(`/ticket-category`, { params }).then(res => res.data),
		delete: id =>
			axios.delete(`/ticket-category/${id}`).then(res => res.data),
		update: (id, ticketcategory) =>
			axios
				.put(`/ticket-category/${id}`, ticketcategory)
				.then(res => res.data),
		submit: ticketcategory =>
			axios.post(`/ticket-category`, ticketcategory).then(res => res.data)
	}
};
