import React, { useState, useEffect } from "react";
import { Button, Checkbox, Dropdown, Form, Grid, Input, Label } from "semantic-ui-react";
import InlineError from "../../messages/InlineError";
import InputMask from "react-input-mask";
import ExtraFieldForm from "../../extra-input/ExtraFieldForm";
import axios from "../../../services/api";
import api from "../../../services/api";
import InvoiceTable from "../../companies/InvoiceTable";

const options = [
  {
    key: "Google",
    text: "Google",
    value: "Google",
  },
  {
    key: "Site Vipphone",
    text: "Site Vipphone",
    value: "Site Vipphone",
  },
  {
    key: "Indicação",
    text: "Indicação",
    value: "Indicação",
  },
  {
    key: "Contato Telefônico",
    text: "Contato Telefônico",
    value: "Contato Telefônico",
  },
];

const optionsCPF = [
  {
    key: 0,
    text: "Selecione",
    value: 0,
  },
  {
    key: 2,
    text: "CPF",
    value: 2,
  },
  {
    key: 1,
    text: "CNPJ",
    value: 1,
  },
];

const FormNewCompany = ({
  company,
  onChange,
  handleExtraFields,
  messageError,
  setAddress,
  part
}) => {
  const [plans, setPlans] = useState([]);

  useEffect(() => {
    if (part == 2) {
      const filterPlan = { filter: JSON.stringify([["active", "=", 1]]) };
      api
        .get("/pricing-plan", { params: { take: 300, filter: filterPlan } })
        .then((res) => {
          setPlans(res.data.data);
        });
    }
  }, [part]);

  const getAddress = (e) => {
    const cep = e.target.value
    axios.get(`https://viacep.com.br/ws/${cep}/json/`).then(res => {

      if (!res.data.erro) {
        setAddress({
          address: res.data.logradouro,
          city: res.data.localidade,
          state: res.data.uf,
          neighborhood: res.data.bairro,
        })
      }
    })
  }
  const currentPlan = plans.find((p) => p.id === company.pricing_plan_id);

  return (
    <Form size="mini">
      {part == 0 && <div className="holder_part">
        <Form.Group widths="equal">
          <Form.Field error={messageError ? !!messageError.name : ""}>
            <label htmlFor="name">Razão Social*</label>
            <Input
              autoComplete="off"
              control={Input}
              name="name"
              onChange={onChange}
              value={company.name}
              placeholder="Nome"
            />
            {messageError ? <InlineError text={messageError.name} /> : ""}
          </Form.Field>

          <Form.Field error={messageError ? !!messageError.username : ""}>
            <label htmlFor="admin">Nome do Administrador*</label>
            <Input
              autoComplete="off"
              control={Input}
              type="text"
              name="admin"
              onChange={(e, { value }) => onChange(e, { name: "username", value })}
              value={company.username}
              placeholder="Nome do Administrador"
            />
            {messageError ? <InlineError text={messageError.username} /> : ""}
          </Form.Field>
        </Form.Group>
        <Form.Group widths="2">
          <Form.Field error={messageError ? !!messageError.cpf_cnpj : false}>
            <label htmlFor="cpf_cnpj">
              {" "}
              <Dropdown
                inline
                options={optionsCPF}
                name="person_type"
                value={company.person_type || 0}
                onChange={(a, b) => {
                  document.getElementsByName('cpf_cnpj')[0].focus()
                  onChange(a, b)
                }}
              />
            </label>
            <Input
              autoComplete="off"
              label={<Dropdown defaultValue=".com" options={options} />}
              labelPosition="left"
              id="cpf_cnpj"
              children={
                <InputMask
                  mask={
                    company.person_type === 1
                      ? "99.999.999/9999-99"
                      : "999.999.999-99"
                  }
                  name="cpf_cnpj"
                  onChange={(e) =>
                    onChange(e, {
                      name: e.target.name,
                      value: e.target.value,
                    })
                  }
                  value={company.cpf_cnpj}
                  placeholder="CPF/CNPJ"
                />
              }
            />
            {messageError ? <InlineError text={messageError.cpf_cnpj} /> : ""}
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field error={messageError ? !!messageError.email : ""}>
            <label htmlFor="admin_mail">Email para Login*</label>
            <Input
              autoComplete="off"
              control={Input}
              name="admin_mail"
              onChange={(e, { value }) => onChange(e, { name: "email", value })}
              value={company.email}
              placeholder="ricardo@monitchat.com"
            />
            {messageError ? <InlineError text={messageError.email} /> : ""}
          </Form.Field>
          <Form.Field error={messageError ? !!messageError.phone_optional : ""}>
            <label htmlFor="phone_optional">Telefone*</label>
            <Input
              autoComplete="off"
              control={Input}
              children={
                <InputMask
                  mask="+55 (99) 99999-9999"
                  name="phone_optional"
                  onChange={(e) =>
                    onChange(e, { name: e.target.name, value: e.target.value })
                  }
                  value={company.phone_optional}
                  placeholder="+55 (__) _____-____"
                />
              }
            />
            {messageError ? <InlineError text={messageError.phone_optional} /> : ""}
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field error={messageError ? !!messageError.password : ""}>
            <label htmlFor="password">Senha*</label>
            <Input
              autoComplete="off"
              control={Input}
              name={"password"}
              type="password"
              value={company.password}
              placeholder="%$3kd5}^#@$%"
              onChange={onChange}
            />
            {messageError ? <InlineError text={messageError.password} /> : ""}
          </Form.Field>

          {company.id ? (
            ""
          ) : (
              <Form.Field error={messageError ? !!messageError.confirmPassword : ""}>
                <label htmlFor="confirmPassword">Confirmação de Senha*</label>
                <Input
                  autoComplete="off"
                  control={Input}
                  name={"confirmPassword"}
                  type="password"
                  value={company.confirmPassword}
                  placeholder="%$3kd5}^#@$%"
                  onChange={onChange}
                />
                {messageError ? <InlineError text={messageError.confirmPassword} /> : ""}
              </Form.Field>
            )}
        </Form.Group>
        <ExtraFieldForm
          extra_fields={company.extra_fields}
          onChange={handleExtraFields}
        />
      </div>}

      {part == 1 && <div className="holder_part">
        <Form.Group widths="equal">
          <Form.Field error={messageError ? !!messageError.zip_code : false}>
            <label htmlFor="zip_code">CEP:</label>
            <Input
              autoComplete="off"
              name="zip_code"
              type="number"
              value={company.zip_code}
              onChange={onChange}
              onBlur={getAddress}
            />
            {messageError ? <InlineError text={messageError.zip_code} /> : ""}
          </Form.Field>
          <Form.Field error={messageError ? !!messageError.address : false}>
            <label htmlFor="address">Endereço:</label>
            <Input
              autoComplete="off"
              name="address"
              value={company.address}
              onChange={onChange}
            />
            {messageError ? <InlineError text={messageError.address} /> : ""}
          </Form.Field>

        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field error={messageError ? !!messageError.city : false}>
            <label htmlFor="city">Cidade:</label>
            <Input
              autoComplete="off"
              name="city"
              value={company.city}
              onChange={onChange}
            />
            {messageError ? <InlineError text={messageError.city} /> : ""}
          </Form.Field>
          <Form.Field error={messageError ? !!messageError.state : false}>
            <label htmlFor="state">Estado:</label>
            <Input
              autoComplete="off"
              name="state"
              value={company.state}
              onChange={onChange}
            />
            {messageError ? <InlineError text={messageError.state} /> : ""}
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field error={messageError ? !!messageError.neighborhood : false}>
            <label htmlFor="neighborhood">Bairro:</label>
            <Input
              autoComplete="off"
              name="neighborhood"
              value={company.neighborhood}
              onChange={onChange}
            />
            {messageError ? (
              <InlineError text={messageError.neighborhood} />
            ) : (
                ""
              )}
          </Form.Field>
          <Form.Field error={messageError ? !!messageError.number : false}>
            <label htmlFor="number">Número:</label>
            <Input
              autoComplete="off"
              name="number"
              type="number"
              value={company.number}
              onChange={onChange}
            />
            {messageError ? <InlineError text={messageError.number} /> : ""}
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field error={messageError ? !!messageError.country : false}>
            <label htmlFor="country">País:</label>
            <Input
              autoComplete="off"
              name="country"
              type="country"
              value={company.country}
              onChange={onChange}
            />
            {messageError ? <InlineError text={messageError.country} /> : ""}
          </Form.Field>
          <Form.Field error={messageError ? !!messageError.complement : ""}>
            <label htmlFor="complement">Complemento:</label>
            <Input
              autoComplete="off"
              name="complement"
              type="complement"
              value={company.complement}
              onChange={onChange}
            />
            {messageError ? (
              <InlineError text={messageError.complement} />
            ) : (
                ""
              )}
          </Form.Field>
        </Form.Group>
      </div>}

      {part === 2 &&
        <div className="holder_part">
          <Form.Group>
            <Form.Field>
              <label htmlFor="origin">Plano Atual</label>
              <Dropdown
                placeholder="Selecione"
                clearable
                fluid
                search
                selection
                value={company.pricing_plan_id}
                name="pricing_plan_id"
                onChange={onChange}
                options={plans.map((p) => ({
                  key: p.id,
                  value: p.id,
                  text: `${p.name} - ${p.description}`,
                }))}
              />
              {messageError ? <InlineError text={messageError.current_plan} /> : ""}
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <label>Contas de Usuário:</label>
              <Label>{currentPlan ? currentPlan.max_users : ""}</Label>
            </Form.Field>
            <Form.Field>
              <label>Contas de WhatsApp:</label>
              <Label>{currentPlan ? currentPlan.max_accounts : ""}</Label>
            </Form.Field>
            <Form.Field>
              <label>MonitBot Liberado ?</label>
              <Label>
                {currentPlan
                  ? currentPlan.virtual_assistant_active
                    ? "Sim"
                    : "Não"
                  : "Não"}
              </Label>
            </Form.Field>
            <Form.Field>
              <label>Campanhas Liberadas ?</label>
              <Label>
                {currentPlan
                  ? currentPlan.campaings_active
                    ? "Sim"
                    : "Não"
                  : "Não"}
              </Label>
            </Form.Field>
          </Form.Group>

          <Form.Group widths="6">
            <Form.Field>
              <label>Valor do Plano:</label>
              <Label>{currentPlan ? currentPlan.price : ""}</Label>
            </Form.Field>
          </Form.Group>
        </div>}

      {part === 3 && <div className="holder_part">
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Form.Group widths="4">
                <Form.Field
                  error={messageError ? !!messageError.due_day : ""}
                >
                  <label htmlFor="due_day">Dia de Vencimento:</label>
                  <Dropdown
                    name="due_day"
                    size="mini"
                    selection
                    style={{ width: "100px" }}
                    search
                    value={company.due_day}
                    onChange={onChange}
                    options={[
                      { key: 5, value: 5, text: "05" },
                      { key: 10, value: 10, text: "10" },
                      { key: 15, value: 15, text: "15" },
                      { key: 20, value: 20, text: "20" },
                      { key: 25, value: 25, text: "25" },
                    ]}
                  />

                  {messageError ? (
                    <InlineError text={messageError.due_day} />
                  ) : (
                      ""
                    )}
                </Form.Field>

                <Form.Field
                  error={messageError ? !!messageError.due_day : ""}
                >
                  <label htmlFor="due_day">Método de Pagamento</label>
                  <Dropdown
                    placeholder="Escolha uma opção"
                    search
                    selection
                    style={{ width: "100px" }}
                    name="payment_type"
                    value={company.payment_type ? company.payment_type : 0}
                    onChange={onChange}
                    options={[
                      {
                        key: 0,
                        value: 0,
                        text: "Boleto",
                        icon: { name: "barcode" },
                      },
                    ]}
                    closeOnChange
                  />

                  {messageError ? (
                    <InlineError text={messageError.due_day} />
                  ) : (
                      ""
                    )}
                </Form.Field>
              </Form.Group>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              {company.id && <InvoiceTable company_id={company.id} /> }
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>}
    </Form>
  );
};

export default FormNewCompany;
