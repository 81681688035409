import React from "react";
import { Grid } from "semantic-ui-react";

export default () => (
  <Grid>
    <Grid.Row>
      <h2>Título</h2>
      <p>Nesta coluna você verá o título da campanha</p>
    </Grid.Row>
  </Grid>
);
