import axios from "../services/api";

export default {
  group: {
    fetchAll: params => axios.get(`/group`, { params }).then(res => res.data),
    fetchId: id => axios.get(`/group/${id}`).then(res => res.data),
    fetchListContacts: (selectedDataId, params) =>
      axios
        .get(`/group/${selectedDataId}/contacts`, { params })
        .then(res => res.data),

    delete: id => axios.delete(`/group/${id}`).then(res => res.data),
    deleteContact: (groupId, contactId) =>
      axios
        .delete(`/group/${groupId}/contacts/${contactId}`)
        .then(res => res.data),
    update: (id, group) =>
      axios.put(`/group/${id}`, group).then(res => res.data),

    submit: group => axios.post(`/group`, group).then(res => res.data),
    importContact: group =>
      axios.post(`/file/excel/getArrayFromExcel`, group).then(res => res.data),
    submitContacts: (groupId, group) =>
      axios.post(`/group/${groupId}/contacts`, group).then(res => res.data),
    sendContacts: (groupId, group) =>
      axios
        .post(`/group/${groupId}/contacts/import`, group)
        .then(res => res.data),
    addContacts: (groupId, params) =>
      axios.post(`/group/${groupId}/contacts`, params).then(res => res.data),
    updateContact: (id, group) =>
      axios.put(`/contact/${id}`, group).then(res => res.data)
  }
};
