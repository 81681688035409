import axios from "../services/api";

export default {
  contact: {
    fetchAll: (params) =>
      axios.get(`/contact`, { params }).then((res) => res.data),
    fetchContactUser: (params) =>
      axios.get(`/contact-user`, { params }).then((res) => res.data),
    fetchId: (id) =>
      axios.get(`/contact/${id}`, { id }).then((res) => res.data),
    blockContact: (id, params) =>
      axios
        .post(`/contact/${id}/blockContact`, { params })
        .then((res) => res.data),
    unblockContact: (id, params) =>
      axios
        .post(`/contact/${id}/unblockContact`, { params })
        .then((res) => res.data),
    delete: (id) => axios.delete(`/contact/${id}`).then((res) => res.data),
    update: (id, contact) =>
      axios.put(`/contact/${id}`, contact).then((res) => res.data),
    getPicture: (id) =>
      axios.get(`/contact/${id}/getPicture`).then((res) => res.data),
    submit: (contact) =>
      axios.post(`/contact`, contact).then((res) => res.data),
    importContacts: (contact) =>
      axios.post(`/contact/import`, contact).then((res) => res.data),
    mergeContact: (id, data) =>
      axios.post(`/contact/${id}/merge`, data).then((res) => res.data),
    removeDuplicated: (data) =>
      axios.post(`/contact/removeDuplicated`, { data }).then((res) => res.data),
    submitContact: (contact) =>
      axios.post(`/contact-user`, contact).then((res) => res.data),
  },
};
