import React from "react";
import { Grid } from "semantic-ui-react";

export default () => (
  <Grid>
    <h2>Corpo da conversa</h2>
    <p>
      Aqui será apresentado todas as conversas, tickets, comentários e em cima
      vocês podrá fechar e atribuuir o ticket para alguém ou pra ti mesmo!
    </p>
    <div
      style={{
        background: "#e8f3fd",
        border: "2px solid #686868",
        borderRadius: 10,
        marginBottom: 15,
      }}
    >
      Para <strong> mensagem enviada </strong>a caixinha da mensagem irá ficar
      dessa cor de fundo
    </div>
    <div
      style={{
        background: "#F0F2F5",
        border: "2px solid #686868",
        borderRadius: 10,
        marginBottom: 15,
      }}
    >
      Para <strong>mensagem recebida </strong> a caixinha da mensagem irá ficar
      dessa cor de fundo
    </div>
    <div
      style={{
        background: "#E1F5FE",
        border: "2px solid #686868",
        borderRadius: 10,
        marginBottom: 15,
      }}
    >
      Para <strong> ticekts abertos </strong> a caixinha da mensagem irá ficar
      dessa cor de fundo
    </div>
    <div
      style={{
        background: "#F9EDE5",
        border: "2px solid #686868",
        borderRadius: 10,
        marginBottom: 15,
      }}
    >
      Para <strong> alteração de tickets </strong> a caixinha da mensagem irá
      ficar dessa cor de fundo
    </div>
    <div
      style={{
        background: "#FBE7D3",
        border: "2px solid #686868",
        borderRadius: 10,
        marginBottom: 15,
      }}
    >
      Para <strong> Comentário </strong> a caixinha da mensagem irá ficar dessa
      cor de fundo
    </div>
  </Grid>
);
