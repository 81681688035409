import React, { Component } from "react";
import { Button, Modal, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Creators as UserActions } from "../../store/ducks/auth";
import api from "../../api/interruption-type";
import DropdownInterruption from "../interruption-type/DropdownInterruption";
import loadingGif from "../../assets/img/loading_gif.gif";

class Interruption extends Component {
  state = {
    interruption: "",
    interruption_type_id: "",
    interruption_name: "Motivo de Pausa",
    interruption_duration: "",
    interruption_icon: "",
    seconds: 60,
    zeroed: false,
    playing: false,
    open: false,
    interruptions: [],
  };
  componentWillMount() {
    api.interruption.fetchAll({ take: 30 }).then((interruptions) => {
      this.setState({
        interruptions: interruptions.data.map((c) => ({
          key: c.id,
          value: c,
          text: c.name,
          duration: c.duration,
          icon: c.fast_menu_icon,
        })),
        loading: false,
      });
    });
  }
  handleInterruptionChange = (value) => {
    this.setState({
      interruption: value.name,
      interruption_name: value.name,
      interruption_type_id: value.id,
      interruption_duration: value.duration,
      interruption_icon: value.fast_menu_icon,
    });
  };

  handlePlay = () => {
    const {
      interruption_type_id,

      open,
      playing,
    } = this.state;
    this.setState({ playing: !playing });
    const { user_id, active } = this.props;

    const pause = {
      id: user_id,
      interruption_type_id,
      active: !active,
    };

    this.props.userPause(pause);
    if (playing) {
      this.setState({
        open: !open,
      });
    }
  };
  openModal = () => {
    const { open } = this.state;
    this.setState({
      open: !open,
      interruption: null,
      interruption_type_id: null,
      interruption_name: "Motivo de Pausa",
      interruption_duration: null,
      interruption_icon: null,
    });
  };
  render() {
    const { name, avatar, currentPause } = this.props;

    const {
      interruption,
      interruption_name,
      interruption_duration,
      interruption_icon,

      open,

      interruptions,
    } = this.state;

    let currentPauseFind = {};
    if (currentPause) {
      currentPauseFind = interruptions.find(
        (c) => c.key === currentPause.interruption_type_id
      );
    }
    return (
      <Modal
        className="modal_interruption"
        basic
        trigger={<span onClick={this.openModal}>Pausa de Atendimento</span>}
        closeIcon={!currentPause}
        open={!!currentPause || open}
        onClose={this.openModal}
        closeOnDimmerClick={false}
      >
        <Modal.Header className="center_header">
          Pausa de Atendimento
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <div className="user_pause">
              <img src={avatar} alt="Usuário" />
              <h2>{name}</h2>
            </div>
            <div className="pause_holder">
              {currentPause ? (
                ""
              ) : (
                <DropdownInterruption
                  onSelectInterruption={this.handleInterruptionChange}
                  interruption={interruption}
                  allowAdditions={false}
                  interruptions={interruptions}
                />
              )}

              <span
                className={
                  interruption_duration > 0 || currentPause
                    ? "duration"
                    : "duration_motive"
                }
              >
                {currentPause && !!currentPauseFind ? (
                  <div>
                    <h3>{currentPauseFind.text}</h3>
                    <Icon name={currentPauseFind.icon} />
                    <img src={loadingGif} alt="" />
                    {`${currentPauseFind.duration - currentPause.duration} ` +
                      `minutos `}
                  </div>
                ) : (
                  ""
                )}
                {currentPause && !currentPauseFind ? (
                  <div>
                    <h3>
                      {!currentPause.user_logged_out ? interruption_name : null}
                    </h3>
                    <Icon name={interruption_icon} />
                    <img src={loadingGif} alt="" />
                    {!currentPause.user_logged_out &&
                      `${interruption_duration - currentPause.duration}` +
                        ` minutos`}
                  </div>
                ) : (
                  ""
                )}
                {interruption_duration > 0 && !currentPause
                  ? `${interruption_duration} minutos`
                  : ""}
                {!interruption_duration && !currentPause
                  ? "Selecione um motivo para começar a pausa"
                  : ""}
              </span>
              {currentPause ? (
                <Button className="button_pause" onClick={this.handlePlay}>
                  <Icon name="stop" />
                  Encerrar Pausa
                </Button>
              ) : (
                <div>
                  {!currentPause && interruption_duration > 0 ? (
                    <Button className="button_pause" onClick={this.handlePlay}>
                      <Icon name={currentPause ? "stop" : "play"} />
                      {currentPause ? "Encerrar Pausa" : "Começar Pausa"}
                    </Button>
                  ) : (
                    ""
                  )}
                </div>
              )}
            </div>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(UserActions, dispatch);

export default connect(
  ({ user }) => ({ currentPause: user.currentPause }),
  mapDispatchToProps
)(Interruption);
