import React, { Component } from "react";

import {
  Table,
  Menu,
  Icon,
  Pagination,
  Dropdown,
  Checkbox,
  Button,
  Input,
  Modal,
  Header,
  Popup,
  Form,
  Message,
} from "semantic-ui-react";
import { DateInput } from "semantic-ui-calendar-react";
import LoaderComponent from "../semantic/Loading";
import PropTypes from "prop-types";
import DropdownUsers from "../users/DropdownUsers";
import api from "../../services/api";
import apiContact from "../../api/contact";
import apiMedia from "../../api/social-media";
import { CSVLink } from "react-csv";
import {jsPDF} from "jspdf";
import "jspdf-autotable";
import DownloadExcel from "./DownloadExcel";
import { MonthInput, YearInput } from "semantic-ui-calendar-react";

const availableLengths = [
  { key: 5, text: 5, value: 5 },
  { key: 10, text: 10, value: 10 },
  { key: 15, text: 15, value: 15 },
  { key: 20, text: 20, value: 20 },
  { key: 40, text: 40, value: 40 },
  { key: 50, text: 50, value: 50 },
  { key: 100, text: 100, value: 100 },
  { key: 200, text: 200, value: 200 },
  { key: 300, text: 300, value: 300 },
  { key: 500, text: 500, value: 500 },
  { key: 1000, text: 1000, value: 1000 },
  { key: 2000, text: 2000, value: 2000 },
];

const months = [
  { month: 1, name: "Jan" },
  { month: 2, name: "Fev" },
  { month: 3, name: "Mar" },
  { month: 4, name: "Abr" },
  { month: 5, name: "Mai" },
  { month: 6, name: "Jun" },
  { month: 7, name: "Jul" },
  { month: 8, name: "Ago" },
  { month: 9, name: "Set" },
  { month: 10, name: "Out" },
  { month: 11, name: "Nov" },
  { month: 12, name: "Dez" },
];

const dateFilter = [
  {
    key: "Hoje",
    text: "Hoje",
    value: "today",
  },
  {
    key: "Ontem",
    text: "Ontem",
    value: "yesterday",
  },
  {
    key: "Na Semana",
    text: "Na Semana",
    value: "week",
  },
  {
    key: "7 dias",
    text: "7 dias",
    value: "last_seven_days",
  },
  {
    key: "Semana passada",
    text: "Semana passada",
    value: "last_week",
  },
  {
    key: "No Mês",
    text: "No Mês",
    value: "month",
  },
  {
    key: "30 dias",
    text: "30 dias",
    value: "last_thirty_days",
  },
  {
    key: "Mês passado",
    text: "Mês passado",
    value: "last_month",
  },
  {
    key: "No Ano",
    text: "No Ano",
    value: "year",
  },
  {
    key: "Ano passado",
    text: "Ano passado",
    value: "last_year",
  },
  {
    key: "Até Agora",
    text: "Até Agora",
    value: "until_now",
  },
  {
    key: "Por Mês",
    text: "Por Mês: ",
    value: "by_month",
  },
];

const dateFilterType = [
  {
    key: "Hoje",
    text: "Hoje",
    value: "today",
  },
  {
    key: "Ontem",
    text: "Ontem",
    value: "yesterday",
  },
  {
    key: "Na Semana",
    text: "Na Semana",
    value: "week",
  },
  {
    key: "7 dias",
    text: "7 dias",
    value: "last_seven_days",
  },
  {
    key: "Semana passada",
    text: "Semana passada",
    value: "last_week",
  },
  {
    key: "No Mês",
    text: "No Mês",
    value: "month",
  },
  {
    key: "30 dias",
    text: "30 dias",
    value: "last_thirty_days",
  },
  {
    key: "Mês passado",
    text: "Mês passado",
    value: "last_month",
  },
  {
    key: "No Ano",
    text: "No Ano",
    value: "year",
  },
  {
    key: "Ano passado",
    text: "Ano passado",
    value: "last_year",
  },
  {
    key: "Até Agora",
    text: "Até Agora",
    value: "until_now",
  },
  {
    key: "Personalizado",
    text: "Personalizado: ",
    value: "custom",
  },
];

const defaultLength = 10;

const iconStyle = {
  position: "absolute",
  top: 0,
  bottom: 0,
  margin: "auto",
  right: "1em",
  lineHeight: 1,
  zIndex: 1,
};

const containerStyle = {
  position: "relative",
};

class DataTable extends Component {
  state = {
    activePage: 1,
    boundaryRange: 1,
    siblingRange: 1,
    loading: false,
    showEllipsis: true,
    showFirstAndLastNav: true,
    showPreviousAndNextNav: true,
    recordsPerPage: 10,
    modalDeleteOpen: false,
    modalDeleteAllOpen: false,
    selectedId: 0,
    allChecked: false,
    checkedItems: [],
    relations: [],
    showFilterByMonth: false,
    pages: [],
    search: "",
    dataCount: 0,
    orderDirection: "desc",
    order: "id",
    hide_actions: true,
    groupId: "",
    errors: "",
    desconnectModal: false,
    reconnectModal: false,
    userModal: false,
    mergeContactModalOpen: false,
    newRelationModalOpen: false,
  };

  handlePaginationChange = (e, { activePage }) => {
    const {
      recordsPerPage: take,
      search,
      order,
      orderDirection,
      filter,
    } = this.state;
    const skip = activePage === 1 ? 0 : take * activePage - take;

    const params = {
      skip,
      search,
      take,
      filter: filter ? filter : { created: "month" },
      order,
      order_direction: orderDirection,
    };

    this.props
      .fetchData({
        ...params,
        filter: {
          ...params.filter,
          selected_month: params.filter.selected_month
            ? months.find(
              (m) =>
                m.name.toLowerCase() ===
                params.filter.selected_month.toLocaleLowerCase()
            ).month
            : "",
        },
      })
      .then(() => {
        this.setState({ activePage, loading: false });
      });
  };

  handleRelationChange = (e, { name, value }) => {
    this.setState({
      [name]: value,
    });

    api.post("/model-relationship", {
      source_model: this.props.relation,
      relations: value,
    });
  };

  componentDidMount() {
    this.setState({
      order: this.props.order ? this.props.order : this.state.order,
    });

    if (this.props.relation) {
      api
        .get("/model-relationship", {
          params: { source_model: this.props.relation },
        })
        .then((res) => {
          const pages = res.data.pages;
          const models = res.data.data;

          this.setState({
            relations: models.map((r) => {
              return parseInt(r.destination_model);
            }),
            pages: pages.map((p) => {
              return {
                key: parseInt(p.id),
                value: parseInt(p.id),
                text: p.title,
              };
            }),
          });
        });
    }
  }
  createPDF = () => {
    const doc = new jsPDF({ orientation: "landscape" });
    const href = window.location.href.substring(
      window.location.href.lastIndexOf("/") + 1
    );
    const pdftable = this.props.csv.map((d, i) =>
      Object.keys(this.props.columns).map((c, i) => d[c])
    );
    doc.autoTable({
      head: [this.props.columns],
      body: pdftable,
      styles: { minCellWidth: 20, overflow: "linebreak", cellWidth: "auto" },
    });
    doc.save(`${href}.pdf`);
  };

  onRecordsPerPageChanged = (e, { value }) => {
    this.setState({
      recordsPerPage: value,
      activePage: 1,
      loading: true,
    });

    const skip = 0;
    const { search, order, orderDirection, filter } = this.state;

    const params = {
      skip,
      search,
      take: value,
      filter: filter ? filter : { created: "month" },
      order,
      order_direction: orderDirection,
    };

    this.props.fetchData(params).then(() => {
      this.setState({ loading: false });
    });
  };

  onNewRelationClick = () => {
    this.setState({
      newRelationModalOpen: !this.state.newRelationModalOpen,
    });
  };

  reloadData = () => {
    const {
      recordsPerPage,
      activePage,
      search,
      order,
      filter,
      orderDirection,
    } = this.state;
    const skip =
      activePage === 1 ? 0 : recordsPerPage * activePage - recordsPerPage;
    this.setState({ loading: true });

    const params = {
      skip,
      search,
      take: this.props.recordsPerPage || recordsPerPage,
      filter: filter
        ? {
          ...filter,
          selected_month: filter.selected_month
            ? months.find(
              (m) =>
                m.name.toLowerCase() ===
                filter.selected_month.toLocaleLowerCase()
            ).month
            : "",
        }
        : { created: "month" },
      order: this.props.oldticketBt ? "start_date" : order,
      order_direction: this.props.oldticketBt ? "desc" : orderDirection,
    };

    this.props.fetchData(params).then((res) => {
      this.setState({ loading: false });
    });
  };

  onPeriodChanged = (e, { name, value }) => {
    if (value === "by_month") {
      this.setState({
        showFilterByMonth: true,
        filter: {
          created: value,
        },
      });
      return;
    }

    const {
      recordsPerPage,
      activePage,
      search,
      order,
      orderDirection,
    } = this.state;
    const skip =
      activePage === 1 ? 0 : recordsPerPage * activePage - recordsPerPage;
    this.setState({
      loading: true,
      showFilterByMonth: false,
      filter: {
        created: value,
      },
    });

    const params = {
      skip,
      search,
      take: recordsPerPage,
      filter: {
        created: value,
      },
      order: this.props.oldticketBt ? "start_date" : order,
      order_direction: this.props.oldticketBt ? "desc" : orderDirection,
    };

    this.props.fetchData(params).then((res) => {
      this.setState({ loading: false });
    });
  };

  resetSearch = () => {
    this.setState({ search: "", loading: true }, this.onSearch);
  };

  removeDuplicated = (ids) => {
    this.setState({ loading: true });
    apiContact.contact
      .removeDuplicated(ids)
      .then((res) => {
        this.setState({ loading: false });
        this.toggleMergeContactModal();
        this.reloadData();
      })
      .catch((res) => {
        this.setState({ loading: false });
      });
  };

  delete = () => {
    this.setState({ loading: true });
    const { selectedId } = this.state;

    this.props.onDelete(selectedId);
    this.setState({
      modalDeleteOpen: false,
      modalDeleteAllOpen: false,
    });
    setTimeout(
      function () {
        this.reloadData();
      }.bind(this),
      2000
    );
  };

  cancelDisconect = (data) => {
    this.setState({
      desconnectModal: !this.state.desconnectModal,
      selectedId: data,
    });
  };

  disconnectUser = async () => {
    this.setState({ loading: true });
    await apiMedia.media.disconnect(this.state.selectedId).then((res) => {
      this.setState({ desconnectModal: false, loading: false });

      this.reloadData();
    });
  };

  rebootServer = async () => {
    this.setState({ loading: true });
    await this.props.reboot(this.state.selectedId);
    this.setState({ desconnectModal: false, loading: false });
  };

  cancelReconnect = (data) => {
    this.setState({
      reconnectModal: !this.state.reconnectModal,
      selectedId: data,
    });
  };

  reconnect = async () => {
    this.setState({ loading: true });
    await api.post(`/social/reconnect/${this.state.selectedId}`).then((res) => {
      this.setState({ reconnectModal: false, loading: false });

      this.reloadData();
    });
  };

  select = (data) => {
    this.setState({
      selectedId: data.id,
    });
    this.props.onEditClick(data);
  };

  selectRow = (data) => {
    this.setState({
      selectedId: data.id,
    });
    this.props.onRowClick(data);
  };

  campaignBack = () => {
    this.props.fetchData({ filter: this.state.filter });
  };

  selectContact = (data) => {
    this.setState({
      selectedId: data.id,
    });
    this.props.onEditContact(data);
  };

  selectGroup = (data) => {
    this.setState({
      selectedId: data.id,
      groupId: data.id,
    });

    this.props.onGroupClick(data);
  };

  backGroup = (data) => {
    this.setState({
      selectedId: data.id,
      groupId: data.id,
    });

    this.props.onGroupClick();
  };

  handleCloseDeleteModal = () => {
    this.setState({
      modalDeleteOpen: false,
      selectedId: 0,
    });
  };
  toggleWallet = (data) => {
    this.props.addWallet(data);
  };
  onOrderChange = (column) => {
    const { search, recordsPerPage, orderDirection, filter } = this.state;
    this.setState({
      loading: true,
    });

    const params = {
      skip: 0,
      search,
      take: recordsPerPage,
      order: column,
      filter: filter ? filter : { created: "month" },
      order_direction: orderDirection === "asc" ? "desc" : "asc",
    };

    this.props.fetchData(params).then(() => {
      this.setState({
        orderDirection: orderDirection === "asc" ? "desc" : "asc",
        order: column,
        loading: false,
      });
    });
  };

  handleOpenDeleteModal = (id) => {
    this.setState({
      modalDeleteOpen: true,
      selectedId: id,
    });
  };

  handleOpenDeleteAllModal = () => {
    const { checkedItems } = this.state;

    if (checkedItems.length > 0) {
      this.setState({
        modalDeleteAllOpen: true,
        selectedId: checkedItems.join(","),
      });
    } else {
      // TODO: show alert to select items to delete
    }
  };

  handleOpenMergeModal = () => {
    const { checkedItems } = this.state;

    if (checkedItems.length > 1) {
      this.setState({
        mergeContactModalOpen: true,
        selectedId: checkedItems.join(","),
      });
    } else {
      // TODO: show alert to select items to delete
    }
  };

  toggleMergeContactModal = () => {
    this.setState({
      mergeContactModalOpen: false,
      selectedId: -1,
    });
  };

  handleCloseDeleteAllModal = () => {
    this.setState({
      modalDeleteAllOpen: false,
      selectedId: -1,
    });
  };

  handleCheckBoxChanged = (checked, id) => {
    const { checkedItems } = this.state;

    if (checked) {
      if (checkedItems.indexOf(id) === -1) {
        this.setState({
          checkedItems: [...checkedItems].concat(id),
        });
      }
    } else {
      this.setState({
        checkedItems: [...checkedItems].filter((c) => c !== id),
        allChecked: false,
      });
    }
  };

  componentDidUpdate(props, state) {
    if (props.data.length !== state.dataCount) {
      this.setState({ dataCount: props.data.length, checkedItems: [] });
    }

    if (this.state.userModal === true && this.props.userModal === false) {
      this.setState({
        dataCount: props.data.length,
        checkedItems: [],
        userModal: false,
      });
    }

    if (this.props.userModal === true && this.state.userModal === false) {
      this.setState({ userModal: true });
    }
  }

  checkAll = (e, { checked }) => {
    this.setState({
      allChecked: checked,
      checkedItems: checked ? this.props.data.map((d) => d.id) : [],
    });
  };

  onSearchChange = (e) => {
    clearTimeout(this.timer);
    this.setState({ search: e.target.value });
    this.timer = setTimeout(this.onSearch, 300);
  };

  onSearch = () => {
    const {
      search,
      recordsPerPage,
      order,
      orderDirection,
      filter,
    } = this.state;

    const params = {
      skip: 0,
      search,
      take: recordsPerPage,
      filter: filter ? filter : { created: "month" },
      order: order || "start_date",
      order_direction: orderDirection || "desc",
    };

    if (search.length > 2 || search.length === 0) {
      this.setState({ loading: true });
      this.props.fetchData(params).then((res) => {
        this.setState({ loading: false });
      });
    }
  };
  cleanErrors = () => {
    this.setState({ errors: "" });
  };
  render() {
    const {
      checkedItems,
      loading,
      allChecked,
      selectedId,
      activePage,
      boundaryRange,
      siblingRange,
      showEllipsis,
      showFirstAndLastNav,
      showPreviousAndNextNav,
      search,
      recordsPerPage,
      order,
      orderDirection,
      errors,
    } = this.state;

    const {
      data,
      columns,
      onAddClick,
      hide_actions,
      action_disconnect,
      action_reconnect,
      phone_icon,
      group_action,
      contactsOpen,
      tableTickets,
      messageError,
      cleanErrors,
      user_id,
      selectUserModal,
      handleUserChange,
      showRefreshButton = true,
      showBackButton = true,
      oldticketBt,
      toggleClosedTickets,
      openUserModal,
      changeStatus,
      mergeTickets,
      campaignActions,
      csv,
      tableCampaing,
      extra_fields,
      fetchUserRecords,
      customButtons = [],
      showDelete = true,
      showEdit = true,
      customRightButtons = [],
      showRightButtonsBar = true,
      showExportOptions = true,
      showAddButton = true,
      showSelectAll = true,
      mergeDuplicated = false,
      showAvailableLengths = true,
      showSearchInput = true,
      setServerId = null,
      deleteMessage = "Não será possível recuperar o registro após sua exclusão."
    } = this.props;

    const columNames = Object.keys(this.props.columns);
    const totalPages = Math.ceil(
      this.props.totalRecords / (this.props.recordsPerPage || recordsPerPage)
    );
    const datacsv =
      csv &&
      csv.map((r) => {
        return {
          ...r,
        };
      });
    const dataColumn =
      datacsv &&
      datacsv.map((d, i) =>
        Object.assign({}, ...columNames.map((c, i) => ({ [c]: d[c] })))
      );

    return (
      <div className="DataTableActions">
        {errors && (
          <div className="errors-table">
            <Button
              circular
              basic
              color="black"
              icon="close"
              className="button-close"
              onClick={this.cleanErrors}
            />
            <p>{errors}</p>
          </div>
        )}
        {messageError && (
          <div className="errors-table">
            <Button
              circular
              basic
              color="black"
              icon="close"
              className="button-close"
              onClick={cleanErrors}
            />
            <p>{messageError}</p>
          </div>
        )}

        {data.map((d, i) => {
          const postpaid = this.props.postpaid || false
          const alertQuota = parseFloat(d.current_percentual_usage) >= 90 && !postpaid;
          
          return <div className="show_phone" key={`contact-${i * 1}`}>
            {hide_actions ? (
              ""
            ) : (
                <div className="icon-list">
                  {phone_icon === true ? (
                    <Popup
                      content={
                        alertQuota ? `Essa conta atingiu ${d.current_percentual_usage}% do limite da franquia de conversas` : (
                          d.connected !== "Conectado" ? `${d.phone_number} ${d.connected}. Tente abrir o Whatsapp no Celular` : d.phone_number)
                      }
                      position="top left"
                      open={d.connected !== "Conectado" || alertQuota}
                      style={{
                        backgroundColor: "#f36262",
                      }}
                      size="mini"
                      trigger={
                        <Icon
                          className={(() => {
                            if (alertQuota) {
                              return "celular desconectado"
                            }

                            if (d.connected === "Desconectado")
                              return "celular desconectado";
                            if (d.connected === "Conectado")
                              return "celular conectado";
                            if (d.connected === "Não Autenticado")
                              return "celular notautenticado";



                          })()}
                          onClick={() => this.select(d)}
                          name="mobile alternate"
                        />
                      }
                    />
                  ) : (
                      ""
                    )}
                </div>
              )}
          </div>
        })}
        {this.props.loading && (
          <div className="loading-datatable">
            <LoaderComponent />
          </div>
        )}

        {this.props.showActions ? (
          <div className="actions">
            <div className="options-list">
              {showBackButton && (
                <Button
                  icon
                  color="blue"
                  size="mini"
                  onClick={() => {
                    window.history.back();
                  }}
                >
                  <Icon name="backward" />
                </Button>
              )}
              {showRefreshButton && (
                <Button icon color="blue" size="mini" onClick={this.reloadData}>
                  <Icon name="refresh" />
                </Button>
              )}
              {oldticketBt ? (
                <Button
                  icon
                  color="blue"
                  size="mini"
                  onClick={toggleClosedTickets}
                >
                  <Icon name="file archive" />
                </Button>
              ) : (
                  ""
                )}
              {oldticketBt && (
                <Button
                  icon
                  color="blue"
                  size="mini"
                  onClick={fetchUserRecords}
                >
                  <Icon name="eye" />
                </Button>
              )}
              {this.props.wallet ? (
                <Button
                  icon
                  color="blue"
                  size="mini"
                  onClick={this.props.fetchWallet}
                  title="Minha carteira"
                >
                  <Icon name="money bill alternate" />
                </Button>
              ) : (
                  ""
                )}
              {(contactsOpen || this.props.contactsImport) && (
                <Button
                  icon
                  size="mini"
                  color="blue"
                  onClick={this.props.importContacts}
                  title="Importar lista de contatos"
                >
                  <Icon name="download" />
                </Button>
              )}
              {customButtons.map((button) => {

                if (button.button) return button.button

                return (
                  <Button
                    key={button.title}
                    icon
                    size="mini"
                    color={button.color}
                    title={button.title}
                    onClick={button.action}
                    loading={button.loading}
                  >
                    <Icon name={button.icon} />
                  </Button>
                );
              })}
            </div>
            {showAvailableLengths && (
              <Form.Input
                control={Dropdown}
                compact
                className="custom-records-per-page"
                size="mini"
                selection
                options={availableLengths}
                defaultValue={defaultLength}
                onChange={this.onRecordsPerPageChanged}
              />
            )}
            <div className="busca-list" style={containerStyle}>
              {showSearchInput && <Icon
                link
                name={"close"}
                style={{
                  ...iconStyle,
                  display: search !== "" ? "block" : "none",
                }}
                onClick={this.resetSearch}
              />}
              {showSearchInput &&
                <Icon
                  link
                  name={"search"}
                  style={{
                    ...iconStyle,
                    display: search !== "" ? "none" : "block",
                  }}
                  onClick={this.onSearch}
                />}
              {showSearchInput && (
                <Input
                  placeholder="Busca"
                  size="mini"
                  fluid
                  value={search}
                  onChange={this.onSearchChange}
                />
              )}
            </div>
            {this.props.showFilterByDate && (
              <Dropdown
                style={{ color: "rgb(47 172 102)", marginLeft: "10px" }}
                inline
                options={dateFilter}
                name="created_at"
                defaultValue={dateFilter[5].value}
                onChange={this.onPeriodChanged}
              />
            )}

            {this.state.showFilterByMonth ? (
              <div style={{ display: "flex", width: 210 }}>
                <MonthInput
                  style={{ width: 100 }}
                  size="small"
                  placeholder="Mês"
                  name="selected_month"
                  iconPosition="left"
                  clearable
                  dateFormat="MMM"
                  localization="pt-br"
                  closeOnMouseLeave
                  value={
                    this.state.filter.selected_month ||
                    months.find((m) => m.month === new Date().getMonth() + 1)
                  }
                  onChange={(a, b) => {
                    this.setState(
                      {
                        filter: {
                          ...this.state.filter,
                          selected_month: b.value,
                          selected_year:
                            this.state.filter.selected_year ||
                            new Date().getFullYear(),
                        },
                      },
                      this.reloadData
                    );
                  }}
                />

                <YearInput
                  style={{ width: 100 }}
                  size="small"
                  placeholder="Ano"
                  name="selected_year"
                  iconPosition="left"
                  clearable
                  dateFormat="YYYY"
                  localization="pt-br"
                  closeOnMouseLeave
                  value={
                    this.state.filter.selected_year || new Date().getFullYear()
                  }
                  onChange={(a, b) => {
                    this.setState(
                      {
                        filter: {
                          ...this.state.filter,
                          selected_year: b.value,
                        },
                      },
                      this.reloadData
                    );
                  }}
                />
              </div>
            ) : null}

            {customRightButtons.length ? (
              <div className="select-all">
                {customRightButtons.map((button) => {

                  if (button.button) return button.button

                  return (
                    <Button
                      key={button.title}
                      icon
                      size="mini"
                      color={button.color}
                      title={button.title}
                      onClick={button.action}
                      loading={button.loading}
                    >
                      <Icon name={button.icon} />
                    </Button>
                  );
                })} </div>) : null}

            {showRightButtonsBar ? (
              <div>
                {showSelectAll && (
                  <div className="select-all">
                    <div className="checkbox-bloco" style={{ height: 25 }}>
                      <Checkbox onChange={this.checkAll} checked={allChecked} />
                    </div>
                    <div className="dropdown-all">
                      <Dropdown direction="right">
                        <Dropdown.Menu position="right">
                          <Dropdown.Item
                            onClick={this.handleOpenDeleteAllModal}
                            icon="trash"
                            text="Deletar"
                          />
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                )}
                {mergeDuplicated ? (
                  <div className="adicionar-list">
                    <Button
                      icon="user times"
                      size="mini"
                      color="blue"
                      onClick={() => this.handleOpenMergeModal(checkedItems)}
                      title="Mesclar Contatos Selecionados"
                    />
                  </div>
                ) : (
                    ""
                  )}
                {oldticketBt ? (
                  <div className="adicionar-list">
                    <Button
                      icon="user"
                      size="mini"
                      color="blue"
                      onClick={() => openUserModal(checkedItems)}
                      title="Alterar responsável"
                    />
                  </div>
                ) : (
                    ""
                  )}
                {oldticketBt ? (
                  <div className="adicionar-list">
                    <Button
                      size="mini"
                      icon="check"
                      color="blue"
                      onClick={() => changeStatus(checkedItems)}
                      title="Alterar Status"
                    />
                  </div>
                ) : (
                    ""
                  )}

                {oldticketBt ? (
                  <div className="adicionar-list">
                    <Button
                      size="mini"
                      icon="users"
                      color="blue"
                      onClick={() => mergeTickets(checkedItems)}
                      title="Mesclar Tickets"
                    />
                  </div>
                ) : (
                    ""
                  )}
                {showAddButton && (
                  <div className="adicionar-list">
                    <Button
                      icon="add"
                      color="blue"
                      size="mini"
                      disabled={this.props.loading}
                      onClick={onAddClick}
                      title={this.props.addButtonLabel || "Novo Registro"}
                    />
                  </div>
                )}
                {showExportOptions && <div className="adicionar-list">
                  <Dropdown
                    floating
                    button
                    className="icon"
                    title="Opções de Exportação"
                    style={{
                      color: "#fff",
                      backgroundColor: "#2fac66",
                      width: "30.25px",
                      height: "28px",
                    }}
                  >
                    <Dropdown.Menu color="blue">
                      <Dropdown.Header icon="tags" content="Exportar" />
                      <Dropdown.Menu scrolling>
                        <Dropdown.Item>
                          <Button
                            color="blue"
                            onClick={this.createPDF}
                            icon="file pdf outline"
                            title="Gerar PDF"
                          />
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <DownloadExcel
                            columNames={columNames}
                            dataColumn={dataColumn}
                          />
                        </Dropdown.Item>
                        {datacsv ? (
                          <Dropdown.Item>
                            <CSVLink data={csv}>
                              <Button color="blue">CSV</Button>
                            </CSVLink>
                          </Dropdown.Item>
                        ) : null}
                      </Dropdown.Menu>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>}
                {this.props.relation ? (
                  <div className="adicionar-list">
                    <Button
                      icon="linkify"
                      color="blue"
                      size="mini"
                      disabled={false}
                      onClick={this.onNewRelationClick}
                      title="Adicionar Relacionamento"
                    />
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
        ) : null}

        <div className="filter" style={{ width: "100%", float: "left" }}>
          {this.props.users && (
            <div style={{ float: "left", marginLeft: 10, fontWeight: "bold" }}>
              <Dropdown
                clearable
                selectOnBlur={false}
                placeholder="Usuário:"
                scrolling
                style={{
                  minWidth: 130,
                  fontSize: "13px",
                  fontWeight: 600,
                  color: "rgb(47 172 102)",
                }}
                name="user_id"
                options={this.props.users}
                onChange={this.props.handleUserSelectChange}
              />
            </div>
          )}
          {this.props.ticketStatus && (
            <div style={{ float: "left", marginLeft: 50, fontWeight: "bold" }}>
              <Dropdown
                clearable
                scrolling
                selectOnBlur={false}
                placeholder="Status:"
                style={{
                  minWidth: 130,
                  fontSize: "13px",
                  fontWeight: 600,
                  color: "rgb(47 172 102)",
                }}
                name="ticket_status_id"
                options={this.props.ticketStatus}
                onChange={this.props.handleStatusFilterChanged}
              />
            </div>
          )}


                      

          {this.props.departments && (
            <div style={{ float: "left", marginLeft: 50, fontWeight: "bold" }}>
              <Dropdown
                clearable
                selectOnBlur={false}
                scrolling
                placeholder="Departamento:"
                style={{
                  minWidth: 130,
                  fontSize: "13px",
                  fontWeight: 600,
                  color: "rgb(47 172 102)",
                }}
                name="department_id"
                options={this.props.departments}
                onChange={this.props.handleDepartmentsFilterChanged}
              />
            </div>
          )}

            {this.props.showDateFilter &&
              <div style={{ float: "left", marginLeft: 50, fontWeight: "bold" }}>
                <Dropdown
                    style={{
                      minWidth: 130,
                      fontSize: "13px",
                      fontWeight: 600,
                      color: "rgb(47 172 102)",
                    }}
                    scrolling
                    clearable
                    options={dateFilterType}
                    name="period"
                    selectOnBlur={false}
                    onChange={this.props.handlePeriodFilterChanged}
                    placeholder="Período"
                  />                  
                </div>
        }

        {this.props.period && this.props.period == "custom" &&
                <div
                        style={{
                          display: "flex",
                          gap: 3
                        }}
                        className="ticket-period-container"
                      >
                        <DateInput
                          size="mini"
                          placeholder="Data Inicial"
                          name="start_date"
                          iconPosition="left"
                          style={{width: 150}}
                          clearable
                          onChange={this.props.handlePeriodFilterChanged}
                          value={this.props.start_date}
                        />

                        <DateInput
                          size="mini"
                          placeholder="Data Final"
                          name="end_date"
                          iconPosition="left"
                          style={{width: 150}}
                          clearable
                          onChange={this.props.handlePeriodFilterChanged}
                          value={this.props.end_date}
                        />
                      </div>}
        </div>
        <Table
          size="small"
          sortable
          unstackable
          celled
          collapsing
          id={this.props.phone_table ? "" : "data-table"}
          style={{ fontSize: "10px", lineHeight: "10px" }}
        >
          <Table.Header>
            <Table.Row>
              {Object.keys(columns).map((c, i) => (
                <Table.HeaderCell
                  key={`column-${i * 1}`}
                  onClick={() => {
                    extra_fields.find((e) => e === c)
                      ? alert(
                        "Por se tratar de um Campo Adicional, não é possível fazer ordenação nesta coluna!"
                      )
                      : this.onOrderChange(c);
                  }}
                >
                  <span>
                    {columns[c]}
                    <span
                      className={
                        c === order
                          ? orderDirection === "asc"
                            ? "ascended"
                            : "descended"
                          : ""
                      }
                    />
                  </span>
                </Table.HeaderCell>
              ))}
              {!hide_actions || this.props.rowSelected ? (
                <Table.HeaderCell className="actions_header">
                  #
                </Table.HeaderCell>
              ) : null}
            </Table.Row>
          </Table.Header>

          {(tableTickets === true || tableCampaing) &&
            !this.props.rowSelected ? (
              <Table.Body className="tickets-row">
                {data.map((d, i) => (
                  <Table.Row className="table-list" key={`contact-${i * 1}`}>
                    {columNames.map((c, i) => (
                      <Table.Cell
                        key={`row-column-${d.id}-${i}`}
                        onClick={() => this.selectRow(d)}
                      >
                        {d[c]}
                      </Table.Cell>
                    ))}

                    {hide_actions ? null : (
                      <Table.Cell>
                        <div className="icon-list">
                          {this.props.controlCampaing &&
                            (d.status_campaing === 1 || d.paused === 1) ? (
                              this.props.loading ? (
                                <Icon loading={this.props.loading} name="spinner" />
                              ) : (
                                  <Icon
                                    onClick={() => this.props.setPaused(d)}
                                    name={d.paused ? "play" : "pause"}
                                  />
                                )
                            ) : null}
                          {setServerId ? <Icon
                            className="celular"
                            onClick={() => setServerId(d.id)}
                            name="search"
                          /> : null}
                          {action_disconnect === true ? (
                            <Modal
                              closeIcon
                              open={this.state.desconnectModal}
                              onClose={this.cancelDisconect}
                              size="mini"
                              trigger={
                                <Icon
                                  name="lock"
                                  onClick={() => this.cancelDisconect(d.id)}
                                />
                              }
                            >
                              <Modal.Header>
                                <Icon name="unlock" /> Deseja desconectar
                            </Modal.Header>
                              <Modal.Description>
                                <p>
                                  Ao desconectar perderá o acesso de envio de
                                  mensagem por este número
                              </p>
                                <br />
                                <p>
                                  <strong>continuar</strong>
                                </p>
                              </Modal.Description>
                              <Modal.Actions>
                                <Button
                                  color="red"
                                  onClick={this.cancelDisconect}
                                >
                                  <Icon name="remove" /> não
                              </Button>
                                <Button
                                  color="green"
                                  loading={this.state.loading}
                                  disabled={this.state.loading}
                                  onClick={this.disconnectUser}
                                >
                                  <Icon name="checkmark" /> Sim
                              </Button>
                              </Modal.Actions>
                            </Modal>
                          ) : (
                              ""
                            )}

                          {action_reconnect === true ? (
                            <Modal
                              closeIcon
                              open={this.state.reconnectModal}
                              onClose={this.cancelReconnect}
                              size="mini"
                              trigger={
                                <Icon
                                  name="refresh"
                                  title="Reconectar"
                                  onClick={() => this.cancelReconnect(d.id)}
                                />
                              }
                            >
                              <Modal.Header>
                                <Icon name="refresh" title="Reconectar" />{" "}
                              Tentaremos reconectar a sua conta
                            </Modal.Header>
                              <Modal.Description>
                                <p>
                                  Tentaremos reconectar sua conta caso ela ainda
                                  esteja ativa em nosso servidor
                              </p>
                                <br />
                                <p>
                                  <strong>Continuar ?</strong>
                                </p>
                              </Modal.Description>
                              <Modal.Actions>
                                <Button
                                  color="red"
                                  onClick={this.cancelReconnect}
                                >
                                  <Icon name="remove" /> não
                              </Button>
                                <Button
                                  color="green"
                                  loading={this.state.loading}
                                  disabled={this.state.loading}
                                  onClick={this.reconnect}
                                >
                                  <Icon name="checkmark" /> Sim
                              </Button>
                              </Modal.Actions>
                            </Modal>
                          ) : (
                              ""
                            )}

                          {phone_icon === true ? (
                            <Icon
                              className="celular"
                              onClick={() => this.select(d)}
                              name="mobile alternate"
                            />
                          ) : (
                              ""
                            )}

                          <span>
                            {group_action === true ? (
                              <Icon
                                className="group"
                                onClick={() => this.selectGroup(d)}
                                name="users"
                              />
                            ) : (
                                ""
                              )}
                          </span>

                          {campaignActions && (
                            <Icon
                              onClick={() => this.props.onRowClick(d)}
                              name="comment"
                              style={{ float: "none" }}
                              title="Detalhes das mensagens"
                            />
                          )}

                          {campaignActions && (
                            <Icon
                              onClick={() => this.props.copyCampaign(d.id)}
                              name="copy"
                              title="Copiar Campanha"
                            />
                          )}

                          {this.props.openSendMessageModal && (
                            <Icon
                              onClick={() => this.props.openSendMessageModal()}
                              name={"comments"}
                            />
                          )}

                          {showEdit && <Icon onClick={() => this.select(d)} name="edit" />}

                          {showDelete && <Icon
                            onClick={() => this.handleOpenDeleteModal(d.id)}
                            name="trash"
                            color="red"
                          />}

                          <Checkbox
                            checked={checkedItems.indexOf(d.id) !== -1}
                            onChange={(e, data) =>
                              this.handleCheckBoxChanged(data.checked, d.id)
                            }
                          />
                        </div>
                      </Table.Cell>
                    )}
                  </Table.Row>
                ))}
              </Table.Body>
            ) : (
              <Table.Body>
                {data.map((d, i) => (
                  <Table.Row className="table-list" key={`contact-${i * 1}`}>
                    {columNames.map((c, i) => (
                      <Table.Cell key={`row-column-${d.id}-${i}`}>
                        {d[c]}
                      </Table.Cell>
                    ))}

                    {this.props.rowSelected && (
                      <Table.Cell>
                        <div className="icon-list">
                          <Icon
                            onClick={() => this.campaignBack(d)}
                            name="backward"
                          />
                        </div>
                      </Table.Cell>
                    )}
                    {!hide_actions && (
                      <Table.Cell>
                        <div className="icon-list">
                          {campaignActions && (
                            <span
                              className={
                                d.status.props.children === "Iniciada"
                                  ? ""
                                  : "hide"
                              }
                            >
                              <Icon name={d.paused === 0 ? "pause" : "play"} />
                            </span>
                          )}
                          {campaignActions && (
                            <span
                              className={
                                d.status.props.children === "Interrompida" ||
                                  (d.status.props.children === "Finalizada" &&
                                    d.sent_messages < d.total_messages)
                                  ? ""
                                  : "hide"
                              }
                            >
                              <Icon name="refresh" />
                            </span>
                          )}
                          {this.props.addToWallet && (
                            <span
                              title="Adicionar na carteira"
                              onClick={() => this.toggleWallet(d)}
                            >
                              <Icon name="money bill alternate" />
                            </span>
                          )}
                          {setServerId ? <Icon
                            className="celular"
                            onClick={() => setServerId(d.id)}
                            name="search"
                          /> : null}
                          {action_disconnect === true ? (
                            <Modal
                              closeIcon
                              open={this.state.desconnectModal}
                              onClose={this.cancelDisconect}
                              size="mini"
                              trigger={
                                <Icon
                                  name="lock"
                                  onClick={() => this.cancelDisconect(d.id)}
                                />
                              }
                            >
                              <Modal.Header>
                                <Icon name="unlock" /> Deseja desconectar
                            </Modal.Header>
                              <Modal.Description>
                                <p>
                                  Ao desconectar perderá o acesso de envio de
                                  mensagem por este número
                              </p>
                                <br />
                                <p>
                                  <strong>continuar</strong>
                                </p>
                              </Modal.Description>
                              <Modal.Actions>
                                <Button
                                  color="red"
                                  onClick={this.cancelDisconect}
                                >
                                  <Icon name="remove" /> não
                              </Button>
                                <Button
                                  color="green"
                                  onClick={this.disconnectUser}
                                >
                                  <Icon name="checkmark" /> Sim
                              </Button>
                              </Modal.Actions>
                            </Modal>
                          ) : (
                              ""
                            )}

                          {d.cloud_id && this.props.reboot_server ? (
                            <Modal
                              closeIcon
                              open={this.state.desconnectModal}
                              onClose={this.cancelDisconect}
                              size="mini"
                              trigger={
                                <Icon
                                  name="power off"
                                  title="Reiniciar"
                                  onClick={() => this.cancelDisconect(d.id)}
                                />
                              }
                            >
                              <Modal.Header>
                                <Icon name="unlock" /> Deseja reiniciar o Servidor
                            </Modal.Header>
                              <Modal.Actions>
                                <Button
                                  color="red"
                                  onClick={this.cancelDisconect}
                                >
                                  <Icon name="remove" /> não
                              </Button>
                                <Button color="green" onClick={this.rebootServer}>
                                  <Icon name="checkmark" /> Sim
                              </Button>
                              </Modal.Actions>
                            </Modal>
                          ) : null}

                          {action_reconnect === true ? (
                            <Modal
                              closeIcon
                              open={this.state.reconnectModal}
                              onClose={this.cancelReconnect}
                              size="mini"
                              trigger={
                                <Icon
                                  name="refresh"
                                  onClick={() => this.cancelReconnect(d.id)}
                                />
                              }
                            >
                              <Modal.Header>
                                <Icon name="refresh" /> Tentaremos reconectar a
                              sua conta
                            </Modal.Header>
                              <Modal.Description>
                                <p>
                                  Tentaremos reconectar sua conta caso ela ainda
                                  esteja ativa em nosso servidor
                              </p>
                                <br />
                                <p>
                                  <strong>Continuar?</strong>
                                </p>
                              </Modal.Description>
                              <Modal.Actions>
                                <Button
                                  color="red"
                                  onClick={this.cancelReconnect}
                                >
                                  <Icon name="remove" /> não
                              </Button>
                                <Button
                                  color="green"
                                  loading={this.state.loading}
                                  disabled={this.state.loading}
                                  onClick={this.reconnect}
                                >
                                  <Icon name="checkmark" /> Sim
                              </Button>
                              </Modal.Actions>
                            </Modal>
                          ) : (
                              ""
                            )}
                          {phone_icon === true ? (
                            <Icon
                              className="celular"
                              onClick={() => this.select(d)}
                              name="mobile alternate"
                            />
                          ) : (
                              ""
                            )}

                          {this.props.openSendMessageModal && (
                            <Icon
                              onClick={() => this.props.openSendMessageModal(d)}
                              title="Enviar Mensagem"
                              name={"send"}
                            />
                          )}

                          {this.props.openUserWallet && (
                            <Icon
                              name="users"
                              onClick={() => this.props.openUserWallet(d)}
                              color="green"
                              title="Carteira"
                            />
                          )}

                          {showEdit && <Icon onClick={() => this.select(d)} name="edit" />}

                          <span>
                            {d.is_system_status === 1 ||
                              d.is_system_status === true && showDelete ? (
                                ""
                              ) : (
                                <Icon
                                  onClick={() => this.handleOpenDeleteModal(d.id)}
                                  name="trash"
                                  color="red"
                                />
                              )}
                          </span>

                          {d.is_system_status === 1 ||
                            d.is_system_status === true ? (
                              ""
                            ) : (
                              <Checkbox
                                checked={checkedItems.indexOf(d.id) !== -1}
                                onChange={(e, data) =>
                                  this.handleCheckBoxChanged(data.checked, d.id)
                                }
                              />
                            )}
                        </div>
                      </Table.Cell>
                    )}
                  </Table.Row>
                ))}
              </Table.Body>
            )}
          {this.props.showPagination && this.props.data.length > 0 ? (
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell>
                  <span
                    style={{
                      textalign: "center",
                      fontSize: 12,
                      display: "flex",
                      alignItems: "center",
                      justifyItems: "start",
                    }}
                  >
                    Total:&nbsp;
                    <strong> {`${this.props.totalRecords} `}</strong>
                  </span>
                </Table.HeaderCell>

                <Table.HeaderCell colSpan={oldticketBt ? 14 : 15}>
                  <Pagination
                    activePage={activePage}
                    boundaryRange={boundaryRange}
                    onPageChange={this.handlePaginationChange}
                    size="mini"
                    siblingRange={siblingRange}
                    totalPages={totalPages}
                    ellipsisItem={showEllipsis ? undefined : null}
                    firstItem={showFirstAndLastNav ? undefined : null}
                    lastItem={showFirstAndLastNav ? undefined : null}
                    prevItem={showPreviousAndNextNav ? undefined : null}
                    nextItem={showPreviousAndNextNav ? undefined : null}
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          ) : null}
        </Table>

        <Modal
          open={this.state.newRelationModalOpen}
          onClose={this.onNewRelationClick}
          closeIcon
          size="small"
        >
          <Header
            icon="linkify"
            content={`Adicionar Relacionamento entre os Cadastros`}
          />
          <Modal.Content>
            <Form>
              <Form.Group widths="equal">
                <Form.Field
                  label="Possui Vários:"
                  control={Dropdown}
                  name="relations"
                  onChange={this.handleRelationChange}
                  search
                  clearable
                  selection
                  multiple
                  closeOnChange
                  scrolling
                  options={this.state.pages}
                  value={this.state.relations}
                />
              </Form.Group>
            </Form>
          </Modal.Content>
        </Modal>

        <Modal
          open={this.state.modalDeleteOpen}
          onClose={this.handleCloseDeleteModal}
          closeIcon
          size="mini"
        >
          <Header
            icon="trash"
            content={`Exclusão do Registro ID: ${selectedId}`}
          />
          <Modal.Content>
            <p>{deleteMessage}</p>
            {selectUserModal ? (
              <div className="selectDrop">
                Selecionar Usuário para assumir Tickets:
                <div className="field">
                  <label>Responsável</label>
                  <DropdownUsers
                    onSelectUser={handleUserChange}
                    user_id={user_id.user_id}
                    allowAdditions={false}
                  />
                </div>
              </div>
            ) : (
                ""
              )}

            <p>
              <strong>Continuar ?</strong>
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button color="red" onClick={this.handleCloseDeleteModal}>
              <Icon name="remove" /> Não
            </Button>
            <Button
              color="green"
              onClick={this.delete}
              disabled={loading}
              loading={loading}
            >
              <Icon name="checkmark" /> Sim
            </Button>
          </Modal.Actions>
        </Modal>

        <Modal
          open={this.state.modalDeleteAllOpen}
          onClose={this.handleCloseDeleteAllModal}
          closeIcon
          size="mini"
        >
          <Header icon="trash" content="Exclusão de Multiplos Registros!" />
          <Modal.Content>
            <p>
              {`Você selecionou ${checkedItems.length
                } registros para exclusão! Esses registros não poderão ser recuperados!`}
            </p>
            <p>
              <strong>Continuar ?</strong>
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button color="red" onClick={this.handleCloseDeleteAllModal}>
              <Icon name="remove" /> Não
            </Button>
            <Button
              color="green"
              onClick={this.delete}
              disabled={loading}
              loading={loading}
            >
              <Icon name="checkmark" /> Sim
            </Button>
          </Modal.Actions>
        </Modal>

        <Modal
          size="small"
          open={this.state.mergeContactModalOpen}
          closeIcon
          onClose={this.toggleMergeContactModal}
        >
          <Modal.Header>Mesclar Contatos</Modal.Header>
          <Modal.Content>
            <Message error>
              Os contatos selecionados e todos os seus dados(Tickets, Mensagens,
              conversas) serão mesclados automaticamente!
            </Message>
          </Modal.Content>

          <Modal.Actions>
            <Button
              negative
              content="Mesclar"
              onClick={() => this.removeDuplicated(this.state.selectedId)}
              loading={loading}
              disabled={loading}
            />
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

DataTable.defaultProps = {
  extra_fields: [],
  showActions: true,
  showPagination: true,
};

DataTable.propTypes = {
  columns: PropTypes.shape({}).isRequired,
  totalRecords: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default DataTable;
