import React, { Component } from "react";
import api from "../../api/ticket-category";
import { Dropdown } from "semantic-ui-react";
import AddCategoryModal from "./modal/AddCategoryModal";

class DropdownCategories extends Component {
  state = {
    ticketcategory: {
      description: "",
      category: "",
    },
    options: [],
    loading: false,
    search: "",
    openModal: false,
  };

  handleCategoryAddition = (e) => {};

  onSearchChange = (e, { searchQuery }) => {
    clearTimeout(this.timer);
    this.setState({ search: searchQuery });
    this.timer = setTimeout(this.onSearchCategory, 300);
  };
  componentWillMount() {
    api.ticketcategory.fetchAll({ take: 200 }).then((ticketcategorys) => {
      this.setState({
        options: ticketcategorys.data.map((c) => ({
          key: c.id,
          value: c.id,
          text: c.description,
          content: (
            <div
              style={{ display: "flex", flexDirection: "column" }}
              key={"custom-item-" + c.id}
            >
              <div>
                <span style={{ fontWeight: 500, color: "#505050" }}>
                  {c.description}
                </span>
              </div>
              <div>
                <span style={{ color: "#838282" }}>{c.ticketCategory}</span>
              </div>
            </div>
          ),
          category: c.ticketCategory,
        })),
        loading: false,
      });
    });
  }

  componentDidMount() {
    const { options } = this.props;

    if (options) {
      this.setState({
        options: options.map((c) => ({
          key: c.id,
          value: c.id,
          text: `${c.description}`,
        })),
      });
    }
  }

  onSearchCategory = async () => {
    this.setState({ loading: true });
    const { search } = this.state;

    await api.ticketcategory.fetchAll({ search }).then((ticketcategorys) => {
      this.setState({
        options: ticketcategorys.data.map((c) => ({
          key: c.id,
          value: c.id,
          text: c.description,
          content: (
            <div
              style={{ display: "flex", flexDirection: "column" }}
              key={"custom-item-" + c.id}
            >
              <div>
                <span style={{ fontWeight: 500, color: "#505050" }}>
                  {c.description}
                </span>
              </div>
              <div>
                <span style={{ color: "#838282" }}>{c.ticketCategory}</span>
              </div>
            </div>
          ),
          category: c.ticketCategory,
        })),
        loading: false,
      });
    });
  };

  onChange = (e, { name, value }) => {
    this.setState((state) => ({
      ticketcategory: { ...state.ticketcategory, [name]: value },
    }));
  };

  cleanErrors = () => {
    this.setState({ errors: "" });
  };

  submit = () => {
    const { ticketcategory } = this.state;

    this.setState({
      loading: true,
    });

    return api.ticketcategory
      .submit(ticketcategory)
      .then((data) => {
        this.setState((state) => ({
          options: [...state.options].concat({
            key: data.data.id,
            value: data.data.id,
            text: data.data.description,
          }),
          loading: false,
          ticketcategory: {
            description: "",
            category: "",
          },
          openModal: !state.openModal,
        }));

        this.props.onSelectCategory(data.data.id);
      })
      .catch((err) => {
        this.setState({
          loading: false,
          errors: err.response,
        });
      });
  };

  toggle = (e, { name, value }) => {
    this.setState((state) => ({
      openModal: !state.openModal,
      ticketcategory: {
        description: value,
        category: "",
      },
    }));
  };

  render() {
    const { options, loading, openModal, ticketcategory } = this.state;
    const { allowAdditions, handleCategoryChange } = this.props;

    return (
      <div>
        <Dropdown
          placeholder="Pesquise uma Categoria"
          fluid
          search
          selection
          scrolling
          allowAdditions={allowAdditions}
          additionLabel="Adicionar: "
          name="ticket_category_id"
          value={this.props.ticket_category_id}
          onSearchChange={this.onSearchChange}
          onChange={(e, { value }) => this.props.onSelectCategory(value)}
          options={options}
          onAddItem={this.toggle}
          closeOnChange
          loading={loading || this.props.loading}
          clearable
        />

        <AddCategoryModal
          open={openModal}
          handleCategoryChange={handleCategoryChange}
          ticketcategory={ticketcategory}
          save={this.submit}
          toggle={this.toggle}
          onChange={this.onChange}
          options={options}
        />
      </div>
    );
  }
}

export default DropdownCategories;
