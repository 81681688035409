import React from "react";
import { Modal, Button, Form, Input } from "semantic-ui-react";

const AddDepartmentModal = ({
  open,
  toggle,
  onChange,
  department,
  loading,
  save,
}) => (
  <Modal
    size="small"
    closeIcon
    open={open}
    onClose={toggle}
    dimmer="blurring"
    closeOnDimmerClick={false}
  >
    <Modal.Header>Cadastro de Departamento</Modal.Header>
    <Modal.Content>
      <Modal.Description>
        <Form size="mini">
          <Form.Group widths="equal">
            <Form.Field
              control={Input}
              label="Nome"
              name="name"
              onChange={onChange}
              value={department.name}
            />
            <Form.Field
              control={Input}
              label="Descrição"
              name="description"
              onChange={onChange}
              value={department.description}
            />
          </Form.Group>
        </Form>
      </Modal.Description>
    </Modal.Content>
    <Modal.Actions>
      <Button.Group>
        <Button onClick={toggle}>Cancelar</Button>
        <Button.Or />
        <Button positive onClick={save} loading={loading} disabled={loading}>
          Salvar
        </Button>
      </Button.Group>
    </Modal.Actions>
  </Modal>
);

export default AddDepartmentModal;
