import React from "react";
import { Grid } from "semantic-ui-react";

export default () => (
  <Grid>
    <h2>Alterar Status do Ticket</h2>
    <p>
      Podemos verificar que uma seta para baixo apareceu aqui , ao aproximar o
      cursor do mouse aparece uma sequência de opções que são editaveis pelo
      administrador do sistema, a pergunta é para que serve ?
    </p>
    <span style={{ textAlign: "center", margin: "auto", marginBottom: 10 }}>
      <p>Pera ai eu respondo!</p>
    </span>
    <p>
      Existe uma sequência de ações , que começa com o inicio do ticket e vai
      seguindo os processo até o seu fechamento. É útil para você saber em que
      fase do atendimento seu cliente está , lembre-se essas ações não são vista
      pelo cliente apenas você e sua equipe poderão visualizar essa sequência de
      ações.
    </p>
    <p>
      Nesta mesta barra temos tambem a opção de se atribuir um Ticket para você
      , veja se o ticket não tem dono você pode atribuir-lo a você , ou se você
      responder a mensagem automaticaente ele passa a ser atribuido a você .
    </p>
    <p>
      Agora se não aparecer nenhuma seta é poque o cliente não tem Ticket , já
      foi atendido.
    </p>
  </Grid>
);
