import React from "react";
import { Grid } from "semantic-ui-react";

export default () => (
  <Grid>
    <Grid.Row>
      <h2>Tickets</h2>
    </Grid.Row>
    <Grid.Row>
      <p>
        Aqui é apresentado um resumo dos Tickets abertos e aguardando e o agente
        que está atendendo o ticket.
      </p>
    </Grid.Row>
  </Grid>
);
