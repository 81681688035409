import axios from "../services/api";

export default {
  ticketStatus: {
    fetchAll: (params) =>
      axios.get(`/ticket-status`, { params }).then((res) => res.data),
    fetchStatusMain: (params) =>
      axios
        .get(`/ticket-status?take=100`, { params })
        .then((res) => res.data.data),
    delete: (id) =>
      axios.delete(`/ticket-status/${id}`).then((res) => res.data),
    update: (id, ticketStatus) =>
      axios.put(`/ticket-status/${id}`, ticketStatus).then((res) => res.data),
    submit: (ticketStatus) =>
      axios.post(`/ticket-status`, ticketStatus).then((res) => res.data),
  },
};
