import React from "react";
import { Grid } from "semantic-ui-react";

export default () => (
  <Grid>
    <Grid.Row>
      <h2>Financeiro</h2>
      <p>
        Aqui é onde se cadastra ver em relação ao plano usado e ver valores dos
        planos para alteração dos mesmos.
      </p>
    </Grid.Row>
  </Grid>
);
