import React from "react";
import { Grid, Icon } from "semantic-ui-react";

export default () => (
  <Grid>
    <h2>Status de conexão</h2>
    <p>
      <Icon name="mobile alternate" className="add-comment" /> Status conexão
      Whatsapp Abre a tela de Edição do Atendimento Automático, onde podemos
      configurar: resposta automática, envio em massa, pode responder,
      atendimento automátizado, o nome do assistente automátizado, enviar emal
      se perder a autenticação com whatsapp, legenda do atendimento e recarregar
      o QRcode para reconectar o whatsapp.
    </p>
    <p>Em exibição fica o status de conexão do whatsapp. os status são:</p>
    <p>
      <Icon name="mobile alternate" color="green" /> Conectado
    </p>
    <p>
      <Icon name="mobile alternate" color="orange" /> Desconectado
    </p>
    <p>
      <Icon name="mobile alternate" color="red" /> Não autenticado
    </p>
  </Grid>
);
