import React, { Component } from "react";
import { connect } from "react-redux";
import api from "../../api/social-media";
import DataTable from "../table/DataTable";
import { Icon } from "semantic-ui-react";
import SocialMediaModal from "../social-media/modal/SocialMediaModal";
import Socket from "./../../services/socket";
import AlertRed from "../alerts/AlertRed";

class Celular extends Component {
  state = {
    phone: [],
    records: [],
    columns: {},
    order: {},
    errors: "",
    loading: false,
    total_records: 0,
    selectedDataIndex: -1,
    editModalOpen: false,
    loadingQrCode: false,
    qrCodeCreated: false,
    qrCodeImage: null,
    loginSuccess: false,
    save_alert: false,
    action_disconnect: true,
    phone_icon: true,
    phoneNumber: "",
    connected: "",
  };

  select = (selectedDataId) => {
    const dataIndex = this.state.records.findIndex(
      (c) => c.id === selectedDataId
    );

    this.setState({ selectedDataIndex: dataIndex, editModalOpen: true });
  };
  
  createQrCode = () => {
    this.setState({
      loadingQrCode: true,
    });

    api.media.createQrCode().then((res) => { });
  };
  componentDidMount() {
    this.fetchRecords();

    Socket.connector.pusher.config.auth.headers.Authorization = `Bearer ${localStorage.token
      }`;

    Socket.private(`channel-${this.props.company_id}`).listen(
      "WhatsAppAccountStatusChanged",
      this.setAccountStatus
    );
  }

  setAccountStatus = (e) => {
    const { account } = e;
    const { records } = this.state;
    const data = records.find((a) => a.id === account.id);
    const dataIndex = records.findIndex((a) => a.id === account.id);

    if (data) {
      this.setState({
        records: [
          ...records.slice(0, dataIndex),
          {
            ...records[dataIndex],
            connected: account.connected,
          },
          ...records.slice(dataIndex + 1),
        ],
      });
    }
  };

  newDataClick = () => {
    const { records } = this.state;

    const newData = records[0]
      ? Object.keys(records[0]).reduce(
        (o, key) => Object.assign(o, { [key]: "" }),
        {}
      )
      : {
        event_type: "",
        url: "",
        headers: "",
        headers_value: "",
      };

    newData.webhook_active = true;
    newData.send_campaing = true;
    newData.auto_reply = false;
    newData.auto_reply_image = false;
    newData.auto_reply_image_path = false;
    newData.media_type = 1;
    newData.active = 1;
    newData.connected = 0;
    newData.can_reply = 0;
    newData.intelliway_integration = 0;
    newData.send_email_on_deauthentication = 0;
    newData.virtual_assistant = "Assistente Automático";

    this.setState(
      {
        records: [...records].concat(newData),
        editModalOpen: true,
      },
      () => {
        this.setState({
          selectedDataIndex: this.state.records.length - 1,
        });
      }
    );
  };

  nextRecord = () => {
    const { records, selectedDataIndex: dataIndex } = this.state;
    const selectedDataIndex =
      dataIndex < records.length - 1 ? dataIndex + 1 : records.length - 1;
    const selectedDataId = records[selectedDataIndex].id;

    this.select(selectedDataId);
  };

  previousRecord = () => {
    const { records, selectedDataIndex: dataIndex } = this.state;
    const selectedDataIndex = dataIndex - 1 > 0 ? dataIndex - 1 : 0;
    const selectedDataId = records[selectedDataIndex].id;

    this.select(selectedDataId);
  };

  fetchRecords = async (params) => {
    this.setState({
      records: this.props.accounts.records,
      order: this.props.accounts.order,
      columns: this.props.accounts.columns,
      total_records: this.props.accounts.total_records,
      loading: false,
    });
  };

  handleChange = (e, { name, value }) => {
    const { selectedDataIndex: dataIndex, records } = this.state;

    this.setState({
      records: [
        ...records.slice(0, dataIndex),
        {
          ...records[dataIndex],
          [name]: value,
        },
        ...records.slice(dataIndex + 1),
      ],
    });
  };

  handleChecked = (e, { name, checked }) => {
    const { selectedDataIndex: dataIndex, records } = this.state;

    this.setState({
      records: [
        ...records.slice(0, dataIndex),
        {
          ...records[dataIndex],
          [name]: checked,
        },
        ...records.slice(dataIndex + 1),
      ],
    });
  };

  cleanErrors = () => {
    this.setState({ errors: "" });
  };

  submit = () => {
    const { selectedDataIndex: dataIndex, records } = this.state;
    this.setState({
      loading: true,
    });
    return api.media
      .submit(records[dataIndex])
      .then((data) => {
        this.setState({
          save_alert: true,
          loading: false,
          selectedDataIndex: -1,
          editModalOpen: false,
          records: [
            ...records.slice(0, dataIndex),
            data.data,

            ...records.slice(dataIndex + 1),
          ],
        });
        setTimeout(
          function () {
            this.setState({ save_alert: false });
          }.bind(this),
          5000
        );
      })
      .catch((err) => {
        this.setState({
          loading: false,
          errors: err.response,
        });
      });
  };

  update = () => {
    const { selectedDataIndex, records } = this.state;
    const data = records[selectedDataIndex];
    this.setState({ loading: true });

    api.media.update(data.id, data).then((data) => {
      this.setState({
        records: [
          ...records.slice(0, selectedDataIndex),
          {
            ...data.data,
          },
          ...records.slice(selectedDataIndex + 1),
        ],
        save_alert: true,
        editModalOpen: false,
        selectedDataIndex: -1,
        loading: false,
      });
      setTimeout(
        function () {
          this.setState({ save_alert: false });
        }.bind(this),
        5000
      );
    }).catch(err => {
      this.setState({
        errors: err.response,
        loading: false
      });
    });
  };

  delete = (id) => api.media.delete(id);

  handleCloseEditModal = () => {
    const { records } = this.state;
    this.setState({
      editModalOpen: false,
      selectedId: -1,
      selectedDataIndex: -1,
      loadingQrCode: false,
      records: [...records].filter((c) => c.id > 0),
    });
  };

  render() {
    const {
      records,
      loading,
      columns,
      total_records,
      selectedDataIndex,
      editModalOpen,
      action_disconnect,
      connected,
      phone_icon,
    } = this.state;
    const postpaid = this.props.user.companies[0].parameters.postpaid
    const media = records[selectedDataIndex];

    return (
      <div className="icone_celular">
        {records.length > 0 &&
          records.filter((c) => c.connected === 0 || c.connected === 1).length ===
          0 ? (
          <AlertRed
            handleClick={this.newDataClick}
            message="Nenhuma conta de Whatsapp Conectada. Sua mensagem não será enviada. Clique aqui para conectar!"
          />
        ) : null}
        <div className="phone_icon">
          {records && records.length > 0 ? (
            <DataTable
              loading={loading}
              onAddClick={this.newDataClick}
              columns={columns}
              postpaid={postpaid}
              phone_icon={phone_icon}
              action_disconnect={action_disconnect}
              phone_table={true}
              data={records.map((r) => {
                return {
                  ...r,
                  connected:
                    r.connected == 1
                      ? "Conectado"
                      : r.connected == 0 || r.connected == ""
                        ? "Desconectado"
                        : r.connected == 2
                          ? "Não Autenticado"
                          : "",
                  can_spam: r.can_spam ? "Sim" : "Não",
                  can_reply: r.can_reply ? "Sim" : "Não",
                  auto_reply: r.auto_reply ? "Sim" : "Não",
                };
              })}
              totalRecords={total_records}
              messageError={
                this.state.errors.status > 0
                  ? this.state.errors.data.message
                  : ""
              }
              onDelete={(id) => this.delete(id)}
              onEditClick={(d) => this.select(d.id)}
              fetchData={this.fetchRecords}
            />
          ) : (
            <Icon
              onClick={this.newDataClick}
              name="whatsapp"
              color="orange"
              size="large"
            />
          )}
        </div>
        {selectedDataIndex !== -1 ? (
          <SocialMediaModal
            handleClose={this.handleCloseEditModal}
            qrCodeImage={this.state.qrCodeImage}
            qrCodeCreated={this.state.qrCodeCreated}
            createQrCode={this.createQrCode}
            loadingQrCode={this.state.loadingQrCode}
            loginSuccess={this.state.loginSuccess}
            phone_number={this.state.phoneNumber}
            connected={connected}
            messageError={
              this.state.errors.status > 0
                ? this.state.errors.data.message
                : ""
            }
            onChange={this.handleChange}
            onChecked={this.handleChecked}
            handleNext={() => { }}
            handlePrevious={() => { }}
            onClickSave={this.update}
            onClickAdd={this.submit}
            handleGroupAddition={this.addGroup}
            media={media}
            modalHeader={
              media.id ? `Edição do ${media.virtual_assistant}` : `Novo Número`
            }
            open={editModalOpen}
            previousButtonEnabled={selectedDataIndex === 0}
            nextButtonEnabled={selectedDataIndex === records.length - 1}
            loading={loading}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return {
    company_id: user.user.company_id,
  };
};

export default connect(mapStateToProps)(Celular);
