import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import MenuContent from "./MenuContent";

const MenuLinks = ({ user: { permissions = [] }, user, conversations }) => {
  const [navLinks, setNavLinks] = useState([]);

  useEffect(() => {
    const companyList =
      (permissions.find((c) => c === "list-report") && user.is_admin) ||
      user.is_partner;
    const editInvoices = permissions.find(
      (c) => c === "edit-company-pricing-plan"
    );
    const verPesquisas =
      permissions.find((c) => c === "list-report") && !user.is_partner;
    const verLogs =
      (permissions.find((c) => c === "list-report") && user.is_admin) ||
      user.is_partner;
    const editCompany = permissions.find((c) => c === "edit-company");
    const addPartner = user.is_manager;
    const ticketList = permissions.find((c) => c === "list-ticket");
    const campaignList = permissions.find((c) => c === "list-campaing");
    const relationshipList = permissions.find((c) => c === "list-relationship");
    const report =
      permissions.find((p) => p === "list-report") && !user.is_partner;
    const menuConversation = permissions.find((p) => p === "list-conversation");
    const dashboard =
      permissions.find((p) => p === "list-report") && !user.is_partner;
    const menuConfiguration = permissions.find(
      (p) => p === "list-configuration"
    );

    const parameters = user.companies.find((c) => c.id === user.company_id)
      .parameters;

    const menuConfirmation = !!parameters.enable_datasigh_integration

    console.log(menuConfirmation)

    setNavLinks([
      {
        name: "",
        links: [
          {
            id: dashboard ? 1 : "",
            title: dashboard ? "Dashboard" : "",
            url: dashboard ? "/dashboard" : "",
            icon: dashboard ? "chart line" : "",
          },
          {
            id: ticketList ? 6 : "",
            title: ticketList ? "Tickets" : "",
            url: ticketList ? "/tickets" : "",
            icon: ticketList ? "ticket alternate" : "",
          },
          {
            id: menuConversation ? 2 : "",
            title: menuConversation ? "Conversas" : "",
            url: menuConversation ? "/conversation" : "",
            icon: menuConversation ? "conversation" : "",
            conversations: true,
          },
          {
            id: menuConfiguration && parameters.monitcall_client_key ? 8 : "",
            title:
              menuConfiguration && parameters.monitcall_client_key
                ? "Ligações"
                : "",
            url:
              menuConfiguration && parameters.monitcall_client_key
                ? "/calls"
                : "",
            icon:
              menuConfiguration && parameters.monitcall_client_key
                ? "phone"
                : "",
          },
          {
            id: relationshipList ? 4 : "",
            title: relationshipList ? "Relacionamento" : "",
            url: relationshipList ? "/relationship" : "",
            icon: relationshipList ? "handshake outline" : "",
          },
          {
            id: campaignList ? 5 : "",
            title: campaignList ? "Campanhas" : "",
            url: campaignList ? "/campaign" : "",
            icon: campaignList ? "bullhorn" : "",
          },

          {
            id: report ? 7 : "",
            title: report ? "Relatórios" : "",
            url: report ? "/report" : "",
            icon: report ? "newspaper outline" : "",
            parent: true,
          },
          {
            id: menuConfiguration ? 8 : "",
            title: menuConfiguration ? "Configurações" : "",
            url: menuConfiguration ? "/configurations" : "",
            icon: menuConfiguration ? "settings" : "",
          },
          {
            id: companyList ? "3" : "",
            title: companyList ? "Administração" : "",
            url: companyList ? "/company" : "",
            icon: companyList ? "configure" : "",
          },
          {
            id: editCompany ? "3" : "",
            title: editCompany ? "Empresas" : "",
            url: editCompany ? "/companies" : "",
            icon: editCompany ? "building" : "",
          },
          {
            id: addPartner ? "11" : "",
            title: addPartner ? "Parceiros" : "",
            url: addPartner ? "/partner" : "",
            icon: addPartner ? "briefcase" : "",
          },
          {
            id: editInvoices ? 8 : "",
            title: editInvoices ? "Faturas" : "",
            url: editInvoices ? "/invoices" : "",
            icon: editInvoices ? "dollar" : "",
          },
          {
            id: editInvoices ? 8 : "",
            title: editInvoices ? "Franquias Extras" : "",
            url: editInvoices ? "/quotas-report" : "",
            icon: editInvoices ? "dollar" : "",
          },
          {
            id: menuConfirmation ? 9 : "",
            title: menuConfirmation ? "Confirmações" : "",
            url: menuConfirmation ? "/confirmacoes" : "",
            icon: menuConfirmation ? "calendar" : "",
          },
          {
            id: verPesquisas ? 9 : "",
            title: verPesquisas ? "Avaliações" : "",
            url: verPesquisas ? "/pesquisas" : "",
            icon: verPesquisas ? "search" : "",
          },
          {
            id: verLogs ? 9 : "",
            title: verLogs ? "Bot Flow" : "",
            url: verLogs ? "/bot-drawer" : "",
            icon: verLogs ? "sitemap" : "",
          },
          {
            id: verLogs ? 9 : "",
            title: verLogs ? "Logs" : "",
            url: verLogs ? "/log" : "",
            icon: verLogs ? "user secret" : "",
          },
        ],
      },
    ]);
  }, [user]);

  return navLinks.map((navLink) => (
    <div className="navConfig" key={`menu-offside-${navLink.id}`}>
      <MenuContent
        navLinks={navLink.links}
        conversationsLoaded={conversations && conversations.length}
      />
    </div>
  ));
};

const mapStateToProps = ({ conversation, user }) => {
  return {
    user: user.user,
    conversations: !conversation.data ? [] : Object.values(conversation.data),
  };
};

export default connect(mapStateToProps, null)(MenuLinks);
