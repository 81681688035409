import React from "react";
import { Grid, Icon } from "semantic-ui-react";

export default () => (
  <Grid>
    <h2>Perfil de usuário</h2>
    <p>Ao clicar na seta irá aparecer as seguintes opções:</p>
    <p>
      <strong>Pausa de Atendimento:</strong> Clicando aqui vai te dar as opções
      de pausa de atendimento
    </p>
    <p>
      <strong>Dados da Empresa:</strong> Clicando aqui vai abrir uma guia para
      se quiser editar os dados da empresa
    </p>
    <p>
      <strong>Perfil:</strong> Clicando aqui abre a tela para editar o perfil do
      usuário logado
    </p>
    <p>
      <strong>Central de suporte:</strong> Em desenvolvimento
    </p>
    <p>
      <strong>Termo de uso:</strong> Clicando aqui abre a tela com os termos de
      uso
    </p>
    <p>
      <strong>Política de privacidade:</strong> Clicando aqui abre a tela com as
      políticas de privacidade
    </p>
    <p>
      <strong>Contato:</strong> Em desenvolvimento
    </p>
    <p>
      <strong>Logout:</strong> Clicando aqui vai sair da plataforma
    </p>
    <p>
      <strong>Autorização de Acesso:</strong> Para dar acesso do suporte a sua
      plataforma com mesmo usuário
    </p>
  </Grid>
);
