import React from "react";
import { Grid } from "semantic-ui-react";

export default () => (
  <Grid>
    <Grid.Row>
      <h2>Mensagem Rápida</h2>
      <p>
        Aqui é onde se cadastra as mensagens rápidas, essas mensagens vão
        aparecer na hora que estiver ou em tickets ou em conversas.
      </p>
    </Grid.Row>
  </Grid>
);
