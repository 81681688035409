import React from "react";
import { Grid } from "semantic-ui-react";

export default () => (
  <Grid>
    <Grid.Row>
      <h2>Enviadas/Total</h2>
      <p>
        Nesta coluna você verá a as número de mensagens enviadas e o tatal a ser
        enviado
      </p>
    </Grid.Row>
  </Grid>
);
