import React from "react";
import { Grid } from "semantic-ui-react";

export default () => (
  <Grid>
    <Grid.Row>
      <h2>Relacionamentos</h2>
      <p>
        Aqui vamos cuidar dos relacionamentos com os clientes, os compromissos,
        configuração do Robô e cadastro de clientes e empresas
      </p>
      <p>Vamos começar com os compromissos....</p>
    </Grid.Row>
  </Grid>
);
