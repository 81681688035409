import React, { Component } from "react";
import NewConversation from "./NewConversation";
import { Link } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import { bindActionCreators } from "redux";
import { Creators as TaskActions } from "../../store/ducks/task";
import { Creators as TourActions } from "../../store/ducks/tour";
import { connect } from "react-redux";
import NewTicketModal from "../tickets/modal/NewTicketModal";

class Icons extends Component {
  state = {
    contact: {},
    ticket: {},
    loading: false,
    showInfo: false,
  };

  render() {
    return (
      <div className="dropdown-criar-novo">
        {/* <Link to="/" onClick={() => this.props.openTour()}>
          <Icon name="rocket" className="add-comment" size="large" />
        </Link> */}
        {/* <Link to="/kb" onClick={() => {}} title="Base de Conhecimento">
          <Icon name="book" className="add-comment" size="large" />
        </Link> */}
        <NewTicketModal />
        <NewConversation />
        {/* <Link to="/events">
          <Icon
            name="calendar alternate outline"
            className="add-comment"
            size="large"
          />
        </Link> */}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...TaskActions, ...TourActions }, dispatch);

const mapStateToProps = (state) => ({
  user: state.user.user,
  task: state.task,
  editModalOpen: state.task.editModalOpen,
  selectedDataIndex: state.task.selectedDataIndex,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Icons);
