import React, { useEffect, useRef, lazy, Suspense } from "react";

import { connect } from "react-redux";
import { Creators as AuthActions } from "./store/ducks/auth";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import "./stylesheets/style.css";
import { Route, useHistory } from "react-router";
import api from "./api/user";
import socket from "./services/socket";
import "./stylesheets/style.css";
import GuestRoute from "./components/routes/GuestRoute";
import AuthorizedRoute from "./components/routes/AuthorizedRoute";
import LoaderComponent from "./components/semantic/Loading";

const ConfigPage = lazy(() => import("./components/pages/ConfigPage"));
const ClientPage = lazy(() => import("./components/pages/ClientPage"));
const TicketCategoryPage = lazy(() =>
  import("./components/pages/TicketCategoryPage")
);
const QuickMessagePage = lazy(() =>
  import("./components/pages/QuickMessagePage")
);
const SocialMediaPage = lazy(() =>
  import("./components/pages/SocialMediaPage")
);
const TicketStatusPage = lazy(() =>
  import("./components/pages/TicketStatusPage")
);
const DepartmentPage = lazy(() => import("./components/pages/DepartmentPage"));
const WebHookPage = lazy(() => import("./components/pages/WebHookPage"));
const ServerPage = lazy(() => import("./components/pages/ServerPage"));
const UsersPage = lazy(() => import("./components/pages/UsersPage"));
const BadWordPage = lazy(() => import("./components/pages/BadWordPage"));
const TagPage = lazy(() => import("./components/pages/TagPage"));
const AlertWordPage = lazy(() => import("./components/pages/AlertWordPage"));
const ParametersPage = lazy(() => import("./components/pages/ParametersPage"));
const PlansPage = lazy(() => import("./components/pages/PlansPage"));
const PricingPlanPage = lazy(() =>
  import("./components/pages/PricingPlanPage")
);
const SurveyPage = lazy(() => import("./components/pages/SurveyPage"));
const ContactsGroupsPages = lazy(() =>
  import("./components/pages/ContactsGroupsPages")
);
const ContactPage = lazy(() => import("./components/pages/ContactPage"));
const RestrictedContactsPages = lazy(() =>
  import("./components/pages/RestrictedContactsPages")
);
const RelationshipPages = lazy(() =>
  import("./components/pages/RelationshipPages")
);
const ProfessionalsPages = lazy(() =>
  import("./components/pages/ProfessionalsPages")
);
const PlacesPages = lazy(() => import("./components/pages/PlacesPages"));
const SpecialitiesPages = lazy(() =>
  import("./components/pages/SpecialitiesPages")
);
const SchedulesPages = lazy(() => import("./components/pages/SchedulesPages"));
const TriggersPages = lazy(() => import("./components/pages/TriggersPages"));
const TicketsPages = lazy(() => import("./components/pages/TicketsPages"));
const CampaignPages = lazy(() => import("./components/pages/CampaignPages"));
const BotContextPages = lazy(() =>
  import("./components/pages/BotContextPages")
);
const BotVarsPages = lazy(() => import("./components/pages/BotVarsPages"));
const BotSubstitutionsPages = lazy(() =>
  import("./components/pages/BotSubstitutionsPages")
);
const EventsPages = lazy(() => import("./components/pages/EventsPages"));
const CompaniesPages = lazy(() => import("./components/pages/CompaniesPages"));
const EmailPages = lazy(() => import("./components/pages/EmailPages"));
const SMSPages = lazy(() => import("./components/pages/SMSPages"));
const CallPages = lazy(() => import("./components/pages/CallPages"));
const SummaryPages = lazy(() => import("./components/pages/SummaryPages"));
const ProfilePages = lazy(() => import("./components/pages/ProfilePages"));
const ReportPages = lazy(() => import("./components/pages/ReportPages"));
const LogPages = lazy(() => import("./components/pages/LogPages"));
const CompanyPages = lazy(() => import("./components/pages/CompanyPages"));
const MonitoringPages = lazy(() =>
  import("./components/pages/MonitoringPages")
);
const InterruptionPages = lazy(() =>
  import("./components/pages/InterruptionPages")
);
const LoginPage = lazy(() => import("./components/pages/LoginPage"));
const RegisterPage = lazy(() => import("./components/pages/RegisterPage"));
const ForgotPage = lazy(() => import("./components/pages/ForgotPage"));
const ResetPage = lazy(() => import("./components/pages/ResetPage"));
const RolesPages = lazy(() => import("./components/pages/RolesPages"));
const PermissionsPages = lazy(() =>
  import("./components/pages/PermissionsPages")
);

const ExtraInputPage = lazy(() => import("./components/pages/ExtraInputPage"));
const Page = lazy(() => import("./components/pages/Page"));
const CustomPage = lazy(() => import("./components/pages/CustomPage"));
const InvoicePage = lazy(() => import("./components/pages/InvoicePage"));
const WhatsappMonitorPage = lazy(() =>
  import("./components/pages/WhatsappMonitorPage")
);
const UserMonitorPage = lazy(() =>
  import("./components/pages/UserMonitorPage")
);
const TicketConversation = lazy(() =>
  import("./components/tickets/modal/TicketConversation")
);
const CallsPages = lazy(() => import("./components/pages/CallsPages"));
const KnowledgeBase = lazy(() => import("./components/pages/KnowledgeBase"));
const SuportePage = lazy(() => import("./components/pages/SuportePage"));
const EditClientPage = lazy(() => import("./components/pages/EditClientPage"));
const ConfirmationPage = lazy(() => import("./components/pages/ConfirmationPage"));
const EditTicketPage = lazy(() => import("./components/pages/EditTicketPage"));
const SyncMessagesPage = lazy(() =>
  import("./components/pages/SyncMessagesPage")
);
const PesquisaSatisfacao = lazy(() =>
  import("./components/pages/PesquisaSatisfacao")
);
const HomePage = lazy(() => import("./components/pages/HomePage"));
const BotPage = lazy(() => import("./components/pages/BotPage"));
const QuotasPage = lazy(() => import("./components/pages/QuotasPage"));
const DissatisfactionTypePage = lazy(() =>
  import("./components/pages/DissatisfactionTypePage")
);
const DashPage = lazy(() => import("./components/pages/DashPage"));
const Facebook = lazy(() => import("./components/pages/FacebookPage"));
const OpenAI = lazy(() => import("./components/pages/OpenAI"));
const PartnerPage = lazy(() => import("./components/pages/PartnerPage"));
const QRCodePage = lazy(() => import("./components/pages/QRCodePage"));
const PromoPage = lazy(() => import("./components/pages/PromoPage"));
const FranquiasAdicionaisPage = lazy(() =>
  import("./components/pages/FranquiasAdicionaisPage")
);

const App = ({
  location,
  setUserInfos,
  user,
  setParameters,
  setPermissions,
  setUserActive,
  logout,
  history
}) => {
  const inactivityTimeoutRef = useRef(null);

  useEffect(() => {
    if (localStorage.token) {
      socket
        .private(`user-logged-out-${user.id}`)
        .listen("UserLoggedOut", logout);

      socket
        .private(`notification-${user.id}`)
        .listen("NewNotificationCreated", (e) => {});

      api.user.getInfo(user.id).then((data) => {
        if (data.is_logged_out) {
          logout();
          return;
        }

        setUserInfos(data);
        setParameters(data.parameters);
        setPermissions(data.permissions, data.pages);
        setUserActive(data.currentPause);

        if (localStorage.redirect) {
          const redirect = localStorage.redirect
          localStorage.removeItem("redirect");
          history.push(redirect);
        }
      });
    }

    const resetTimeout = () => {
      if (inactivityTimeoutRef.current) {
        clearTimeout(inactivityTimeoutRef.current);
      }
      inactivityTimeoutRef.current = setTimeout(() => {
        window.location.reload();
      }, 600000);
    };

    const clearEvents = () => {
      window.removeEventListener("mousemove", resetTimeout);
      window.removeEventListener("keypress", resetTimeout);
    };

    const setEvents = () => {
      window.addEventListener("mousemove", resetTimeout);
      window.addEventListener("keypress", resetTimeout);
    };

    setEvents();
    resetTimeout();

    return () => {
      clearEvents();
      clearTimeout(inactivityTimeoutRef.current);
    };
  }, []);

  return (
    <div className="App">
      <Suspense fallback={<LoaderComponent></LoaderComponent>}>
        <AuthorizedRoute
          location={location}
          path="/"
          exact
          component={HomePage}
        />
        <AuthorizedRoute
          location={location}
          path="/dashboard"
          exact
          component={SummaryPages}
        />
        <AuthorizedRoute
          location={location}
          path="/conversation/caller/:caller"
          exact
          requiredPermission="list-conversation"
          component={HomePage}
        />
        <AuthorizedRoute
          location={location}
          path="/conversation/:conversationId/ticket/:ticketId"
          exact
          requiredPermission="list-conversation"
          component={HomePage}
        />
        <AuthorizedRoute
          location={location}
          path="/ticket/:conversationId"
          exact
          requiredPermission="list-ticket"
          component={TicketConversation}
        />
        <AuthorizedRoute
          location={location}
          path="/conversation"
          requiredPermission="list-conversation"
          exact
          component={HomePage}
        />
        <AuthorizedRoute
          location={location}
          path="/quotas"
          requiredPermission="list-report"
          exact
          component={QuotasPage}
        />

        <AuthorizedRoute
          location={location}
          path="/whatsapp-monitor"
          requiredPermission="delete-permission"
          exact
          component={WhatsappMonitorPage}
        />
        <AuthorizedRoute
          location={location}
          path="/facebook"
          requiredPermission="list-report"
          component={Facebook}
        />
        <AuthorizedRoute
          location={location}
          path="/openai"
          requiredPermission="list-configuration"
          component={OpenAI}
        />
        <AuthorizedRoute
          location={location}
          path="/user-monitor"
          exact
          component={UserMonitorPage}
        />
        <AuthorizedRoute
          location={location}
          path="/calls"
          requiredPermission="delete-permission"
          exact
          component={CallsPages}
        />
        <AuthorizedRoute
          location={location}
          path="/configurations"
          requiredPermission="list-configuration"
          exact
          component={ConfigPage}
        />
        <AuthorizedRoute
          location={location}
          path="/invoices"
          requiredPermission="edit-company-pricing-plan"
          exact
          component={InvoicePage}
        />
        <AuthorizedRoute
          location={location}
          path="/quotas-report"
          requiredPermission="edit-company-pricing-plan"
          exact
          component={FranquiasAdicionaisPage}
        />
        <AuthorizedRoute
          location={location}
          path="/pesquisas"
          exact
          component={SurveyPage}
        />
        <AuthorizedRoute
          location={location}
          path="/client"
          requiredPermission="edit-client"
          exact
          component={ClientPage}
        />
        <AuthorizedRoute
          location={location}
          path="/client/:id"
          requiredPermission="edit-client"
          exact
          component={EditClientPage}
        />
        <AuthorizedRoute
          location={location}
          path="/ticket/:id/edit"
          requiredPermission="edit-ticket"
          exact
          component={EditTicketPage}
        />
        <AuthorizedRoute
          location={location}
          path="/sync-messages"
          requiredPermission="edit-ticket"
          exact
          component={SyncMessagesPage}
        />
        <AuthorizedRoute
          location={location}
          path="/users"
          requiredPermission="edit-user"
          exact
          component={UsersPage}
        />
        <AuthorizedRoute
          location={location}
          path="/department"
          requiredPermission="edit-department"
          exact
          component={DepartmentPage}
        />
        <AuthorizedRoute
          location={location}
          path="/extra-input"
          requiredPermission="edit-extra-input"
          exact
          component={ExtraInputPage}
        />
        <AuthorizedRoute
          location={location}
          path="/page"
          exact
          component={Page}
          requiredPermission="edit-page"
        />
        <AuthorizedRoute
          location={location}
          path="/custom-page/:id"
          exact
          component={CustomPage}
        />
        <AuthorizedRoute
          location={location}
          path="/ticket-status"
          exact
          requiredPermission="list-ticket-status"
          component={TicketStatusPage}
        />
        <AuthorizedRoute
          location={location}
          path="/ticket-category"
          requiredPermission="list-ticket-category"
          exact
          component={TicketCategoryPage}
        />
        <AuthorizedRoute
          location={location}
          path="/quick-message"
          requiredPermission="edit-fast-message"
          exact
          component={QuickMessagePage}
        />
        <AuthorizedRoute
          location={location}
          path="/social-media"
          requiredPermission="edit-social-account"
          exact
          component={SocialMediaPage}
        />
        <AuthorizedRoute
          location={location}
          path="/webhook"
          requiredPermission="list-webhook"
          exact
          component={WebHookPage}
        />
        <AuthorizedRoute
          location={location}
          path="/server"
          requiredPermission="list-server"
          exact
          component={ServerPage}
        />
        <AuthorizedRoute
          location={location}
          path="/bad-word"
          requiredPermission="list-bad-word"
          exact
          component={BadWordPage}
        />
        <AuthorizedRoute
          location={location}
          path="/tag"
          requiredPermission="list-bad-word"
          exact
          component={TagPage}
        />
        <AuthorizedRoute
          location={location}
          path="/dissatisfaction-type"
          requiredPermission="list-bad-word"
          exact
          component={DissatisfactionTypePage}
        />
        <AuthorizedRoute
          location={location}
          path="/alert-word"
          requiredPermission="list-alert-word"
          exact
          component={AlertWordPage}
        />
        <AuthorizedRoute
          location={location}
          path="/parameters"
          requiredPermission="list-parameter"
          exact
          component={ParametersPage}
        />
        <AuthorizedRoute
          location={location}
          path="/plans"
          requiredPermission="list-report"
          exact
          component={PlansPage}
        />
        <AuthorizedRoute
          location={location}
          path="/pricing-plan"
          requiredPermission="edit-pricing-plan"
          exact
          component={PricingPlanPage}
        />
        <AuthorizedRoute
          location={location}
          path="/bot-drawer"
          requiredPermission="list-report"
          exact
          component={BotPage}
        />
        <AuthorizedRoute
          location={location}
          path="/contacts"
          requiredPermission="list-contact"
          exact
          component={ContactPage}
        />
        <AuthorizedRoute
          location={location}
          path="/contacts/groups"
          requiredPermission="list-contact-group"
          exact
          component={ContactsGroupsPages}
        />
        <AuthorizedRoute
          location={location}
          path="/confirmacoes"
          exact
          component={ConfirmationPage}
        />
        <AuthorizedRoute
          location={location}
          path="/contacts/restricted-contacts"
          exact
          component={RestrictedContactsPages}
        />
        <AuthorizedRoute
          location={location}
          path="/relationship"
          requiredPermission="list-relationship"
          exact
          component={RelationshipPages}
        />
        <AuthorizedRoute
          location={location}
          path="/professionals"
          requiredPermission="list-professional"
          exact
          component={ProfessionalsPages}
        />
        <AuthorizedRoute
          location={location}
          path="/specialities"
          requiredPermission="list-speciality"
          exact
          component={SpecialitiesPages}
        />
        <AuthorizedRoute
          location={location}
          path="/schedules"
          requiredPermission="list-schedule"
          exact
          component={SchedulesPages}
        />
        <AuthorizedRoute
          location={location}
          path="/triggers"
          requiredPermission="list-bot-trigger"
          exact
          component={TriggersPages}
        />
        <AuthorizedRoute
          location={location}
          path="/places"
          requiredPermission="list-place"
          exact
          component={PlacesPages}
        />
        <AuthorizedRoute
          location={location}
          path="/tickets"
          requiredPermission="list-ticket"
          exact
          component={TicketsPages}
        />
        <AuthorizedRoute
          location={location}
          path="/campaign"
          requiredPermission="list-campaing"
          exact
          component={CampaignPages}
        />
        <AuthorizedRoute
          location={location}
          requiredPermission="list-bot-context"
          path="/bot-context"
          exact
          component={BotContextPages}
        />
        <AuthorizedRoute
          location={location}
          requiredPermission="list-bot-var"
          path="/bot-vars"
          exact
          component={BotVarsPages}
        />
        <AuthorizedRoute
          location={location}
          path="/bot-substitutions"
          requiredPermission="list-bot-substitution"
          exact
          component={BotSubstitutionsPages}
        />
        <AuthorizedRoute
          location={location}
          requiredPermission="list-report"
          path="/company"
          exact
          component={CompaniesPages}
        />
        <AuthorizedRoute
          location={location}
          requiredPermission="list-company"
          path="/partner"
          exact
          component={PartnerPage}
        />
        <AuthorizedRoute
          location={location}
          path="/events"
          exact
          component={EventsPages}
        />
        <AuthorizedRoute
          location={location}
          requiredPermission="list-email"
          path="/email"
          exact
          component={EmailPages}
        />
        <AuthorizedRoute
          location={location}
          requiredPermission="list-sms"
          path="/sms"
          exact
          component={SMSPages}
        />
        <AuthorizedRoute
          location={location}
          requiredPermission="list-call"
          path="/call"
          exact
          component={CallPages}
        />

        <AuthorizedRoute
          location={location}
          path="/profile"
          exact
          component={ProfilePages}
        />

        <GuestRoute
          location={location}
          path="/kb"
          exact
          component={KnowledgeBase}
        />

        <GuestRoute
          location={location}
          path="/promo/group/:group"
          exact
          component={PromoPage}
        />

        <Route
          location={location}
          path="/pesquisa/:id"
          exact
          render={(props) => <PesquisaSatisfacao {...props} />}
        />

        <AuthorizedRoute
          location={location}
          path="/report"
          requiredPermission="list-report"
          exact
          component={DashPage}
        />

        <AuthorizedRoute
          location={location}
          path="/dash"
          requiredPermission="list-report"
          exact
          component={ReportPages}
        />

        <AuthorizedRoute
          location={location}
          path="/log"
          requiredPermission="list-report"
          exact
          component={LogPages}
        />

        <AuthorizedRoute
          location={location}
          requiredPermission="list-company"
          path="/companies"
          exact
          component={CompanyPages}
        />

        <AuthorizedRoute
          location={location}
          path="/monitoring"
          exact
          component={MonitoringPages}
        />
        <AuthorizedRoute
          location={location}
          path="/interruption-type"
          requiredPermission="list-interruption-type"
          exact
          component={InterruptionPages}
        />
        <AuthorizedRoute
          location={location}
          requiredPermission="list-role"
          path="/roles"
          exact
          component={RolesPages}
        />

        <AuthorizedRoute
          location={location}
          requiredPermission="list-permission"
          path="/permissions"
          exact
          component={PermissionsPages}
        />

        <GuestRoute
          location={location}
          path="/login"
          exact
          component={LoginPage}
        />

        <GuestRoute
          location={location}
          path="/qrcode/:key"
          exact
          component={QRCodePage}
        />

        <GuestRoute
          location={location}
          path="/suporte"
          exact
          component={SuportePage}
        />

        <GuestRoute
          location={location}
          path="/register"
          exact
          component={RegisterPage}
        />
        <GuestRoute
          location={location}
          path="/forgot"
          exact
          component={ForgotPage}
        />
        <GuestRoute location={location} path="/reset" component={ResetPage} />
      </Suspense>
    </div>
  );
};

App.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(AuthActions, dispatch);

const mapStateToProps = ({ user: { user } }) => {
  return {
    user,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
