import React from "react";
import { Grid, Icon } from "semantic-ui-react";

export default () => (
  <Grid>
    <h2>Calendário</h2>
    <p>
      <Icon name="calendar alternate outline" className="add-comment" /> Abre o
      calendário de eventos e tarefas!
    </p>
  </Grid>
);
