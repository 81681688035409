import api from "../../api/ticket-status";

export const Types = {
  IS_FETCHING: "ticketStatus/IS_FETCHING",
  TICKET_STATUS_FETCH: "ticketStatus/TICKET_STATUS_FETCH",
};

const INITIAL_STATE = {
  records: [],
};

// reducer
export default function task(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case Types.IS_FETCHING:
      return { ...state, loading: action.loading };
    case Types.TICKET_STATUS_FETCH:
      return {
        records: action.payload,
      };

    default:
      return state;
  }
}

// actions

export const Creators = {
  fetchTicketStatus: (params) => (dispatch) => {
    dispatch({ type: Types.IS_FETCHING, loading: true });

    const filter = { filter: JSON.stringify([["active", "=", 1]]) };

    api.ticketStatus
      .fetchStatusMain({ order: "menu_order", order_direction: "asc", filter })
      .then((res) => {
        dispatch({
          type: Types.TICKET_STATUS_FETCH,
          payload: res,
        });
      });
  },
};
