import React from "react";
import { connect } from "react-redux";
import { Modal, Button, Icon } from "semantic-ui-react";
import FormSocialMedia from "../forms/FormSocialMedia";
import Socket from "./../../../services/socket";
import api from "../../../api/social-media";
import { Creators as ConversationActions } from "../../../store/ducks/conversation";
import { bindActionCreators } from "redux";
import AlertError from "../../alerts/AlertError";
import { ConnectButton } from "360dialog-connect-button";

class SocialMediaModal extends React.Component {
  state = {
    qrCodeCreated: false,
    qrCodeImage: "",
    account_number: "",
    loadingQrCode: false,
    qrCodeRead: false,
    token: "",
  };

  componentDidMount() {

    if (this.props.editModalOpen) {
      document.removeEventListener("keydown", () => { });
      
    }

    const { company_id } = this.props;

    Socket.private(`channel-${company_id}`).listen(
      "WhatsAppLoginSuccess",
      (e) => {
        window.location.reload();
      }
    );

    Socket.private(`qrcode-read-${this.state.token}`).listen(
      "QRCodeRead",
      (e) => {
        if (e.token === this.state.token) {
          this.setState({
            qrCodeRead: true,
          });
        }
      }
    );

    Socket.private(`channel-${company_id}`).listen(
      "WhatsAppQRCodeCreated",
      (e) => {
        this.setState({
          qrCodeCreated: true,
          qrCodeImage: e.qrCode.file_name,
          loadingQrCode: false,
        });
      }
    );

    /* if (this.props.media.whatsapp_official_account) {
      api.media.balance(this.props.media.id).then(res => {
        
      })
    } */
  }

  componentWillMount() {
    this.setState({
      token: [...Array(32)]
        .map((i) => (~~(Math.random() * 36)).toString(36))
        .join(""),
    });
  }

  handleChange = (e, { name, value }) => {
    this.setState({
      [name]: value
    })
  }

  componentWillUnmount() {
    const { company_id } = this.props;

    Socket.leave(`whatsapp-login-${company_id}`);
    Socket.leave(`whatsapp-qrcode-${company_id}`);
  }

  save = () => {
    if (this.props.media.id) {
      this.props.onClickSave();
    } else {
      this.props.onClickAdd();
    }
  };

  sendTextMessage = () => { };

  createQrCode = () => {
    this.setState({
      loadingQrCode: true,
    });

    api.media
      .createQrCode({ token: this.state.token, phone_number: this.state.account_number })
      .then((res) => {


        if (res.session && res.session.qrcode && (res.session.status == "QRCODE" || res.session.status == "INITIALIZING")) {
          this.setState({
            qrCodeCreated: true,
            qrCodeImage: res.session.qrcode,
            loadingQrCode: false,
          });
        }
      })
      .catch((err) => {


        if (err.response.data && err.response.data.errors) {
          const errors = err.response.data.errors

          for (var e in errors) {
            this.setState({
              error: { message: errors[e][0], [e]: errors[e][0] },
              loadingQrCode: false,
              qrCodeCreated: false,
            });
          }

        } else {

          this.setState({
            error: err.response
              ? err.response.data
              : { message: "Tente Novamente" },
            loadingQrCode: false,
            qrCodeCreated: false,
          });
        }

        setTimeout(() => {
          this.setState({
            error: null,
          });
        }, 10000);
      });
  };

  render() {
    const {
      media,
      handleClose,
      onChange,
      onChecked,
      open,
      modalHeader = "",
      loading,
      loginSuccess,
      phone_number,
      connected,
    } = this.props;

    const {
      qrCodeRead,
      qrCodeCreated,
      qrCodeImage,
      loadingQrCode,
      account_number
    } = this.state;

    return (
      <Modal
        closeIcon
        open={open}
        onClose={handleClose}
        dimmer="blurring"
        closeOnDimmerClick={false}
      >
        <Modal.Header>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            {modalHeader}
            <ConnectButton
              callback={() => { }}
              partnerId={"HIHQmHPA"}
              title="Conectar com 360Dialog"
              label="Conectar com 360Dialog"
              style={{
                outline: "none",
                background: "#ff4369",
                color: "white",
                cursor: "pointer",
                borderRadius: "3px",
                border: "none",
              }}
            />
          </div>
        </Modal.Header>

        <Modal.Content>
          {this.state.error ? (
            this.state.error.no_plan ? (
              <AlertError
                style={{
                  zIndex: 10000,
                  padding: "5px 10px",
                  marginTop: "-25px",
                  width: 300,
                }}
                message={this.state.error.message}
              />
            ) : (
              <AlertError
                style={{
                  zIndex: 10000,
                  padding: "5px 10px",
                  marginTop: "-25px",
                  width: 300,
                }}
                message={this.state.error.message}
              />
            )
          ) : null}
          {this.props.messageError ? <AlertError
            style={{
              zIndex: 10000,
              padding: "5px 10px",
              marginTop: "-25px",
              width: 300,
            }}
            message={this.props.messageError}
          /> : null}
          <Modal.Description>
            <div className="holder_media">
              <div className="form-media">
                <FormSocialMedia
                  media={media}
                  phone_number={phone_number}
                  account_number={account_number}
                  qrCodeCreated={qrCodeCreated}
                  qrCodeRead={qrCodeRead}
                  loginSuccess={loginSuccess}
                  qrCodeImage={qrCodeImage}
                  onChangeAccount={this.handleChange}
                  error={this.state.error}
                  loadingQrCode={loadingQrCode}
                  createQrCode={this.createQrCode}
                  onChange={onChange}
                  onChecked={onChecked}
                  connected={connected}
                />
              </div>

            </div>
          </Modal.Description>
        </Modal.Content>
        {media.id || media.whatsapp_official_account ? (
          <Modal.Actions>
            <Button.Group>
              <Button onClick={handleClose}>Cancelar</Button>
              <Button.Or />
              <Button
                positive
                onClick={this.save}
                loading={loading}
                disabled={loading}
              >
                Salvar
              </Button>

            </Button.Group>
          </Modal.Actions>
        ) : (
          ""
        )}
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ConversationActions, dispatch);

const mapStateToProps = (state) => {
  return {
    company_id: state.user.user.company_id,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SocialMediaModal);
