import React from "react";
import { Grid } from "semantic-ui-react";

export default () => (
  <Grid>
    <Grid.Row>
      <h2>Geral</h2>
      <p>
        Aqui vamos apresentar o menu Geral do sistema, nessa opção aparecerá os
        menus relacionados aos agentes, e atendimento de tickets.
      </p>
    </Grid.Row>
  </Grid>
);
