// Types declaration
export const Types = {
  TOGGLE_NEW_TICKET_MODAL: "ticket/TOGGLE_NEW_TICKET_MODAL",
};

// Reducer
const INITIAL_STATE = {
  newTicketModalOpen: false,
};

export default function conversation(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case Types.TOGGLE_NEW_TICKET_MODAL:
      return { ...state, newTicketModalOpen: !state.newTicketModalOpen };

    default:
      return state;
  }
}

// Actions
export const Creators = {
  toggleNewTicketModal: () => (dispatch) =>
    dispatch({ type: Types.TOGGLE_NEW_TICKET_MODAL }),
};
