import React from "react";
import { Grid } from "semantic-ui-react";

export default () => (
  <Grid>
    <Grid.Row>
      <h2>Perfis de acesso</h2>
      <p>
        Aqui é onde se cadastra os perfis de acessos, por exemplo: Agente,
        usuário, administrador
      </p>
    </Grid.Row>
  </Grid>
);
