import React, { useState } from "react";
import { Form, Divider, Rating, Image, Grid } from "semantic-ui-react";
import InputMask from "react-input-mask";
import {
  DateInput,
  TimeInput,
  DateTimeInput,
} from "semantic-ui-calendar-react";

const ExtraFieldForm = ({
  extra_fields = [],
  onChange,
  className,
  columns = 3,
}) => {
  const [type, setType] = useState("password");

  const rows = extra_fields.length / columns;

  let groups = [];
  for (var i = 0; i <= rows; i++) {
    groups.push(i);
  }

  const toggleText = () => {
    const newType = type === "password" ? "text" : "password";

    setType(newType);
  };

  return (
    <Grid columns={columns}>
      {groups.map((g, i) => {
        return (
          <Grid.Row key={`extra-field-row-${i}`}>
            {!extra_fields
              ? []
              : extra_fields
                .slice(i * columns, columns * (i + 1))
                .map((e, j) => {
                  switch (e.input_type) {
                    case 0:
                      return (
                        <Grid.Column key={`extra-field-column-${j}-row${i}`}>
                          <Form.Input
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                            width={e.width}
                            action={
                              e.is_link
                                ? {
                                  color: "teal",
                                  icon: "linkify",
                                  onClick: e.value
                                    ? () => window.open(e.value)
                                    : () => { },
                                }
                                : null
                            }
                            fluid
                            label={e.label}
                            placeholder={e.placeholder}
                            name={e.input_name}
                            value={
                              e.is_link
                                ? e.value
                                  ? e.value
                                  : "http://"
                                : e.value
                            }
                            onChange={(a, d) => onChange(a, d, e.id)}
                          />
                        </Grid.Column>
                      );
                      break;
                    case 1:
                      return (
                        <Grid.Column key={`extra-field-column-${j}-row${i}`}>
                          <Form.Dropdown
                            options={e.options.map((o) => ({
                              key: o.id,
                              value: o.id,
                              text: o.value,
                            }))}
                            selection
                            clearable
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                            width={e.width}
                            fluid
                            label={e.label}
                            placeholder={"Selecione uma Opção"}
                            name={e.input_name}
                            value={e.value ? parseInt(e.value) : null}
                            onChange={(a, d) => onChange(a, d, e.id)}
                          />
                        </Grid.Column>
                      );
                      break;
                    case 3:
                      return (
                        <Grid.Column key={`extra-field-column-${j}-row${i}`}>
                          <Form.TextArea
                            rows={2}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                            label={e.label}
                            name={e.input_name}
                            width={e.width}
                            value={e.value}
                            onChange={(a, d) => onChange(a, d, e.id)}
                            title={e.placeholder}
                            placeholder={e.placeholder}
                          />
                        </Grid.Column>
                      );
                      break;
                    case 4:
                      return (
                        <Grid.Column key={`extra-field-column-${j}-row${i}`}>
                          <div>
                            <Form.Field className={className}>
                              <b>{e.label}</b>
                            </Form.Field>
                            {e.options.map((o) => {
                              return (
                                <Form.Radio
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                  label={o.value}
                                  title={e.placeholder}
                                  name={e.input_name}
                                  value={o.id}
                                  checked={
                                    parseInt(e.value) === parseInt(o.id)
                                  }
                                  onChange={(a, d) => onChange(a, d, e.id)}
                                />
                              );
                            })}
                            <Form.Field />
                          </div>
                        </Grid.Column>
                      );
                      break;
                    case 5:
                      return (
                        <Divider key={`extra-field-divider-${j}-row${i}`} />
                      );

                      break;
                    case 6:
                      return (
                        <Grid.Column key={`extra-field-column-${j}-row${i}`}>
                          <div>
                            <Form.Field className={className}>
                              <b>{e.label}</b>
                            </Form.Field>
                            <Rating
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                              className={className}
                              title={e.placeholder}
                              maxRating={e.max_range}
                              defaultRating={e.value ? e.value : e.min_range}
                              icon={e.rating_icon}
                              size={e.rating_size}
                              onRate={(a, d) => onChange(a, d, e.id)}
                            />
                          </div>
                        </Grid.Column>
                      );
                      break;
                    case 7:
                      return (
                        <Grid.Column key={`extra-field-column-${j}-row${i}`}>
                          <div>
                            <Form.Field>
                              <b>{e.label}</b>
                            </Form.Field>
                            <Image
                              src={`${process.env.REACT_APP_DO_BUCKET_URL}/app/profile/profile_pic.png`}
                              size="tiny"
                            />
                          </div>
                        </Grid.Column>
                      );
                      break;
                    case 8:
                      return (
                        <Grid.Column key={`extra-field-column-${j}-row${i}`}>
                          <div>
                            <Form.Field>
                              <b>{e.label}</b>

                              <DateInput
                                name={e.input_name}
                                placeholder={e.placeholder}
                                iconPosition="left"
                                dateFormat="DD/MM/YYYY"
                                value={e.value}
                                onChange={(a, d) => onChange(a, d, e.id)}
                              />
                            </Form.Field>
                          </div>
                        </Grid.Column>
                      );
                      break;
                    case 9:
                      return (
                        <Grid.Column key={`extra-field-column-${j}-row${i}`}>
                          <div>
                            <Form.Field className={className}>
                              <b>{e.label}</b>
                            </Form.Field>
                            <TimeInput
                              name={e.input_name}
                              placeholder={e.placeholder}
                              iconPosition="left"
                              value={e.value}
                              onChange={(a, d) => onChange(a, d, e.id)}
                            />
                          </div>
                        </Grid.Column>
                      );
                      break;
                    case 10:
                      return (
                        <Grid.Column key={`extra-field-column-${j}-row${i}`}>
                          <div>
                            <Form.Field className={className}>
                              <b>{e.label}</b>
                            </Form.Field>
                            <DateTimeInput
                              name={e.input_name}
                              placeholder={e.placeholder}
                              iconPosition="left"
                              dateTimeFormat="DD/MM/YYYY HH:mm"
                              value={e.value}
                              onChange={(a, d) => onChange(a, d, e.id)}
                            />
                          </div>
                        </Grid.Column>
                      );
                      break;
                    case 11:
                      return (
                        <Grid.Column key={`extra-field-column-${j}-row${i}`}>
                          <Form.Input
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                            width={e.width}
                            type={type}
                            fluid
                            label={e.label}
                            placeholder={e.placeholder}
                            name={e.input_name}
                            value={e.value}
                            onClick={toggleText}
                            onChange={(a, d) => onChange(a, d, e.id)}
                          />
                        </Grid.Column>
                      );
                      break;
                    case 12:
                      return (
                        <Grid.Column key={`extra-field-column-${j}-row${i}`}>
                          <Form.Input
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                            width={e.width}
                            action={
                              e.is_link
                                ? {
                                  color: "teal",
                                  icon: "linkify",
                                  onClick: e.value
                                    ? () => window.open(e.value)
                                    : () => { },
                                }
                                : null
                            }
                            fluid
                            label={e.label}
                            placeholder={e.placeholder}
                            name={e.input_name}
                            children={
                              <InputMask
                                mask={e.mask}
                                name={e.input_name}
                                onChange={(evt) =>
                                  onChange(
                                    evt,
                                    {
                                      name: evt.target.name,
                                      value: evt.target.value,
                                    },
                                    e.id
                                  )
                                }
                                value={e.value}
                                placeholder={e.placeholder}
                              />
                            }
                            value={
                              e.is_link
                                ? e.value
                                  ? e.value
                                  : "http://"
                                : e.value
                            }
                            onChange={(a, d) => onChange(a, d, e.id)}
                          />
                        </Grid.Column>
                      );
                      break;
                    default:
                      break;
                  }
                })}
          </Grid.Row>
        );
      })}
    </Grid>
  );
};

export default ExtraFieldForm;
