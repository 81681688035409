import React from "react";
import { Creators as ConversationActions } from "../../store/ducks/conversation";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import logo from "../../assets/img/logogreen.png";
import MenuLinks from "./MenuLinks";

const MenuOffside = () => {
  const toggleClass = () => {
    const element = document.getElementsByName("MenuOffside");
    for (let i = 0; i < element.length; i += 1) {
      element[i].classList.toggle("off-side");
    }
  };

  const screenResize = () => {
    const w = window.innerWidth;

    if (w <= 768) {
      const element = document.getElementsByName("MenuOffside");
      for (let i = 0; i < element.length; i += 1) {
        element[i].classList.toggle("off-side-mobile");
      }
    }
  };

  return (
    <div
      className="MenuOffside"
      name="MenuOffside"
      onMouseOver={toggleClass}
      onMouseOut={toggleClass}
    >
      <Link to="/" className="logo">
        <img src={localStorage.getItem('logo_mobile')?localStorage.getItem('logo_mobile') :logo} alt="" />
      </Link>

      <div className="openMenu" onClick={screenResize}>
        <div className="iconSeta">
          <Icon name="chevron right" />
          <Icon name="chevron left" />
        </div>
      </div>
      <div className="holderNav">
        <PerfectScrollbar>
          <MenuLinks />
        </PerfectScrollbar>
      </div>
    </div>
  );
};

const mapStateToProps = ({ conversation, user, media }) => {
  return {
    user: user.user,
    permissions: user.user.permissions,
    conversation,
    media,
    activeConversationId: conversation.activeConversationId,
    filter: conversation.filter ? conversation.filter : "",
    conversations: !conversation.data ? [] : Object.values(conversation.data),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ConversationActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuOffside);
