import React from "react";
import { Grid } from "semantic-ui-react";

export default () => (
  <Grid>
    <h2>Escrevendo uma mensagem</h2>
    <p>Aqui é onde você vai escrever suas mensagens</p>
    <p>
      Como podemos perceber existem 3 áreas principais neste campo, uma a de
      digitar o texto, outra relacionado a conversa, são para configurações,
      ativação do robô....
    </p>
    <p>
      Com excessão da parte do texto, que é só clicar e digitar vou começar a
      explicar as duas partes então vamos...
    </p>
  </Grid>
);
