import api from "../../api/conversation";
import apimedia from "../../api/social-media";
import moment from "moment";
import "moment/locale/pt-br";

// Types declaration
export const Types = {
  FETCHED: "conversation/FETCHED",
  TICKET_DELETED: "conversation/TICKET_DELETED",
  SYNC_FROM_LOCAL: "conversation/SYNC_FROM_LOCAL",
  FETCHED_SEARCH: "conversation/FETCHED_SEARCH",
  UPDATE_CONVERSATION: "conversation/UPDATE_CONVERSATION",
  UPDATE_CONVERSATION_COUNT: "conversation/UPDATE_CONVERSATION_COUNT",
  GET_CONVERSATION: "conversation/GET_CONVERSATION",
  READ_CONVERSATION: "conversation/READ_CONVERSATION",
  COUNT_CONVERSATION: "conversation/COUNT_CONVERSATION",
  ADD_CONVERSATION: "conversation/ADD_CONVERSATION",
  UNREAD_MESSAGES_CHANGED: "UNREAD_MESSAGES_CHANGED",
  FETCHED_MEDIA: "conversation/FETCHED_MEDIA",
  CHANGE_TICKET_OWNER: "conversation/CHANGE_TICKET_OWNER",
  COMMENT_RECEIVED: "conversation/COMMENT_RECEIVED",
  ON_ERROR: "conversation/ON_ERROR",
  IS_FETCHING: "conversation/IS_FETCHING",
  IS_FETCHED: "conversation/IS_FETCHED",
  SELECTED: "conversation/SELECTED",
  REFRESH_CONVERSATION_STATUS: "conversation/REFRESH_CONVERSATION_STATUS",
  REFRESH_CONVERSATION_MEDIA: "conversation/REFRESH_CONVERSATION_MEDIA",
  UNSELECTED: "conversation/UNSELECTED",
  MESSAGES_FETCHED: "conversation/MESSAGES_FETCHED",
  COMMENTS_FETCHED: "conversation/COMMENTS_FETCHED",
  IS_FETCHING_TICKETS: "conversation/IS_FETCHING_TICKETS",
  SET_CONVERSATION_STATE: "conversation/SET_CONVERSATION_STATE",
  IS_FETCHING_MESSAGES: "conversation/IS_FETCHING_MESSAGES",
  TICKETS_FETCHED: "conversation/TICKETS_FETCHED",
  SET_ACTIVE_TICKET: "conversation/SET_ACTIVE_TICKET",
  SET_TICKET_USER: "conversation/SET_TICKET_USER",
  TICKET_USER_CHANGED: "conversation/TICKET_USER_CHANGED",
  ADD_MESSAGE: "conversation/ADD_MESSAGE",
  UPDATE_TICKET_STATUS: "conversation/UPDATE_TICKET_STATUS",
  AUTO_REPLY: "conversation/AUTO_REPLY",
  UPDATE_MESSAGE_STATUS: "conversation/UPDATE_MESSAGE_STATUS",
  REVOKE_MESSAGE: "conversation/REVOKE_MESSAGE",
  REMOVE_BY_CONTACT: "conversation/REMOVE_BY_CONTACT",
  ADD_COMMENT: "conversation/ADD_COMMENT",
  SET_TICKET_PROGRESS: "conversation/SET_TICKET_PROGRESS",
  SET_TICKET_DEPARTMENT: "conversation/SET_TICKET_DEPARTMENT",
  ADD_TICKET: "conversation/ADD_TICKET",
  TOGGLE_SCROLLING: "conversation/TOGGLE_SCROLLING",
  TICKET_ADDED: "conversation/TICKET_ADDED",
  USER_LOGGED_OUT: "user/USER_LOGGED_OUT",
  SET_ENTER_AS_SEND: "conversation/SET_ENTER_AS_SEND",
  SET_FILTER: "conversation/SET_FILTER",
  STATS_FETCHED: "conversation/STATS_FETCHED",
  ARCHIVE_CONVERSATION: "conversation/ARCHIVE_CONVERSATION",
  HIDE_CONVERSATION: "conversation/HIDE_CONVERSATION",
  ADD_USER_WALLET: "conversation/ADD_USER_WALLET",
  CHANGE_CLIENT: "conversation/CHANGE_CLIENT",
  UPDATE_CLIENT: "conversation/UPDATE_CLIENT",
};

// Reducer
const INITIAL_STATE = {
  data: [],
  media: [],
  activeConversationId: -1,
  loading: true,
  error: {},
  last_read_conversation: {},
  last_sync: new Date(),
  last_count_date: new Date(),
  loadingTickets: false,
  loadingMessages: false,
  enterAsSend: true,
  sendingMessage: false,
  filter: "",
  loadingStats: false,
  waiting: 0,
  assigned: 0,
  total: 0,
  scrolling: false,
  conversationStatus: localStorage.getItem('conversationStatus') || "assigned",
  conversationMedia: localStorage.getItem('conversationMedia') || "",
  user_id: null,
  department_id: null
};

const isBotActive = (chat) => {
  return chat.auto_reply
}

const ticketHasOwner = (ticket) => {
  return ticket.user_id
}

const isNewOwnerDifferent = (ticket) => {
  return ticket.user_id != ticket.previous_user
}

const newUserIsLoggedUser = (ticket, user) => {
  return ticket.user_id == user.id
}
const ownerWasLoggedUser = (ticket, user) => {
  return ticket.previous_user == user.id
}
const ownerWasNull = (ticket) => {
  return !ticket.previous_user
}

const shouldChatBeWaiting = (chat, userId) => {
  return !isBotActive(chat) && !userId
}

const isMyChat = (chat, me) => {
  return chat.assigned_to == me.id
}

export default function conversation(state = INITIAL_STATE, action = {}) {
  switch (action.type) {

    case Types.FETCHED: {
      
      const data = action.clear
      ? action.data
      : state.data.concat(
        action.data.filter((el) => {
          return !el.hide && !state.data.some((a) => a.id === el.id);
        })
      );

      //localStorage.setItem(`conversations${action.company_id}`, JSON.stringify(data))
      localStorage.setItem(`assigned${action.company_id}`, action.assigned)
      localStorage.setItem(`total${action.company_id}`, action.total)
      localStorage.setItem(`waiting${action.company_id}`, action.waiting)

      return {
        ...state,
        assigned: action.assigned,
        total: action.total,
        waiting: action.waiting,
        total_assigned: action.assigned,
        total_records: action.total,
        total_waiting: action.waiting,
        last_sync: new Date(),
        conversationStatus: action.conversationStatus || state.conversationStatus,
        conversationMedia: action.conversationMedia || state.conversationMedia,
        user_id: action.user_id,
        department_id: action.department_id,
        data,
        loading: false,
      };
    }
    case Types.SYNC_FROM_LOCAL: {

      const conversationsInLocalStorage = JSON.parse(localStorage.getItem(`conversations${action.company_id}`))
      
      if(!conversationsInLocalStorage) return state

      const assigned = localStorage.getItem(`assigned${action.company_id}`)
      const total = localStorage.getItem(`total${action.company_id}`)
      const waiting = localStorage.getItem(`waiting${action.company_id}`)

      const data = state.data.concat(
        conversationsInLocalStorage.filter((el) => {
          return !el.hide && !state.data.some((a) => a.id === el.id);
        })
      );

      return {
        ...state,
        assigned,
        total,
        waiting,
        total_assigned:assigned,
        total_records:total,
        total_waiting:waiting,
        data,
        loading: false
      };
    }
    case Types.FETCHED_SEARCH:
      return { ...state, data: action.data, loading: false };
    case Types.FETCHED_MEDIA:
      return { ...state, media: action.data, loading: false };
    case Types.SELECTED: {
      const conversation = state.data.find(
        (c) => c.id === action.conversation.id
      );

      return {
        ...state,
        loadingTickets: conversation ? !conversation.contact.tickets : true,
        loadingMessages: conversation ? !conversation.messages.length : true,
        data: conversation ? state.data : [...state.data, action.conversation],
        activeConversationId: action.conversation.id,
      };
    }
    case Types.TICKET_DELETED: {
      const conversations = state.data.filter((c) => c.active_ticket_id != action.ticketId);

      return {
        ...state,
        data: conversations
      };
    }
    case Types.UNSELECTED:
      return { ...state, activeConversationId: action.payload };
    case Types.TOGGLE_SCROLLING:
      return { ...state, scrolling: action.payload };
    case Types.READ_CONVERSATION: {
      const conversation = state.data.find((c) => c.id == action.payload.conversation.id);
      console.log(conversation)
      if(conversation) return state

      localStorage.setItem(`assigned${action.company_id}`, action.payload.total_assigned)
      localStorage.setItem(`total${action.company_id}`, action.payload.total_records)
      localStorage.setItem(`waiting${action.company_id}`, action.payload.total_waiting)

      const conversationId = action.payload.conversation.id
      return {
        ...state,
        data: [{...action.payload.conversation, new: true}, ...state.data],
        loading: false,
        last_read_conversation: {
          ...state.last_read_conversation,
          [conversationId]: new Date()
        },
        waiting: action.payload.total_waiting,
        assigned: action.payload.total_assigned,
        total: action.payload.total_records,
        total_waiting: action.payload.total_waiting,
        total_assigned: action.payload.total_assigned,
        total_records: action.payload.total_records
      };
    }
    case Types.UPDATE_CONVERSATION_COUNT: {

      return {
        ...state,
        ...action.count
      }
    }
    case Types.COUNT_CONVERSATION: {

      localStorage.setItem(`assigned${action.company_id}`, action.total_assigned)
      localStorage.setItem(`total${action.company_id}`, action.total_records)
      localStorage.setItem(`waiting${action.company_id}`, action.total_waiting)

      return {
        ...state,
        last_count_date: new Date(),
        waiting: action.total_waiting,
        assigned: action.total_assigned,
        total: action.total_records,
        total_waiting: action.total_waiting,
        total_assigned: action.total_assigned,
        total_records: action.total_records
      };
    }
    case Types.GET_CONVERSATION:
      const conversation = state.data.find((c) => c.id === action.payload.id);
      const conversationIndex = state.data.findIndex(
        (c) => c.id === action.payload.id
      );

      const joincomments = conversation && conversation.comments ? conversation.comments.map((t) => ({
        id: t.id,
        ticket_number: t.ticket_number,
        forwardedTo: t.forwardedTo,
        user: t.user.name,
        avatar: t.user.avatar,
        progress_percentage: t.progress_percentage,
        status: t.status,
        icon: t.icon,
        created_at: t.created_at,
        timestamp: t.timestamp,
        message: t.comment,
        source: "comment",
      })) : [];

      return {
        ...state,
        loadingTickets: false,
        loadingMessages: false,
        data: conversation
          ? [
            {
              ...action.payload,
              contact: { ...action.payload.contact, loaded: true },
              messages: [
                ...action.payload.messages,
                ...joincomments.filter((el) => {
                  return !conversation.messages.some((a) => a.id === el.id)
                }),
              ],
              loaded: true,
              tickets: [...action.payload.contact.tickets] || [],
            },
            ...state.data.slice(0, conversationIndex),
            ...state.data.slice(conversationIndex + 1),
          ]
          : [
            {
              ...action.payload,
              tickets: [...action.payload.contact.tickets] || [],
            },
            ...state.data,
          ],
      };

    case Types.ADD_CONVERSATION: {
      const conversation = state.data.find((c) => c.id === action.payload.id);

      if (conversation) return state;

      return {
        ...state,
        data: [{ ...action.payload }, ...state.data],
      };
    }
    case Types.CHANGE_TICKET_OWNER: {
      const ticket = action.ticket;
      const user = action.user;
      const conversation = state.data.find((c) => c.id == ticket.conversation_id);
      if (!conversation) return state;
      const index = state.data.findIndex((c) => c.id === conversation.id);

      const newState = {
        ...state,
        data: [
          ...state.data.slice(0, index),
          {
            ...conversation,
            assigned_to: action.ticket.user_id,
            activeTicket: {
              ...conversation.activeTicket,
              user: action.ticket.user_name,
              user_id: action.ticket.user_id,
              user_avatar: action.ticket.user_avatar
            }
          },
          ...state.data.slice(index + 1),
        ]
      };
      return newState;
    }
    case Types.ON_ERROR:

      return {
        ...state,
        activeConversation: {},
        loading: false,
        error: action.error,
      };
    case Types.IS_FETCHING_TICKETS:
      return { ...state, loadingTickets: action.loadingTickets };
    case Types.UPDATE_CONVERSATION: {
      const conversation_id = action.payload.id;
      const activeConversation = state.data.find(
        (c) => c.id === conversation_id
      );
      const index = state.data.findIndex((c) => c.id === conversation_id);

      if (activeConversation) {
        const newState = {
          ...state,
          data: [
            ...state.data.slice(0, index),
            {
              ...action.payload,
            },
            ...state.data.slice(index + 1),
          ],
        };
        return newState;
      }

      return { ...state };
    }
    case Types.IS_FETCHING_MESSAGES:
      return { ...state, loadingMessages: action.loadingMessages };
    case Types.USER_LOGGED_OUT:
      return INITIAL_STATE;
    case Types.SET_ENTER_AS_SEND:
      return { ...state, enterAsSend: !state.enterAsSend };

    case Types.TICKETS_FETCHED: {
      const { tickets, id } = action;
      const index = state.data.findIndex((c) => c.id === id);

      const joincomments = tickets.map((t) =>
        t.comments.map((c) => ({
          id: c.id,
          ticket_number: t.ticket_number,
          forwardedTo: c.forwardedTo,
          user: c.user,
          avatar: c.avatar,
          progress_percentage: c.progress_percentage,
          status: c.status,
          icon: c.icon,
          created_at: c.created_at,
          timestamp: c.timestamp,
          message: c.message,
          source: "comment",
        }))
      );

      const newTicketsArray = tickets;

      const newState = {
        ...state,
        loadingTickets: false,
        data: [
          ...state.data.slice(0, index),
          {
            ...state.data[index],
            tickets: state.data[index].tickets.find((c) =>
              newTicketsArray.map((d) => c.id === d.id)
            )
              ? state.data[index].tickets
              : [...state.data[index].tickets].concat(...newTicketsArray),

            messages:
              !!state.data[index].messages
                .filter(
                  (c) =>
                    c.source === "whatsapp" ||
                    c.source === "campaing" ||
                    c.source === "monitcall" ||
                    c.source === "monitchat" ||
                    c.source === "whatsapp" ||
                    c.source === "email"
                )
                .find((ticket) =>
                  newTicketsArray.map((d) => ticket.id === d.id)
                ) ||
                !!state.data[index].messages
                  .filter(
                    (c) =>
                      c.source === "whatsapp" ||
                      c.source === "user_comment" ||
                      c.source === "campaing" ||
                      c.source === "monitcall" ||
                      c.source === "monitchat" ||
                      c.source === "whatsapp" ||
                      c.source === "email"
                  )
                  .find((ticket) => joincomments.map((d) => ticket.id === d.id))
                ? state.data[index].messages
                : [...state.data[index].messages]
                  .concat(newTicketsArray)
                  .concat(...joincomments),

            activeTicketIndex: tickets.length - 1,
          },
          ...state.data.slice(index + 1),
        ]
      };

      return newState;
    }
    case Types.ADD_TICKET: {
      const ticket = action.payload;
      const conversation_id = ticket.conversation_id;
      const conversation = state.data.find((c) => c.id === conversation_id);
      const index = state.data.findIndex((c) => c.id === conversation_id);

      const NewTicket = {
        ...ticket,
        conversation_id: ticket.conversation_id,
        current_progress: ticket.current_progress,
        id: ticket.id,
        progress: ticket.current_progress,
        start_date: ticket.start_date,
        status: ticket.status,
        ticket_number: ticket.ticket_number,
        user: ticket.user,
        user_avatar: ticket.avatar,
      };

      if (conversation) {
        const newState = {
          ...state,
          loadingTickets: false,
          data: [
            {
              ...conversation,
              contact: {
                ...conversation.contact,
                tickets: conversation.contact.tickets
                  ? [{ ...NewTicket }, ...conversation.contact.tickets]
                  : [{ ...NewTicket }],
              },
              archived: 0,
              active_ticket_id: NewTicket.id,
              tickets: [...conversation.tickets, { ...NewTicket }],
              messages: conversation.messagesLoaded
                ? [...conversation.messages, { ...NewTicket }]
                : [],
            },
            ...state.data.slice(0, index),
            ...state.data.slice(index + 1),
          ],
        };
        return newState;
      }

      return state;
    }
    case Types.UPDATE_TICKET_STATUS: {
      const ticket = action.payload;
      const conversation_id = ticket.conversation_id;
      const activeConversation = state.data.find(
        (c) => c.id === conversation_id
      );
      const index = state.data.findIndex((c) => c.id === conversation_id);

      if (activeConversation) {
        const ticketIndex = activeConversation.tickets.findIndex(
          (t) => t.id === ticket.id
        );

        const tickets = activeConversation.contact.tickets;

        if (tickets) {
          const contactTicketIndex = activeConversation.contact.tickets.findIndex(
            (t) => t.id === ticket.id
          );

          const newState = {
            ...state,
            data: [
              ...state.data.slice(0, index),
              {
                ...activeConversation,
                contact: {
                  ...activeConversation.contact,
                  tickets: [
                    ...activeConversation.contact.tickets.slice(
                      0,
                      contactTicketIndex
                    ),
                    { ...ticket },
                    ...activeConversation.contact.tickets.slice(
                      contactTicketIndex + 1
                    ),
                  ],
                },
                tickets: [
                  ...activeConversation.tickets.slice(0, ticketIndex),
                  { ...ticket },
                  ...activeConversation.tickets.slice(ticketIndex + 1),
                ],
              },
              ...state.data.slice(index + 1),
            ],
          };

          return newState;
        }
      }
      return state;
    }
    case Types.ADD_USER_WALLET: {
      const wallet = action.payload;
      const conversationId = state.data.find(
        (c) => c.contact.id === wallet.contact
      );
      const index = state.data.findIndex((c) => c.id === conversationId.id);

      const newState = {
        ...state,
        data: [
          ...state.data.slice(0, index),
          {
            ...state.data[index],
            contact: {
              ...state.data[index].contact,
              users: [
                state.data[index].contact.users[0] === wallet.user_id
                  ? ""
                  : wallet.user_id,
              ],
            },
          },
          ...state.data.slice(index + 1),
        ],
      };
      return newState;
    }
    case Types.ARCHIVE_CONVERSATION: {
      const { conversation } = action.payload;

      const newState = {
        ...state,
        activeConversationId:
          conversation.id === state.activeConversationId
            ? conversation.ticketScreen
              ? state.activeConversationId
              : -1
            : state.activeConversationId,
        data: state.data.filter((c) => c.id !== conversation.id),
      };

      return newState;
    }
    case Types.HIDE_CONVERSATION: {
      const conversation = action.payload;
      const newState = {
        ...state,
        data: state.data.filter((c) => c.id !== conversation.id),
      };

      return newState;
    }
    case Types.SET_CONVERSATION_STATE: {
      const { conversationState, phone_number } = action.payload;

      const conversation = state.data.find(
        (c) => c.contact.phone_number === phone_number
      );

      if (conversation) {
        const index = state.data.findIndex((c) => c.id === conversation.id);

        const newState = {
          ...state,
          data: [
            ...state.data.slice(0, index),
            {
              ...state.data[index],
              conversationState,
            },
            ...state.data.slice(index + 1),
          ],
        };
        return newState;
      }

      return state;
    }
    case Types.CHANGE_CLIENT: {
      const { conversation } = action.payload;
      const index = state.data.findIndex((c) => c.id === conversation.id);
      const newState = {
        ...state,
        data: [
          ...state.data.slice(0, index),
          {
            ...state.data[index],
            contact: {
              ...conversation.contact,
              client: conversation.contact.client
                ? conversation.contact.client
                : [],
              tickets: conversation.contact.tickets || [],
              name: conversation.contact.name ? conversation.contact.name : [],
            },
          },
          ...state.data.slice(index + 1),
        ],
      };
      return newState;
    }

    case Types.SET_ACTIVE_TICKET: {
      const { ticket } = action;

      const index = state.data.findIndex((c) => c.id === ticket.conversation_id);
      const activeConversation = state.data[index];
      if (activeConversation) {
        const activeTicketIndex = activeConversation.tickets.findIndex(
          (t) => t.id === ticket.id
        );

        const newState = {
          ...state,
          loadingTickets: false,
          data: [
            ...state.data.slice(0, index),
            {
              ...activeConversation,
              activeTicketIndex,
              active_ticket_id: ticket.id,
            },
            ...state.data.slice(index + 1),
          ],
        };

        return newState;
      }
      return state;
    }

    case Types.SET_TICKET_PROGRESS: {
      const { ticket } = action;
      const index = state.data.findIndex(
        (c) => c.id === ticket.conversation_id
      );

      const conversation = state.data[index];
      if (conversation) {
        const activeTicketIndex = conversation.tickets.findIndex(
          (t) => t.id === ticket.id
        );

        const newState = {
          ...state,

          data: [
            ...state.data.slice(0, index),
            {
              ...conversation,
              contact: {
                ...conversation.contact,
                tickets: conversation.contact.tickets
                  ? [
                    ...conversation.contact.tickets.slice(
                      0,
                      activeTicketIndex
                    ),
                    {
                      ...conversation.contact.tickets[activeTicketIndex],
                      current_progress: ticket.current_progress,
                    },
                    ...conversation.contact.tickets.slice(
                      activeTicketIndex + 1
                    ),
                  ]
                  : [],
              },
            },
            ...state.data.slice(index + 1),
          ],
        };

        return newState;
      }
      return state;
    }

    case Types.SET_TICKET_DEPARTMENT: {
      const { department } = action;
      const index = state.data.findIndex(
        (c) => c.id === department.conversationId
      );

      const conversation = state.data[index];

      if (conversation) {
        const activeTicketIndex = conversation.tickets.findIndex(
          (t) => t.id === department.ticketId
        );
        
        const newState = {
          ...state,
          data: [
            ...state.data.slice(0, index),
            {
              ...conversation,
              activeTicket: {
                ...conversation.activeTicket,
                department: department.name,
                department_id: department.id,
              },
              contact: {
                ...conversation.contact,
                tickets: conversation.contact.tickets
                  ? [
                    ...conversation.contact.tickets.slice(
                      0,
                      activeTicketIndex
                    ),
                    {
                      ...conversation.contact.tickets[activeTicketIndex],
                      department: department.name,
                      department_id: department.id,
                    },
                    ...conversation.contact.tickets.slice(
                      activeTicketIndex + 1
                    ),
                  ]
                  : [],
              },
            },
            ...state.data.slice(index + 1),
          ],
        };

        return newState;
      }
      return state;
    }

    case Types.SET_TICKET_USER: {
      const { tickets } = action;
      const { activeConversationId } = state;
      const index = state.data.findIndex((c) => c.id === activeConversationId);
      const activeConversation = state.data[index];

      const newTicketsArray = tickets.data.map((t) => ({
        id: t.id,
        ticket_number: t.ticket_number,
        created_at: t.created_at,
        current_progress: t.current_progress,
        timestamp: t.timestamp,
        user: t.user,
        user_avatar: t.user_avatar,
        source: t.source,
      }));

      const newState = {
        ...state,
        loadingTickets: false,
        data: [
          ...state.data.slice(0, index),
          {
            ...activeConversation,
            activeTicket: newTicketsArray.find(
              (t) => t.id === activeConversation.active_ticket_id
            ),
            contact: {
              ...activeConversation.contact,
              tickets: newTicketsArray,
            },
            tickets: newTicketsArray,
          },
          ...state.data.slice(index + 1),
        ],
      };

      return newState;
    }

    case Types.TICKET_USER_CHANGED: {
      const { ticket } = action;

      const conversationIndex = state.data.findIndex(
        (c) => c.id === ticket.conversation_id
      );

      const conversation = state.data.find(
        (c) => c.id === ticket.conversation_id
      );

      if (conversation) {
        const activeTicketIndex = conversation.tickets.findIndex(
          (t) => t.id === ticket.id
        );

        const newState = {
          ...state,
          data: [
            ...state.data.slice(0, conversationIndex),
            {
              ...conversation,
              activeTicket: {
                ...conversation.activeTicket,
                user_id: ticket.user_id,
                user_avatar: ticket.user_avatar,
                user: ticket.user_name,
              },
              contact: {
                ...conversation.contact,
                tickets: conversation.contact.tickets
                  ? [
                    ...conversation.contact.tickets.slice(
                      0,
                      activeTicketIndex
                    ),
                    {
                      ...conversation.contact.tickets[activeTicketIndex],
                      user_id: ticket.user_id,
                      user: ticket.user_name,
                      user_avatar: ticket.user_avatar,
                    },
                    ...conversation.contact.tickets.slice(
                      activeTicketIndex + 1
                    ),
                  ]
                  : [],
              },
              tickets: conversation.tickets
                ? [
                  ...conversation.tickets.slice(0, activeTicketIndex),
                  {
                    ...conversation.tickets[activeTicketIndex],
                    user_id: ticket.user_id,
                    user: ticket.user_name,
                    user_avatar: ticket.user_avatar,
                  },
                  ...conversation.tickets.slice(activeTicketIndex + 1),
                ]
                : [],
            },
            ...state.data.slice(conversationIndex + 1),
          ],
        };

        return newState;
      }

      return state;
    }
    case Types.COMMENTS_FETCHED: {
      const { comments, conversationId } = action;

      const index = state.data.findIndex((c) => c.id === conversationId);
      const conversation = state.data[index]

      const joincomments = comments.data.filter(el => {
        return !conversation.messages.some((a) => a.id === el.id)
      }).map((t) => ({
        id: t.id,
        ticket_number: t.ticket_number,
        forwardedTo: t.forwardedTo,
        user: t.user.name,
        avatar: t.user.avatar,
        progress_percentage: t.progress_percentage,
        status: t.status,
        icon: t.icon,
        created_at: t.created_at,
        timestamp: t.timestamp,
        message: t.comment,
        source: "comment",
      }));

      const newState = {
        ...state,
        data: [
          ...state.data.slice(0, index),
          {
            ...conversation,
            messages: [
              ...conversation.messages,
              ...joincomments,
            ],
            comments: [
              ...conversation.comments,
              ...comments.data.filter(el => {
                return !conversation.comments.some((a) => a.id === el.id)
              })
            ],
          },
          ...state.data.slice(index + 1),
        ],
      };

      return newState;
    }
    case Types.COMMENT_RECEIVED: {
      const { comment } = action;
      const conversationId = comment.conversation_id
      const index = state.data.findIndex((c) => c.id == conversationId);
      const conversation = state.data[index]

      if (!conversation) {
        return state
      }

      if (conversation.messages.find(c => c.id == comment.id)) {
        return state
      }

      const newComment = {
        id: comment.id,
        ticket_number: comment.ticket_number,
        forwardedTo: comment.forwardedTo,
        user: comment.user.name,
        avatar: comment.user.avatar,
        progress_percentage: comment.progress_percentage,
        status: comment.status,
        icon: comment.icon,
        created_at: comment.created_at,
        timestamp: comment.timestamp,
        message: comment.comment,
        source: "comment",
      };

      const newState = {
        ...state,
        data: [
          ...state.data.slice(0, index),
          {
            ...conversation,
            messages: [
              ...conversation.messages,
              { ...newComment }
            ],
            comments: conversation.comments ? [
              ...conversation.comments,
              { ...comment }
            ] : [
                { ...comment }
              ],
          },
          ...state.data.slice(index + 1),
        ],
      };

      return newState;
    }
    case Types.MESSAGES_FETCHED: {
      const { messages, conversationId } = action;
      const index = state.data.findIndex((c) => c.id == conversationId);
      const conversation = state.data[index]

      const joincomments = conversation && conversation.comments ? conversation.comments.filter(el => {
        return !conversation.messages.some((a) => a.id === el.id)
      }).map((t) => ({
        id: t.id,
        ticket_number: t.ticket_number,
        forwardedTo: t.forwardedTo,
        user: t.user.name,
        avatar: t.user.avatar,
        progress_percentage: t.progress_percentage,
        status: t.status,
        icon: t.icon,
        created_at: t.created_at,
        timestamp: t.timestamp,
        message: t.comment,
        source: "comment",
      })) : [];

      const newState = {
        ...state,
        loadingMessages: false,
        data: [
          ...state.data.slice(0, index),
          {
            ...conversation,
            messages: [
              ...conversation.messages,
              ...messages.data.data.filter((el) => {
                return !conversation.messages.some((a) => a.id === el.id);
              }),
              ...joincomments
            ],
            messagesLoaded: true,
          },
          ...state.data.slice(index + 1),
        ]
      };

      return newState;
    }
    case Types.STATS_FETCHED: {
      const { stats, id } = action;
      const index = state.data.findIndex((c) => c.id === id);

      const newState = {
        ...state,
        loadingStats: false,
        data: [
          ...state.data.slice(0, index),
          {
            ...state.data[index],
            stats: stats.data,
          },
          ...state.data.slice(index + 1),
        ],
      };

      return newState;
    }
    case Types.ADD_MESSAGE: {
      const message = action.payload;

      const { conversation_id } = message;
      const conversation = state.data.find((c) => c.id == conversation_id);
      const index = state.data.findIndex((c) => c.id == conversation_id);

      if (conversation) {
        const messageExists = conversation.messages.find(
          (m) => m.id === message.id
        );

        if (!messageExists) {
          const ticket = conversation.tickets.find(
            (t) => t.id === message.ticket_id
          );

          if (ticket || !message.ticket_id) {
            const newState = {
              ...state,
              loadingTickets: false,
              data: [
                {
                  ...conversation,
                  last_message: {
                    message: message.message,
                    created_at: message.human_date,
                    created_at_raw:
                      message.created_at ||
                      moment().format("YYYY-MM-DD HH:mm:ss"),
                    type: message.message_type,
                  },
                  archived: false,
                  account_number: message.account_number
                    ? message.account_number
                    : conversation.account_number,
                  unread_messages:
                    !message.sender || message.sender === 0
                      ? conversation.unread_messages + 1
                      : conversation.unread_messages,
                  messages:
                    [...conversation.messages, { ...message }],
                },
                ...state.data.slice(0, index),
                ...state.data.slice(index + 1),
              ],
            };
            return newState;
          } else {

            const newState = {
              ...state,
              data: [
                {
                  ...conversation,
                  unread_messages:
                    message.sender === 0
                      ? conversation.unread_messages + 1
                      : conversation.unread_messages,
                  messages: [...conversation.messages, { ...message }],
                  account_number: message.account_number
                    ? message.account_number
                    : conversation.account_number,
                  last_message: {
                    message: message.message,
                    created_at: message.human_date,
                    created_at_raw:
                      message.created_at ||
                      moment().format("YYYY-MM-DD HH:mm:ss"),
                    type: message.message_type,
                  },
                },
                ...state.data.slice(0, index),
                ...state.data.slice(index + 1),
              ],
            };
            return newState;
          }
        }
      }
      return state;
    }

    case Types.ADD_COMMENT: {
      const message = action.payload.data;
      const { conversation_id } = message;
      const activeConversation = state.data.find(
        (c) => c.id === conversation_id
      );

      if (!activeConversation) {
        return state;
      }
      const index = state.data.findIndex((c) => c.id === conversation_id);
      const indexTicket = activeConversation.tickets.findIndex(
        (c) => c.id === message.ticket_id
      );

      if (message.status) {
        const newState = {
          ...state,
          loadingTickets: false,
          data: [
            ...state.data.slice(0, index),
            {
              ...activeConversation,

              tickets: [
                ...activeConversation.tickets.slice(0, indexTicket),
                {
                  ...activeConversation.tickets[indexTicket],
                  progress_percentage: message.status.progress_percentage,
                  current_progress: message.status.progress_percentage,
                },
                ...activeConversation.tickets.slice(indexTicket + 1),
              ],

              messages: [...activeConversation.messages].concat({
                ...message,
                message: message.comment,
                user: message.user.name,
                avatar: message.user.avatar,
              }),
            },
            ...state.data.slice(index + 1),
          ],
        };
        return newState;
      }
      const newState = {
        ...state,
        loadingTickets: false,
        data: [
          ...state.data.slice(0, index),
          {
            ...activeConversation,

            messages: [...activeConversation.messages].concat({
              ...message,
              message: message.comment,
              user: message.user.name,
              avatar: message.user.avatar,
            }),
          },
          ...state.data.slice(index + 1),
        ],
      };
      return newState;
    }

    case Types.AUTO_REPLY: {
      const autoReply = action.payload;
      const { conversation_id, auto_reply } = autoReply;
      const activeConversation = state.data.find(
        (c) => c.id === conversation_id
      );
      const index = state.data.findIndex((c) => c.id === conversation_id);

      // let waiting = state.waiting

      /* if((activeConversation.auto_reply != auto_reply) && !activeConversation.assigned_to)
      {
        if(auto_reply)
        {
          waiting = waiting - 1
        }else{
          waiting = waiting + 1
        }
      } */

      const newState = {
        ...state,
        data: [
          ...state.data.slice(0, index),
          {
            ...activeConversation,
            auto_reply,
          },
          ...state.data.slice(index + 1),
        ]
      };

      return newState;
    }
    case Types.UNREAD_MESSAGES_CHANGED: {
      const unreadMessages = action.payload;

      const { conversationId, unreadMessages: unread } = unreadMessages;
      const activeConversation = state.data.find(
        (c) => c.id === conversationId
      );
      const index = state.data.findIndex((c) => c.id === conversationId);

      if (activeConversation) {
        const newState = {
          ...state,
          data: [
            ...state.data.slice(0, index),
            {
              ...activeConversation,
              unread_messages: unread,
            },
            ...state.data.slice(index + 1),
          ],
        };
        return newState;
      }

      return state;
    }

    case Types.REMOVE_BY_CONTACT: {
      const contact_id = action.payload;
      return {
        ...state,
        data: state.data.filter((c) => c.contact.id !== contact_id),
      };
    }

    case Types.REFRESH_CONVERSATION_STATUS: {
      const newState = {
        ...state,
        data: state.data.map((c) => c),
        conversationStatus: action.payload
      };

      return newState;
    }

    case Types.REFRESH_CONVERSATION_MEDIA: {
      const totalPayload = state.data.filter(c => c.source == action.payload.media).length
      const totalNotPayload = state.data.filter(c => c.source != action.payload.media).length
      const userId = action.payload.user.user.id


      const newState = {
        ...state,
        data: state.data.map((c) => c),
        conversationMedia: action.payload.media,
        waiting: !action.payload.media ? state.total_waiting : state.data.filter(c => c.source == action.payload.media && c.activeTicket && !c.activeTicket.user_id && !c.auto_reply).length,
        assigned: !action.payload.media ? state.total_assigned : state.data.filter(c => c.source == action.payload.media && c.activeTicket && c.activeTicket.user_id == userId).length,
        total: !action.payload.media ? state.data.length : state.data.length - totalNotPayload,
      };

      return newState;
    }

    case Types.UPDATE_MESSAGE_STATUS: {
      const message = action.payload;
      const { conversation_id } = message;
      const activeConversation = state.data.find(
        (c) => c.id === conversation_id
      );
      const index = state.data.findIndex((c) => c.id === conversation_id);
      if (activeConversation) {
        const messageIndex = activeConversation.messages.findIndex((m) =>
          message.message_token && message.message_token.length > 0
            ? m.message_token === message.message_token
            : m.id === message.id
        );

        if (messageIndex > -1) {
          const newState = {
            ...state,
            loadingTickets: false,
            filter: "",
            data: [
              ...state.data.slice(0, index),
              {
                ...activeConversation,
                last_message: {
                  message: message.message,
                  created_at: message.human_date,
                  created_at_raw:
                    message.created_at ||
                    moment().format("YYYY-MM-DD HH:mm:ss"),
                  type: message.message_type,
                },
                messages: activeConversation.messages.find((c) =>
                  message.message_token && message.message_token.length > 0
                    ? c.message_token === message.message_token
                    : c.id === message.id
                )
                  ? [
                    ...activeConversation.messages.slice(0, messageIndex),
                    {
                      ...activeConversation.messages[messageIndex],
                      ...message,
                    },
                    ...activeConversation.messages.slice(messageIndex + 1),
                  ]
                  : activeConversation.messages.find((c) =>
                    message.message_token && message.message_token.length > 0
                      ? c.message_token === message.message_token
                      : c.id === message.id
                  ) && activeConversation.messagesLoaded
                    ? activeConversation.messages
                    : [...activeConversation.messages],
              },
              ...state.data.slice(index + 1),
            ],
          };
          return newState;
        }

        if (message.id) {
          const newState = {
            ...state,
            data: [
              ...state.data.slice(0, index),
              {
                ...activeConversation,
                last_message: {
                  message: message.message,
                  created_at: message.human_date,
                  created_at_raw:
                    message.created_at ||
                    moment().format("YYYY-MM-DD HH:mm:ss"),
                  type: message.message_type,
                },
                messages: [
                  ...activeConversation.messages,
                  { ...message }
                ]
              },
              ...state.data.slice(index + 1),
            ],
          };
          return newState;
        }
        return state;
      }
    }
    case Types.REVOKE_MESSAGE: {
      const message = action.payload.message;
      const conversationId = action.payload.conversationId

      const activeConversation = state.data.find(
        (c) => c.id === conversationId
      );
      const index = state.data.findIndex((c) => c.id === conversationId);
      if (activeConversation) {
        const newState = {
          ...state,
          data: [
            ...state.data.slice(0, index),
            {
              ...activeConversation,
              messages: activeConversation.messages ? activeConversation.messages.filter(m => m.message_id != message) : [],
              last_message: {}
            },
            ...state.data.slice(index + 1),
          ],
        };
        return newState;
      }
    }
    case Types.SET_FILTER:
      return {
        ...state,
        filter: action.filter,
      };
    case Types.UPDATE_CLIENT:
      return {
        ...state,
        data: action.conversation,
      };

    default:
      return state;
  }
}

// Actions
export const Creators = {
  getStats: (activeConversation) => (dispatch) => {
    api.conversations.getStats(activeConversation.id).then((stats) => {
      dispatch({
        type: Types.STATS_FETCHED,
        stats,
        id: activeConversation.id,
      });
    });
  },
  syncFromLocal: (data) => (dispatch, getState) => {
    return dispatch({type: Types.SYNC_FROM_LOCAL, data, company_id: getState().user.user.company_id})
  },
  fetchAll: (filter, clear = false, conversationStatus) => (dispatch, getState) =>
    api.conversations
      .fetchAll(filter)
      .then((conversations) => {
        return dispatch({ type: Types.FETCHED, data: conversations.data, clear, assigned: conversations.total_assigned, total: conversations.total_records, waiting: conversations.total_waiting, conversationStatus, user_id: filter.user_id, department_id: filter.department_id, company_id: getState().user.user.company_id })
      }
      )
      .catch((err) => dispatch(Creators.error(err.response))),
  fetchMedia: () => (dispatch) =>
    apimedia.media
      .fetchData()
      .then((media) => dispatch({ type: Types.FETCHED_MEDIA, data: media }))
      .catch((err) => dispatch(Creators.error(err.response))),
  removeByContact: (contact_id) => (dispatch) =>
    dispatch({
      type: Types.REMOVE_BY_CONTACT,
      payload: contact_id,
    }),
  fetchMessagesTake: (id, take, length) => (dispatch) =>
    api.conversations
      .fetchMessages(id, take, length)
      .then((messages) => dispatch({ type: Types.MESSAGES_FETCHED, messages, conversationId: id }))
      .catch((err) => {
        return dispatch(Creators.error(err.response))
      }),
  fetchCommentsTake: (id, take, length = 0, date = null) => (dispatch) =>
    api.conversations
      .fetchComments(id, take, length, date)
      .then((comments) => dispatch({ type: Types.COMMENTS_FETCHED, comments, conversationId: id }))
      .catch((err) => dispatch(Creators.error(err.response))),
  addTicket: (ticket) => (dispatch) => {
    dispatch({ type: Types.ADD_TICKET, payload: ticket });
  },
  setConversationState: ({ state, phone_number }) => (dispatch) => {
    dispatch({
      type: Types.SET_CONVERSATION_STATE,
      payload: { conversationState: state, phone_number },
    });
  },
  archiveConversation: (conversation) => (dispatch) => {
    dispatch({
      type: Types.ARCHIVE_CONVERSATION,
      payload: conversation,
    });
  },
  hideConversation: (conversation) => (dispatch) => {
    dispatch({
      type: Types.HIDE_CONVERSATION,
      payload: conversation,
    });
  },
  ticketUserChanged: (ticket) => (dispatch) => {
    dispatch({
      type: Types.TICKET_USER_CHANGED,
      ticket,
    });
  },
  changeClient: (conversation) => (dispatch) => {
    dispatch({
      type: Types.CHANGE_CLIENT,
      payload: conversation,
    });
  },
  fetchTicketsTake: (id, take, length) => (dispatch) =>
    api.conversations
      .fetchTickets(id, take, length)
      .then((tickets) =>
        dispatch({
          type: Types.TICKETS_FETCHED,
          tickets: tickets.data,
          id
        })
      )
      .catch((err) => dispatch(Creators.error(err.response))),
  unselectConversation: () => (dispatch) =>
    dispatch({ type: Types.UNSELECTED, payload: -1 }),
  updateTicketStatus: (payload) => (dispatch) =>
    dispatch({ type: Types.UPDATE_TICKET_STATUS, payload }),
  conversationSelected: (conversation, take = 5) => async (dispatch) => {
    if (conversation) {
      dispatch({ type: Types.SELECTED, conversation });
      dispatch({
        type: Types.IS_FETCHING_MESSAGES,
        loadingMessages: conversation && conversation.messages && !conversation.messages.length || conversation && !conversation.loaded ? true : false,
      });
    }
  },
  addTextMessage: (message) => (dispatch) => {
    dispatch({ type: Types.ADD_MESSAGE, payload: message });
  },
  updateConversationCount: (count) => (dispatch) => {

    dispatch({ type: Types.UPDATE_CONVERSATION_COUNT, count });
  },
  updateConversation: (data) => (dispatch) => {
    dispatch({ type: Types.UPDATE_CONVERSATION, payload: data });
  },
  updateMessageStatus: (message) => (dispatch) => {
    dispatch({
      type: Types.UPDATE_MESSAGE_STATUS,
      payload: message,
    });
  },
  refreshConversationStatus: (status) => (dispatch) => {
    dispatch({
      type: Types.REFRESH_CONVERSATION_STATUS,
      payload: status
    });
  },
  refreshConversationMedia: (media) => (dispatch, getState) => {

    dispatch({
      type: Types.REFRESH_CONVERSATION_MEDIA,
      payload: {media, user:getState().user}
    });
  },
  setActiveTicket: (ticket, e) => (dispatch) => {
    if (e) {
      e.preventDefault();
    }
    dispatch({ type: Types.SET_ACTIVE_TICKET, ticket });
  },
  setTicketProgress: (ticket) => (dispatch) => {
    dispatch({ type: Types.SET_TICKET_PROGRESS, ticket });
  },
  setTicketDepartment: (department) => (dispatch) => {
    dispatch({ type: Types.SET_TICKET_DEPARTMENT, department });
  },
  sendTextMessage: (conversationId, message) => (dispatch) => {
    dispatch({ type: Types.ADD_MESSAGE, payload: message });
    return api.conversations
      .sendTextMessage(conversationId, message)
      .then((data) => {
        dispatch({
          type: Types.UPDATE_MESSAGE_STATUS,
          payload: data,
        });

        dispatch({
          type: Types.SET_ACTIVE_TICKET,
          ticket: {
            id: data.ticket_id,
            conversation_id: conversationId,
          },
        });
      });
  },
  resendMessage: (message) => (dispatch) => {
    message.status = -1;
    dispatch({
      type: Types.UPDATE_MESSAGE_STATUS,
      payload: message,
    });

    return api.conversations.resendMessage(message);
  },
  deleteMessage: (message) => (dispatch) => {
    message.deleted = true;
    dispatch({
      type: Types.UPDATE_MESSAGE_STATUS,
      payload: message,
    });

    return api.conversations.deleteMessage(message);
  },
  revokeMessage: (message, conversationId) => (dispatch) => {
    dispatch({
      type: Types.REVOKE_MESSAGE,
      payload: { message, conversationId },
    });
  },
  toggleScrolling: (scrolling) => (dispatch) => {
    dispatch({
      type: Types.TOGGLE_SCROLLING,
      payload: scrolling,
    });
  },
  sendDocumentMessage: (conversationId, message) => (dispatch) => {
    dispatch({ type: Types.ADD_MESSAGE, payload: message });

    return api.conversations
      .sendDocumentMessage(conversationId, message)
      .then((data) => {
        dispatch({
          type: Types.UPDATE_MESSAGE_STATUS,
          payload: data,
        });
      });
  },
  sendVoiceMessage: (conversationId, message) => (dispatch) => {
    dispatch({ type: Types.ADD_MESSAGE, payload: message });

    return api.conversations
      .sendVoiceMessage(conversationId, message)
      .then((data) => {
        dispatch({
          type: Types.UPDATE_MESSAGE_STATUS,
          payload: data,
        });
      });
  },
  addConversation: (conversation) => (dispatch) => {
    dispatch({
      type: Types.ADD_CONVERSATION,
      payload: conversation,
    });
  },
  ticketDeleted: (ticketId) => (dispatch) =>{
      dispatch({type: Types.TICKET_DELETED, ticketId})
  },
  readConversation: (conversationId) => (dispatch, getState) => {
    const last_count_read_conversation = getState().conversation.last_read_conversation[conversationId]
    const now = new Date();

    if(last_count_read_conversation)
    {
      var diferencaEmSegundos = (now.getTime() - last_count_read_conversation.getTime()) / 1000;
      if(diferencaEmSegundos < 5)
      {
        return new Promise(function(resolve, reject) {
          resolve("Não é necessário ler essa conversa");
        });
      }
    }

    return api.conversations.read(conversationId).then((data) => {
      if (data.data.data.length) {
        dispatch({
          type: Types.READ_CONVERSATION,
          payload: { 
            conversation: data.data.data[0], 
            total_assigned: data.data.total_assigned,
            total_records: data.data.total_records,
            total_waiting: data.data.total_waiting
          },
        });
      }
    });
  },
  countConversation: () => (dispatch, getState) => {
    const last_count_date = getState().conversation.last_count_date;
    const now = new Date();

    var diferencaEmSegundos = (now.getTime() - last_count_date.getTime()) / 1000;
    /* if(diferencaEmSegundos < 10)
    {
      return new Promise(function(resolve, reject) {
        resolve("Não é necessário contar as conversas");
      });
    } */
    let count = []
    api.conversations.count().then((data) => {
      count = data
      dispatch({
        type: Types.COUNT_CONVERSATION,
        total_assigned: data.data.total_assigned,
        total_records: data.data.total_records,
        total_waiting: data.data.total_waiting
      });
    });

    return new Promise(function(resolve, reject) {
      resolve(count);
    });
  },
  getConversation: (conversationId, params = {}) => (dispatch) => {
    return api.conversations.get(conversationId, params).then((data) => {
      if (data.data.data.id) {
        dispatch({
          type: Types.GET_CONVERSATION,
          payload: data.data.data,
        });
        return data.data.data
      }
      return null
    });
  },
  sendImageMessage: (conversationId, message) => (dispatch) => {

    return api.conversations
      .sendImageMessage(conversationId, message)
      .then((data) => {
        dispatch({ type: Types.ADD_MESSAGE, payload: data });
      });
  },
  enableAutoReply: (conversationId, autoReply) => (dispatch) =>
    api.conversations
      .enableAutoReply(conversationId, autoReply)
      .then((data) => {
        dispatch({ type: Types.AUTO_REPLY, payload: autoReply });
      }),

  changeAutoReply: (conversationId, autoReply) => (dispatch) => {
    dispatch({ type: Types.AUTO_REPLY, payload: autoReply, conversationId });
  },
  changeUnreadMessages: (data) => (dispatch) => {
    dispatch({
      type: Types.UNREAD_MESSAGES_CHANGED,
      payload: data,
    });
  },
  changeTicketOwner: (ticket, status, user) => (dispatch) => {
    dispatch({ type: Types.CHANGE_TICKET_OWNER, ticket, status, user });
  },
  addComment: (comment) => (dispatch) => dispatch({ type: Types.COMMENT_RECEIVED, comment }),
  sendComment: (comment, ticketId) => (dispatch) =>
    api.conversations.sendComment(comment, ticketId).then((data) => {
      const datasend = {
        ...data.data,
        ticket_number: ticketId,
      };
    }),

  WalletToConversation: (data) => (dispatch) => {
    dispatch({ type: Types.ADD_USER_WALLET, payload: data });
  },

  error: (data) => ({
    type: Types.ON_ERROR,
    error: data,
  }),
  toggleEnterAsSend: () => ({
    type: Types.SET_ENTER_AS_SEND,
  }),
  foucsTextArea: () => {
    const message = document.querySelector("textarea[name='message']");
    if (message) {
      message.focus();
    }
  },

  setFilter: (filter) => ({
    type: Types.SET_FILTER,
    filter,
  }),
  updateContact: () => async (dispatch) => {
    const conversation = await api.conversations.fetchAll();
    dispatch({
      type: Types.UPDATE_CLIENT,
      conversation: conversation.data,
    });
  },
  fetchSearch: (search, status = "assigned") => (dispatch) =>
    api.conversations
      .fetchAll(
        search.length > 0
          ? { search, status }
          : { status, filter: JSON.stringify([["archived", "=", 0]]) }
      )
      .then((conversations) =>
        dispatch({
          type: Types.FETCHED_SEARCH,
          data: conversations.data,
        })
      )
      .catch((err) => dispatch(Creators.error(err.response))),
};
