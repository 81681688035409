import React from "react";
import { Link } from "react-router-dom";

const AlertError = ({
  message = "Erro ao executar esta operação",
  style = {},
  children,
}) => (
  <div className="AlertError" style={style}>
    <span>{message}</span>
    <br />
    {children}
  </div>
);

export default AlertError;
