import React from "react";
import { Modal, Button } from "semantic-ui-react";
import FormNewConversation from "../forms/FormNewConversation";

class NewConversationModal extends React.Component {
  componentDidMount() {
    if (this.props.showNavigation) {
      document.removeEventListener("keydown", () => {});
      
    }
  }

  save = () => {
    this.props.onClickAdd();
  };
  render() {
    const {
      handleClose,
      onChange,
      onChecked,
      modalHeader,
      newConversation,
      open,
      onClickAdd,
      loading,
      contact,
      generalError,
      cleanErrors,
      handleContactChange,
      handleMediaChange,
    } = this.props;

    return (
      <Modal
        size="small"
        closeIcon
        open={open}
        onClose={handleClose}
        dimmer="blurring"
        closeOnDimmerClick={false}
      >
        <Modal.Header>{modalHeader}</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            {generalError && (
              <div className="errors-table">
                <Button
                  circular
                  basic
                  color="black"
                  icon="close"
                  className="button-close"
                  onClick={cleanErrors}
                />
                <p>{generalError}</p>
              </div>
            )}
            <div className="holder_conversation">
              <div className="form-conversation">
                <FormNewConversation
                  handleClose={handleClose}
                  onChange={onChange}
                  onChecked={onChecked}
                  modalHeader={modalHeader}
                  newConversation={newConversation}
                  open={open}
                  contact={contact}
                  onClickAdd={onClickAdd}
                  loading={loading}
                  handleContactChange={handleContactChange}
                  handleMediaChange={handleMediaChange}
                />
              </div>
            </div>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button.Group>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button.Or />
            <Button
              positive
              onClick={this.save}
              loading={loading}
              disabled={loading}
            >
              Enviar
            </Button>
          </Button.Group>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default NewConversationModal;
