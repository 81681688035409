import React, { Component } from "react";
import api from "../../api/company";
import CompanyModal from "../company/modal/CompanyModal";

class EditCompany extends Component {
  state = {
    company: [],
    errors: { message: "", phone_number: "", global: "" },
    confirm: {
      email: "",
      username: "",
      password: "",
      confirmPassword: "",
    },
    loading: false,
    conversationModalOpen: false,
    save_alert: false,
  };

  componentWillMount() {
    this.fetchRecords();
  }

  fetchRecords = async (params) => {
    this.setState({ loading: true });
    const { company_id } = this.props;

    return await api.company.fetchCompany(company_id).then((res) => {
      this.setState({
        company: res.data,
        loading: false,
      });
    });
  };

  openModal = () => {
    this.setState({
      conversationModalOpen: true,
    });
  };

  handleChange = (e) =>
    this.setState({
      company: {
        ...this.state.company,
        [e.target.name]: e.target.value,
      },
    });

  handleChecked = (e, { name, checked }) => {
    this.setState({
      company: { ...this.state.company, [name]: checked },
    });
  };

  cleanErrors = () => {
    this.setState({ errors: "" });
  };
  handleCloseEditModal = () => {
    this.setState({
      conversationModalOpen: false,
    });
  };

  onSelectOrigin = (e, { name, value }) =>
    this.setState({
      company: {
        ...this.state.company,
        origin: value,
      },
    });

  onSelectCode = (e, { name, value }) =>
    this.setState({
      company: {
        ...this.state.company,
        selectionCode: value,
      },
    });

  onSelectSegment = (e, { name, value }) =>
    this.setState({
      company: {
        ...this.state.company,
        segment: value,
      },
    });
  update = () => {
    const { company } = this.state;
    const { company_id } = this.props;
    const data = company;
    this.setState({ loading: true });

    api.company
      .update(company_id, data)
      .then((data) => {
        this.setState({
          confirm: {
            email: "",
            username: "",
            password: "",
            confirmPassword: "",
          },
          save_alert: true,
          editModalOpen: false,
          selectedDataIndex: -1,
          loading: false,
        });
        setTimeout(
          function () {
            this.setState({ save_alert: false });
          }.bind(this),
          5000
        );
      })
      .catch((err) => {
        this.setState({
          loading: false,
          errors: err.response,
        });
      });
  };
  render() {
    const {
      loading,

      company,
      confirm,
      conversationModalOpen,
    } = this.state;
    return (
      <div>
        {/* <span onClick={this.openModal}>Dados da Empresa</span> */}
        <CompanyModal
          handleClose={this.handleCloseEditModal}
          onChange={this.handleChange}
          onChecked={this.handleChecked}
          handleMediaChange={this.handleMediaChange}
          handleContactChange={this.handleContactChange}
          modalHeader={`Editar Empresa`}
          validate={() => true}
          data={confirm}
          generalError={
            this.state.errors.status > 0 ? this.state.errors.data.message : ""
          }
          messageError={
            this.state.errors.status > 0 ? this.state.errors.data.errors : ""
          }
          showNavigation={false}
          editCompany
          cleanErrors={this.cleanErrors}
          company={company}
          open={conversationModalOpen}
          onClickSave={this.update}
          onClickAdd={this.submit}
          loading={loading}
          onSelectOrigin={this.onSelectOrigin}
          onSelectCode={this.onSelectCode}
          onSelectSegment={this.onSelectSegment}
        />
      </div>
    );
  }
}

export default EditCompany;
