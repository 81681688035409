import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal, Dropdown, Button, Icon, Grid } from "semantic-ui-react";
import { Creators as AuthActions } from "../../store/ducks/auth";
import API from "../../services/api";
import Socket from "../../services/socket";

class ChangeCompany extends Component {
  state = {
    companies: [],
    loadingCompanies: true,
    company_id: -1,
    token: null,
    showLogoutModal: false,
    
  };

  onSearchChange = (e, { searchQuery }) => {
    clearTimeout(this.timer);
    this.setState({ search: searchQuery });
    this.timer = setTimeout(this.onSearchCompany, 300);
  };

  onSearchCompany = async () => {
    this.setState({ loading: true });
    const { search } = this.state;
    const filter = JSON.stringify([["active", "=", 1]]);

    await API.get(`/company?search=${search}&filter=${filter}`).then(
      (companies) => {
        this.setState({
          companies: companies.data.data.map((c) => ({
            key: c.id,
            value: c.id,
            text: c.name,
          })),
          loading: false,
        });
      }
    );
  };

  componentDidMount() {
    this.setState({
      company_id: this.props.company_id,
    });
  }

  requestAccess = () => {
    if (this.state.company_id != this.props.user.default_company_id) {
      Socket.leave(`access-request-created-${this.state.token}`);
      this.setState({token: null})
      API.post(`/access-request`, { company_id: this.state.company_id }).then(
        (res) => {
          this.setState({
            token: res.data.request.authorization_token,
          });
          Socket.private(
            `access-request-authorized-${res.data.request.authorization_token}`
          ).listen("AccessRequestAuthorized", (e) => {
            this.props.logout(true);
          });
        }
      );
    }
  };

  handleChange = (e, { name, value }) => {
    if (value === this.props.user.default_company_id) {
      this.setState(
        {
          company_id: value,
        },
        () => {
          API.post(`/user/changeCompany/${value}`).then((c) => {
            this.props.logout(true);
          });
        }
      );
    } else {
      this.setState(
        {
          company_id: value,
        },
        () => {
          API.post(`/access-request`, { company_id: value }).then((res) => {
            this.setState({
              token: res.data.request.authorization_token,
            });

            Socket.private(
              `access-request-authorized-${
                res.data.request.authorization_token
              }`
            ).listen("AccessRequestAuthorized", (e) => {
              this.setState({showLogoutModal: true})
            });
          });
        }
      );
    }
  };

  render() {
    return (
      <>
      <Modal trigger={<a href="/#">Selecionar Empresa</a>} size="small">
        <Modal.Header>Selecionar Empresa</Modal.Header>
        <Modal.Content>
          <Grid>
            <Grid.Row>
              <Grid.Column width={10}>
                <Dropdown
                  fluid
                  placeholder="Selecione a Empresa"
                  search
                  onSearchChange={this.onSearchChange}
                  value={this.state.company_id}
                  name="company_id"
                  selection
                  loading={this.state.loading}
                  onChange={this.handleChange}
                  options={this.state.companies}
                />
              </Grid.Column>
              <Grid.Column width={2}>
                <Button
                  icon
                  onClick={this.requestAccess}
                  loading={this.state.loading}
                  disabled={this.state.loading}
                  title="Solicitar Novo Codigo"
                >
                  <Icon name="refresh" />
                </Button>
              </Grid.Column>
            </Grid.Row>
            {this.state.token ? <Grid.Row>
              <Grid.Column>
                Sua solicitação foi enviada, aguarde o usuário aceitar o acesso!
              </Grid.Column>
              </Grid.Row> : null}
          </Grid>
        </Modal.Content>
      </Modal>
      <Modal size="small" open={this.state.showLogoutModal}>
        <Modal.Header>Faça Login Novamente</Modal.Header>
        <Modal.Content>
          <h2>Seu acesso foi liberado, faça login novamente para iniciar uma sessão na Empresa solicitada.</h2>
        </Modal.Content>
        <Modal.Actions>
          <Button primary disabled={this.state.loading} loading={this.state.loading} onClick={() => {
            this.setState({loading: true})
            this.props.logout(true)
          }}>Login</Button>
        </Modal.Actions>
      </Modal>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(AuthActions, dispatch);

const mapStateToProps = ({ user }) => {
  return {
    user: user.user,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangeCompany);
