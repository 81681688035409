import React from "react";
import { Modal, Button, Form, Input } from "semantic-ui-react";

const AddUserModal = ({ open, toggle, onChange, user, loading, save }) => (
  <Modal
    size="small"
    closeIcon
    open={open}
    onClose={toggle}
    dimmer="blurring"
    closeOnDimmerClick={false}
  >
    <Modal.Header>Cadastro de Responsável</Modal.Header>
    <Modal.Content>
      <Modal.Description>
        <Form size="mini">
          <Form.Group widths="equal">
            <Form.Field
              control={Input}
              label="Nome"
              name="name"
              onChange={onChange}
              value={user.name}
            />
            <Form.Field
              control={Input}
              label="Email"
              name="email"
              onChange={onChange}
              value={user.email}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field
              control={Input}
              label="Senha"
              name="password"
              onChange={onChange}
              value={user.password}
            />
            <Form.Field
              control={Input}
              label="Número de Telefone"
              name="phone_number"
              onChange={onChange}
              value={user.phone_number}
            />
          </Form.Group>
        </Form>
      </Modal.Description>
    </Modal.Content>
    <Modal.Actions>
      <Button.Group>
        <Button onClick={toggle}>Cancelar</Button>
        <Button.Or />
        <Button positive onClick={save} loading={loading} disabled={loading}>
          Salvar
        </Button>
      </Button.Group>
    </Modal.Actions>
  </Modal>
);

export default AddUserModal;
