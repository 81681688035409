import React from "react";
import { Grid } from "semantic-ui-react";

export default () => (
  <Grid>
    <Grid.Row>
      <h2>Compromissos</h2>
      <p>
        Aqui é onde configuramos os compromissos, agenda, os profissionais, os
        horários de funcionamento, especialidades.
      </p>
      <p>
        <strong> Evento e tarefas:</strong> Te leva ao calendário de eventos,
        onde ficarão as suas tarefas agendadas.
      </p>
      <p>
        <strong>Profissionais:</strong> Exibirá uma lista de profissionais e da
        pra pra adicionar profissionais e exportar se assim desejar
      </p>
      <p>
        <strong>Endereços:</strong> Cadastra endereços da sua empresa, se tiver
        mais de um endereço é aqui você cadastra.
      </p>
      <p>
        <strong>Especialidades:</strong> Mostra as especialidades dos agentes.
      </p>
      <p>
        <strong>Horários:</strong> Exibe os horários de funcionamento da
        empresa, que horas vai ser permitido enviar mensagem, o robô já vai
        informar que está fora do horário de funcionamento se chegar mensagem
        fora do horário.
      </p>
    </Grid.Row>
  </Grid>
);
