import React from "react";
import { Grid } from "semantic-ui-react";
import PerfectScrollbar from "react-perfect-scrollbar";

export default () => (
  <Grid>
    <PerfectScrollbar>
      <Grid.Row>
        <h2>Menu Superior</h2>
      </Grid.Row>
      <Grid.Row>
        <p>
          Neste Menu você encontra as seguintes funcionalidades: <br />{" "}
          <strong>
            {" "}
            Tutorial, adicionar uma nova conversa, ir para eventos, status da
            conexão/ Edição do atendimento automático e opções do usuário.
          </strong>
        </p>
      </Grid.Row>
    </PerfectScrollbar>
  </Grid>
);
