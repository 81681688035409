import axios from "../services/api";

export default {
  user: {
    ping: () => axios.post('/auth/ping').then((res) => res),
    login: (credentials) =>
      axios
        .post("/auth/login", {
          email: credentials.email,
          password: credentials.password,
        })
        .then((res) => res.data),
    logout: (ignore_revoke) => axios.post("/auth/logout", { ignore_revoke }),
    get: () => axios.get("/user"),
    getInfo: (id) => axios.get(`/user/${id}/info/`).then((res) => res.data),
    submitPause: (user) =>
      axios.post(`/user/pause/${user.id}`, user).then((res) => res.data),
    getPermissions: (user) =>
      axios.get(`/user/${user.id}/permissions`).then((res) => res.data),
    getCurrentPause: () =>
      axios.get(`/user/getCurrentPause`).then((res) => res.data),
    forgotPassword: (user) =>
      axios.post(`/auth/requestPasswordResetLink`, user).then((res) => res),
    resetPassword: (params, user) =>
      axios.post(`/auth/resetPassword/${params}`, user).then((res) => res),
  },
};
