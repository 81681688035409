import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { Creators as AuthActions } from "../../store/ducks/auth";
import { Dropdown } from "semantic-ui-react";
import Celular from "./Celular";
import Terms from "./Terms";
import PrivacyPolicy from "./PrivacyPolicy";
import Interruption from "./Interruption";
import Support from "./Support";
import Socket from "./../../services/socket";
import EditCompany from "./EditCompany";
import ChangeCompany from "./ChangeCompany";
import AccessRequest from "./AccessRequest";

const Perfil = ({
  isAuthenticated,
  logout,
  name,
  user,
  avatar,
  user_id,
  active,
  setUserActive,
  setUserAvatar,
  company_id,
  accounts,
  id,
}) => {
  useEffect(() => {
    Socket.private(`user-paused-time-changed-${id}`).listen(
      "UserPausedTimeChanged",
      (e) => setUserActive(e.interruption)
    );
  }, [id, setUserActive]);

  useEffect(() => {
    Socket.private(`user-profile-changed-${id}`).listen(
      "UserProfilePictureChanged",
      (e) => setUserAvatar(e.url)
    );
  }, [avatar, setUserAvatar]);

  const [nameLogin, setNameLogin] = useState(name);

  useEffect(() => {
    const modifyName = name.split(/^\b(\w*\w*)\b/);
    setNameLogin(modifyName[1]);
  }, [name]);

  return (
    <div className="perfil">
      {accounts.records.length > 0 && <Celular user={user} accounts={accounts} />}
      <div className="foto-perfil">
        <img src={avatar} alt="" />
        <Dropdown text={nameLogin} className="userFilter">
          <Dropdown.Menu className="menu_right">
            <Dropdown.Item>
              <Interruption
                name={nameLogin}
                avatar={avatar}
                user_id={user_id}
                active={active}
              />
            </Dropdown.Item>

            <Dropdown.Item>
              <a href="/profile">Perfil</a>
            </Dropdown.Item>
            <Dropdown.Divider />

            <Dropdown.Divider />
            <Dropdown.Item>
              <a href="https://www.vipphone.com.br/general-5" target="_blank">Termos de Uso</a>
            </Dropdown.Item>
            <Dropdown.Item>
              <a href="https://www.vipphone.com.br/general-5" target="_blank">Termos de Uso</a>
            </Dropdown.Item>
            <Dropdown.Item>
              {isAuthenticated ? (
                <a onClick={() => logout()}>Logout</a>
              ) : (
                <Link to="/login"> Login </Link>
              )}
            </Dropdown.Item>
            {user.monitchat_support_user || user.companies.lenght > 0 ? (
              <Dropdown.Item>
                <ChangeCompany company_id={user.company_id} />
              </Dropdown.Item>
            ) : null}
            {user.default_company_id !== 1 ? (
              <Dropdown.Item>
                <AccessRequest />
              </Dropdown.Item>
            ) : null}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

const mapStateToProps = ({ user, accounts }) => ({
  user_id: user.user.id,
  active: user.active,
  name: user.user.name,
  avatar: user.avatar,
  id: user.user.id,
  accounts,
  user: user.user,
  company_id: user.user.company_id,
  isAuthenticated: user.isAuthenticated,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(AuthActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Perfil);
