import React from "react";

const AlertSuccess = ({
  message = "Seu registro foi salvo com sucesso",
  style = {},
}) => (
  <div className="AlertSuccess" style={style}>
    <h3>{message}</h3>
  </div>
);

export default AlertSuccess;
