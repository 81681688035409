import axios from "../services/api";

export default {
  whatsapp: {
    billingMessages: (params) =>
      axios
        .get(`/whatsapp-report/billingMessages`, { params })
        .then((res) => res.data),
  },
};
