import { combineReducers } from "redux";
import user from "./auth";
import conversation from "./conversation";
import contact from "./contact";
import stats from "./stats";
import accounts from "./accounts";
import task from "./task";
import tour from "./tour";
import ticket from "./ticket";
import ticketStatus from "./ticketStatus";

export default combineReducers({
  user,
  conversation,
  contact,
  stats,
  accounts,
  task,
  tour,
  ticket,
  ticketStatus,
});
