import Echo from "laravel-echo";

window.Pusher = require("pusher-js");

const socket = new Echo({
  broadcaster: "pusher",
  key: process.env.REACT_APP_WEB_SOCKET_KEY,
  authEndpoint: `${process.env.REACT_APP_API_V2_DOMAIN}/broadcasting/auth`,
  cluster: "mt1",
  encrypted: true,
  httpHost: process.env.REACT_APP_WEB_SOCKET_SERVER,
  wsHost: process.env.REACT_APP_WEB_SOCKET_SERVER,
  enabledTransports: ["ws", "wss"],
});

socket.connector.pusher.config.auth.headers.Authorization = `Bearer ${localStorage.token}`;

export default socket;


/*import Echo from "laravel-echo"
window.Pusher = require("pusher-js")

const socket = new Echo({
  broadcaster: "pusher",
  key: process.env.REACT_APP_WEB_SOCKET_KEY,
  cluster: "sa1",
  forceTLS: true,
  authEndpoint: `${process.env.REACT_APP_API_V2_DOMAIN}/broadcasting/auth`,
  encrypted: true,
  enabledTransports: ["ws", "wss"]
})

socket.connector.pusher.config.auth.headers.Authorization = `Bearer ${localStorage.token}`

export default socket*/