import React from "react";
import { Grid } from "semantic-ui-react";

export default () => (
  <Grid>
    <Grid.Row>
      <h2>Entregues/Total</h2>
      <p>
        Nesta coluna você verá as campanhas que já foram entregues e o Total a
        ser entregue.
      </p>
    </Grid.Row>
  </Grid>
);
