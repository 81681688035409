import React from "react";
import { Grid, Icon } from "semantic-ui-react";

export default () => (
  <Grid>
    <h2>Foto do Perfil</h2>
    <p>
      <Icon name="user circle" color="blue" size="large" /> Foto do usuário do
      perfil do usuário, só aparece se o tiver permissão do cliente de
      vizualização!
    </p>
  </Grid>
);
