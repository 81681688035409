import React from "react";
import { Grid } from "semantic-ui-react";

export default () => (
  <Grid>
    <Grid.Row>
      <h2>Relatórios</h2>
    </Grid.Row>
    <Grid.Row>
      <p>
        Nesta Parte Colocamos alguns relatórios de Tickets rápidos então você
        vai encontrar:{" "}
      </p>
      <strong>Tickets abertos:</strong>Aqui apresentará todos os tickets abertos
      <br />
      <strong> Tickets Atendidos</strong>Aqui apresentará todos os tickets
      atendidos
      <br />
      <strong>Tickets Fechados</strong>Aqui apresentará todos os tickets
      fechados
      <br /> <strong>Tickets aguardando</strong>Aqui apresentará todos os
      tickets aguardando <br />
      <h4>Opções de filtro</h4>
      Todos os relatórios tem as seguintes opções de Filtro: <br />
      Selecionar por quem está atribuido o ticket e o período da criação do
      ticket
    </Grid.Row>
  </Grid>
);
