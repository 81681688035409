import React, { Component } from "react";
import _ from "lodash";
import { Table, Label, Icon } from "semantic-ui-react";
import LoaderComponent from "../semantic/Loading";
import "jspdf-autotable";
import api from "../../api/company";
import apiInvoice from "../../api/invoice";
const columns = {
  due_date: "Vencimento",
  company_name: "Empresa",
  invoice_number: "Número da Fatura",
  description: "Descrição",
  paid_at: "Data de Pagamento",
  payment_type: "Método de Pagamento",
  status: "Status",
  total_value: "Valor Total",
};

class InvoiceTable extends Component {
  state = {
    activePage: 1,
    loading: false,
    recordsPerPage: 10,
    selectedId: 0,
    data: [],
    orderDirection: "desc",
    order: "id",
  };

  fetchData(){
    if(this.props.company_id){
      this.setState({ loading: true });
    api.company
      .fetchCompany(this.props.company_id)
      .then((res) => {
        this.setState({
          loading: false,
          data: res.data.invoices.map((r) => {
            return {
              ...r,
              active: r.active ? "Sim" : "Não",
              description: r.plan ? r.plan.description : "",
              payment_type:
                r.payment_type === "Boleto" ? (
                  r.invoice_url ? (
                    <a href={r.invoice_url} target="blank">
                      {r.payment_type}
                    </a>
                  ) : (
                    r.payment_type
                  )
                ) : (
                  r.payment_type
                ),
              status: (
                <Label size="tiny">
                  <span className="textCenter">
                    {r.status === "Pago" ? (
                      <Icon name="check" color="green" />
                    ) : (
                      <Icon name="calendar alternate outline" color="red" />
                    )}
                    {r.status}
                  </span>
                </Label>
              ),
              recurrent: r.recurrent ? "Sim" : "Não",
            };
          }),
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
    }
  }

  componentWillMount() {
    
    this.fetchData()
  }

  delete = (id) => apiInvoice.invoice.delete(id);

  handleSort = (clickedColumn) => () => {
    const { order, data, orderDirection } = this.state;

    if (order !== clickedColumn) {
      this.setState({
        order: clickedColumn,
        data: _.sortBy(data, [clickedColumn]),
        orderDirection: "ascending",
      });

      return;
    }

    this.setState({
      data: data.reverse(),
      orderDirection:
        orderDirection === "ascending" ? "descending" : "ascending",
    });
  };

  render() {
    const { order, orderDirection, data, loading } = this.state;
    const columNames = Object.keys(columns);

    return (
      <div className="DataTableActions">
        {loading && (
          <div className="loading-datatable">
            <LoaderComponent />
          </div>
        )}

        <Table sortable unstackable celled id={"data-table"} size="small">
          <Table.Header>
            <Table.Row>
              {Object.keys(columns).map((c, i) => (
                <Table.HeaderCell
                  sorted={order === c ? orderDirection : null}
                  onClick={this.handleSort(c)}
                  key={`column-${i * 1}`}
                >
                  <span>
                    {columns[c]}
                    <span
                      className={
                        c === order
                          ? orderDirection === "asc"
                            ? "ascended"
                            : "descended"
                          : ""
                      }
                    />
                  </span>
                </Table.HeaderCell>
              ))}
              <Table.HeaderCell
                  key={`delete`}
                >#</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {data.map((d, i) => (
              <Table.Row className="table-list" key={`contact-${i * 1}`}>
                {columNames.map((c, i) => (
                  <Table.Cell key={`row-column-${d.id}-${i}`}>
                    {d[c]}
                  </Table.Cell>
                ))}
                <Table.Cell>
                <Icon
                    onClick={() => {
                      this.delete(d.id).then(() => {
                        this.fetchData()
                      })
                    }}
                    name="trash"
                    style={{cursor: "pointer"}}
                    color="red"
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    );
  }
}

export default InvoiceTable;
