import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  TextArea,
  Dropdown,
  Modal,
  Grid,
  Card,
  Button,
  Icon,
  GridColumn,
  Segment,
} from "semantic-ui-react";
import { connect } from "react-redux";
import InlineError from "../messages/InlineError";
import DropdownTemplate from "../social-media/DropdownTemplate";
import apiConversation from "../../api/conversation";
import $ from "jquery";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { DateTimeInput } from "semantic-ui-calendar-react";
const FormNewConversation = ({
  onChange,
  newConversation,
  messageError,
  handleMediaChange,
  accounts,
  handleClose,
  contact = {},
}) => {
  const [templates, setTemplates] = useState([]);
  const [accountId, setAccountId] = useState(null);
  const [template, setTemplate] = useState();
  const [whatsappOficial, setWhatsappOficial] = useState(false);
  const [value, setValue] = useState();
  const [parameters, setParameters] = useState({});
  const [templateModalOpen, setTemplateModalOpen] = useState(false);
  const [sendingTemplate, setSendingTemplate] = useState(false);
  const [error, setError] = useState({});
  const [phone, setPhone] = useState();
  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Janeiro é 0

    return `${day}/${month}`;
  }
  const onTemplateSelected = (e, { name, value }) => {
    if (typeof newConversation.phone_number !== "string") {
      setError({ phone_number: "Informe o telefone para envio" });
      return;
    }
    setValue(value);
    if (!!value) {
      setTemplateModalOpen(true);
    }

    setTemplate({
      ...templates.find((t) => t.name === value),
      components: templates.find((t) => t.name === value)
        ? templates
            .find((t) => t.name === value)
            .components.map((t) => {
              return { ...t, parameters: [] };
            })
        : [],
    });
  };

  const onChangeParameter = (e, { name, value, type, elementPosition }) => {
    const componentPosition = template.components.findIndex(
      (a) => a.type.toLowerCase() === type
    );

    setParameters({
      ...parameters,
      [name]: value,
    });
    setTemplate({
      ...template,
      components: [
        ...template.components.slice(0, componentPosition),
        {
          ...template.components[componentPosition],
          parameters: [
            ...template.components[componentPosition].parameters.slice(
              0,
              elementPosition
            ),
            {
              type: "text",
              text: value,
            },
            ...template.components[componentPosition].parameters.slice(
              elementPosition + 1
            ),
          ],
        },
        ...template.components.slice(componentPosition + 1),
      ],
    });
  };

  const generateTemplateMessage = () => {
    let message = "";
    if (template.components) {
      template.components.map((component, i) => {
        const regexp = /\{\{\d\}\}/g;
        const str = component.text || "";
        const matches = str.match(regexp);

        let text = str;

        message += "<p><p>";
        if (component.text && matches) {
          for (var j = 0; j < matches.length; j++) {
            text = text.replace(
              `{{${j + 1}}}`,
              parameters[
                `component-${component.type.toLowerCase()}-${j + 1}`
              ] || matches[j]
            );
          }
        }

        if (component.text) {
          message += text;
        }
      });
    }

    return message;
  };

  const sendTemplate = () => {
    setSendingTemplate(true);
    apiConversation.conversations
      .startConversation({
        message_token: [...Array(32)]
          .map((i) => (~~(Math.random() * 36)).toString(36))
          .join(""),
        template: {
          ...template,
          data: {
            file_name: template.template_image_file_name,
            ext: template.template_image_ext,
            data: template.template_image_data,
            message_type: template.template_image_message_type,
            type: template.template_image_type,
          },
          message: generateTemplateMessage(),
          components: template.components.map((component) => {
            return {
              parameters: component.parameters,
              type: component.type.toLowerCase(),
              format: component.format ? component.format.toLowerCase() : null,
            };
          }),
        },
        accountNumber: newConversation.account_number,
        phone_number: newConversation.phone_number,
        send_at: newConversation.send_at,
      })
      .then((res) => {
        console.log(res.data);
        setTemplateModalOpen(false);
        setSendingTemplate(false);
        setTemplate(null);
        setParameters({});
        handleClose();
      })
      .catch((err) => {
        if (err.response.data.error) {
          setError({ phone_number: err.response.data.error });
        }
        if (err.response.data.message) {
          setError({ phone_number: err.response.data.message });
        }
        setSendingTemplate(false);
      });
  };

  useEffect(() => {
    if (contact && contact.id) {
      onChange(
        { target: { name: "phone_number", value: contact.phone_number } },
        { name: "phone_number", value: contact.phone_number }
      );
      setPhone(`+${contact.phone_number}`);
    }

    const account = accounts.find(
      (c) => c.phone_number == newConversation.account_number
    );

    if (account) {
      const { tpl } = newConversation;

      if (tpl && tpl.templateName) {
        if (templates.length > 0) {
          onTemplateSelected(null, { value: tpl.templateName });

          const params = tpl.tpl.split("|");

          const vals = {};
          const pars = [];

          const currTemplate = {
            ...templates.find((t) => t.name === tpl.templateName),
            components: templates.find((t) => t.name === tpl.templateName)
              ? templates
                  .find((t) => t.name === tpl.templateName)
                  .components.map((t) => {
                    return { ...t, parameters: [] };
                  })
              : [],
          };

          for (var par of params) {
            const key = parseInt(par.split(":")[0]);

            const name = `component-body-${key}`;
            const keyValue = par.split(":")[1];

            const componentPosition = currTemplate.components.findIndex(
              (a) => a.type.toLowerCase() === "body"
            );

            vals[name] = keyValue;

            pars.push({
              text: keyValue,
              type: "text",
            });
          }

          setParameters(vals);
          setTemplate((old) => ({
            ...currTemplate,
            components: [
              ...old.components.slice(0, 0),
              { ...old.components[0], parameters: pars },
              ...old.components.slice(1),
            ],
          }));
        }
      }
    }
  }, [templates]);

  useEffect(() => {
    const account = accounts.find(
      (c) => c.phone_number == newConversation.account_number
    );
    if (typeof newConversation.phone_number == "string") {
      setPhone(newConversation.phone_number);
      checkTickets({ target: { value: newConversation.phone_number } });
    }
    if (account) {
      setWhatsappOficial(!!account.whatsapp_official_account);
      setAccountId(account.id);
    }
  }, [newConversation.account_number]);

  const checkTickets = (e) => {
    setError({});
    apiConversation.conversations.checkTicket(e.target.value).then((res) => {
      if (res.data) {
        const { activeTicket } = res.data.data;
        if (activeTicket) {
          const currentProgress = parseFloat(activeTicket.current_progress);
          if (currentProgress < 100) {
            setError({
              phone_number: `Ja existe um atendimento em andamento para este contato: #${activeTicket.ticket_number}`,
            });
          }
        }
      }
    });
  };

  return (
    <Segment>
      <Form autoComplete="off" size="mini">
        <Form.Group widths="equal">
          <Form.Field error={messageError && !!messageError.phone_number}>
            <label htmlFor="message">Número do Contato</label>
            <PhoneInput
              placeholder="Enter phone number"
              defaultCountry="BR"
              value={phone}
              countryCallingCodeEditable={false}
              international
              onBlur={(e) =>
                checkTickets({
                  target: { name: "phone_number", value: e.target.value },
                })
              }
              className="number_br"
              name="phone_number"
              onChange={(a) => {
                onChange(
                  { target: { name: "phone_number", value: a } },
                  { name: "phone_number", value: a }
                );
                setPhone(a);
              }}
            />
            {messageError && <InlineError text={messageError.phone_number} />}
            {error.phone_number && <InlineError text={error.phone_number} />}
          </Form.Field>
        </Form.Group>
        <Form.Field>
          <label htmlFor="account_number">Conta de Saída</label>
          <Dropdown
            autoComplete="off"
            name="account_number"
            onChange={handleMediaChange}
            selection
            closeOnChange
            options={accounts.map((c) => ({
              key: c.id,
              value: c.phone_number,
              text: c.phone_number,
            }))}
            value={newConversation.account_number}
          />
        </Form.Field>

        <Form.Field>
          <label htmlFor="send_at">Data e Hora de Envio (Agendar Envio)</label>
          <DateTimeInput
            placeholder="Escolha uma data para agendar o envio"
            name="send_at"
            dateTimeFormat="YYYY-MM-DD HH:mm"
            iconPosition="left"
            clearable
            clearIcon={<Icon name="remove" color="red" />}
            value={newConversation.send_at}
            onChange={handleMediaChange}
          />
        </Form.Field>

        {accountId &&
          typeof newConversation.phone_number == "string" &&
          whatsappOficial && (
            <Form.Field>
              <label htmlFor="template">Template</label>
              <DropdownTemplate
                accountId={accountId}
                onChange={onTemplateSelected}
                setTemplates={setTemplates}
                value={value}
              />
            </Form.Field>
          )}
        {!whatsappOficial && (
          <Form.Field error={messageError && !!messageError.message}>
            <label htmlFor="message">Mensagem</label>
            <TextArea
              autoComplete="off"
              control="TextArea"
              name="message"
              onChange={onChange}
              value={newConversation.message}
              placeholder="Escreva sua mensagem"
            />
            {messageError && <InlineError text={messageError.message} />}
          </Form.Field>
        )}
      </Form>

      {template ? (
        <Modal
          open={templateModalOpen}
          onClose={() => {
            setTemplateModalOpen(!templateModalOpen);
            setValue(null);
            setTemplate(null);
            setParameters({});
          }}
          closeIcon
          closeOnEscape
        >
          <Modal.Header>Envio de template "{template.name}"</Modal.Header>
          <Modal.Content>
            <Segment>
              <Form>
                <Form.Field error={messageError && !!messageError.phone_number}>
                  <label htmlFor="message">Número do Contato</label>
                  <PhoneInput
                    placeholder="Enter phone number"
                    defaultCountry="BR"
                    value={phone}
                    countryCallingCodeEditable={false}
                    international
                    onBlur={(e) =>
                      checkTickets({
                        target: { name: "phone_number", value: e.target.value },
                      })
                    }
                    className="number_br"
                    name="phone_number"
                    onChange={(a) => {
                      onChange(
                        { target: { name: "phone_number", value: a } },
                        { name: "phone_number", value: a }
                      );
                      setPhone(a);
                    }}
                  />
                  {messageError && (
                    <InlineError text={messageError.phone_number} />
                  )}
                  {error.phone_number && (
                    <InlineError text={error.phone_number} />
                  )}
                </Form.Field>
              </Form>
            </Segment>
            <Segment>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={16}>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap:"10px"
                      }}
                    >
                      <div
                        class="Information_template"
                        data-tooltip="Categoria do Template"
                        data-position="top center"
                      >
                        <p>
                          <Icon name="block layout"></Icon> {template.category}
                        </p>
                      </div>
                      <div
                        class="Information_template"
                        data-tooltip="Ultima modificação"
                        data-position="top center"
                      >
                        <p>
                          <Icon name="clock outline"></Icon>{" "}
                          {formatDate(template.modified_at)}
                        </p>
                      </div>
                    </div>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={8}>
                    <strong>Parâmetros</strong>
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <strong>Visualização</strong>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={8}>
                    <Form>
                      {template.components
                        ? template.components.map((component, j) => {
                            if (
                              component.type.toLowerCase() == "header" &&
                              component.format &&
                              component.format.toLowerCase() == "image"
                            ) {
                              $(document).ready((event) => {
                                $("#template_conversation_header_image").change(
                                  (e) => {
                                    if (e.target.files.length > 0) {
                                      const tmppath = URL.createObjectURL(
                                        e.target.files[0]
                                      );
                                      $("#conversation_img_preview")
                                        .fadeIn("fast")
                                        .attr("src", tmppath);

                                      const file = document.getElementById(
                                        "template_conversation_header_image"
                                      ).files[0];
                                      const reader = new FileReader();
                                      const ext = file.name.split(".").pop();
                                      reader.readAsDataURL(file);

                                      reader.onload = (e) => {
                                        setTemplate({
                                          ...template,
                                          template_image_file_name: file.name,
                                          template_image_ext: ext,
                                          template_image_data: e.target.result,
                                          template_image_message_type: 4,
                                          template_image_type: "image",
                                        });
                                      };
                                    }
                                  }
                                );
                              });

                              return (
                                <div
                                  key={`component-${component.type.toLowerCase()}-${component.format.toLowerCase()}-${j}`}
                                >
                                  <label
                                    htmlFor="template_conversation_header_image"
                                    style={{
                                      cursor: "pointer",
                                      padding: 5,
                                      backgroundColor: "#ccc",
                                      border: "1px solid #eee",
                                      borderRadius: 10,
                                    }}
                                    className="btn"
                                  >
                                    Selecionar Imagem
                                  </label>
                                  <input
                                    type="file"
                                    accept=".png,.jpeg,.jpg"
                                    style={{ visibility: "hidden" }}
                                    title="Imagem do Cabeçalho do Template"
                                    id="template_conversation_header_image"
                                    name="template_conversation_header_image"
                                  />
                                </div>
                              );
                            } else if (
                              component.type.toLowerCase() == "header" &&
                              component.format &&
                              component.format.toLowerCase() == "video"
                            ) {
                              $(document).ready((event) => {
                                $("#template_conversation_header_video").change(
                                  (e) => {
                                    if (e.target.files.length > 0) {
                                      const tmppath = URL.createObjectURL(
                                        e.target.files[0]
                                      );
                                      $("#conversation_video_preview").attr(
                                        "src",
                                        tmppath
                                      );

                                      $(
                                        "#conversation_video_preview"
                                      )[0].load();

                                      const file = document.getElementById(
                                        "template_conversation_header_video"
                                      ).files[0];
                                      const reader = new FileReader();
                                      const ext = file.name.split(".").pop();
                                      reader.readAsDataURL(file);

                                      reader.onload = (e) => {
                                        setTemplate({
                                          ...template,
                                          template_image_file_name: file.name,
                                          template_image_ext: ext,
                                          template_image_data: e.target.result,
                                          template_image_message_type: 1,
                                          template_image_type: "video",
                                        });
                                      };
                                    }
                                  }
                                );
                              });

                              return (
                                <div
                                  key={`component-${component.type.toLowerCase()}-${component.format.toLowerCase()}-${j}`}
                                >
                                  <label
                                    htmlFor="template_conversation_header_video"
                                    style={{
                                      cursor: "pointer",
                                      padding: 5,
                                      backgroundColor: "#ccc",
                                      border: "1px solid #eee",
                                      borderRadius: 10,
                                    }}
                                    className="btn"
                                  >
                                    Selecionar Vídeo
                                  </label>
                                  <input
                                    type="file"
                                    accept=".mp4"
                                    style={{ visibility: "hidden" }}
                                    title="Imagem do Cabeçalho do Template"
                                    id="template_conversation_header_video"
                                    name="template_conversation_header_video"
                                  />
                                </div>
                              );
                            } else if (
                              component.type.toLowerCase() == "header" &&
                              component.format &&
                              component.format.toLowerCase() == "document"
                            ) {
                              $(document).ready((event) => {
                                $("#template_conversation_header_image").change(
                                  (e) => {
                                    if (e.target.files.length > 0) {
                                      const file = document.getElementById(
                                        "template_conversation_header_image"
                                      ).files[0];
                                      const reader = new FileReader();
                                      const ext = file.name.split(".").pop();
                                      reader.readAsDataURL(file);

                                      reader.onload = (e) => {
                                        setTemplate({
                                          ...template,
                                          template_image_file_name: file.name,
                                          template_image_ext: ext,
                                          template_image_data: e.target.result,
                                          template_image_message_type: 3,
                                          template_image_type: "document",
                                        });
                                      };
                                    }
                                  }
                                );
                              });

                              return (
                                <div
                                  key={`component-${component.type.toLowerCase()}-${component.format.toLowerCase()}-${j}`}
                                >
                                  <label
                                    htmlFor="template_conversation_header_image"
                                    style={{
                                      cursor: "pointer",
                                      padding: 5,
                                      backgroundColor: "#ccc",
                                      border: "1px solid #eee",
                                      borderRadius: 10,
                                    }}
                                    className="btn"
                                  >
                                    Selecionar Documento
                                  </label>
                                  <input
                                    type="file"
                                    style={{ visibility: "hidden" }}
                                    title="Documento do Cabeçalho do Template"
                                    id="template_conversation_header_image"
                                    name="template_conversation_header_image"
                                  />
                                </div>
                              );
                            } else {
                              const regexp = /\{\{\d\}\}/g;
                              const str = component.text || "";
                              const matches = str.match(regexp);

                              if (!matches || !matches.length) return null;

                              return matches.map((match, i) => (
                                <Form.Field
                                  control={Input}
                                  label={`${
                                    component.type.toLowerCase() == "header"
                                      ? "Título"
                                      : "Mensagem"
                                  } ${match}`}
                                  key={`component-${component.type.toLowerCase()}-${i}`}
                                  onChange={(e, data) =>
                                    onChangeParameter(e, {
                                      name: data.name,
                                      value: data.value,
                                      type: component.type.toLowerCase(),
                                      elementPosition: i,
                                    })
                                  }
                                  name={`component-${component.type.toLowerCase()}-${i +
                                    1}`}
                                />
                              ));
                            }
                          })
                        : null}
                    </Form>
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <Card>
                      {template.components
                        ? template.components.map((component, i) => {
                            if (
                              component.format &&
                              component.format.toLowerCase() == "image"
                            ) {
                              return (
                                <img
                                  key={`value-${component.type.toLowerCase()}-${i}-${component.format.toLowerCase()}-${i}`}
                                  id="conversation_img_preview"
                                ></img>
                              );
                            }

                            if (
                              component.format &&
                              component.format.toLowerCase() == "video"
                            ) {
                              return (
                                <video
                                  controls
                                  autoPlay
                                  key={`value-${component.type.toLowerCase()}-${i}-${component.format.toLowerCase()}-${i}`}
                                  id="conversation_video_preview"
                                ></video>
                              );
                            }

                            if (
                              component.format &&
                              component.format.toLowerCase() == "document" &&
                              !!template.template_image_file_name
                            ) {
                              const template_ext = template.template_image_ext;

                              return (
                                <div className="file-message-template">
                                  <div className="file-type">
                                    <div>
                                      <Icon
                                        name={
                                          template_ext === "pdf"
                                            ? "file pdf"
                                            : template_ext === "docx" ||
                                              template_ext === "doc"
                                            ? "file word"
                                            : template_ext === "xls" ||
                                              template_ext === "xlsx"
                                            ? "file excel"
                                            : "file"
                                        }
                                        color={
                                          template_ext === "pdf"
                                            ? "red"
                                            : template_ext === "docx"
                                            ? "blue"
                                            : template_ext === "xls" ||
                                              template_ext === "xlsx"
                                            ? "green"
                                            : "grey"
                                        }
                                        size="big"
                                      />
                                    </div>
                                  </div>
                                  <div className="file-url">
                                    {template.template_image_file_name}
                                  </div>
                                </div>
                              );
                            }

                            const regexp = /\{\{\d\}\}/g;
                            const str = component.text || "";
                            const matches = str.match(regexp);

                            let text = str;
                            if (component.text && matches) {
                              for (var j = 0; j < matches.length; j++) {
                                text = text.replace(
                                  `{{${j + 1}}}`,
                                  parameters[
                                    `component-${component.type.toLowerCase()}-${j +
                                      1}`
                                  ] || matches[j]
                                );
                              }
                            }

                            return component.text ? (
                              <Card.Content
                                key={`value-${component.type.toLowerCase()}-${i}`}
                              >
                                {text}
                              </Card.Content>
                            ) : null;
                          })
                        : null}
                    </Card>
                  </Grid.Column>
                </Grid.Row>
                {
                  <Grid.Row>
                    <Grid.Column>
                      <Form.Field>
                        <label htmlFor="send_at">
                          Data e Hora de Envio (Agendar Envio)
                        </label>
                        <DateTimeInput
                          placeholder="Escolha uma data para agendar o envio"
                          name="send_at"
                          dateTimeFormat="YYYY-MM-DD HH:mm"
                          iconPosition="left"
                          clearable
                          clearIcon={<Icon name="remove" color="red" />}
                          value={newConversation.send_at}
                          onChange={handleMediaChange}
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                }
              </Grid>
            </Segment>
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={sendTemplate}
              loading={sendingTemplate}
              disabled={sendingTemplate}
            >
              Enviar
            </Button>
          </Modal.Actions>
        </Modal>
      ) : null}
    </Segment>
  );
};

const mapStateToProps = ({ accounts }) => {
  return {
    accounts: accounts.records,
  };
};

export default connect(mapStateToProps)(FormNewConversation);
