import React from "react";
import { Card, Button, Icon, Message } from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Creators as TaskAction } from "../../store/ducks/task";
import audionotification from "../../assets/sound/notification.mp3";

function TaskEvent(props) {
  //change status Event
  const changeStatusTask = event => {
    const changeStatus = {
      ...event,
      status: event.status ? 0 : 1
    };
    props.alterTask({
      id: changeStatus.id,
      payload: changeStatus
    });
    props.alterEventTask({
      id: changeStatus.id,
      payload: changeStatus
    });
    props.removeEventTask(event);
    setTimeout(() => {
      props.fetchRecords();
    }, 1400);
  };

  //remove event
  const deleteTask = event => {
    const changeStatus = {
      ...event,
      status: event.status ? 0 : 1
    };
    props.alterEventTask({
      id: changeStatus.id,
      payload: changeStatus
    });
   
    props.deleteTask(event.id);
    props.removeEventTask(event);
    setTimeout(() => {
      props.fetchRecords();
    }, 1400); 

  };

  //close card event no action
  const closedEventTask = event => {
    const changeStatus = {
      ...event,
      status: event.status ? 0 : 1
    };
    props.alterEventTask({
      id: changeStatus.id,
      payload: changeStatus
    });
    setTimeout(() => {
      props.removeEventTask(event);
    }, 1000);
  };
  return (
    <Card.Group className="task_event-card">
      {
        props.task.success &&(
          <Card className="card-task-animation_open">
          <Message positive>
            <Message.Header>Tarefa agendada com sucesso</Message.Header>
          </Message>
          </Card>
        )
      }
      {
        props.task.errors&& (
          <Card className="card-task-animation_open">
          <Message error>
            <Message.Header>Erro ao Criar Tarefa</Message.Header>
          </Message>
          </Card>
        )
      }
      {props.task.records &&
        props.task.records.map((element, i) =>
          !!element.message && element.message.status === "success" ? (
            <Card
              key={`record-${i}`}
              className={
                !!element.message && element.message.status === "success"
                  ? "card-task-animation_open"
                  : "card-task-animation_close"
              }
            >
              <Message positive>
                <Message.Header>Deletada com Sucesso</Message.Header>
              </Message>
            </Card>
          ) : (
            !!element.message &&
            element.message.response && (
              <Card>
                <Message error>
                  <Message.Header>Operação não efetuada</Message.Header>
                </Message>
              </Card>
            )
          )
        )}

      {props.task.records &&
        props.task.records.map((element, i) =>
          !!element.status_change &&
          element.status_change.status === "success" ? (
            <Card
              key={`status-${i}`}
              className={
                element.status_change &&
                element.status_change.status === "success"
                  ? "card-task-animation_open"
                  : "card-task-animation_close"
              }
            >
              {
                element.status_change&& (
                  <Message positive>
                    <Message.Header>{element.status_change.message}</Message.Header>
                  </Message>
                )
              }
            </Card>
          ) : (
            !!element.status_change &&
            element.status_change.response && (
              <Card>
              <Message error>
                  <Message.Header>Operação não efetuada</Message.Header>
              </Message>
              </Card>
            )
          )
        )}

      {props.task.event &&
        props.task.event.map(event => (
          <Card
            className={
              event.status
                ? "card-task-animation_close"
                : "card-task-animation_open"
            }
            key={event.id}
            style={{
              width: "350px",
              boxShadow: `0 8px 16px 0 rgba(0,0,0,0.2)`
            }}
          >
            <Card.Header>
              {" "}
              <Icon style={{ marginTop: "5px" }} name="clock" />
              <strong style={{ marginTop: "5px" }}>
                Tarefa agendada:{" "}
              </strong>{" "}
              <Button
                icon="close"
                color="blue"
                floated="right"
                size="mini"
                onClick={() => closedEventTask(event)}
              />{" "}
            </Card.Header>
            <Card.Content>
              <iframe
                title="audio"
                src={audionotification}
                allow="autoplay"
                id="audio"
                style={{
                  width: "0px",
                  height: "0px",
                  overflow: "hidden",
                  display: "block",
                  border: "0px"
                }}
              />
              <label>
                <strong>Titulo:</strong> {event.title}{" "}
              </label>{" "}
              <br />
              <label>
                <strong>Descrição:</strong> {event.description}
              </label>
            </Card.Content>
            <Card.Content extra>
                  <Button onClick={() => changeStatusTask(event)} color="green">
                    Concluir Tarefa
                </Button>
                  <Button onClick={() => deleteTask(event)} color="red">
                    Deletar Tarefa
                  </Button>
            </Card.Content>
          </Card>
        ))}
    </Card.Group>
  );
}
const mapDispatchToProps = dispatch => bindActionCreators(TaskAction, dispatch);
 
const mapStateToProps = state => ({
  task: state.task,
  user: state.user.user
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaskEvent);
