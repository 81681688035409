import React from "react";
import { Grid, Icon } from "semantic-ui-react";

export default () => (
  <Grid>
    <h2>Aba de relacionada a tickets e tarefas</h2>
    <p>
      Temos um checkbox habilitando o envio de mensagem com o Enter, então se
      você não quiser enviar uma mensagem quando apertar enter é só desmarcar
      essa opção.
    </p>
    <p>
      <Icon name="android" size="large" color="grey" />
      Ativar ou desativa o robô, para aquela conversa. ele para de fazer
      interações com usuário
    </p>
    <p>
      <Icon name="mail forward" size="large" color="grey" />
      Encaminhar ticket para outro agente
    </p>
    <p>
      <Icon name="calendar alternate outline" size="large" color="grey" />
      Criar uma nova tarefa, essa tarefa você será notificado pelo whatsapp se o
      agente tiver cadastrado com uma conta válida e pela plataforma
    </p>
    <p>
      <Icon name="plus" size="large" color="grey" />
      Adicionar um comentátio na conversa
    </p>
    <p>
      <Icon name="ticket" size="large" color="grey" />
      Criar um novo Ticket
    </p>
  </Grid>
);
