import axios from "../services/api";

export default {
  ticket: {
    fetchAll: (params) =>
      axios.get(`/ticket`, { params }).then((res) => res.data),
    fetchClosed: (params) =>
      axios.get(`/ticket?closed=1`, { params }).then((res) => res.data),

    fetchTicket: (params) =>
      axios.get(`/ticket/${params}`).then((res) => res.data),
    delete: (id, noOwnerOnly = false) =>
      axios
        .delete(`/ticket/${id}`, { data: { noOwnerOnly } })
        .then((res) => res.data),
    update: (id, ticket) =>
      axios.put(`/ticket/${id}`, ticket).then((res) => res.data),
    submit: (ticket) => axios.post(`/ticket`, ticket).then((res) => res.data),
    merge: (data) => axios.post(`/ticket/merge`, data).then((res) => res.data),
    uploadFiles: (files, id) =>
      axios
        .post(`/ticket/uploadFiles/${id}`, { files })
        .then((res) => res.data),
    changeUser: (ticket) =>
      axios.post(`/ticket/setTicketOwner`, ticket).then((res) => res.data),
    changeStatus: (ticket) =>
      axios.post(`/ticket/setTicketStatus`, ticket).then((res) => res.data),
    comments: (ticketId) =>
      axios.get(`/comment?ticket_id=${ticketId}`).then((res) => res.data),
    messages: (ticketId) =>
      axios.get(`/ticket/${ticketId}/messages`).then((res) => res.data),
    conversation: (ticketId) =>
      axios.get(`/ticket/${ticketId}/conversation`).then((res) => res.data),
    submitComment: (ticket) =>
      axios.post(`/comment`, ticket).then((res) => res.data),
  },
};
