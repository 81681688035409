import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Checkbox,
  Dropdown,
  Divider,
  Label,
  Button,
  Grid,
  Icon,
} from "semantic-ui-react";
import InlineError from "../../messages/InlineError";
import api from "../../../services/api";
import InputMask from "react-input-mask";
import ExtraFieldForm from "../../extra-input/ExtraFieldForm";
import InvoiceTable from "../../companies/InvoiceTable";
import segmentos from "./segments";
import FranquiaMensagemSummay from "../../summary/FranquiaMensagemSummay";
import DropdownUsers from "../../users/DropdownUsers";
import axios from "../../../services/api";
import CompanyWebhook from "../../companies/CompanyWebhook";

const options = [
  {
    key: "Google",
    text: "Google",
    value: "Google",
  },
  {
    key: "Site Vipphone",
    text: "Site Vipphone",
    value: "Site Vipphone",
  },
  {
    key: "Indicação",
    text: "Indicação",
    value: "Indicação",
  },
  {
    key: "Contato Telefônico",
    text: "Contato Telefônico",
    value: "Contato Telefônico",
  },
];
const optionsCPF = [
  {
    key: 2,
    text: "CPF",
    value: 2,
  },
  {
    key: 1,
    text: "CNPJ",
    value: 1,
  },
];

const FormCompany = ({
  company,
  onChange,
  onChecked,
  onSelectOrigin,
  onSelectSegment,
  messageError,
  handleExtraFields,
  part,
  setAddress
}) => {
  const [plans, setPlans] = useState([]);
  const [servers, setServers] = useState([]);
  const [loadingConnection, setLoadingConnection] = useState(true);
  const [loadingWaba, setLoadingWaba] = useState(false);
  const [monitcallConnected, setMonitcallConnected] = useState(false);

  const getAddress = (e) => {
    const cep = e.target.value
    axios.get(`https://viacep.com.br/ws/${cep}/json/`).then(res => {

      if (!res.data.erro) {
        setAddress({
          address: res.data.logradouro,
          city: res.data.localidade,
          state: res.data.uf,
          neighborhood: res.data.bairro,
        })
      }
    })
  }

  useEffect(() => {
    if (part == 2) {
      const filterPlan = { filter: JSON.stringify([["active", "=", 1]]) };
      api
        .get("/pricing-plan", { params: { take: 300, filter: filterPlan } })
        .then((res) => {
          setPlans(res.data.data);
        });

      const filterServer = {
        filter: JSON.stringify([["company_id", "=", company.id]]),
      };

      api
        .get("/server", { params: { take: 100, filter: filterServer } })
        .then((res) => {
          setServers(res.data.data);
        });

      testServer(company.parameters.monitcall_base_url);
    }
  }, [part]);

  const simulateWaba = () => {
    setLoadingWaba(true);
    api.get(`/company/simulateWABA/${company.id}`).then((res) => {
      setLoadingWaba(false);
    });
  };
  const testServer = (server) => {
    setLoadingConnection(true);
    api
      .get("/monitcall/serverTest?server=" + server)
      .then((res) => {
        setLoadingConnection(false);
        setMonitcallConnected(res.data.connected);
      })
      .catch((err) => {
        setLoadingConnection(false);
        setMonitcallConnected(false);
      });
  };

  const currentPlan = plans.find((p) => p.id === company.pricing_plan_id);

  return (
    <Form size="mini">
      {part === 0 ? (
        <div className="holder_part">
          <Form.Group widths="equal">
            <Form.Field error={!!messageError ? !!messageError.name : false}>
              <label htmlFor="name">Razão Social*</label>
              <Input
                autoComplete="off"
                name="name"
                onChange={onChange}
                value={company.name}
                placeholder="Nome"
              />
              {messageError ? <InlineError text={messageError.name} /> : ""}
            </Form.Field>

            <Form.Field error={messageError ? !!messageError.segment : ""}>
              <label htmlFor="segment">Segmento</label>
              <Dropdown
                placeholder="Qual o segmento da empresa?"
                fluid
                selection
                search
                name="segment"
                value={company.segment}
                options={segmentos}
                onChange={onSelectSegment}
              />
              {messageError ? <InlineError text={messageError.segment} /> : false}
            </Form.Field>
            {/* Segmento */}
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field error={messageError ? !!messageError.origin : false}>
              <label htmlFor="origin">Origem</label>
              <Dropdown
                placeholder="Onde você descobriu o Monitchat?"
                fluid
                selection
                name="origin"
                value={company.origin}
                options={options}
                onChange={onSelectOrigin}
              />
              {messageError ? <InlineError text={messageError.origin} /> : ""}
            </Form.Field>
            {/* Origem */}

            <Form.Field error={messageError ? !!messageError.cpf_cnpj : false}>
              <label htmlFor="cpf_cnpj">
                {" "}
                <Dropdown
                  inline
                  options={optionsCPF}
                  name="person_type"
                  value={company.person_type}
                  onChange={onChange}
                />
              </label>
              <Input
                autoComplete="off"
                label={<Dropdown defaultValue=".com" options={options} />}
                labelPosition="left"
                children={
                  <InputMask
                    mask={
                      company.person_type === 1
                        ? "99.999.999/9999-99"
                        : "999.999.999-99"
                    }
                    name="cpf_cnpj"
                    onChange={(e) =>
                      onChange(e, {
                        name: e.target.name,
                        value: e.target.value,
                      })
                    }
                    value={company.cpf_cnpj}
                    placeholder="CPF/CNPJ"
                  />
                }
              />
              {messageError ? <InlineError text={messageError.cpf_cnpj} /> : ""}
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field error={messageError ? !!messageError.username : false}>
              <label htmlFor="username">Nome do Usuário*</label>
              <Input
                autoComplete="off"
                children={
                  <Input
                    name="username"
                    autoComplete="off"
                    onChange={onChange}
                    value={company.username}
                    placeholder="Nome do Administrador"
                  />
                }
              />
              {messageError ? <InlineError text={messageError.username} /> : ""}
            </Form.Field>

            <Form.Field error={messageError ? !!messageError.phone_optional : false}>
              <label htmlFor="phone_optional">Telefone*</label>
              <Input
                autoComplete="off"
                children={
                  <InputMask
                    mask="+55 (99) 99999-9999"
                    name="phone_optional"
                    onChange={(e) => onChange(e, { name: "phone_optional", value: e.target.value })}
                    value={company.phone_optional}
                    placeholder="Telefone"
                  />
                }
              />
              {messageError ? (
                <InlineError text={messageError.phone_optional} />
              ) : (
                  ""
                )}
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field error={messageError ? !!messageError.email : false}>
              <label htmlFor="email">Email Administrativo*</label>
              <Input
                autoComplete="off"
                type="email"
                name="email"
                onChange={onChange}
                value={company.email}
                placeholder="Email"
              />
              {messageError ? <InlineError text={messageError.email} /> : ""}
            </Form.Field>
            <Form.Field
              error={messageError ? !!messageError.email_financial : false}
            >
              <label htmlFor="email_financial">Email Financeiro*</label>
              <Input
                autoComplete="off"
                type="email_financial"
                name="email_financial"
                onChange={onChange}
                value={company.email_financial}
                placeholder="Email"
              />
              {messageError ? (
                <InlineError text={messageError.email_financial} />
              ) : (
                  ""
                )}
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <div className="field">
              <label htmlFor="email_financial">Usuário Admin</label>
              <DropdownUsers
                onSelectUser={(value) => onChange(null, { name: "user_id", value })}
                user_id={company.user_id}
                allowAdditions={false}
                multiple={false}
                company_id={company.id}

              />
            </div>
          </Form.Group>
          <Divider />
          <ExtraFieldForm
            extra_fields={company.extra_fields}
            onChange={handleExtraFields}
          />
        </div>
      ) : (
          ""
        )}

      {part === 1 ? (
        <div className="holder_part">
          <Form.Group widths="equal">
            <Form.Field error={messageError ? !!messageError.address : false}>
              <label htmlFor="address">Endereço:</label>
              <Input
                autoComplete="off"
                name="address"
                value={company.address}
                onChange={onChange}
              />
              {messageError ? <InlineError text={messageError.address} /> : ""}
            </Form.Field>
            <Form.Field error={messageError ? !!messageError.neighborhood : false}>
              <label htmlFor="neighborhood">Bairro:</label>
              <Input
                autoComplete="off"
                name="neighborhood"
                value={company.neighborhood}
                onChange={onChange}
              />
              {messageError ? (
                <InlineError text={messageError.neighborhood} />
              ) : (
                  ""
                )}
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field error={messageError ? !!messageError.city : false}>
              <label htmlFor="city">Cidade:</label>
              <Input
                autoComplete="off"
                name="city"
                value={company.city}
                onChange={onChange}
              />
              {messageError ? <InlineError text={messageError.city} /> : ""}
            </Form.Field>
            <Form.Field error={messageError ? !!messageError.state : false}>
              <label htmlFor="state">Estado:</label>
              <Input
                autoComplete="off"
                name="state"
                value={company.state}
                onChange={onChange}
              />
              {messageError ? <InlineError text={messageError.state} /> : ""}
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field error={messageError ? !!messageError.zip_code : false}>
              <label htmlFor="zip_code">CEP:</label>
              <Input
                autoComplete="off"
                name="zip_code"
                onBlur={getAddress}
                type="number"
                value={company.zip_code}
                onChange={onChange}
              />
              {messageError ? <InlineError text={messageError.zip_code} /> : ""}
            </Form.Field>
            <Form.Field error={messageError ? !!messageError.number : false}>
              <label htmlFor="number">Número:</label>
              <Input
                autoComplete="off"
                name="number"
                type="number"
                value={company.number}
                onChange={onChange}
              />
              {messageError ? <InlineError text={messageError.number} /> : ""}
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field error={messageError ? !!messageError.country : false}>
              <label htmlFor="country">País:</label>
              <Input
                autoComplete="off"
                name="country"
                type="country"
                value={company.country}
                onChange={onChange}
              />
              {messageError ? <InlineError text={messageError.country} /> : ""}
            </Form.Field>
            <Form.Field error={messageError ? !!messageError.complement : ""}>
              <label htmlFor="complement">Complemento:</label>
              <Input
                autoComplete="off"
                name="complement"
                type="complement"
                value={company.complement}
                onChange={onChange}
              />
              {messageError ? (
                <InlineError text={messageError.complement} />
              ) : (
                  ""
                )}
            </Form.Field>
          </Form.Group>
        </div>
      ) : (
          ""
        )}
      {part === 2 ? (
        <div className="holder_part">
          <Form.Group>
            <Form.Field>
              <label htmlFor="origin">Plano Atual</label>
              <Dropdown
                placeholder="Selecione"
                clearable
                fluid
                search
                selection
                value={company.pricing_plan_id}
                name="pricing_plan_id"
                onChange={onChange}
                options={plans.map((p) => ({
                  key: p.id,
                  value: p.id,
                  text: `${p.name} - ${p.description}`,
                }))}
              />
              {messageError ? <InlineError text={messageError.origin} /> : ""}
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <label>Contas de Usuário:</label>
              <Label>{currentPlan ? currentPlan.max_users : ""}</Label>
            </Form.Field>
            <Form.Field>
              <label>Contas de WhatsApp:</label>
              <Label>{currentPlan ? currentPlan.max_accounts : ""}</Label>
            </Form.Field>
            <Form.Field>
              <label>MonitBot Liberado ?</label>
              <Label>
                {currentPlan
                  ? currentPlan.virtual_assistant_active
                    ? "Sim"
                    : "Não"
                  : "Não"}
              </Label>
            </Form.Field>
            <Form.Field>
              <label>Campanhas Liberadas ?</label>
              <Label>
                {currentPlan
                  ? currentPlan.campaings_active
                    ? "Sim"
                    : "Não"
                  : "Não"}
              </Label>
            </Form.Field>
          </Form.Group>

          <Form.Group widths="6">
            <Form.Field>
              <label>Valor do Plano:</label>
              <Label>{currentPlan ? currentPlan.price : ""}</Label>
            </Form.Field>

            <Form.Field
              style={{ width: "120px" }}
              error={messageError ? !!messageError.billing_value : ""}
            >
              <label htmlFor="billing_value">Valor Negociado:</label>
              <Input
                size="mini"
                autoComplete="off"
                name="billing_value"
                value={company.billing_value}
                onChange={onChange}
              />
              {messageError ? (
                <InlineError text={messageError.billing_value} />
              ) : (
                  ""
                )}
            </Form.Field>

            <Form.Field
              style={{ width: "120px" }}
              error={messageError ? !!messageError.whatsapp_quota : ""}
            >
              <label htmlFor="whatsapp_quota">Conversas Service</label>
              <Input
                size="mini"
                autoComplete="off"
                name="whatsapp_quota"
                value={company.whatsapp_quota}
                onChange={onChange}
              />
              {messageError ? (
                <InlineError text={messageError.whatsapp_quota} />
              ) : (
                  ""
                )}
            </Form.Field>
                     
            <Form.Field
              style={{ width: "120px" }}
              error={messageError ? !!messageError.marketing_quota : ""}
            >
              <label htmlFor="marketing_quota">Conversas Marketing</label>
              <Input
                size="mini"
                autoComplete="off"
                name="marketing_quota"
                value={company.marketing_quota}
                onChange={onChange}
              />
              {messageError ? (
                <InlineError text={messageError.marketing_quota} />
              ) : (
                  ""
                )}
            </Form.Field>

            <Form.Field
              style={{ width: "120px" }}
              error={messageError ? !!messageError.utility_quota : ""}
            >
              <label htmlFor="utility_quota">Conversas Utility</label>
              <Input
                size="mini"
                autoComplete="off"
                name="utility_quota"
                value={company.utility_quota}
                onChange={onChange}
              />
              {messageError ? (
                <InlineError text={messageError.utility_quota} />
              ) : (
                  ""
                )}
            </Form.Field>
          </Form.Group>

          
          <Form.Group widths="6">
            <Form.Field>
              
              
            </Form.Field>

            <Form.Field
              style={{ width: "120px" }}
              error={messageError ? !!messageError.service_price : ""}
            >
              <label htmlFor="service_price">Valor Unitário Service</label>
              <Input
                size="mini"
                autoComplete="off"
                name="service_price"
                value={company.service_price}
                onChange={onChange}
              />
              {messageError ? (
                <InlineError text={messageError.service_price} />
              ) : (
                  ""
                )}
            </Form.Field>

            <Form.Field
              style={{ width: "120px" }}
              error={messageError ? !!messageError.utility_price : ""}
            >
              <label htmlFor="utility_price">Valor Unitário Utility</label>
              <Input
                size="mini"
                autoComplete="off"
                name="utility_price"
                value={company.utility_price}
                onChange={onChange}
              />
              {messageError ? (
                <InlineError text={messageError.utility_price} />
              ) : (
                  ""
                )}
            </Form.Field>
                     
            <Form.Field
              style={{ width: "120px" }}
              error={messageError ? !!messageError.marketing_price : ""}
            >
              <label htmlFor="marketing_price">Valor Unitário Marketing</label>
              <Input
                size="mini"
                autoComplete="off"
                name="marketing_price"
                value={company.marketing_price}
                onChange={onChange}
              />
              {messageError ? (
                <InlineError text={messageError.marketing_price} />
              ) : (
                  ""
                )}
            </Form.Field>
          </Form.Group>


          <Form.Field error={messageError ? !!messageError.client_key : ""}>
            <label htmlFor="client_key">Chave de Acesso:</label>
            <Input
              autoComplete="off"
              control={Input}
              name="client_key"
              value={company.client_key}
            />
            {messageError ? <InlineError text={messageError.client_key} /> : ""}
          </Form.Field>
          <Form.Group widths="equal">          
            <div className="field">
              <Checkbox
                label="Ativo"
                onChange={onChecked}
                name="active"
                checked={!!company.active}
              />
            </div>
            <div className="field">
              <Checkbox
                label="Integração Intelliway"
                onChange={onChecked}
                name="intelliway_integration"
                checked={!!company.intelliway_integration}
              />
            </div>
          </Form.Group>
          <Form.Group widths="equal">
            <div className="field">
              <Checkbox
                label="Integração com Facebook"
                onChange={onChecked}
                name="facebook_active"
                checked={!!company.facebook_active}
              />
            </div>
            <div className="field">
              <Checkbox
                label="Integração com Instagram"
                onChange={onChecked}
                name="instagram_active"
                checked={!!company.instagram_active}
              />
            </div>
          </Form.Group>
          <Form.Group widths="equal">
            <div className="field">
              <Checkbox
                label="Integração Monitcall"
                onChange={onChecked}
                name="monitcall_integration"
                checked={!!company.monitcall_integration}
              />
            </div>
            <div className="field">
              <Checkbox
                label="Conta Paga"
                onChange={onChecked}
                name="paid_account"
                checked={!!company.paid_account}
              />
            </div>
          </Form.Group>
          <Form.Group widths="equal">
            <div className="field">
              <Checkbox
                label="Período de Teste Expirado"
                name="trial_period_expired"
                checked={!!company.trial_period_expired}
              />
            </div>

            <div className="field">
              <Checkbox
                label="Utiliza Servidor Externo"
                onChange={onChecked}
                name="external_server"
                checked={!!company.external_server}
              />
            </div>
          </Form.Group>
          <Form.Group widths="equal">
            <div className="field">
              <Checkbox
                label="Bloqueado"
                name="blocked"
                onChange={onChecked}
                checked={company.blocked}
              />
            </div>
            <div className="field">
              <Checkbox
                label="Habilitar Base de Conhecimento"
                onChange={onChecked}
                name="knowledge_base_active"
                checked={!!company.knowledge_base_active}
              />
            </div>
          </Form.Group>
          {!!company.external_server && (
            <Form.Group>
              <Form.Field>
                <label htmlFor="origin">Servidor</label>
                <Dropdown
                  placeholder="Selecione"
                  clearable
                  fluid
                  search
                  selection
                  value={company.server_id}
                  name="server_id"
                  onChange={onChange}
                  options={servers.map((p) => ({
                    key: p.id,
                    value: p.id,
                    text: `${p.name} - ${p.host_name}`,
                  }))}
                />
                {messageError ? <InlineError text={messageError.origin} /> : ""}
              </Form.Field>
            </Form.Group>
          )}
          <Form.Group widths="equal">
            <div className="field">
              <Checkbox
                label="Gerar Fatura"
                onChange={onChecked}
                name="create_invoice"
                checked={!!company.create_invoice}
              />
            </div>
            <div className="field">
              <Checkbox
                label="Ignorar Pagamentos Vencidos"
                onChange={onChecked}
                name="ignore_overdue_payment"
                checked={!!company.ignore_overdue_payment}
              />
            </div>
          </Form.Group>
          <Form.Group widths="equal">
            <div className="field">
              <Button
                size="mini"
                onClick={simulateWaba}
                loading={loadingWaba}
                title="Simular consumo WABA"
                icon={<Icon name="whatsapp" />}
              />
            </div>
          </Form.Group>
          <Form.Group widths="equal">
            <Input
              label="MONITCALL"
              name="monitcall_base_url"
              value={company.parameters.monitcall_base_url}
              placeholder="http://app.monitcall"
              action={{
                loading: loadingConnection,
                color: monitcallConnected ? "green" : "red",
                icon: "check",
                title: "Testar Conexão",
                onClick: () => {
                  testServer(company.parameters.monitcall_base_url);
                },
              }}
            />
          </Form.Group>
          <Form.Group>
          <Form.Field>
            <Checkbox
              label="Plano Pós Pago"
              name="postpaid"
              onChange={onChecked}
              checked={company.postpaid}
            />
          </Form.Field>
          </Form.Group>
        </div>
      ) : (
          ""
        )}

      {part === 3 ? (
        <div className="holder_part">
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Form.Group widths="4">
                  <Form.Field
                    error={messageError ? !!messageError.due_day : ""}
                  >
                    <label htmlFor="due_day">Dia de Vencimento:</label>
                    <Dropdown
                      name="due_day"
                      size="mini"
                      selection
                      style={{ width: "100px" }}
                      search
                      value={company.due_day}
                      onChange={onChange}
                      options={[
                        { key: 5, value: 5, text: "05" },
                        { key: 10, value: 10, text: "10" },
                        { key: 15, value: 15, text: "15" },
                        { key: 20, value: 20, text: "20" },
                        { key: 25, value: 25, text: "25" },
                      ]}
                    />

                    {messageError ? (
                      <InlineError text={messageError.due_day} />
                    ) : (
                        ""
                      )}
                  </Form.Field>

                  <Form.Field
                    error={messageError ? !!messageError.due_day : ""}
                  >
                    <label htmlFor="due_day">Método de Pagamento</label>
                    <Dropdown
                      placeholder="Escolha uma opção"
                      search
                      selection
                      style={{ width: "100px" }}
                      name="payment_type"
                      value={company.payment_type ? company.payment_type : 0}
                      onChange={onChange}
                      options={[
                        {
                          key: 0,
                          value: 0,
                          text: "Boleto",
                          icon: { name: "barcode" },
                        },
                      ]}
                      closeOnChange
                    />

                    {messageError ? (
                      <InlineError text={messageError.due_day} />
                    ) : (
                        ""
                      )}
                  </Form.Field>
                </Form.Group>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <InvoiceTable company_id={company.id} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      ) : (
          ""
        )}

      {part === 4 ? <FranquiaMensagemSummay company={company} /> : ""}
      {part === 5 ? <CompanyWebhook company={company} /> : ""}
    </Form>
  );
};

export default FormCompany;
