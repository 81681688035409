import React from "react";
import { Grid } from "semantic-ui-react";

export default () => (
  <Grid>
    <Grid.Row>
      <h2>Status</h2>
      <p>
        Nesta coluna você verá o status da campanha, se está aguardando, está
        executando ou se foi finalizado
      </p>
    </Grid.Row>
  </Grid>
);
