import React, { Component } from "react";
import ReactExport from "react-data-export";
import { Button } from "semantic-ui-react";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class DownloadExcel extends Component {
  render() {
    return (
      <ExcelFile
        element={<Button color="blue" icon="file excel" title="Exportar para Excel" />}
      >
        <ExcelSheet data={this.props.dataColumn} name="tabela-monitchat">
          {this.props.columnsTitle
            ? this.props.columnsTitle.map((c) => (
              <ExcelColumn key={c} label={c} value={c} />
            ))
            : this.props.columNames.map((c) => (
              <ExcelColumn key={c} label={c} value={c} />
            ))}
        </ExcelSheet>
      </ExcelFile>
    );
  }
}

export default DownloadExcel;
