import api from "../../api/stats";

// Types declaration
export const Types = {
  IS_FETCHING_STATISTICS_COUNT: "stats/IS_FETCHING_STATISTICS_COUNT",
  STATISTICS_COUNT_FETCHED: "stats/STATISTICS_COUNT_FETCHED",
  IS_FETCHING_OPEN_TICKETS_COUNT: "stats/IS_FETCHING_OPEN_TICKETS_COUNT",
  IS_FETCHING_CLOSED_TICKETS_COUNT: "stats/IS_FETCHING_CLOSED_TICKETS_COUNT",
  IS_FETCHING_ASSIGNED_TICKETS_COUNT:
    "stats/IS_FETCHING_ASSIGNED_TICKETS_COUNT",
  IS_FETCHING_IGNORED_TICKETS_COUNT: "stats/IS_FETCHING_IGNORED_TICKETS_COUNT",
  IS_FETCHING_BOT_ASSIGNED_TICKETS_COUNT:
    "stats/IS_FETCHING_BOT_ASSIGNED_TICKETS_COUNT",
  IS_FETCHING_WAITING_TICKETS_COUNT: "stats/IS_FETCHING_WAITING_TICKETS_COUNT",
  IS_FETCHING_BOT_CLOSED_TICKETS_COUNT:
    "stats/IS_FETCHING_BOT_CLOSED_TICKETS_COUNT",
  OPEN_TICKETS_COUNT_FETCHED: "stats/OPEN_TICKETS_COUNT_FETCHED",
  CLOSED_TICKETS_COUNT_FETCHED: "stats/CLOSED_TICKETS_COUNT_FETCHED",
  WAITING_TICKETS_COUNT_FETCHED: "stats/WAITING_TICKETS_COUNT_FETCHED",
  ASSIGNED_TICKETS_COUNT_FETCHED: "stats/ASSIGNED_TICKETS_COUNT_FETCHED",
  IGNORED_TICKETS_COUNT_FETCHED: "stats/IGNORED_TICKETS_COUNT_FETCHED",
  ASSIGNED_BOT_TICKETS_COUNT_FETCHED:
    "stats/ASSIGNED_BOT_TICKETS_COUNT_FETCHED",
  CLOSED_BOT_TICKETS_COUNT_FETCHED: "stats/CLOSED_BOT_TICKETS_COUNT_FETCHED",
  SET_FILTER: "stats/SET_FILTER",
};

// Reducer
const INITIAL_STATE = {
  openTicketsCount: 0,
  ignoredTicketsCount: 0,
  assignedTicketsCount: 0,
  assignedTicketsByBotCount: 0,
  waitingTicketCount: 0,
  closedTicketsCount: 0,
  closedTicketsByBotCount: 0,
  loadingStatisticsCount: false,
  loadingOpenTicketsCount: true,
  loadingIgnoredTicketsCount: true,
  loadingClosedTicketsCount: false,
  loadingAssignedTicketsCount: false,
  loadingWaitingTicketsCount: false,
  loadingClosedTicketsByBotCount: false,
  loadingAssignedTicktsByBotCount: false,
  filter: {
    created: "today",
    by: "me",
    user_id: null,
    current_status: null,
    department_id: null,
    start: null,
    end: null,
    start_date: null,
    end_date: null,
  },
};

export default function conversation(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case Types.IS_FETCHING_STATISTICS_COUNT:
      return { ...state, loadingStatisticsCount: action.payload };
    case Types.IS_FETCHING_OPEN_TICKETS_COUNT:
      return { ...state, loadingOpenTicketsCount: action.payload };
    case Types.IS_FETCHING_CLOSED_TICKETS_COUNT:
      return { ...state, loadingClosedTicketsCount: action.payload };
    case Types.IS_FETCHING_ASSIGNED_TICKETS_COUNT:
      return { ...state, loadingAssignedTicketsCount: action.payload };
    case Types.IS_FETCHING_IGNORED_TICKETS_COUNT:
      return { ...state, loadingIgnoredTicketsCount: action.payload };
    case Types.IS_FETCHING_BOT_ASSIGNED_TICKETS_COUNT:
      return { ...state, loadingAssignedTicktsByBotCount: action.payload };
    case Types.IS_FETCHING_WAITING_TICKETS_COUNT:
      return { ...state, loadingWaitingTicketsCount: action.payload };
    case Types.IS_FETCHING_BOT_CLOSED_TICKETS_COUNT:
      return { ...state, loadingClosedTicketsByBotCount: action.payload };
    case Types.OPEN_TICKETS_COUNT_FETCHED:
      return { ...state, openTicketsCount: action.payload };
    case Types.CLOSED_TICKETS_COUNT_FETCHED:
      return { ...state, closedTicketsCount: action.payload };
    case Types.WAITING_TICKETS_COUNT_FETCHED:
      return { ...state, waitingTicketCount: action.payload };
    case Types.ASSIGNED_TICKETS_COUNT_FETCHED:
      return { ...state, assignedTicketsCount: action.payload };
    case Types.STATISTICS_COUNT_FETCHED: {
      return {
        ...state,
        assignedTicketsCount: action.payload.assignedTickets.count,
        ignoredTicketsCount: action.payload.ignoredTickets.count,
        openTicketsCount: action.payload.openTickets.count,
        waitingTicketCount: action.payload.waitingTickets.count,
      };
    }
    case Types.IGNORED_TICKETS_COUNT_FETCHED:
      return { ...state, ignoredTicketsCount: action.payload };
    case Types.ASSIGNED_BOT_TICKETS_COUNT_FETCHED:
      return { ...state, assignedTicketsByBotCount: action.payload };
    case Types.CLOSED_BOT_TICKETS_COUNT_FETCHED:
      return { ...state, closedTicketsByBotCount: action.payload };
    case Types.SET_FILTER:
      return { ...state, filter: action.payload };
    default:
      return state;
  }
}

// Actions
export const Creators = {
  getStatisticCount: (params) => (dispatch) => {
    dispatch({
      type: Types.IS_FETCHING_STATISTICS_COUNT,
      payload: true,
    });
    api.statistics
      .fetchAll(params)
      .then((data) =>
        dispatch({
          type: Types.STATISTICS_COUNT_FETCHED,
          payload: data,
        })
      )
      .then(() =>
        dispatch({
          type: Types.IS_FETCHING_STATISTICS_COUNT,
          payload: false,
        })
      );
  },
  getOpenTicketsCount: (params) => (dispatch) => {
    dispatch({
      type: Types.IS_FETCHING_OPEN_TICKETS_COUNT,
      payload: true,
    });
    api.openTickets
      .fetchAll(params)
      .then((count) =>
        dispatch({
          type: Types.OPEN_TICKETS_COUNT_FETCHED,
          payload: count,
        })
      )
      .then(() =>
        dispatch({
          type: Types.IS_FETCHING_OPEN_TICKETS_COUNT,
          payload: false,
        })
      );
  },
  getClosedTicketsCount: (params) => (dispatch) => {
    dispatch({
      type: Types.IS_FETCHING_CLOSED_TICKETS_COUNT,
      payload: true,
    });
    api.closedTickets
      .fetchAll(params)
      .then((count) =>
        dispatch({
          type: Types.CLOSED_TICKETS_COUNT_FETCHED,
          payload: count,
        })
      )
      .then(() =>
        dispatch({
          type: Types.IS_FETCHING_CLOSED_TICKETS_COUNT,
          payload: false,
        })
      );
  },
  getWaitingTicketsCount: (params) => (dispatch) => {
    dispatch({
      type: Types.IS_FETCHING_WAITING_TICKETS_COUNT,
      payload: true,
    });
    api.waitingTicket
      .fetchAll(params)
      .then((count) =>
        dispatch({
          type: Types.WAITING_TICKETS_COUNT_FETCHED,
          payload: count,
        })
      )
      .then(() =>
        dispatch({
          type: Types.IS_FETCHING_WAITING_TICKETS_COUNT,
          payload: false,
        })
      );
  },
  getAssignedTicketsCount: (params) => (dispatch) => {
    dispatch({
      type: Types.IS_FETCHING_ASSIGNED_TICKETS_COUNT,
      payload: true,
    });
    api.assignedTickets
      .fetchAll(params)
      .then((count) =>
        dispatch({
          type: Types.ASSIGNED_TICKETS_COUNT_FETCHED,
          payload: count,
        })
      )
      .then(() =>
        dispatch({
          type: Types.IS_FETCHING_ASSIGNED_TICKETS_COUNT,
          payload: false,
        })
      );
  },
  setFilter: (filter) => (dispatch) => {
    dispatch({
      type: Types.SET_FILTER,
      payload: filter,
    });
  },
  getIgnoredTicketsCount: (params) => (dispatch) => {
    dispatch({
      type: Types.IS_FETCHING_IGNORED_TICKETS_COUNT,
      payload: true,
    });
    api.ignoredTickets
      .fetchAll(params)
      .then((count) =>
        dispatch({
          type: Types.IGNORED_TICKETS_COUNT_FETCHED,
          payload: count,
        })
      )
      .then(() =>
        dispatch({
          type: Types.IS_FETCHING_IGNORED_TICKETS_COUNT,
          payload: false,
        })
      )
      .catch((err) => {
        dispatch({
          type: Types.IS_FETCHING_IGNORED_TICKETS_COUNT,
          payload: false,
        });
      });
  },
  getBotClosedTicketsCount: (params) => (dispatch) => {
    dispatch({
      type: Types.IS_FETCHING_BOT_CLOSED_TICKETS_COUNT,
      payload: true,
    });
    api.closedTicketsByBot
      .fetchAll(params)
      .then((count) =>
        dispatch({
          type: Types.CLOSED_BOT_TICKETS_COUNT_FETCHED,
          payload: count,
        })
      )
      .then(() =>
        dispatch({
          type: Types.IS_FETCHING_BOT_CLOSED_TICKETS_COUNT,
          payload: false,
        })
      );
  },
  getBotAssignedTicketsCount: (params) => (dispatch) => {
    dispatch({
      type: Types.IS_FETCHING_BOT_ASSIGNED_TICKETS_COUNT,
      payload: true,
    });
    api.closedTicketsByBot
      .fetchAll(params)
      .then((count) =>
        dispatch({
          type: Types.ASSIGNED_BOT_TICKETS_COUNT_FETCHED,
          payload: count,
        })
      )
      .then(() =>
        dispatch({
          type: Types.IS_FETCHING_BOT_ASSIGNED_TICKETS_COUNT,
          payload: false,
        })
      );
  },
};
