/* eslint-disable react/forbid-prop-types */
import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import jwtDecode from "jwt-decode";
import { bindActionCreators } from "redux";
import { Creators as AuthActions } from "../../store/ducks/auth";
import SplashScreen from "../pages/SplashScreen";

const AuthorizedRoute = ({
  isAuthenticated,
  component: Component,
  logout,
  permissions = [],
  location,
  requiredPermission,
  ...rest
}) => {
  const token = localStorage.token;

  let tokenExpired = true;

  if (token) {
    try {
      const tokenDecoded = jwtDecode(localStorage.token);
      tokenExpired = Date.now() >= tokenDecoded.exp * 1000;

      if (tokenExpired) {
        localStorage.removeItem("token");
        window.location.reload();
      }
    } catch (err) {
      localStorage.removeItem("token");
      window.location.reload();
    }
  } else {
    if (location.pathname) {
      if (location.pathname != "/login") {
        localStorage.setItem("redirect", location.pathname);
      }
    }
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated && !tokenExpired ? (
          requiredPermission ? (
            permissions.length > 0 ? (
              !permissions.find((c) => c === requiredPermission) ? (
                <Redirect to="/" />
              ) : (
                <Component {...props} />
              )
            ) : (
              <SplashScreen />
            )
          ) : (
            <Component {...props} />
          )
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

AuthorizedRoute.propTypes = {
  component: PropTypes.any.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(AuthActions, dispatch);

function mapStateToProps({ user }) {
  return {
    isAuthenticated: user.isAuthenticated,
    tokenExpiration: user.user.exp,
    permissions: user.user.permissions,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthorizedRoute);
