import React from "react";
import { Grid } from "semantic-ui-react";

export default () => (
  <Grid>
    <Grid.Row>
      <h2>Dados</h2>
      <p>
        Aqui onde será apresentado pra ti todas as campanhas que você criou!
      </p>
    </Grid.Row>
  </Grid>
);
