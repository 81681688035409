import React from "react";
import { Grid } from "semantic-ui-react";

export default () => (
  <Grid>
    <h2>Contato</h2>
    <p>
      Aqui exibe as informações do contato. na parte de cima o nome ou o número
      se o contato não estiver cadastrado em contatos.
    </p>
    <p>
      Na parte de abaixo aparecerá a Empresa do usuário se no cadastro dele
      tiver cadastrado a empresa se não o campo ficará vazio.
    </p>
  </Grid>
);
