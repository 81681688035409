import React, { useEffect, useState } from "react";
import { Grid, Button, Icon } from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { Creators as TourActions } from "../../../store/ducks/tour";

function DetailMainMenu({
  goTo,
  closeTour,
  openTour,
  openTourCompanies,
  openTourConversation,
  openTourRelationship,
  openTourCampaign,
  openTourTickets,
  openTourReport,
  openTourConfigurations,
  openTourCompany,
  openTourRelationshipAgente,
  openTourConfigurationsAgente,
  openTourConfigurationsSupervisor,
  user,
}) {
  const [role, setRole] = useState("");
  const fetchRoles = () => {
    const admin = user.roles.filter((admin) => admin === "admin");
    const agente = user.roles.filter((agente) => agente === "agente");
    const supervisor = user.roles.filter(
      (supervisor) => supervisor === "supervisor"
    );
    if (admin.length > 0) {
      setRole("admin");
    } else if (supervisor.length > 0 && !admin.length > 0) {
      setRole("supervisor");
    } else if (
      agente.length > 0 &&
      !admin.length > 0 &&
      !supervisor.length > 0
    ) {
      setRole("agente");
    }
  };

  useEffect(() => {
    fetchRoles();
  }, [user.roles]);

  DetailMainMenu.propTypes = {
    goTo: PropTypes.func.isRequired,
  };

  const openTourComponent = (goTo, openNewTour) => {
    goTo(0);
    closeTour();
    switch (openNewTour) {
      case "conversation":
        return openTourConversation();
      case "relationship":
        if (role === "agente") {
          return openTourRelationshipAgente();
        }
        return openTourRelationship();
      case "companies":
        return openTourCompanies();
      case "campaign":
        return openTourCampaign();
      case "tickets":
        return openTourTickets();
      case "report":
        return openTourReport();
      case "configurations":
        if (role === "agente") {
          return openTourConfigurationsAgente();
        } else if (role === "supervisor") {
          return openTourConfigurationsSupervisor();
        }
        return openTourConfigurations();
      case "company":
        return openTourCompany();
      case "inicio":
        return openTour();
      default:
        break;
    }
  };

  return (
    <Grid>
      <Grid.Row>
        <h2>Menu Principal</h2>
        <p>Aqui Você escolhe qual parte do Menu Principal quer conhecer:</p>
        <br />
      </Grid.Row>
      <Grid>
        <NavLink
          style={{ width: "100%", marginBottom: "10px" }}
          to="/dashboard"
          onClick={(e) => openTourComponent(goTo, "inicio", e)}
        >
          <Button color="blue" basic fluid>
            <Icon name="home" className="add-comment" />
            Inicio
          </Button>
        </NavLink>
        <NavLink
          style={{ width: "100%", marginBottom: "10px" }}
          to="conversation"
          onClick={(e) => openTourComponent(goTo, "conversation", e)}
        >
          <Button color="blue" basic fluid>
            <Icon name="conversation" className="add-comment" />
            Conversas
          </Button>
        </NavLink>
        <br />

        <NavLink
          style={{ width: "100%", marginBottom: "10px" }}
          to="/relationship"
          onClick={() => openTourComponent(goTo, "relationship")}
        >
          <Button color="blue" basic fluid>
            <Icon name="handshake" className="add-comment" />
            Relacionamento
          </Button>
        </NavLink>

        <NavLink
          style={{ width: "100%", marginBottom: "10px" }}
          to="/campaign"
          onClick={() => openTourComponent(goTo, "campaign")}
        >
          <Button color="blue" basic fluid>
            <Icon name="bullhorn" className="add-comment" />
            Campanhas
          </Button>
        </NavLink>

        <NavLink
          style={{ width: "100%", marginBottom: "10px" }}
          to="/tickets"
          onClick={() => openTourComponent(goTo, "tickets")}
        >
          <Button color="blue" basic fluid>
            <Icon name="ticket" className="add-comment" />
            Tickets
          </Button>
        </NavLink>
        {role !== "agente" && (
          <NavLink
            style={{ width: "100%", marginBottom: "10px" }}
            to="/report"
            onClick={() => openTourComponent(goTo, "report")}
          >
            <Button color="blue" basic fluid>
              <Icon name="newspaper" className="add-comment" />
              Relatórios
            </Button>
          </NavLink>
        )}
        <NavLink
          style={{ width: "100%", marginBottom: "10px" }}
          to="/configurations"
          onClick={() => openTourComponent(goTo, "configurations")}
        >
          <Button color="blue" basic fluid>
            <Icon name="settings" className="add-comment" />
            Configurações
          </Button>
        </NavLink>
        {role == "admin" && (
          <NavLink
            style={{ width: "100%", marginBottom: "10px" }}
            to="/company"
            onClick={() => openTourComponent(goTo, "company")}
          >
            <Button color="blue" basic fluid>
              <Icon name="configure" className="add-comment" />
              Administração
            </Button>
          </NavLink>
        )}
      </Grid>
    </Grid>
  );
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(TourActions, dispatch);

const mapStateToProps = (state) => ({
  tour: state.tour,
  user: state.user.user,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailMainMenu);
