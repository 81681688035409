import axios from "../services/api";

export default {
	task: {
		fetchAll: params =>
			axios.get(`/task`, { params }).then(res => res.data),
		delete: id => axios.delete(`/task/${id}`).then(res => res.data),
		fetchData: id => axios.get(`/task/${id}`).then(res => res.data),
		update: (id, task) =>
			axios.put(`/task/${id}`, task).then(res => res.data),
		submit: task => axios.post(`/task`, task).then(res => res.data)
	}
};
