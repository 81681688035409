import axios from "../services/api";

export default {
  company: {
    fetchAll: (params) =>
      axios.get(`/company`, { params }).then((res) => res.data),
    fetchCompany: (id) => axios.get(`/company/${id}`).then((res) => res.data),
    fetchCompanies: (params) =>
      axios.get(`/company/companies`, { params }).then((res) => res.data),
    delete: (id) => axios.delete(`/company/${id}`).then((res) => res.data),
    update: (id, company) =>
      axios.put(`/company/${id}`, company).then((res) => res.data),
    paymentInformation: (id, company) =>
      axios
        .put(`/company/${id}/paymentInformation`, company)
        .then((res) => res.data),
    enableEmail: (id, company) =>
      axios.put(`/company/${id}/enableEmail`, company).then((res) => res.data),
    changePlan: (id, company) =>
      axios.put(`/company/${id}/changePlan`, company).then((res) => res.data),
    changeExternalInfo: (id, company) =>
      axios.put(`/company/${id}/changeExternalInfo`, company).then((res) => res.data),
    submit: (company) =>
      axios.post(`/company`, company).then((res) => res.data),
    submitAuth: (company) =>
      axios.post(`/auth/company`, company).then((res) => res.data),
    submitRegister: (company) =>
      axios.post(`/auth/register`, company).then((res) => res.data),
    generateResetDataCode: () =>
      axios.post(`/company/generateResetDataCode`).then((res) => res),
    resetData: (params) =>
      axios.post(`/company/resetData`, params).then((res) => res),
  },
};
