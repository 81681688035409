import React from "react";
import { Grid } from "semantic-ui-react";

export default () => (
  <Grid>
    <h2>Carteira</h2>
    <p>
      Aqui aparecerá se o usuário pertence a sua carteira, bom somente se você
      tiver permissão de administrador que verá as conversas de outras
      carteiras.
    </p>{" "}
    <p>Você pode remover o contato da sua carteira clicando no seu nome!</p>
  </Grid>
);
