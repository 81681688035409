import React from "react";
import { Grid } from "semantic-ui-react";

export default () => (
  <Grid>
    <Grid.Row>
      <h2>Mídias Sociais</h2>
      <p>
        Aqui é onde se cadastra as contas de whatsapp, você poderá desconectar
        ou deletar também. lembrando que você poderá adicionar contas whatsapp
        na medida do seu plano!
      </p>
    </Grid.Row>
  </Grid>
);
