import axios from "../services/api";

export default {
  statistics: {
    fetchAll: (params) =>
      axios.get(`/statistic`, { params }).then((res) => res.data),
    getAverageWaitingTime: (params) =>
      axios
        .get(`/statistic/averageWaitingTime`, { params })
        .then((res) => res.data),
    getClientsPizza: (params) =>
      axios
        .get(`/statistic/clientAnsweredPizza`, { params })
        .then((res) => res.data),
    getNpsAcumulado: (params) =>
      axios.get(`/statistic/npsAcumulado`, { params }).then((res) => res.data),
    getUserOpenPizza: (params) =>
      axios.get(`/statistic/userOpenPizza`, { params }).then((res) => res.data),
    getUserClosedPizza: (params) =>
      axios
        .get(`/statistic/userClosedPizza`, { params })
        .then((res) => res.data),
    getAverageServiceTime: (params) =>
      axios
        .get(`/statistic/averageServiceTime`, { params })
        .then((res) => res.data),
    getAverageOpenTime: (params) =>
      axios
        .get(`/statistic/averageOpenTime`, { params })
        .then((res) => res.data),
    getAverageBotTime: (params) =>
      axios
        .get(`/statistic/averageBotTime`, { params })
        .then((res) => res.data),
    conversationsClosedByUser: (params) =>
      axios
        .get(`/statistic/conversationsClosedByUser`, { params })
        .then((res) => res.data),
    npsSituation: (params) =>
    axios
      .get(`/statistic/npsSituation`, { params })
      .then((res) => res.data),
    confirmationSituation: (params) =>
    axios
      .get(`/statistic/confirmationSituation`, { params })
      .then((res) => res.data),
    npsByUsers: (params) =>
      axios
        .get(`/statistic/npsByUsers`, { params })
        .then((res) => res.data),
    getConversationsClosed: (params) =>
      axios
        .get(`/statistic/conversationsClosed`, { params })
        .then((res) => res.data),
    getConversations: (params) =>
      axios.get(`/statistic/conversations`, { params }).then((res) => res.data),
    getNewContacts: (params) =>
      axios.get(`/statistic/newContacts`, { params }).then((res) => res.data),

    getNewConversations: (params) =>
      axios
        .get(`/statistic/newConversations`, { params })
        .then((res) => res.data),
  },
  openTickets: {
    fetchAll: (params) =>
      axios
        .get(`/statistic/openTickets`, { params })
        .then((res) => res.data.count),
  },
  ignoredTickets: {
    fetchAll: (params) =>
      axios
        .get(`/statistic/ignoredTickets`, { params })
        .then((res) => res.data.count),
  },
  assignedTickets: {
    fetchAll: (params) =>
      axios
        .get(`/statistic/assignedTickets`, { params })
        .then((res) => res.data.count),
  },
  assignedTicketsByBot: {
    fetchAll: (params) =>
      axios
        .get(`/statistic/assignedTicketsByBot`, { params })
        .then((res) => res.data.count),
  },
  waitingTicket: {
    fetchAll: (params) =>
      axios
        .get(`/statistic/waitingTicket`, { params })
        .then((res) => res.data.count),
  },
  closedTickets: {
    fetchAll: (params) =>
      axios
        .get(`/statistic/closedTickets`, { params })
        .then((res) => res.data.count),
  },
  closedTicketsByBot: {
    fetchAll: (params) =>
      axios
        .get(`/statistic/closedTicketsByBot`, { params })
        .then((res) => res.data.count),
  },
  statistic: {
    fetchAll: (params) =>
      axios.get(`/statistic/activities`, { params }).then((res) => res),
  },
  total: {
    fetchAll: (params) =>
      axios.get(`/ticket-report`, { params }).then((res) => res),
  },
};
