import React from "react";
import { Grid } from "semantic-ui-react";

export default () => (
  <Grid>
    <Grid.Row>
      <h2>Status do atendimento</h2>
      <p>
        Aqui é onde se cadastra os status do atendimento, é onde vai aparecer se
        o progresso, se vai aparecer no menu rápido, se vai está ativo e onde
        cadastra o ícone que irá aparecer.
      </p>
    </Grid.Row>
  </Grid>
);
