import React from "react";
import { Grid } from "semantic-ui-react";

export default () => (
  <Grid>
    <Grid.Row>
      <h2>Menu Principal</h2>
    </Grid.Row>
    <Grid.Row>
      <p>
        Este é o menu principal onde você irá nevegar nas principais funções do
        Monitchat, que são:{" "}
        <strong>
          Inicio, Conversas, Relacionamento, Campanhas, Tickets, Relatórios,
          Configurações, Administração.
        </strong>
        <br />
        <br />
        <strong>
          É importante salientar que só irá aparecer as funções que você tem
          acesso!
        </strong>
      </p>
    </Grid.Row>
  </Grid>
);
