import React from "react";
import { Grid } from "semantic-ui-react";

export default () => (
  <Grid>
    <Grid.Row>
      <h2>Categorias de atendimento</h2>
      <p>
        Aqui é onde se cadastra as categorias de atendimento, que é usado para
        distinguir os tickets por categorias, e também o robô poderá ser usado
        para distribuir por categoria de atendimento tendo em vista que você
        poderá cadastrar os agentes em categorias.
      </p>
    </Grid.Row>
  </Grid>
);
