import React from "react";
import { Grid } from "semantic-ui-react";

export default () => (
  <Grid>
    <Grid.Row>
      <h2>Produtividade</h2>
      <p>
        <strong>
          Neste Menu ficará todos relatório de produtividade, então vai aparecer
          a produtividade dos seus agentes, quem está com tickets abertos, como
          anda a produção da plataforma.
        </strong>
      </p>
    </Grid.Row>
  </Grid>
);
