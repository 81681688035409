import React from "react";
import { Grid, Icon } from "semantic-ui-react";

export default () => (
  <Grid>
    <h2>Informações do usuário</h2>
    <p>Aparece as informações do usuário</p>
    <p>Podemos verificar aqui é:</p>
    <p>
      <Icon size="large" name="phone" rotated="clockwise" /> Ligar para usuário.
      (Só irá funcionar se seu Monitchat estiver integrado com o MonitCall)
    </p>
    <p>
      <Icon size="large" name="mail outline" />
      Abre o seu programa padrão de e-mail para enviar um e-mail
    </p>
    <p>
      <Icon size="large" name="building outline" /> Adicionar nova companhia
    </p>
    <h3>Informações da Empresa</h3>
    <p>
      Aqui irá mostrar informações da empresa se o usuário estiver cadastrado a
      alguma empresa.
    </p>
    <p>
      Você poderá editar as informações clicando no campo do texto ou no botão{" "}
      <Icon size="large" color="grey" name="plus square" inverted />
    </p>
    <p>No fim pararece os seus tickets já finalizados e o ativo!</p>
  </Grid>
);
