import React from "react";
import { Grid, Icon } from "semantic-ui-react";

export default () => (
  <>
    <Grid>
      <h2>Status do Ticket</h2>
      <br />
      <Grid.Row>
        <div className="icones_status ">
          <div className="icone tempo-expirado" style={{ marginRight: 5 }}>
            {" "}
            <Icon.Group>
              <Icon name="comment outline" />
              <Icon corner name="clock" />
            </Icon.Group>
          </div>
          Quando você não fechou o atendimento do cliente no tempo que você
          configurou lá nos parâmetros, aparecerá este ícone.
        </div>
        <br />
        <p>
          {" "}
          <Icon name="android" size="large" color="grey" /> Quando essa conversa
          foi gerada pela plataforma Monitchat aparecerá o simbolo do Android
        </p>
      </Grid.Row>
    </Grid>
  </>
);
