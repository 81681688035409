import React from "react";
import { Grid } from "semantic-ui-react";

export default () => (
  <Grid>
    <Grid.Row>
      <h2>Contato</h2>
      <p>
        Nesta coluna você verá contato ao qual o ticket foi atribuido, se o
        contato estiver salvo em sua lista de contatos aparecerá o nome se não
        aparecerá somente o número
      </p>
    </Grid.Row>
  </Grid>
);
