import React, { useState } from "react";
import { Form, Input, Dropdown, Checkbox } from "semantic-ui-react";
import DropdownMedia from "../../social-media/DropdownMedia";

const eventsOptions = [
  { key: 1, text: "Mensagem recebida", value: 1 },
  { key: 2, text: "Mensagem Enviada", value: 2 },
  { key: 11, text: "Mensagem Respondida", value: 11 },
  { key: 10, text: "Mensagem Atualizada", value: 10 },
  { key: 3, text: "QR Code Criado", value: 3 },
  { key: 5, text: "Novo Ticket Criado", value: 5 },
  { key: 6, text: "Mudança no Status do Ticket", value: 6 },
  { key: 9, text: "Mudança no avatar do contato", value: 9 },
];

const FormWebhook = ({ webhook, onChange }) => {
  return (
    <Form size="mini">
      <Form.Field
        autoComplete="off"
        control={Input}
        label="Url a ser requisitada"
        name="url"
        onChange={onChange}
        value={webhook.url}
      />
      <Form.Field>
        <label>Quando este evento ocorrer:</label>
        <Dropdown
          selection
          name="event_type"
          options={eventsOptions}
          value={webhook.event_type}
          placeholder="Selecione um evento"
          onChange={onChange}
          clearable
        />
      </Form.Field>

      <Form.Group widths="equal">
        <Form.Field
          autoComplete="off"
          control={Input}
          label="Cabeçalhos"
          title="Informe os cabeçalhos separados com ;"
          placeholder="Ex: Content-Type;Authorization"
          name="headers"
          onChange={onChange}
          value={webhook.headers}
        />
        <Form.Field
          autoComplete="off"
          control={Input}
          label="Valores"
          title="Informe os valores dos cabeçalhos separados com ; respectivamente"
          placeholder="application/json;Bearer y87asdf9678dfsa.asdfasd896fasdf.asdfasd-fasdf"
          name="headers_value"
          onChange={onChange}
          value={webhook.headers_value}
        />
      </Form.Group>
      <Form.Group widths="6">
        <Form.Field>
          <label>Entrando por esse número</label>
          <DropdownMedia
            multiple={false}
            name="account_id"
            onSelectMedia={(value, text) => onChange(null, { name: "account_id", value })}
            accounts={webhook.account_id}
            allowAdditions={false}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group widths="6">
        <Form.Field>
          <label>Ativado</label>
          <Checkbox
            name="active"
            onChange={(e, { checked, name }) => onChange(e, { value: checked, name })}
            checked={webhook.active}
          />
        </Form.Field>
      </Form.Group>
    </Form>
  );
};

export default FormWebhook;
