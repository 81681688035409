import React, { Component } from "react";
import _ from "lodash";
import { Table, Label, Icon } from "semantic-ui-react";
import LoaderComponent from "../semantic/Loading";
import "jspdf-autotable";
import api from "../../api/company";
import apiWebhook from "../../api/webhook";
import DataTable from "../table/DataTable";
import WebhookModal from "../webhook/modal/WebhookModal";

const columns = {
  id: "Id",
  event: "Evento",
  url: "Endpoint",
};

class CompanyWebhook extends Component {

  state = {
    records: [],
    columns: {},
    errors: "",
    total_records: 0,
    selectedDataIndex: -1,
    editModalOpen: false,
    save_alert: false,
    activePage: 1,
    loading: false,
    recordsPerPage: 10,
    selectedId: 0,
    orderDirection: "desc",
    order: "id",
  };

  fetchData = async () => {
    return await api.company
      .fetchCompany(this.props.company.id)
      .then((res) => {
        this.setState({
          loading: false,
          records: res.data.webhooks
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  }
  componentWillMount() {
    this.setState({ loading: true });
    this.fetchData()
  }

  select = (selectedDataId) => {
    const { records } = this.state;
    const dataIndex = records.findIndex((c) => c.id === selectedDataId);
    this.setState({ selectedDataIndex: dataIndex, editModalOpen: true });
  };

  newDataClick = () => {
    const { records } = this.state;

    const newData = records[0]
      ? Object.keys(records[0]).reduce(
        (o, key) => Object.assign(o, { [key]: "" }),
        {}
      )
      : {
        event_type: "",
        url: "",
        headers: "",
        headers_value: "",
        company_id: this.props.company.id
      };


    this.setState(
      {
        records: [...records].concat(newData),
        editModalOpen: true,
      },
      () => {
        this.setState({
          selectedDataIndex: this.state.records.length - 1,
        });
      }
    );
  };

  nextRecord = () => {
    const { records, selectedDataIndex: dataIndex } = this.state;
    const selectedDataIndex =
      dataIndex < records.length - 1 ? dataIndex + 1 : records.length - 1;
    const selectedDataId = records[selectedDataIndex].id;

    this.select(selectedDataId);
  };

  previousRecord = () => {
    const { records, selectedDataIndex: dataIndex } = this.state;
    const selectedDataIndex = dataIndex - 1 > 0 ? dataIndex - 1 : 0;
    const selectedDataId = records[selectedDataIndex].id;

    this.select(selectedDataId);
  };

  fetchRecords = async (params) => {
    this.setState({ loading: true });

    return await apiWebhook.webhook.fetchAll(params).then((res) => {
      this.setState({
        records: res.data,
        order: res.order,
        columns: res.columns,
        total_records: res.total_records,
        loading: false,
      });
    });
  };

  handleChange = (e, { name, value }) => {

    const { selectedDataIndex: dataIndex, records } = this.state;

    this.setState({
      records: [
        ...records.slice(0, dataIndex),
        {
          ...records[dataIndex],
          [name]: value,
        },
        ...records.slice(dataIndex + 1),
      ],
    });
  };

  cleanErrors = () => {
    this.setState({ errors: "" });
  };

  submit = () => {
    const { selectedDataIndex: dataIndex, records } = this.state;
    this.setState({
      loading: true,
    });

    return apiWebhook.webhook
      .submit(records[dataIndex])
      .then((data) => {
        this.setState({
          save_alert: true,
          loading: false,
          selectedDataIndex: -1,
          editModalOpen: false,
          records: [
            ...records.slice(0, dataIndex),
            data.webhook,
            ...records.slice(dataIndex + 1),
          ],
        });
        setTimeout(
          function () {
            this.setState({ save_alert: false });
          }.bind(this),
          5000
        );
      })
      .catch((err) => {
        this.setState({
          loading: false,
          errors: err.response,
          editModalOpen: false,
          selectedId: -1,
          selectedDataIndex: -1,
          records: [...records].filter((c) => c.id > 0),
        });
      });
  };

  update = () => {
    const { selectedDataIndex, records } = this.state;
    const data = records[selectedDataIndex];
    this.setState({ loading: true });

    apiWebhook.webhook
      .update(data.id, data)
      .then((data) => {
        this.setState({
          records: [
            ...records.slice(0, selectedDataIndex),
            { ...data.data },
            ...records.slice(selectedDataIndex + 1),
          ],
          save_alert: true,
          editModalOpen: false,
          selectedDataIndex: -1,
          loading: false,
        });
        setTimeout(
          function () {
            this.setState({ save_alert: false });
          }.bind(this),
          5000
        );
      })
      .catch((err) => {
        this.setState({
          loading: false,
          errors: err.response,
          editModalOpen: false,
          selectedId: -1,
          selectedDataIndex: -1,
          records: [...records].filter((c) => c.id > 0),
        });
      });
  };

  delete = (id) => apiWebhook.webhook.delete(id);

  handleCloseEditModal = () => {
    const { records } = this.state;
    this.setState({
      editModalOpen: false,
      selectedId: -1,
      selectedDataIndex: -1,
      records: [...records].filter((c) => c.id > 0),
    });
  };

  render() {

    const {
      records,
      loading,
      total_records,
      selectedDataIndex,
      editModalOpen,
    } = this.state;

    const webhook = records[selectedDataIndex];

    return (
      <div className="DataTableActions">
        {loading && (
          <div className="loading-datatable">
            <LoaderComponent />
          </div>
        )}

        <DataTable
          loading={loading}
          csv={records}
          onAddClick={this.newDataClick}
          columns={columns}
          show
          showBackButton={false}
          showRefreshButton={false}
          showExportOptions={false}
          showSelectAll={false}
          showAvailableLengths={false}
          showSearchInput={false}
          data={records}
          totalRecords={records.length}
          messageError={
            this.state.errors.status > 0
              ? this.state.errors.data.message
              : ""
          }
          cleanErrors={this.cleanErrors}
          onDelete={(id) => this.delete(id)}
          onEditClick={(d) => this.select(d.id)}
          fetchData={this.fetchData}
        />
        {selectedDataIndex !== -1 ? (
          <WebhookModal
            handleClose={this.handleCloseEditModal}
            onChange={this.handleChange}
            handleNext={this.nextRecord}
            handlePrevious={this.previousRecord}
            onClickSave={this.update}
            onClickAdd={this.submit}
            webhook={webhook}
            modalHeader={
              webhook.id
                ? `Edição do ${webhook.cpmpany_id}`
                : "Novo Webhook"
            }
            open={editModalOpen}
            previousButtonEnabled={selectedDataIndex === 0}
            nextButtonEnabled={selectedDataIndex === records.length - 1}
            loading={loading}
          />
        ) : null}
      </div>
    );
  }
}

export default CompanyWebhook;
