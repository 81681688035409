import React from "react";
import { Grid } from "semantic-ui-react";

export default () => (
  <Grid>
    <Grid.Row>
      <h2>Boas Vindas!!!</h2>
    </Grid.Row>
    <Grid.Row>
      <p>
        Seja Bem Vindo Ao Monitchat, Eu vou guiá-lo as principais funções do
        portal!!
      </p>
      <p>
        Nós da MonitChat somos especialistas em atendimento, então se depois de
        passar por este tutorial ainda tiver alguma dúvida fique a vontade em
        entrar em contato conosco, temos um time a sua disposição!! nos contatos
        abaixo:
      </p>
      <p>
        <strong>E-mail:</strong> contato@vipphone.com.br
      </p>
      <p>
        <strong>Telefone:</strong> +55 (27) 3010 - 9500{" "}
      </p>
      <p>
        <strong> Então vamos lá... </strong>
      </p>
    </Grid.Row>
  </Grid>
);
