import api from "../../api/contact";
import apigroups from "../../api/group"
import apiclients from "../../api/client"

// Types declaration
export const Types = {
    ON_ERROR: "contact/ON_ERROR",
    IS_FETCHING: "contact/IS_FETCHING",
    IS_UPDATING: "contact/IS_UPDATING",
    UPDATED: "contact/UPDATED",
    IS_FETCHED: "contact/IS_FETCHED",
    SELECTED: "contact/SELECTED",
    NEXT_RECORD: "contact/NEXT_RECORD",
    MODAL_CLOSED: "contact/MODAL_CLOSED",
    PREVIOUS_RECORD: "contact/PREVIOUS_RECORD",
    SET_FILTER: "contact/SET_FILTER",
    NEW_CLICK: "contact/NEW_CLICK",
    FETCH_GROUPS: "contact/FETCH_GROUPS",
    GROUPS_FETCHED: "contact/GROUPS_FETCHED",
    CLIENT_FETCHED: "contact/CLIENT_FETCHED",
    FETCH_CLIENT: "contact/FETCH_CLIENT",
    HANDLE_CHANGE: "contact/HANDLE_CHANGE",
    HANDLE_CHECK: "contact/HANDLE_CHECK",
    ADD_CONTACT: "contact/ADD_CONTACT",
    GROUP_SELECTED: "contact/GROUP_SELECTED",
    CLIENT_SELECTED: "contact/CLIENT_SELECTED",
    SEARCH_CLIENT_CHANGED: "contact/SEARCH_CLIENT_CHANGED",
};

// Reducer
const INITIAL_STATE = {
    records: [],
    columns: {},
    clients: [],
    groups: [],
    order: {},
    loading: false,
    total_records: 0,
    selectedDataIndex: -1,
    editModalOpen: false,
    error: {},
    selectedDataId: -1,
    recordsPerPage: 10,
    checkedItems: [],
    loadingGroups: false,
    loadingClients: false,
    searchClient: ''
};

export default function contact(state = INITIAL_STATE, action = {}) {
    switch (action.type) {
        case Types.IS_FETCHING:
            return { ...state, loading: action.loading };
        case Types.FETCH_GROUPS:
            return { ...state, loadingGroups: action.payload };
        case Types.IS_UPDATING:
            return { ...state, loading: action.loading };
        case Types.ON_ERROR:
            return { ...state, loading: false, error: action.payload };
        case Types.UPDATED: {
            const { selectedDataIndex, records } = state
            return {
                ...state,
                records: [
                    ...records.slice(0, selectedDataIndex),
                    { ...action.payload.data },
                    ...records.slice(selectedDataIndex + 1)
                ],
                loading: false
            }
        }
        case Types.ADD_CONTACT: {
            const { selectedDataIndex: dataIndex, records } = state
            return {
                ...state,
                loading: false,
                records: [
                    ...records.slice(0, dataIndex),
                    action.payload,
                    ...records.slice(dataIndex + 1)
                ]
            }
        }
        case Types.IS_FETCHED:
            return {
                ...state,
                records: action.payload.data,
                order: action.payload.order,
                columns: action.payload.columns,
                total_records: action.payload.total_records,
                loading: false
            };
        case Types.SELECTED: {
            const { records } = state;
            const dataIndex = records.findIndex(c => c.id === action.payload);

            return {
                ...state,
                selectedDataIndex: dataIndex,
                selectedDataId: action.payload,
                editModalOpen: true
            }
        }
        case Types.MODAL_CLOSED: {
            const { records } = state

            return {
                ...state,
                editModalOpen: false,
                selectedDataId: -1,
                selectedDataIndex: -1,
                records: [...records].filter(c => c.id > 0)
            }
        }
        case Types.PREVIOUS_RECORD: {
            const selectedDataIndex = state.selectedDataIndex - 1 > 0 ? state.selectedDataIndex - 1 : 0;
            const selectedDataId = state.records[selectedDataIndex].id

            return {
                ...state,
                selectedDataId,
                selectedDataIndex
            }
        }
        case Types.NEXT_RECORD: {

            const selectedDataIndex = state.selectedDataIndex < state.records.length - 1 ? state.selectedDataIndex + 1 : state.records.length - 1;
            const selectedDataId = state.records[selectedDataIndex].id

            return {
                ...state,
                selectedDataId,
                selectedDataIndex
            }
        }
        case Types.NEW_CLICK: {
            const newData = Object.keys(state.records[0]).reduce((o, key) => Object.assign(o, { [key]: "" }), {});

            newData.groups = [];
            newData.webhook_active = true;
            newData.send_campaing = true;

            return {
                ...state,
                records: [...state.records].concat(newData),
                editModalOpen: true,
                selectedDataIndex: state.records.length
            }
        }
        case Types.GROUPS_FETCHED: {
            return {
                ...state,
                groups: action.payload.map(c => ({ key: c.id, value: c.id, text: c.name })),
                loadingGroups: false,
            }
        }
        case Types.CLIENT_FETCHED: {
            return {
                ...state,
                clients: [...state.clients].concat({
                    key: action.payload.id,
                    value: action.payload.id,
                    text: action.payload.name
                }),
                loadingClients: false,
            }
        }
        case Types.SEARCH_CLIENT_CHANGED: {
            return {
                ...state,
                searchClient: action.payload.value
            }
        }
        case Types.HANDLE_CHANGE: {

            const { selectedDataIndex: dataIndex, records } = state
            const { name, value } = action.payload

            return {
                ...state,
                records: [
                    ...records.slice(0, dataIndex),
                    {
                        ...records[dataIndex],
                        [name]: value
                    },
                    ...records.slice(dataIndex + 1)
                ]
            }
        }
        case Types.HANDLE_CHECK: {

            const { selectedDataIndex: dataIndex, records } = state
            const { name, checked } = action.payload

            return {
                ...state,
                records: [
                    ...records.slice(0, dataIndex),
                    {
                        ...records[dataIndex],
                        [name]: checked
                    },
                    ...records.slice(dataIndex + 1)
                ]
            }
        }
        case Types.GROUP_SELECTED: {
            const { selectedDataIndex: dataIndex, records } = state
            return {
                ...state,
                records: [
                    ...records.slice(0, dataIndex),
                    {
                        ...records[dataIndex],
                        groups: action.payload
                    },
                    ...records.slice(dataIndex + 1)
                ]
            }
        }
        case Types.CLIENT_SELECTED: {
            const { selectedDataIndex: dataIndex, records } = state
            const { name, value } = action.payload
            return {
                ...state,
                records: [
                    ...records.slice(0, dataIndex),
                    {
                        ...records[dataIndex],
                        [name]: value
                    },
                    ...records.slice(dataIndex + 1)
                ]
            }
        }

        default:
            return state;
    }
}

// Actions
export const Creators = {
    fetchRecords: params => async dispatch => {
        dispatch({ type: Types.IS_FETCHING, loading: true })
        api.contact
            .fetchAll(params)
            .then(res =>
                dispatch({ type: Types.IS_FETCHED, payload: res })
            )
            .catch(err => dispatch(Creators.error(err.response)))
    },
    select: data => dispatch => dispatch({ type: Types.SELECTED, payload: data.id }),
    update: data => dispatch => {
        dispatch({ type: Types.IS_UPDATING, loading: true })
        api.contact.update(data.id, data).then(data => {
            dispatch({ type: Types.UPDATED, payload: data })
        });
    },
    submit: data => dispatch => {
        api.contact
            .submit(data)
            .then(contact => {
                dispatch({ type: Types.ADD_CONTACT, payload: contact })
            })
            .catch(err => { });
    },
    delete: id => dispatch => {
        dispatch({ type: Types.IS_DELETING })

        api.contact.delete(id).then(res => {
            dispatch({ type: Types.DELETED, payload: id })
        })
    },
    onGroupSelected: (name, value) => dispatch => dispatch({ type: Types.GROUP_SELECTED, payload: value }),
    onClientSelected: (name, value) => dispatch => dispatch({ type: Types.CLIENT_SELECTED, payload: { name, value } }),
    onSearchClientChange: value => dispatch => {
        dispatch({ type: Types.SEARCH_CLIENT_CHANGED, payload: value })
    },
    onSearchClient: search => dispatch => {

        if (search.length > 2) {
            dispatch({ type: Types.FETCH_CLIENT, payload: true })
            apiclients.client.fetchAll({search}).then(clients => {
                dispatch({type: Types.SEARCH_CLIENT, payload: clients.records})
            });
        }

        dispatch({ type: Types.SEARCH_CLIENT })
    },
    nextRecord: () => dispatch => dispatch({ type: Types.NEXT_RECORD }),
    previousRecord: () => dispatch => dispatch({ type: Types.PREVIOUS_RECORD }),
    handleClose: () => dispatch => dispatch({ type: Types.MODAL_CLOSED }),
    handleChange: (name, value) => dispatch => dispatch({ type: Types.HANDLE_CHANGE, payload: { name, value } }),
    handleChecked: (name, checked) => dispatch => dispatch({ type: Types.HANDLE_CHECK, payload: { name, checked } }),
    error: data => dispatch => dispatch({ type: Types.ON_ERROR, payload: data }),
    handleNewClick: () => dispatch => dispatch({ type: Types.NEW_CLICK }),
    fetchGroups: () => dispatch => {
        dispatch({ type: Types.FETCH_GROUPS, payload: true })
        apigroups.group
            .fetchAll()
            .then(res =>
                dispatch({ type: Types.GROUPS_FETCHED, payload: res.data })
            )
            .catch(err => dispatch(Creators.error(err.response)))
    },
    getClient: id => dispatch => {
        dispatch({ type: Types.FETCH_CLIENT, payload: true })

        apiclients.client
            .get(id)
            .then(client =>
                dispatch({ type: Types.CLIENT_FETCHED, payload: client })
            )
            .catch(err => dispatch(Creators.error(err.response)))

    }
};
