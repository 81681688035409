import React from "react";
import { Grid, Icon } from "semantic-ui-react";

export default () => (
  <Grid>
    <Grid.Row>
      <h2>Busca Contatos</h2>
      <p>
        <Icon name="search" size="large" color="grey" />
        Aqui você irá buscar por contatos, você pode pesquisar por número ou
        pelo nome.
      </p>
    </Grid.Row>
  </Grid>
);
