import React from "react";
import { Modal, Button, Form, Input } from "semantic-ui-react";
import DropdownCategories from "../../ticket-category/DropdownCategories";

const AddCategoryModal = ({
  open,
  toggle,
  onChange,
  ticketcategory,
  loading,
  save,
  handleCategoryChange,
}) => (
  <Modal
    size="small"
    closeIcon
    open={open}
    onClose={toggle}
    dimmer="blurring"
    closeOnDimmerClick={false}
  >
    <Modal.Header>Cadastro de Categoria</Modal.Header>
    <Modal.Content>
      <Modal.Description>
        <Form size="mini">
          <Form.Group widths="equal">
            <Form.Field
              control={Input}
              label="Nome"
              name="description"
              onChange={onChange}
              value={ticketcategory.description}
            />
            <div className="field">
              <label>Categoria Pai</label>
              <DropdownCategories
                onSelectCategory={handleCategoryChange}
                ticket_category_id={ticketcategory.ticket_category_id}
                allowAdditions={false}
              />
            </div>
          </Form.Group>
        </Form>
      </Modal.Description>
    </Modal.Content>
    <Modal.Actions>
      <Button.Group>
        <Button onClick={toggle}>Cancelar</Button>
        <Button.Or />
        <Button positive onClick={save} loading={loading} disabled={loading}>
          Salvar
        </Button>
      </Button.Group>
    </Modal.Actions>
  </Modal>
);

export default AddCategoryModal;
