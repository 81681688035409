import React, { Component } from "react";
import { Icon, Popup } from "semantic-ui-react";

export class PauseInfo extends Component {
  state = {
    records: [],
  };
  render() {
    const { pause, interruptions } = this.props;

    const pauseSpc = interruptions.find(
      (c) => c.id === pause.interruption_type_id
    );

    return (
      <div className="holder">
        {pauseSpc ? (
          <span className="pausa">
            <Popup
              content={
                <div className="motivopausa">
                  <span className="db">
                    <b>{pauseSpc.name}</b>
                  </span>
                  {pause.duration ? pauseSpc.duration - pause.duration : "0"}{" "}
                  <span className=""> minutos</span>
                </div>
              }
              trigger={
                <Icon
                  name={
                    pause.interruption_type_id
                      ? pauseSpc.fast_menu_icon
                      : "lock"
                  }
                  className={
                    pauseSpc.duration - pause.duration > 0
                      ? "em_tempo"
                      : "atrasado"
                  }
                />
              }
              position="top center"
            />
          </span>
        ) : (
          <span className="pausa">
            <Popup
              content={
                <div className="motivopausa">
                  <span className="db">
                    <b>Efetuou Logout</b>
                  </span>
                </div>
              }
              trigger={<Icon name={"log out"} />}
              position="top center"
            />
          </span>
        )}
      </div>
    );
  }
}

export default PauseInfo;
