import React from "react";
import { Grid } from "semantic-ui-react";

export default () => (
  <Grid>
    <Grid.Row>
      <h2>Usuários</h2>
      <p>Aqui é onde se cadastra os agentes do sistema</p>
    </Grid.Row>
  </Grid>
);
