import React from "react";
import { Grid } from "semantic-ui-react";

export default () => (
  <Grid>
    <Grid.Row>
      <h2>Conversas</h2>
      <p>Bem Vindos em uma das principais áreas da plataforma!!</p>
      <p>
        <strong>
          Para Iniciar temos que conectar uma conta do Whatsapp se ainda não
          conectou esta é a sua oporunidade de se conectar!!!
        </strong>
      </p>
      <p>
        Está ansioso eu também então passe no próximo passo a gente inicia....{" "}
      </p>
    </Grid.Row>
  </Grid>
);
