import React, { Component } from "react";
import api from "../../api/conversation";
import queryString from "query-string";
import NewConversationModal from "./NewConversationModal";
import { Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

class NewConversation extends Component {
  state = {
    newConversation: [],
    errors: { message: "", phone_number: "" },
    loading: false,
    conversationModalOpen: false,
    save_alert: false,
    account_number: "",
  };

  componentDidMount() {
    const accounts = this.props.accounts.records.filter(
      (a) => a.connected != 2
    );
    this.setState({
      newConversation: {
        ...this.state.newConversation,
        account_number: accounts[0] ? accounts[0].phone_number : "",
      },
    });

    const { phone, message, template, template_name } = queryString.parse(
      window.location.search.replace("hashtag", "#")
    );

    if (phone) {

      this.openModal(`+${phone}`, message, { tpl: template, templateName: template_name });
    }


  }

  openModal = (phone_number = "", message = "", tpl = null) => {
    const accounts = this.props.accounts.records.filter(
      (a) => a.connected != 2
    );

    this.setState({
      conversationModalOpen: true,
      newConversation: {
        account_number: accounts[0] ? accounts[0].phone_number : "",
        phone_number,
        message,
        tpl
      },
    });
  };

  handleChange = (e) => {
    this.setState({
      newConversation: {
        ...this.state.newConversation,
        [e.target.name]: e.target.value,
      },
    });
  }

  handleChecked = (e, { name, checked }) => {
    this.setState({
      newConversation: { ...this.state.newConversation, [name]: checked },
    });
  };

  cleanErrors = () => {
    this.setState({ errors: "" });
  };

  handleMediaChange = (e, { name, value }) => {
    this.setState({
      newConversation: {
        ...this.state.newConversation,
        [name]: value,
      },
    });
  };

  handleContactChange = (value) => {
    this.setState({
      newConversation: { ...this.state.newConversation, contact: value },
    });
  };

  handleCloseEditModal = () => {
    this.setState({
      conversationModalOpen: false,
      newConversation: [],
    });
  };
  sendMessage = () => {
    const { errors, newConversation } = this.state;

    if (!newConversation.message) {
      errors.message = "Não pode enviar sem mensagem";
      return;
    }
    if (!newConversation.phone_number) {
      errors.phone_number = "Informe o número de destino";
      return;
    }

    if (!newConversation.account_number) {
      errors.account_number = "Informe a conta de saída";
      return;
    }

    this.setState({
      loading: true,
    });

    const data = {
      message_type: 0,
      sender: 1,
      phone_number: newConversation.phone_number.replace(/\D/g, ""),
      account_number: newConversation.account_number,
    };

    const dataJoin = { ...newConversation, ...data };
    
    return api.conversations
      .sendNewConversation(dataJoin)
      .then((data) => {
        this.setState({
          save_alert: true,
          loading: false,
          conversationModalOpen: false,
          newConversation: [],
        });
        setTimeout(
          function () {
            this.setState({ save_alert: false });
          }.bind(this),
          5000
        );
      })
      .catch((err) => {
        this.setState({
          loading: false,
          errors: err.response,
          contactModalOpen: false,
        });
      });
  };
  render() {
    const {
      loading,

      newConversation,
      conversationModalOpen,
    } = this.state;

    return (
      <div className="icone-add">
        <Icon.Group
          size="large"
          className="add-comment"
          title="Nova Conversa"
          onClick={this.openModal}
        >
          <Icon name="user" />
          <Icon corner name="add" />
        </Icon.Group>

        <NewConversationModal
          handleClose={this.handleCloseEditModal}
          onChange={this.handleChange}
          onChecked={this.handleChecked}
          handleMediaChange={this.handleMediaChange}
          handleContactChange={this.handleContactChange}
          modalHeader={`Nova Conversa`}
          messageError={
            this.state.errors.status > 0 ? this.state.errors.data.errors : ""
          }
          generalError={
            this.state.errors.status > 0 ? this.state.errors.data.message : ""
          }
          cleanErrors={this.cleanErrors}
          newConversation={newConversation}
          open={conversationModalOpen}
          onClickAdd={this.sendMessage}
          loading={loading}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ accounts }) => {
  return {
    accounts: accounts,
  };
};

export default connect(mapStateToProps)(withRouter(NewConversation));
