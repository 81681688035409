import React from "react";
import { Grid } from "semantic-ui-react";

export default () => (
  <Grid>
    <Grid.Row>
      <h2>Tarefas</h2>
    </Grid.Row>
    <Grid.Row>
      <p>
        Nessa parte aparecerá suas tarefas a ser realizadas, as que foi
        deixadas, ou que já estão concluidas ficaram no calendário, que pode ser
        acessado pelo menu superior ou em Relacionamento > Eventos e Tarefas.{" "}
        <br />
        Por aqui você terá o poder de deletar ou concluir suas tarefas!
        <h4>Novidade da plataforma:</h4>
        Se você tem uma tarefa agendada e não estiver logado, não se preocupe o
        Monitchat enviará um whatsapp para lembrar que você tem uma tarefa
        agendada naquele momento, nós não queremos que perca o compromisso não é
        mesmo!!!
        <br />
        Você tambem pode enviar comando do seu próprio whatsapp para a
        plataforma!!
        <br />
        Basta enviar para o número da empresa /task titulo da tarefa; descrição
        da tarefa; a data que você quer ser lembrado
        <br />
        Aqui vamos ter duas possibilidades, colocar data específica ou minutos,
        por exemplo: <br />
        <strong>
          /task Reunião Financeiro; Reunião com todo financeiro daqui a 15
          minutos;15
        </strong>{" "}
        <br />
        <strong>
          /task Reunião Financeiro; Reunião com todo financeiro dia a 15/03/2020
          às 13:00 minutos;15/03/2020;13:00
        </strong>{" "}
        <br />
        Muito simples não!!!{" "}
        <strong>
          Mas lembrem sempre colocar o ; e o titlulo, a descrição e o tempo!!!{" "}
        </strong>
      </p>
    </Grid.Row>
  </Grid>
);
