import axios from "../services/api";

export default {
  user: {
    fetchAll: (params) =>
      axios.get(`/user`, { params }).then((res) => res.data),
    chats: (params) =>
      axios.get(`/user/chat`, { params }).then((res) => res.data),
    monitor: (params) =>
      axios.get(`/user/monitor`, { params }).then((res) => res.data),
    fetchRole: (params) =>
      axios.get(`/role`, { params }).then((res) => res.data),
    fetchUsers: (params) =>
      axios.get(`/user?take=200`, { params }).then((res) => res.data),
    getContacts: (id, params) =>
      axios.get(`/user/${id}/contacts`, { params }).then((res) => res.data),
    fetchConection: (id) => axios.get(`/user/${id}`).then((res) => res),
    fetchChat: (id) =>
      axios.get(`/chat-message/getMessagesFor/${id}`).then((res) => res.data),
    sendMessage: (params) =>
      axios.post(`/chat-message/send`, params).then((res) => res),
    fetchUser: (id) => axios.get(`/user/${id}`).then((res) => res.data),
    fetchOnline: (params) =>
      axios.get(`/user/check`, { params }).then((res) => res.data),
    delete: (id, user) =>
      axios.post(`/user/${id}`, user).then((res) => res.data),
    update: (id, user) =>
      axios.put(`/user/${id}`, user).then((res) => res.data),
    submit: (user) => axios.post(`/user`, user).then((res) => res.data),
    changePassword: (id, user) =>
      axios.put(`/user/changePassword/${id}`, user).then((res) => res.data),
  },
};
