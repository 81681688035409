import React from "react";
import { Grid, Icon } from "semantic-ui-react";

export default () => (
  <Grid>
    <Grid.Row>
      <h2>Menu Conversas</h2>
      <p>Aqui aparecerão todas as conversas!!!</p>
      <p>
        {" "}
        <Icon name="warning sign" color="yellow" />
        <strong>
          {" "}
          Atenção temos duas formas de apresentar as conversas,
        </strong>{" "}
        é configurado nos parâmetros (Falaremos dele mais tarde...) enfim as
        duas formas são:
      </p>
      <h4 style={{ marginTop: 0, marginBottom: 0 }}>Por departamento </h4>
      <p>Todos os agentes conseguem ver os tickets, e as mensagens!</p>
      <h4 style={{ marginTop: 0, marginBottom: 0 }}>Por Carteira </h4>
      <p>
        Os agentes só conseguirão ver somente as conversas e tickets que estão
        em sua carteira e os novas conversas que ainda não está na carteira de
        ninguém.{" "}
      </p>
      <p>
        {" "}
        <strong>
          Para iniciarmos clique na primeira conversa para que possamos
          descobrir juntos todas as funcionalidades!
        </strong>
      </p>
    </Grid.Row>
  </Grid>
);
