import React from "react";
import { Grid, Icon, List } from "semantic-ui-react";

export default () => (
  <Grid>
    <h2 style={{ marginBottom: 5 }}>Conversa</h2>
    <p>
      <Icon name="warning sign" color="yellow" />
      <strong> não se esqueça de selecionar a conversa </strong>
      <Icon name="warning sign" color="yellow" />
    </p>
    <p>
      Aqui veremos as informações da conversa como:{" "}
      <p>
        <List as="ul" style={{ marginTop: 10 }}>
          <List.Item as="li">Nome do usuário</List.Item>
          <List.Item as="li">Empresa do usuário</List.Item>
          <List.Item as="li">Foto do perfil</List.Item>
          <List.Item as="li">Quem está atendendo</List.Item>
          <List.Item as="li">Por onde veio o chamado</List.Item>
          <List.Item as="li">Pertence a sua carteira</List.Item>
          <List.Item as="li">Adicionar e remover da carteira</List.Item>
          <List.Item as="li">Arquivar a conversa</List.Item>
          <List.Item as="li">
            Última mensagem enviado ou recebida pelo usuário
          </List.Item>
          <List.Item as="li">Horário que foi essa última interação</List.Item>
        </List>
      </p>{" "}
    </p>

    <p>
      {" "}
      <strong>
        Está ansioso eu também então passe no próximo passo a gente inicia....{" "}
      </strong>
    </p>
  </Grid>
);
