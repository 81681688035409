import axios from "../services/api";

export default {
  media: {
    fetchAll: (params) =>
      axios.get(`/social/whatsapp`, { params }).then((res) => res.data),
    monitor: (params) =>
      axios.get(`/social/whatsapp/monitor`, { params }).then((res) => res.data),
    templates: (id) =>
      axios.get(`/social/${id}/templates`).then((res) => res.data),
    balance: (id) =>
      axios.get(`/social/${id}/balance`).then((res) => res.data),
    quotas: (id) =>
      axios.get(`/social/${id}/quotas`).then((res) => res.data),
    fetchData: (params) =>
      axios.get(`/social/whatsapp`, { params }).then((res) => res.data.data),
    delete: (id) =>
      axios.delete(`/social/whatsapp/${id}`).then((res) => res.data),
    update: (id, media) =>
      axios.put(`/social/whatsapp/${id}`, media).then((res) => res.data),
    submit: (media) =>
      axios.post(`/social/whatsapp`, media).then((res) => res.data),
    createQrCode: (params) =>
      axios
        .post(`/social/whatsapp/createQrCode`, params)
        .then((res) => res.data),
    disconnect: (id) =>
      axios.post(`/social/disconnect/${id}`).then((res) => res.data),
    syncMessages: (params) =>
      axios
        .post(`/social/sync/${params.account_id}`, params)
        .then((res) => res.data),
    reconnect: (id) =>
      axios.post(`/social/reconnect/${id}`).then((res) => res.data),
  },
};
