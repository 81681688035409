import React from "react";
import { Grid } from "semantic-ui-react";

export default () => (
  <Grid>
    <Grid.Row>
      <h2>Parâmetros</h2>
      <p>
        Aqui é onde se cadastra todos os parâmetros pré estabelecidos do sistema
        são eles:
      </p>
      <p>
        <strong>Confirmação de Leitura</strong>
      </p>
      <p>
        <strong>Atribuição do Ticket</strong>
      </p>
      <p>
        <strong>Ordernar Por Mensagens Não Lidas</strong>
      </p>
      <p>
        <strong>Criar Ticket Pela Campanha</strong>
      </p>
      <p>
        <strong>Criar Ticket Ao Enviar uma Mensagem</strong>
      </p>
      <p>
        <strong>Solicitar Preenchimento do Ticket</strong>
      </p>
      <p>
        <strong>Restrição de Contatos á Usuários</strong>
      </p>
      <p>
        <strong>Tempo limite de atendimento</strong>
      </p>
      <p>
        <strong>Tempo entre a criação automatica de Tickets</strong>
      </p>
      <p>
        <strong>Mensagem na Criação do Ticket</strong>
      </p>
      <p>
        <strong>Mensagem de Agradecimento</strong>
      </p>
      <p>
        <strong>Integração com Monitcall</strong>
      </p>
      <p>
        <strong>Integração Intelliway</strong>
      </p>
      <p>
        <strong>Horario de Atendimento</strong>
      </p>
      <h3>
        Como a maioria é auto explicado vou explicar alguns os mais
        complicadinhos...
      </h3>
    </Grid.Row>
  </Grid>
);
