import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal, Loader, Button, Icon } from "semantic-ui-react";
import { Creators as AuthActions } from "../../store/ducks/auth";
import API from "../../services/api";
import Socket from "../../services/socket";
import DataTable from "../table/DataTable";

class AccessRequest extends Component {
  state = {
    requests: [],
    loading: true,
    loadingAuthorization: false,
    unlock: false,
    current_token: ""
  };

  componentDidMount() {
    Socket.private(`channel-${this.props.user.company_id}`).listen(
      "AccessRequestCreated",
      this.requestReceived
    );
    this.fetchRecords()
  }

  requestReceived = (e) => {

    this.setState({
      loading: false,
      unlock: false,
      loadingAuthorization: false,
    }, this.fetchRecords);
  };

  fetchRecords = () => {
    API.get(`/access-request`, {}).then((res) => {
      this.setState({
        loadingAuthorization: false,
        unlock: !this.state.unlock,
        requests: res.data.data.slice(0, 10),
      });
    });
  }

  authorizeRequest = (id) => {
    const request = this.state.requests.find(r => r.id == id);
    
    this.setState({ loadingAuthorization: true });
    API.put(`/access-request/${request.id}`, {}).then((res) => {
      this.fetchRecords()
      this.setState({
        loadingAuthorization: false,
        unlock: !this.state.unlock
      });
    });
  };

  render() {
    return (
      <Modal trigger={<a href="/#">Autorização de Acesso</a>}>
        <Modal.Header>Autorizar Acesso ao Suporte Monitchat</Modal.Header>
        <Modal.Content>
          <div style={{ minHeight: "50px" }}>
            {this.state.loading && this.state.requests.length < 1 ? (
              <Loader />
            ) : (
              <DataTable
                loading={this.state.loading && this.state.requests.length < 1}
                hide_actions={true}
                columns={{
                  id: "ID",
                  requested_by: "Solicitado Por",
                  authorized_by: "Autorizado por",
                  authorization_token: "Token de Acesso",
                  created_at: "Solicitado Em",
                  authorized_on: "Autorizado Em",
                  revoked_on: "Acesso Removido Em",
                  action: "#",
                }}
                data={this.state.requests.map((r) => ({
                  ...r,
                  action: (
                    <Button
                      color={r.authorized_on ? "red" : "green"}
                      icon
                      disabled={r.revoked_on}
                      title={
                        r.authorized_on
                          ? "Revogar Acesso"
                          : "Autorizar Acesso"
                      }
                      loading={this.state.loadingAuthorization}
                      onClick={r.revoked_on ? () => {} : () => this.authorizeRequest(r.id)}
                    >
                      <Icon name={r.authorized_on ? "lock" : "unlock"} />
                    </Button>
                  ),
                }))}
                totalRecords={this.state.requests.length}
                showActions={false}
                showPagination={false}
              />
            )}
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(AuthActions, dispatch);

const mapStateToProps = ({ user }) => {
  return {
    user: user.user,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccessRequest);
