import React from "react";
import { Grid, Dropdown, Input, Button, Checkbox } from "semantic-ui-react";

export default () => (
  <Grid>
    <Grid.Row>
      <h2>Ações</h2>
      <p>Aqui é onde fica as ações que podemos realizar</p>
      <p>
        <Button icon="backward" color="blue" /> Voltar a página anterior, por
        exemplo você estava em conversas e veio pra campanha, este botão voltará
        pra conversas
      </p>
      <p>
        <Button icon="refresh" color="blue" /> Atualiza os dados da tabela
      </p>
      <p style={{ marginBottom: 2 }}>
        <Dropdown text="10" selection />
      </p>
      <p>seleciona a quantidade de dados que vai ser exibido na tabela</p>
      <p style={{ marginBottom: 2 }}>
        <Input icon="search" placeholder="Busca" />
      </p>
      <p>Buscar por uma campanha</p>
      <p>
        <Button color="blue">CSV</Button> Exporta os dados para arquivo CSV
      </p>
      <p>
        <Button color="blue">XLS</Button> Exporta os dados para arquivo XLS
      </p>
      <p>
        <Button icon="file pdf outline" color="blue" /> Exporta os dados para
        arquivo PDF
      </p>
      <p>
        <Button icon="plus" color="blue" /> cria uma nova campanha
      </p>
      <br />
      <div style={{ marginTop: 10 }}>
        <div>
          <Checkbox checked={false} />
          <Dropdown>
            <Dropdown.Menu>
              <Dropdown.Item icon="trash" text="Deletar" />
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <p>Seleciona todas as campanhas para deletar</p>
      </div>
    </Grid.Row>
  </Grid>
);
