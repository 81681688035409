import React from "react";
import { Grid } from "semantic-ui-react";

export default () => (
  <Grid>
    <Grid.Row>
      <h2>Campanha</h2>
      <p>
        <strong>
          Neste Menu ficará todos relatório de campanhas, se todas as mensagens
          foram enviadas, a performace da campanha.
        </strong>
      </p>
    </Grid.Row>
  </Grid>
);
