import React, { Component } from "react";
import { connect } from "react-redux";

import api from "../../api/users";
import { Icon } from "semantic-ui-react";

import PerfectScrollbar from "react-perfect-scrollbar";
import Socket from "../../services/socket";
import PauseInfo from "./PauseInfo";
import WindowChat from "./WindowChat";
import audio from "../../assets/sound/knock.mp3";

const audioSource = new Audio(audio);

class InternalChat extends Component {
  state = {
    records: [],
    interruptions: [],
    loading: false,
    activeChat: false,
    showHeader: false,
    userChats: [],
    query: "",
    search: "",
    chats: [],
    message: "",
    countChatWithUnreadMessages: [],
    activeWindow: "",
  };

  handleInputChange = () => {
    clearTimeout(this.timer);
    this.setState({
      query: this.search.value,
    });
    this.timer = setTimeout(this.onSearchUser, 300);
  };
  handleChange = (e, { name, value }) => {
    this.setState({
      message: value,
    });
  };
  onSearchUser = async () => {
    this.setState({
      loading: true,
      query: this.search.value,
    });
    this.setState({ search: this.state.query.toLowerCase() });
    const { search } = this.state;

    const filter = JSON.stringify([["active", "=", 1]]);

    await api.user
      .fetchAll(search ? { search, filter, order: "is_online", orderDirection: "desc" } : { take: 20, filter, order: "is_online", orderDirection: "desc" })
      .then((res) => {
        this.setState({
          records: res.data.filter((c) =>
            c.name.toLowerCase().includes(search)
          ),
        });
      });
  };
  cleanSearch = () => {
    this.search.value = "";
    clearTimeout(this.timer);
    this.setState({
      query: this.search.value,
    });
    this.timer = setTimeout(this.onSearchUser, 300);
  };

  fetchRecords = (params) => {
    this.setState({ loading: true });

    return api.user.fetchAll(params).then((res) => {

      this.setState({
        records: res.data.filter((c) => c.active === 1),
        loading: false,
      });
    });
  };

  fetchChats = (params) => {

    this.setState({ loading: true });

    return api.user.chats(params).then((res) => {

      this.setState({
        userChats: res.chats,
        loading: false,
        countChatWithUnreadMessages: res.chats.filter(c => c.participants.find(u => u.is_me && u.unread_messages > 0)).map(a => a.id)
      });
    });
  };

  componentDidMount() {
    const filter = JSON.stringify([["active", "=", 1]]);
    this.fetchRecords({ take: 50, filter, order: "is_online", orderDirection: "desc" }).then(() => {
      this.fetchChats().then(() => {
        Socket.connector.pusher.config.auth.headers.Authorization = `Bearer ${localStorage.token
        }`;
  
      Socket.private(`channel-${this.props.company_id}`).listen(
        "UserPauseChanged",
        this.setUserStatus
      );
        
      Socket.private(`channel-${this.props.company_id}`).listen(
        "UserLoggedIn",
        (e) => {
          const { records } = this.state;
          const index = records.findIndex((user) => user.id == e.user.id);

          this.setState({
            records: [
              ...records.slice(0, index),
              {
                ...records[index],
                is_online: e.user.is_online
              },
              ...records.slice(index + 1),
            ],
          });
        }
      );

      Socket.private(`new-chat-message-${this.props.user_id}`).listen(
        "NewChatMessage",
        (e) => {
          // from: id do usuário que enviou a mensagem
          // to: id do usuário para quem a mensagem foi enviada
  
          if (this.state.records.length === 0) {
            const filter = JSON.stringify([["active", "=", 1]]);
            this.fetchRecords({ take: 50, filter, order: "is_online", orderDirection: "desc" });
          }
  
          const message = e.message;
          const users = this.state.records;
  
          this.updateMessages(message);

          const chatOpen = this.state.chats.findIndex(c => c.chatId == message.chat_id)

          if(chatOpen < 0)
          {
            this.setState({ activeChat: true });
            this.openChat(users.find((c) => c.id == e.message.from), message.chat_id);
          }
        }
      );
      })
    })
    
  }

  setUserStatus = (e) => {

    const { records } = this.state;
    const index = records.findIndex((user) => user.id === e.user_id);

    this.setState({
      records: [
        ...records.slice(0, index),
        {
          ...records[index],
          currentPause: e.currentPause,
          is_online: !e.currentPause
        },
        ...records.slice(index + 1),
      ],
    });
  };

  updateMessages = (message) => {

    const { chats } = this.state;
    
    const findConversation = this.state.chats.findIndex(
      (c) => c.id == message.from
    );
    const data = this.state.chats.find((c) => c.id == message.from);
    
    const userChatIndex = this.state.userChats.findIndex(c => c.id == message.chat_id)

    const chatExists = this.state.countChatWithUnreadMessages.includes(message.chat_id)
    
 
    this.setState(
      {
        countChatWithUnreadMessages: chatExists ? this.state.countChatWithUnreadMessages: [...this.state.countChatWithUnreadMessages, message.chat_id],
        userChats: userChatIndex > -1 ? [
          ...this.state.userChats.slice(0, userChatIndex),
          {
            ...this.state.userChats[userChatIndex],
            participants: this.state.userChats[userChatIndex].participants.map(c => {
              return !c.is_me ? c : {...c, unread_messages: c.unread_messages + 1}
            }),
          },
          ...this.state.userChats.slice(userChatIndex + 1),
        ] : this.state.userChats
      }, () => {
        this.notify()
      }
    );

    if (findConversation > -1) {
      const indexChat = chats[findConversation].chat.length;
      const dataMessage = {
        from: message.from,
        to: message.to,
        text: message.text,
        chatId: message.chat_id
      };

      this.setState(
        {
          chats: [
            ...chats.slice(0, findConversation),
            {
              ...data,
              chat: [
                ...chats[findConversation].chat.slice(0, indexChat),
                { ...dataMessage },
                ...chats[findConversation].chat.slice(indexChat + 1),
              ],
            },
            ...chats.slice(findConversation + 1),
          ]
          
        },
        () => {
          
          const body = document.querySelector(
            `#chat-window-${findConversation} .message-body .scrollbar-container`
          );
          const boxes = document.querySelector(
            `#chat-window-${findConversation} .messages`
          );
          body.scrollTo(0, boxes.scrollHeight);
        }
      );
    }
  };
  activeClick = () => {

    if (this.state.records.length === 0) {
      const filter = JSON.stringify([["active", "=", 1]]);
      this.fetchRecords({ take: 50, filter, order: "is_online", orderDirection: "desc" });
    }

    this.setState((state) => ({
      activeChat: !state.activeChat,
    }));
  };

  activeHeader = () => {
    this.setState((state) => ({
      showHeader: !state.showHeader,
    }));
  };
  loadingSwitch = () => {
    const { loading } = this.state;
    this.setState({
      loading: !loading,
    });
  };
  sendMessage = (data, index) => {
    const { user_id } = this.props;
    const { message, chats } = this.state;


    const userChatIndex = this.state.userChats.findIndex(c => c.id == data.chatId)

    this.setState({
      loading: true,
      countChatWithUnreadMessages: this.state.countChatWithUnreadMessages.filter(c => c != data.chatId),
      userChats: [
        ...this.state.userChats.slice(0, userChatIndex),
        {
          ...this.state.userChats[userChatIndex],
          participants: this.state.userChats[userChatIndex].participants.map(c => {
            return !c.is_me ? c : {...c, unread_messages: 0}
          }),
        },
        ...this.state.userChats.slice(userChatIndex + 1),
      ]
    });

    const sendObject = {
      from: user_id,
      to: data.id,
      text: message,
      chat_id: data.chatId
    };
    if (message) {
      api.user.sendMessage(sendObject).then((res) => {
        const indexChat = chats[index].chat.length;
        const dataMessage = res.data;

        this.setState(
          {
            message: "",
            chats: [
              ...chats.slice(0, index),
              {
                ...data,
                chat: [
                  ...chats[index].chat.slice(0, indexChat),
                  { ...dataMessage },
                  ...chats[index].chat.slice(indexChat + 1),
                ],
              },
              ...chats.slice(index + 1),
            ],
          },
          () => {
            const body = document.querySelector(
              `#chat-window-${index} .message-body .scrollbar-container`
            );
            const boxes = document.querySelector(
              `#chat-window-${index} .messages`
            );
            body.scrollTo(0, boxes.scrollHeight);
          }
        );
      });
    }
  };

  enterMessage = (e, data, index) => {
    if (e.keyCode === 13 && e.shiftKey === false && this.state.message) {
      e.preventDefault();
      const { user_id } = this.props;
      const { message, chats } = this.state;
      const userChatIndex = this.state.userChats.findIndex(c => c.id == data.chatId)
    
      this.setState({
        loading: true,
        countChatWithUnreadMessages: this.state.countChatWithUnreadMessages.filter(c => c != data.chatId),
        userChats: [
          ...this.state.userChats.slice(0, userChatIndex),
          {
            ...this.state.userChats[userChatIndex],
            participants: this.state.userChats[userChatIndex].participants.map(c => {
              return !c.is_me ? c : {...c, unread_messages: 0}
            }),
          },
          ...this.state.userChats.slice(userChatIndex + 1),
        ]
      });
      const sendObject = {
        from: user_id,
        to: data.id,
        text: message,
        chat_id: data.chatId
      };
      if (message) {
        api.user.sendMessage(sendObject).then((res) => {
          const indexChat = chats[index].chat.length;
          const dataMessage = res.data;

          this.setState(
            {
              message: "",
              chats: [
                ...chats.slice(0, index),
                {
                  ...data,
                  chat: [
                    ...chats[index].chat.slice(0, indexChat),
                    { ...dataMessage },
                    ...chats[index].chat.slice(indexChat + 1),
                  ],
                },
                ...chats.slice(index + 1),
              ],
            },
            () => {
              const body = document.querySelector(
                `#chat-window-${index} .message-body .scrollbar-container`
              );
              const boxes = document.querySelector(
                `#chat-window-${index} .messages`
              );
              body.scrollTo(0, boxes.scrollHeight);
            }
          );
        });
      }
    }
  };

  notify = (title, message, link) => {
    var notification = null;
    if (Notification.permission === "granted") {
      audioSource.play();
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission().then(function (permission) {
        // If the user accepts, let's create a notification
        if (permission === "granted") {
          audioSource.play();
        }
      });
    }
    
  };

  openChat = (data, chatId) => {
    const { chats, loading } = this.state;
    this.setState({
      loading: !loading,
    });
    const findChat = chats.findIndex((c) => c.id === data.id);
    
    if (findChat > -1) {
      this.setState({
        activeWindow: findChat,
      });
    } else {
      if (data) {
        const chatsIndex = chats.length;
        const findChat = chats.find((c) => c.id === data.id);
        
        if (!findChat && chats.length < 3) {
          this.setState(
            {
              activeWindow: chatsIndex,
              message: "",
            }
          )
        }

        if (chats.length > 2) {
          const firstID = chats[0].id;
          const filterChats = chats.filter((c) => c.id !== firstID);
          const filterIndex = filterChats.length;
          this.setState(
            {
              activeWindow: filterIndex,
              message: "",
            }
          )
        }

        api.user.fetchChat(data.id).then((res) => {
          const dataChat = res.data;

          if (!findChat && chats.length < 3) {
            this.setState(
              {
                chats: [
                  ...chats.slice(0, chatsIndex),
                  { ...data, chatId, chat: dataChat.map(m => ({...m, chatId})) },
                  ...chats.slice(chatsIndex + 1),
                ],
                activeWindow: chatsIndex,
                message: "",
              },
              () => {
                const body = document.querySelector(
                  `#chat-window-${chatsIndex} .message-body .scrollbar-container`
                );
                const boxes = document.querySelector(
                  `#chat-window-${chatsIndex} .messages`
                );
                body.scrollTo(0, boxes.scrollHeight);
              }
            );
          }
          if (chats.length > 2) {
            const firstID = chats[0].id;
            const filterChats = chats.filter((c) => c.id !== firstID);
            const filterIndex = filterChats.length;
            this.setState(
              {
                chats: [
                  ...filterChats.slice(0, filterIndex),
                  { ...data, chat: dataChat },
                  ...filterChats.slice(filterIndex + 1),
                ],
                activeWindow: filterIndex,
                message: "",
              },
              () => {
                const body = document.querySelector(
                  `#chat-window-${filterIndex} .message-body .scrollbar-container`
                );
                const boxes = document.querySelector(
                  `#chat-window-${filterIndex} .messages`
                );
                body.scrollTo(0, boxes.scrollHeight);
              }
            );
          }
        });
      }
    }
  };
  activeWindow = (data) => {
    this.setState({
        activeWindow: data,
      });  
  };

  minimizeWindow = (data, index) => {
    const { chats, loading } = this.state;
    const findChat = chats.findIndex((c) => c.id === data.id);
    
    this.setState({
      activeWindow: "",
    });

    /* if(this.state.activeWindow == findChat)
    {
      console.log('porque não entra aqui ?')
      this.activeWindow("")
    } */
  }

  closeWindow = (data, index) => {
    const { chats } = this.state;

    this.setState(
      {
        chats: chats.filter((c) => c.id !== data.id),
        message: "",
      },
      () => { }
    );
  };
  render() {
    const {
      records,
      loading,
      activeChat,
      showHeader,
      chats,
      message,
      activeWindow
    } = this.state;
    const { avatar } = this.props;

    // const user = records.sort((a, b) => b.active - a.active);
    const user = records;

    return (
      <div className={showHeader ? "showHeader ativo" : "showHeader"}>
        <div
          className={
            activeChat ? "internalChat ativo" : "internalChat desativado"
          }
          onMouseEnter={this.activeHeader}
          onMouseLeave={this.activeHeader}
        >
          {this.state.countChatWithUnreadMessages.length > 0 ?
          <div style={
              {
                width: 18,
                height: 18,
                backgroundColor: "red",
                zIndex: 1000,
                position: "absolute",
                marginTop: -19,
                fontSize: 12,
                color: "white",
                fontWeight: "bold",
                textAlign: "center",
                marginLeft: 205,
                borderRadius: "50%",
                border: "1px solid red"
              }}> 
              {this.state.countChatWithUnreadMessages.length}
              </div> : null}
          <div className="headerChat" onClick={this.activeClick}>
            <div className="avatar_user">
              <img src={avatar} alt="" />
            </div>
            <span>Chat Interno</span>
            <Icon name="edit outline" />
          </div>
          <div className="bodyChat">
            <PerfectScrollbar
              ref={(ref) => {
                this._scrollBarRef = ref;
              }}
            >
              <div className="busca">
                <div className="inputBusca" name="busca-header">
                  <input
                    type="text"
                    onChange={this.handleInputChange}
                    ref={(input) => (this.search = input)}
                  />

                  <button className="botaoBusca" onClick={this.cleanSearch}>
                    <Icon name={this.state.query ? "close" : "search"} />
                  </button>
                </div>
              </div>

              {this.state.userChats
                .map((c, interruption, i) => {
                  const other = c.participants.find(u => !u.is_me);
                  const me = c.participants.find(u => u.is_me);

                  const d = user.find(u => u.id == other.id)

                  if(d)
                  {

                  return (
                    <div className="holderChat" key={`internal-chat-${c.id}`}>
                      <div
                        className={
                          d.is_online ? "usercard" : "usercard offline"
                        }
                        onClick={() => this.openChat(d, c.id)}
                        key={`user-${i * 1}`}
                      >
                        <div
                          className={
                            d.active
                              ? "avatar_user ativo"
                              : "avatar_user inativo"
                          }
                        >
                          <img src={d.avatar} alt="" />
                        </div>
                        <div className="holder-user" style={{display: "flex", flexDirection: "column"}}>
                          <span className="name" style={{fontSize: 15, height: 11}}>{d.name}</span>
                          <span className="name" style={{fontSize: 10, height: 11, fontStyle: "italic"}}>visto por último {d.last_seen_human}</span>

                          {d.currentPause ? (
                            <PauseInfo
                              pause={d.currentPause}
                              interruptions={
                                d.interruptionType ? [d.interruptionType] : []
                              }
                            />
                          ) : (
                              ""
                            )}
                        </div>
                        {me.unread_messages > 0 ? <div style={{
                        backgroundColor: "red",
                        width: 12,
                        height: 12,
                        fontSize: 8,
                        fontWeight: "bold",
                        borderRadius: "50%",
                        zIndex: 1000,
                        color: "white",
                        position: "absolute",
                        textAlign: "center"
                        }}><span style={{
                          marginTop: -4,
                          position: "absolute",
                          marginLeft: -4
                        }}>{me.unread_messages}</span></div> : null}
                      </div>
                    </div>
                  
                  );
                          }else{
                            
                          }
                })}

              
            </PerfectScrollbar>
          </div>

          {chats ? (
            <WindowChat
              chats={chats.map(c => {
                return {
                  ...c,
                  unread_messages: this.state.userChats.find(u => u.id == c.chatId).participants.find(c => c.is_me).unread_messages
                }
              })}
              message={message}
              loading={loading}
              activeWindow={this.state.activeWindow}
              active={this.activeWindow}
              closeWindow={this.closeWindow}
              minimizeWindow={this.minimizeWindow}
              enterMessage={this.enterMessage}
              sendMessage={this.sendMessage}
              loadingSwitch={this.loadingSwitch}
              handleChange={this.handleChange}
            />
          ) : (
              ""
            )}
        </div>

      </div>
    );
  }
}

const mapStateToProps = ({ user: { user } }) => ({
  name: user.name,
  user_id: user.id,
  company_id: user.company_id,
  avatar: user.avatar,
});
export default connect(mapStateToProps)(InternalChat);
