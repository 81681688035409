import React from "react";
import { Grid } from "semantic-ui-react";

export default () => (
  <Grid>
    <Grid.Row>
      <h2>Integrações e Mensagens</h2>
      <p>
        Aqui vamos aprensetar o menu relacionado as Integrações com whatsapp,
        tudo relacionado a conexão, palavras rápidas, palavras impróprias e
        palavra alerta.
      </p>
    </Grid.Row>
  </Grid>
);
