import React from "react";
import { Modal, Button, Form, Input } from "semantic-ui-react";
import InputMask from "react-input-mask";

const AddContactModal = ({
  open,
  toggle,
  onChange,
  contact,
  loading,
  save,
}) => (
  <Modal
    size="small"
    closeIcon
    open={open}
    onClose={toggle}
    dimmer="blurring"
    closeOnDimmerClick={false}
  >
    <Modal.Header>Cadastro de Contato</Modal.Header>
    <Modal.Content>
      <Modal.Description>
        <Form size="mini">
          <Form.Group widths="equal">
            <Form.Field
              control={Input}
              label="Nome"
              name="name"
              onChange={onChange}
              value={contact.name}
            />
            <Form.Field>
              <label htmlFor="message">Telefone</label>
              <Input
                autoComplete="off"
                control="Input"
                children={
                  <InputMask
                    mask="+55 (99) 99999-9999"
                    name="phone_number"
                    className="number_br"
                    onChange={(e) =>
                      onChange(e, {
                        name: e.target.name,
                        value: e.target.value,
                      })
                    }
                    value={contact.phone_number}
                  />
                }
              />
            </Form.Field>
          </Form.Group>
        </Form>
      </Modal.Description>
    </Modal.Content>
    <Modal.Actions>
      <Button.Group>
        <Button onClick={toggle}>Cancelar</Button>
        <Button.Or />
        <Button positive onClick={save} loading={loading} disabled={loading}>
          Salvar
        </Button>
      </Button.Group>
    </Modal.Actions>
  </Modal>
);

export default AddContactModal;
