import React, { useState, useEffect } from "react";
import api from "../../api/social-media";
import { Dropdown } from "semantic-ui-react";
import { connect } from "react-redux";

const DropdownTemplate = ({ onChange, accountId, value, setTemplates, accounts, name = "template", doNotLoad = false, currentTemplates, onlyMarketing=false }) => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);

  useEffect(() => {

    let id = accountId
    if (!id) {
      const waba = accounts.filter(w => w.whatsapp_official_account).slice(0, 1)

      if (waba.length > 0) {
        id = waba.slice(0, 1)[0].id
      }
    }
    if (!doNotLoad) {
      getData(id);
    } else {
      setOptions(currentTemplates.map((d) => ({
        key: d.name,
        value: d.name,
        text: d.name,
      })));
    }
  }, [accountId]);

  const getData = (accountId = null) => {
    setLoading(true);

    api.media.templates(accountId).then((res) => {
      let data = [];
      data = res.templates.filter((t) => t.language == "pt_BR" && t.name.indexOf('sample') < 0);

      if(onlyMarketing)
      {
        data = res.templates.filter((t) => t.language == "pt_BR" && t.category == "MARKETING" && t.name.indexOf('sample') < 0);
      }

      const options = data.map((d) => ({
        key: d.name,
        value: d.name,
        text: d.name,
      }));
      setOptions(options);
      setTemplates(data);
      setLoading(false);
    }).catch(() => {
      setLoading(false)
    });
  };

  return (
    <Dropdown
      placeholder="Templates Disponíveis"
      fluid
      search
      selection
      scrolling
      name={name}
      value={value}
      onChange={onChange}
      options={options}
      closeOnChange
      loading={loading}
      selectOnBlur={false}
      clearable
    />
  );
};

const mapStateToProps = ({ conversation, user, accounts }) => {
  return {
    accounts: accounts.records,
  };
};

export default connect(mapStateToProps, null)(DropdownTemplate);
