import React, { Component } from "react";
import api from "../../api/department";
import { Dropdown } from "semantic-ui-react";
import AddDepartmentModal from "./modal/AddDepartmentModal";

class DropdownDepartments extends Component {
  state = {
    department: {
      name: "",
      description: "",
    },
    options: [],
    loading: false,
    search: "",
    openModal: false,
  };

  handleDepartmentAddition = (e) => { };

  onSearchChange = (e, { searchQuery }) => {
    clearTimeout(this.timer);
    this.setState({ search: searchQuery });
    this.timer = setTimeout(this.onSearchDepartment, 300);
  };

  componentWillMount() {
    api.department.fetchAll({take: 100}).then((departments) => {
      this.setState({
        options: departments.data.map((c) => ({
          key: c.id,
          value: c.id,
          text: c.name,
        })),
        loading: false,
      });
    });
  }

  componentDidMount() {
    const { options } = this.props;

    if (options) {
      this.setState({
        options: options.map((c) => ({
          key: c.id,
          value: c.id,
          text: `${c.name}`,
        })),
      });
    }
  }

  onSearchDepartment = async () => {
    this.setState({ loading: true });
    const { search } = this.state;

    await api.department.fetchAll({ search }).then((departments) => {
      this.setState({
        options: departments.data.map((c) => ({
          key: c.id,
          value: c.id,
          text: c.name,
        })),
        loading: false,
      });
    });
  };

  onChange = (e, { name, value }) => {
    this.setState((state) => ({
      department: { ...state.department, [name]: value },
    }));
  };

  cleanErrors = () => {
    this.setState({ errors: "" });
  };

  submit = () => {
    const { department } = this.state;

    this.setState({
      loading: true,
    });

    return api.department
      .submit(department)
      .then((data) => {
        this.setState((state) => ({
          options: [...state.options].concat({
            key: data.data.id,
            value: data.data.id,
            text: data.data.name,
          }),
          loading: false,
          department: {
            name: "",
            description: "",
          },
          openModal: !state.openModal,
        }));

        this.props.onSelectDepartment(data.data.id);
      })
      .catch((err) => {
        this.setState({
          loading: false,
          errors: err.response,
        });
      });
  };

  toggle = (e, { name, value }) => {
    this.setState((state) => ({
      openModal: !state.openModal,
      department: {
        name: value,
        description: "",
      },
    }));
  };

  render() {
    const { options, loading, openModal, department } = this.state;

    return (
      <div style={this.props.style || {}}>
        <Dropdown
          placeholder="Pesquise um Departamento"
          fluid
          search
          selection
          multiple={this.props.multiple}
          scrolling
          allowAdditions
          selectOnBlur={this.props.selectOnBlur || false}
          additionLabel="Adicionar: "
          name={this.props.name || "department_id"}
          value={
            this.props.departments
              ? this.props.departments
              : this.props.department_id
          }
          onSearchChange={this.onSearchChange}
          onChange={(e, { value }) => this.props.onSelectDepartment(value)}
          options={options}
          onAddItem={this.toggle}
          closeOnChange={this.props.closeOnChange}
          loading={loading || this.props.loading}
          clearable
        />
        <AddDepartmentModal
          open={openModal}
          department={department}
          save={this.submit}
          toggle={this.toggle}
          onChange={this.onChange}
        />
      </div>
    );
  }
}

export default DropdownDepartments;
