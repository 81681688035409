import React from "react";
import { Grid } from "semantic-ui-react";

export default () => (
  <Grid>
    <Grid.Row>
      <h2>Número</h2>
      <p>
        Nesta coluna você o número do Ticket, que é gerado na abertura do ticket
      </p>
    </Grid.Row>
  </Grid>
);
