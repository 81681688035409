import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Button,
  Dropdown,
  Form,
  Icon,
  Input,
  Modal,
  TextArea,
} from "semantic-ui-react";
import { bindActionCreators } from "redux";
import { Creators as TicketActions } from "../../../store/ducks/ticket";

import DropdownDepartments from "../../departments/DropdownDepartments";
import DropdownCategories from "../../ticket-category/DropdownCategories";
import DropdownUsers from "../../users/DropdownUsers";
import api from "../../../api/ticket";
import apiContact from "../../../api/contact";
import DropdownContacts from "../../contact/DropdownContacts";
import DropdownClients from "../../client/DropdownClients";

const initialState = {
  user_id: "",
  title: "",
  incident: "",
  department_id: "",
  ticket_category_id: "",
  contact_id: "",
  contacts: [],
  email: "",
};

const NewTicketModal = ({
  user,
  company,
  showLink = true,
  callback,
  toggleNewTicketModal,
  newTicketModalOpen,
  contact,
}) => {
  const [ticket, setTicket] = useState({ ...initialState, user_id: user.id });
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const submit = () => {
    setLoading(true);
    api.ticket
      .submit(ticket)
      .then((res) => {
        if (res.ticket.id) {
          window.open(`/ticket/${res.ticket.id}/edit`);
        }
        setLoading(false);
        setTicket({ ...initialState, user_id: user.id });

        if (callback) {
          callback(res.ticket);
        }
      })
      .catch((err) => setLoading(false));
  };

  const setContact = (contact_id) => {
    setTicket({ ...ticket, contact_id: contact_id });
    apiContact.contact.fetchId(contact_id).then((res) => {
      setTicket({ ...ticket, contact_id: contact_id, email: res.data.email });
    });
  };

  const setClient = (client_id) => {
    setTicket({ ...ticket, client_id: client_id });
  };

  useEffect(() => {
    if (contact) {
      setTicket({ ...ticket, contact_id: contact.id });
    }
  }, []);
  return (
    <>
      {showLink && (
        <Link to="#" onClick={toggleNewTicketModal} title="Novo Ticket">
          <Icon name="ticket" className="ticket" id='ticket-modal' size="large" />
        </Link>
      )}
      <Modal
        size="small"
        closeIcon
        open={newTicketModalOpen}
        onClose={toggleNewTicketModal}
        dimmer="blurring"
        closeOnDimmerClick={true}
      >
        <Modal.Header>Novo Ticket</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <div className="holder_ticket">
              <div className="form-ticket">
                <Form>
                  <Form.Group>
                    <Form.Field
                      autoComplete="off"
                      autoFocus
                      control={Input}
                      label="Resumo"
                      name="title"
                      onChange={(e, { name, value }) =>
                        setTicket({ ...ticket, [name]: value })
                      }
                      value={ticket.title}
                    />
                  </Form.Group>
                  <Form.Group>
                    <div className="field">
                      <label>Contato</label>
                      <DropdownContacts
                        contact_id={ticket.contact_id}
                        multiple={false}
                        options={
                          contact
                            ? [
                                {
                                  id: contact.id,
                                  name: contact.name,
                                  phone_number: contact.phone_number,
                                  avatar: contact.avatar,
                                },
                              ]
                            : []
                        }
                        name="contact_id"
                        onSelectContact={(value) => {
                          setContact(value);
                        }}
                      />
                    </div>
                  </Form.Group>
                  <Form.Group>
                    <Form.Field
                      label="Cliente"
                      clients={ticket.client_id}
                      control={DropdownClients}
                      openOnFocus={true}
                      multiple={false}
                      loading={loading}
                      name="client_id"
                      onSelectClient={(value) => {
                        setClient(value);
                      }}
                    />
                  </Form.Group>
                  {company.enable_email
                    ? ticket.contact_id && (
                        <Form.Field
                          control={Input}
                          name="email"
                          label="Email do Contato"
                          type="email"
                          onChange={(e, { name, value }) =>
                            setTicket({ ...ticket, [name]: value })
                          }
                          value={ticket.email}
                        />
                      )
                    : null}
                  <Form.Group>
                    <div className="field">
                      <label>Responsável</label>
                      <DropdownUsers
                        onSelectUser={(value) => {
                          setTicket({ ...ticket, user_id: value });
                        }}
                        user_id={ticket.user_id}
                        allowAdditions={false}
                      />
                    </div>
                  </Form.Group>
                  <Form.Group>
                    <Form.Field
                      control={TextArea}
                      label="Solicitação"
                      name="incident"
                      onChange={(e, { name, value }) =>
                        setTicket({ ...ticket, [name]: value })
                      }
                      value={ticket.incident}
                    />
                  </Form.Group>
                  {company.enable_email && (
                    <Form.Group>
                      <Form.Field
                        control={Dropdown}
                        label="Com Cópia Para"
                        name="contacts"
                        fluid
                        placeholder="example@example.com"
                        search
                        selection
                        multiple={true}
                        additionLabel="Clique para adicionar CC: "
                        options={options}
                        closeOnChange
                        clearable
                        onChange={(e, { name, value }) =>
                          setTicket({ ...ticket, [name]: value })
                        }
                        allowAdditions
                        onAddItem={(e, { value }) =>
                          setOptions(
                            options
                              .filter((c) => c !== value)
                              .concat({
                                key: value,
                                text: value,
                                value,
                              })
                          )
                        }
                        value={ticket.contacts}
                      />
                    </Form.Group>
                  )}
                  <Form.Group>
                    <Form.Field>
                      Departamento
                      <DropdownDepartments
                        department_id={ticket.department_id}
                        openOnFocus={true}
                        name="department_id"
                        onSelectDepartment={(value) => {
                          setTicket({ ...ticket, department_id: value });
                        }}
                      />
                    </Form.Field>
                    <Form.Field>
                      Categoria
                      <DropdownCategories
                        ticket_category_id={ticket.ticket_category_id}
                        openOnFocus={true}
                        name="ticket_category_id"
                        onSelectCategory={(value) => {
                          setTicket({ ...ticket, ticket_category_id: value });
                        }}
                      />
                    </Form.Field>
                  </Form.Group>
                </Form>
              </div>
            </div>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button.Group>
            <Button onClick={toggleNewTicketModal}>Cancelar</Button>
            <Button.Or />
            <Button
              positive
              loading={loading}
              disabled={loading}
              onClick={submit}
            >
              Salvar
            </Button>
          </Button.Group>
        </Modal.Actions>
      </Modal>
    </>
  );
};

const mapStateToProps = ({ user, ticket }) => {
  return {
    user: user.user,
    company: user.user.companies[0],
    newTicketModalOpen: ticket.newTicketModalOpen,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...TicketActions }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewTicketModal);
