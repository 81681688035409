import React, { Component } from "react";
import api from "../../api/client";
import { Dropdown } from "semantic-ui-react";

class DropdownClients extends Component {
  state = {
    client: {
      name: "",
    },
    options: [],
    loading: false,
    search: "",
    openModal: false,
  };

  componentWillMount() {
    if (this.props.options) {
      if (this.state.options.length == 0 && this.props.options.length > 0) {
        const { options } = this.props;
        this.setState({
          options,
        });
      }
    }
  }

  handleClientsAddition = (e) => { };

  onSearchChange = (e, { searchQuery }) => {
    clearTimeout(this.timer);
    this.setState({ search: searchQuery });
    this.timer = setTimeout(this.onSearchClients, 300);
  };

  componentDidMount() {
    if (this.props.options) {
      this.setState({
        options: this.props.options.map((c) => ({
          key: c.id,
          value: c.id,
          text: `${c.name}`,
        })),
      });
    }
  }

  onSearchClients = async () => {
    this.setState({ loading: true });
    const { search } = this.state;

    await api.client.fetchAll({ search }).then((client) => {
      this.setState({
        options: client.data.map((c) => ({
          key: c.id,
          value: c.id,
          text: c.name,
        })),
        loading: false,
      });
    });
  };

  onChange = (e, { name, value }) => {
    this.setState((state) => ({
      client: { ...state.client, [name]: value },
    }));
  };

  cleanErrors = () => {
    this.setState({ errors: "" });
  };

  submit = () => {
    const { client } = this.state;

    this.setState({
      loading: true,
    });

    return api.client
      .submit(client)
      .then((data) => {
        this.setState((state) => ({
          options: [
            {
              key: data.data.id,
              value: data.data.id,
              text: data.data.name,
            },
          ],
          loading: false,
          client: {
            name: "",
            description: "",
          },
          openModal: !state.openModal,
        }));

        this.props.onSelectClient(data.data.id);
      })
      .catch((err) => {
        this.setState({
          loading: false,
          errors: err.response,
        });
      });
  };

  toggle = (e, { value }) => {
    this.setState(
      (state) => ({
        openModal: !state.openModal,
        options: this.state.options.concat({ key: value, text: value, value }),
        client: {
          name: value,
          groups: [],
          tickets: [],
        },
      }),
      () => {
        this.submit();
      }
    );
  };

  render() {

    const { options, loading } = this.state;

    return (
      <div>
        <Dropdown
          placeholder="Pesquise um Cliente"
          fluid
          search
          selection
          scrolling
          multiple={false}
          allowAdditions={this.props.allowAdditions || true}
          additionLabel="Novo: "
          name={this.props.name}
          value={this.props.clients}
          onSearchChange={this.onSearchChange}
          onChange={(e, { value }) => this.props.onSelectClient(value)}
          options={this.props.options && !options.length ? this.props.options.map(o => ({
            key: o.id,
            value: o.id,
            text: o.name,
          })) : options}
          onAddItem={this.toggle}
          closeOnChange
          openOnFocus={this.props.openOnFocus}
          loading={loading || this.props.loading}
          clearable
        />
      </div>
    );
  }
}

DropdownClients.defaultProps = {
  name: "clients",
  openOnFocus: false,
};

export default DropdownClients;
