import React from "react";
import { Grid } from "semantic-ui-react";

export default () => (
  <Grid>
    <Grid.Row>
      <h2>Tickets</h2>
      <p>
        <strong>
          Neste Menu ficará todos relatório de tickets, quantidades abertos,
          fechados , atendidos e aguardando, e seus gráficos relacionados
        </strong>
      </p>
    </Grid.Row>
  </Grid>
);
