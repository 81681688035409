import React, { Component } from "react";
import { Header, Modal } from "semantic-ui-react";

class Terms extends Component {
    render() {
        return (
            <Modal trigger={<a href="/#">Termo de Uso</a>}>
                <Modal.Header>Termo de Uso</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <p>
                            Estes Termos de uso se aplicam ao software
                            MONITCHAT, Help Desk. Qualquer usuário deve ler e
                            concordar com todos os termos antes de utilizar o
                            software. O software objeto dessa licença pode ser
                            tratado como programa ou software.
                        </p>
                        <Header>APRESENTAÇÃO</Header>
                        <p>
                            O presente contrato tem por finalidade regular
                            direitos e obrigações relativos à utilização da
                            Plataforma MONITCHAT , Help Desk, que a CONTRATADA
                            disponibilizará à CONTRATANTE, consubstanciada na
                            aplicação MONITCHAT, Help Desk, bem como transmissão
                            de dados criptografada, acesso e armazenamento de
                            dados em ambiente seguro e suporte técnico.
                        </p>

                        <p>
                            O MONITCHAT é acessado via internet. Para que o
                            usuário possa utilizar o software, ele deve estar
                            instalado e mantido hospedado em um servidor da L2
                            Software Ltda. ou em outro por ela autorizado.
                        </p>

                        <p>
                            Seu cadastramento no Serviço ou o uso que fizer dele
                            será considerado como sua anuência às condições ora
                            estabelecidas, incluindo, mas não somente, a
                            política de privacidade e segurança do MONITCHAT e
                            os preços e forma de pagamento acordados no momento
                            da contratação.
                        </p>

                        <Header>LICENÇA DE USO</Header>

                        <p>
                            A CONTRATANTE deverá pagar o valor mensal por
                            usuário de acordo com os planos exibidos na seguinte
                            URL:
                        </p>

                        <Header>PLANOS</Header>

                        <p>
                            Todas as cobranças são realizadas de forma
                            antecipada para cada mês de uso de forma
                            proporcional à quantidade de usuários ativos na
                            conta da CONTRATANTE. Caso a CONTRATANTE contrate
                            novos usuários durante o mês em andamento, será
                            cobrado valor proporcional aos dias restantes até o
                            final do mês contratado para cada novo usuário no
                            ato de sua contratação no sistema, no caso de
                            pagamento com cartão de crédito. No caso de
                            pagamento com boleto, a cobrança dos novos usuários
                            será realizada junto da contratação do mês seguinte.
                        </p>

                        <p>
                            Os valores pagos podem sofrer modificações tanto por
                            opção da CONTRATADA quanto caso o CONTRATANTE opte
                            por outro plano oferecido pela L2 Software Ltda.
                            Neste formato comercial, a L2 Software Ltda atua
                            como provedor de serviços.
                        </p>

                        <p>
                            No caso de não pagamento dos valores referentes ao
                            licenciamento, a CONTRATADA poderá interromper
                            temporariamente a prestação dos serviços até a
                            normalização da inadimplência. A CONTRADA deverá
                            notificar a CONTRATANTE da situação irregular por um
                            prazo de 30 (trinta) dias para regularização, sendo
                            o período superior a 30 (trinta) dias passível de
                            rescisão da prestação de serviços.
                        </p>

                        <p>
                            Será disponibilizado um espaço no servidor para
                            armazenamento de arquivos no total de 1 (hum)
                            gigabytes multiplicado pela quantidade de usuários
                            contratada. Para aquisição de mais espaço para
                            armazenamento, será cobrado o valor mensal de R$
                            0,50 (zero reais e cinquenta centavos) por cada
                            gigabyte extra. Este valor poderá ser reajustado a
                            qualquer momento.
                        </p>

                        <Header>VIGÊNCIA</Header>

                        <p>
                            O prazo do contrato tem duração indeterminada,
                            podendo ser rescindido a qualquer momento, por
                            qualquer das partes, sem qualquer ônus. A rescisão
                            deve ser realizada dentro do pela CONTRATANTE pelo
                            software.
                        </p>

                        <Header>CONTEÚDO</Header>

                        <p>
                            A CONTRATANTE é a responsável pelas informações
                            inseridas no software, pelo cadastramento,
                            permissões, senhas e modo de utilização dos seus
                            funcionários. A L2 Software Ltda não será
                            responsável por qualquer conteúdo inserido no
                            software.
                        </p>

                        <p>
                            Caso alguma das regras aqui estabelecidas ou
                            qualquer norma legal em vigor seja desrespeitada, a
                            L2 Software Ltda se reserva do direito de suspender,
                            retirar ou excluir o software e suspender o direito
                            da CONTRATANTE de adquirir nova licença do
                            MONITCHAT.
                        </p>

                        <Header>RESTRIÇÕES</Header>

                        <p>É expressamente proibido:</p>

                        <ul>
                            <li>
                                A utilização do software fora das condições
                                estabelecidas nesta licença de uso.
                            </li>

                            <li>
                                Traduzir, fazer engenharia reversa, descompilar,
                                copiar imagens, códigos ou quaisquer partes do
                                software para utilização fora dele.
                            </li>

                            <li>
                                Alugar, arrendar, atribuir ou transferir o
                                produto licenciado.
                            </li>

                            <li>
                                Remover ou alterar qualquer aviso de copyright,
                                marca registrada, ou outro aviso de direitos de
                                propriedade colocados no software ou parte dele.
                            </li>
                        </ul>

                        <Header>SUPORTE TÉCNICO</Header>

                        <p>
                            O suporte técnico aos usuários limita-se a
                            esclarecimentos sobre o software. Pressupõe-se que o
                            usuário possua o conhecimento necessário para
                            utilizar as funções básicas do computador, e que
                            este possua as configurações adequadas para o bom
                            funcionamento do software. O horário de atendimento
                            acontece entre 9h00 e 18h00, de segunda-feira à
                            sexta-feira, exceto feriados, somente por e-mail.
                        </p>

                        <Header>ATUALIZAÇÕES</Header>

                        <p>
                            A L2 Software Ltda pode implementar modificações,
                            atualizações e novas funções nos softwares a
                            qualquer momento sem necessidade de aprovação por
                            parte da CONTRATANTE. A instalação das atualizações
                            é feita de forma automática pela L2 Software Ltda.
                            Todas as atualizações e modificações que afetem o
                            uso diário do software devem ser notificadas à
                            CONTRATANTE até 1 um dia após terem sido
                            implementadas.
                        </p>

                        <Header>SEGURANÇA DOS DADOS</Header>

                        <p>
                            A CONTRATADA se compromete a não divulgar, não
                            liberar acesso a terceiros sem expressa autorização
                            da CONTRATANTE e a utilizar protocolos de segurança
                            durante a troca de informações entre o servidor sob
                            os cuidados da L2 Software Ltda. e os computadores
                            utilizados pela CONTRATANTE, a fim de manter os
                            dados desta em sigilo.
                        </p>

                        <p>
                            L2 Software Ltda. se compromete a manter os dados
                            que a CONTRATADA inserir no software em servidor
                            protegido, e a manter uma cópia do banco de dados
                            atualizada e em ambiente seguro.
                        </p>

                        <Header>CONDIÇÕES FINAIS</Header>

                        <p>
                            No caso de interrupção do funcionamento do software
                            por falhas técnicas da CONTRATADA, desde que
                            devidamente informadas no prazo de 24h, a CONTRATADA
                            se compromete a repor o igual período da
                            interrupção. Em hipótese alguma a L2 Software Ltda
                            será responsável por lucros cessantes, indenizações
                            de qualquer espécie, danos comerciais, especiais,
                            acidentais, consequenciais ou outros diretos ou
                            indiretos.
                        </p>

                        <p>
                            A L2 Software Ltda Ltda se reserva o direito de
                            modificar os termos e as condições deste Contrato ou
                            de suas políticas relacionadas ao Serviço a qualquer
                            momento, passando a vigorar a partir da postagem de
                            uma versão atualizada deste Contrato no Serviço, com
                            notificação aos usuários dessa(s) alteração(ões).
                            Você é responsável por analisar regularmente este
                            Contrato. O uso continuado do Serviço após qualquer
                            alteração constituirá sua anuência a tal alteração.
                        </p>
                    </Modal.Description>
                </Modal.Content>
            </Modal>
        );
    }
}

export default Terms;
