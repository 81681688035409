import axios from "../services/api";

export default {
	interruption: {
		fetchAll: params =>
			axios.get(`/interruption-type`, { params }).then(res => res.data),
		delete: id =>
			axios.delete(`/interruption-type/${id}`).then(res => res.data),
		update: (id, interruption) =>
			axios
				.put(`/interruption-type/${id}`, interruption)
				.then(res => res.data),
		submit: interruption =>
			axios.post(`/interruption-type`, interruption).then(res => res.data),
		teste: params =>
				axios(`/user-interruption`, {params}).then(res => res)
	}
};
