import React from "react";
import { Grid } from "semantic-ui-react";

export default () => (
  <Grid>
    <Grid.Row>
      <h2>Cliente</h2>
      <p>
        Nesta coluna aparecerá a empresa na qual o contato trabalha, se ele
        estiver cadastrado em uma empresa!
      </p>
    </Grid.Row>
  </Grid>
);
