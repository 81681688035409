export default [
  {
    key: "Alimentos e Bebidas",
    value: "Alimentos e Bebidas",
    text: "Alimentos e Bebidas",
  },
  {
    key: "Arte e Antiguidades",
    value: "Arte e Antiguidades",
    text: "Arte e Antiguidades",
  },
  {
    key: "Artigos Religiosos",
    value: "Artigos Religiosos",
    text: "Artigos Religiosos",
  },
  {
    key: "Assinaturas e Revistas",
    value: "Assinaturas e Revistas",
    text: "Assinaturas e Revistas",
  },
  {
    key: "Automóveis e Veículos",
    value: "Automóveis e Veículos",
    text: "Automóveis e Veículos",
  },
  {
    key: "Bebês e Cia",
    value: "Bebês e Cia",
    text: "Bebês e Cia",
  },
  {
    key: "Brindes / Materiais Promocionais",
    value: "Brindes / Materiais Promocionais",
    text: "Brindes / Materiais Promocionais",
  },
  {
    key: "Brinquedos e Games",
    value: "Brinquedos e Games",
    text: "Brinquedos e Games",
  },
  {
    key: "Casa e Decoração",
    value: "Casa e Decoração",
    text: "Casa e Decoração",
  },
  {
    key: "CDs",
    value: "CDs",
    text: "CDs",
  },
  {
    key: "Colecionáveis",
    value: "Colecionáveis",
    text: "Colecionáveis",
  },
  {
    key: "Compras Coletivas",
    value: "Compras Coletivas",
    text: "Compras Coletivas",
  },
  {
    key: "Construção e Ferramentas",
    value: "Construção e Ferramentas",
    text: "Construção e Ferramentas",
  },
  {
    key: "Cosméticos e Perfumaria",
    value: "Cosméticos e Perfumaria",
    text: "Cosméticos e Perfumaria",
  },
  {
    key: "Cursos e Educação",
    value: "Cursos e Educação",
    text: "Cursos e Educação",
  },
  {
    key: "Discos de Vinil",
    value: "Discos de Vinil",
    text: "Discos de Vinil",
  },
  {
    key: "DVDs",
    value: "DVDs",
    text: "DVDs",
  },
  {
    key: "Eletrodomésticos",
    value: "Eletrodomésticos",
    text: "Eletrodomésticos",
  },
  {
    key: "Eletrônicos",
    value: "Eletrônicos",
    text: "Eletrônicos",
  },
  {
    key: "Emissoras de Rádio",
    value: "Emissoras de Rádio",
    text: "Emissoras de Rádio",
  },
  {
    key: "Emissoras de Televisão",
    value: "Emissoras de Televisão",
    text: "Emissoras de Televisão",
  },
  {
    key: "Empregos",
    value: "Empregos",
    text: "Empregos",
  },
  {
    key: "Empresas de Telemarketing",
    value: "Empresas de Telemarketing",
    text: "Empresas de Telemarketing",
  },
  {
    key: "Esporte e Lazer",
    value: "Esporte e Lazer",
    text: "Esporte e Lazer",
  },
  {
    key: "Flores, Cestas e Presentes",
    value: "Flores, Cestas e Presentes",
    text: "Flores, Cestas e Presentes",
  },
  {
    key: "Fotografia",
    value: "Fotografia",
    text: "Fotografia",
  },
  {
    key: "Igrejas / Templos / Instituições Religiosas",
    value: "Igrejas / Templos / Instituições Religiosas",
    text: "Igrejas / Templos / Instituições Religiosas",
  },
  {
    key: "Indústria, Comércio e Negócios",
    value: "Indústria, Comércio e Negócios",
    text: "Indústria, Comércio e Negócios",
  },
  {
    key: "Infláveis Promocionais",
    value: "Infláveis Promocionais",
    text: "Infláveis Promocionais",
  },
  {
    key: "Informática",
    value: "Informática",
    text: "Informática",
  },
  {
    key: "Ingressos",
    value: "Ingressos",
    text: "Ingressos",
  },
  {
    key: "Instrumentos Musicais",
    value: "Instrumentos Musicais",
    text: "Instrumentos Musicais",
  },
  {
    key: "Joalheria",
    value: "Joalheria",
    text: "Joalheria",
  },
  {
    key: "Lazer",
    value: "Lazer",
    text: "Lazer",
  },
  {
    key: "LD",
    value: "LD",
    text: "LD",
  },
  {
    key: "Livros",
    value: "Livros",
    text: "Livros",
  },
  {
    key: "MD",
    value: "MD",
    text: "MD",
  },
  {
    key: "Moda e Acessórios",
    value: "Moda e Acessórios",
    text: "Moda e Acessórios",
  },
  {
    key: "Motéis",
    value: "Motéis",
    text: "Motéis",
  },
  {
    key: "Música Digital",
    value: "Música Digital",
    text: "Música Digital",
  },
  {
    key: "Natal",
    value: "Natal",
    text: "Natal",
  },
  {
    key: "Negócios e Oportunidades",
    value: "Negócios e Oportunidades",
    text: "Negócios e Oportunidades",
  },
  {
    key: "Outros Serviços",
    value: "Outros Serviços",
    text: "Outros Serviços",
  },
  {
    key: "Outros Serviços de Avaliação",
    value: "Outros Serviços de Avaliação",
    text: "Outros Serviços de Avaliação",
  },
  {
    key: "Papelaria e Escritório",
    value: "Papelaria e Escritório",
    text: "Papelaria e Escritório",
  },
  {
    key: "Páscoa",
    value: "Páscoa",
    text: "Páscoa",
  },
  {
    key: "Pet Shop",
    value: "Pet Shop",
    text: "Pet Shop",
  },
  {
    key: "Saúde",
    value: "Saúde",
    text: "Saúde",
  },
  {
    key: "Serviço Advocaticios",
    value: "Serviço Advocaticios",
    text: "Serviço Advocaticios",
  },
  {
    key: "Serviço de Distribuição de Jornais / Revistas",
    value: "Serviço de Distribuição de Jornais / Revistas",
    text: "Serviço de Distribuição de Jornais / Revistas",
  },
  {
    key: "Serviços Administrativos",
    value: "Serviços Administrativos",
    text: "Serviços Administrativos",
  },
  {
    key: "Serviços Artísticos",
    value: "Serviços Artísticos",
    text: "Serviços Artísticos",
  },
  {
    key: "Serviços de Abatedouros / Matadouros",
    value: "Serviços de Abatedouros / Matadouros",
    text: "Serviços de Abatedouros / Matadouros",
  },
  {
    key: "Serviços de Aeroportos",
    value: "Serviços de Aeroportos",
    text: "Serviços de Aeroportos",
  },
  {
    key: "Serviços de Agências",
    value: "Serviços de Agências",
    text: "Serviços de Agências",
  },
  {
    key: "Serviços de Aluguel / Locação",
    value: "Serviços de Aluguel / Locação",
    text: "Serviços de Aluguel / Locação",
  },
  {
    key: "Serviços de Armazenagem",
    value: "Serviços de Armazenagem",
    text: "Serviços de Armazenagem",
  },
  {
    key: "Serviços de Assessorias",
    value: "Serviços de Assessorias",
    text: "Serviços de Assessorias",
  },
  {
    key: "Serviços de Assistência Técnica / Instalações ",
    value: "Serviços de Assistência Técnica / Instalações ",
    text: "Serviços de Assistência Técnica / Instalações ",
  },
  {
    key: "Serviços de Associações",
    value: "Serviços de Associações",
    text: "Serviços de Associações",
  },
  {
    key: "Serviços de Bancos de Sangue",
    value: "Serviços de Bancos de Sangue",
    text: "Serviços de Bancos de Sangue",
  },
  {
    key: "Serviços de Bibliotecas",
    value: "Serviços de Bibliotecas",
    text: "Serviços de Bibliotecas",
  },
  {
    key: "Serviços de Cartórios",
    value: "Serviços de Cartórios",
    text: "Serviços de Cartórios",
  },
  {
    key: "Serviços de Casas Lotéricas",
    value: "Serviços de Casas Lotéricas",
    text: "Serviços de Casas Lotéricas",
  },
  {
    key: "Serviços de Confecções",
    value: "Serviços de Confecções",
    text: "Serviços de Confecções",
  },
  {
    key: "Serviços de Consórcios",
    value: "Serviços de Consórcios",
    text: "Serviços de Consórcios",
  },
  {
    key: "Serviços de Consultorias",
    value: "Serviços de Consultorias",
    text: "Serviços de Consultorias",
  },
  {
    key: "Serviços de Cooperativas",
    value: "Serviços de Cooperativas",
    text: "Serviços de Cooperativas",
  },
  {
    key: "Serviços de Despachante",
    value: "Serviços de Despachante",
    text: "Serviços de Despachante",
  },
  {
    key: "Serviços de Engenharia",
    value: "Serviços de Engenharia",
    text: "Serviços de Engenharia",
  },
  {
    key: "Serviços de Estacionamentos",
    value: "Serviços de Estacionamentos",
    text: "Serviços de Estacionamentos",
  },
  {
    key: "Serviços de Estaleiros",
    value: "Serviços de Estaleiros",
    text: "Serviços de Estaleiros",
  },
  {
    key: "Serviços de Exportação / Importação",
    value: "Serviços de Exportação / Importação",
    text: "Serviços de Exportação / Importação",
  },
  {
    key: "Serviços de Geólogos",
    value: "Serviços de Geólogos",
    text: "Serviços de Geólogos",
  },
  {
    key: "Serviços de joalheiros",
    value: "Serviços de joalheiros",
    text: "Serviços de joalheiros",
  },
  {
    key: "Serviços de Leiloeiros",
    value: "Serviços de Leiloeiros",
    text: "Serviços de Leiloeiros",
  },
  {
    key: "Serviços de limpeza",
    value: "Serviços de limpeza",
    text: "Serviços de limpeza",
  },
  {
    key: "Serviços de Loja de Conveniência",
    value: "Serviços de Loja de Conveniência",
    text: "Serviços de Loja de Conveniência",
  },
  {
    key: "Serviços de Mão de Obra",
    value: "Serviços de Mão de Obra",
    text: "Serviços de Mão de Obra",
  },
  {
    key: "Serviços de Órgão Públicos",
    value: "Serviços de Órgão Públicos",
    text: "Serviços de Órgão Públicos",
  },
  {
    key: "Serviços de Pesquisas",
    value: "Serviços de Pesquisas",
    text: "Serviços de Pesquisas",
  },
  {
    key: "Serviços de Portos",
    value: "Serviços de Portos",
    text: "Serviços de Portos",
  },
  {
    key: "Serviços de Saúde / Bem Estar",
    value: "Serviços de Saúde / Bem Estar",
    text: "Serviços de Saúde / Bem Estar",
  },
  {
    key: "Serviços de Seguradoras",
    value: "Serviços de Seguradoras",
    text: "Serviços de Seguradoras",
  },
  {
    key: "Serviços de Segurança",
    value: "Serviços de Segurança",
    text: "Serviços de Segurança",
  },
  {
    key: "Serviços de Sinalização",
    value: "Serviços de Sinalização",
    text: "Serviços de Sinalização",
  },
  {
    key: "Serviços de Sindicatos / Federações",
    value: "Serviços de Sindicatos / Federações",
    text: "Serviços de Sindicatos / Federações",
  },
  {
    key: "Serviços de Traduções",
    value: "Serviços de Traduções",
    text: "Serviços de Traduções",
  },
  {
    key: "Serviços de Transporte",
    value: "Serviços de Transporte",
    text: "Serviços de Transporte",
  },
  {
    key: "Serviços de Utilidade Pública",
    value: "Serviços de Utilidade Pública",
    text: "Serviços de Utilidade Pública",
  },
  {
    key: "Serviços em Agricultura / Pecuária / Piscicultura",
    value: "Serviços em Agricultura / Pecuária / Piscicultura",
    text: "Serviços em Agricultura / Pecuária / Piscicultura",
  },
  {
    key: "Serviços em Alimentação",
    value: "Serviços em Alimentação",
    text: "Serviços em Alimentação",
  },
  {
    key: "Serviços em Arte",
    value: "Serviços em Arte",
    text: "Serviços em Arte",
  },
  {
    key: "Serviços em Cine / Foto / Som",
    value: "Serviços em Cine / Foto / Som",
    text: "Serviços em Cine / Foto / Som",
  },
  {
    key: "Serviços em Comunicação",
    value: "Serviços em Comunicação",
    text: "Serviços em Comunicação",
  },
  {
    key: "Serviços em Construção",
    value: "Serviços em Construção",
    text: "Serviços em Construção",
  },
  {
    key: "Serviços em Ecologia / Meio Ambiente",
    value: "Serviços em Ecologia / Meio Ambiente",
    text: "Serviços em Ecologia / Meio Ambiente",
  },
  {
    key: "Serviços em Eletroeletrônica / Metal Mecânica",
    value: "Serviços em Eletroeletrônica / Metal Mecânica",
    text: "Serviços em Eletroeletrônica / Metal Mecânica",
  },
  {
    key: "Serviços em Festas / Eventos",
    value: "Serviços em Festas / Eventos",
    text: "Serviços em Festas / Eventos",
  },
  {
    key: "Serviços em Informática",
    value: "Serviços em Informática",
    text: "Serviços em Informática",
  },
  {
    key: "Serviços em Internet",
    value: "Serviços em Internet",
    text: "Serviços em Internet",
  },
  {
    key: "Serviços em Jóias / Relógios / Óticas",
    value: "Serviços em Jóias / Relógios / Óticas",
    text: "Serviços em Jóias / Relógios / Óticas",
  },
  {
    key: "Serviços em Telefonia",
    value: "Serviços em Telefonia",
    text: "Serviços em Telefonia",
  },
  {
    key: "Serviços em Veículos",
    value: "Serviços em Veículos",
    text: "Serviços em Veículos",
  },
  {
    key: "Serviços Esotéricos / Místicos",
    value: "Serviços Esotéricos / Místicos",
    text: "Serviços Esotéricos / Místicos",
  },
  {
    key: "Serviços Financeiros",
    value: "Serviços Financeiros",
    text: "Serviços Financeiros",
  },
  {
    key: "Serviços Funerários",
    value: "Serviços Funerários",
    text: "Serviços Funerários",
  },
  {
    key: "Serviços Gerais",
    value: "Serviços Gerais",
    text: "Serviços Gerais",
  },
  {
    key: "Serviços Gráficos / Editoriais",
    value: "Serviços Gráficos / Editoriais",
    text: "Serviços Gráficos / Editoriais",
  },
  {
    key: "Serviços para Animais",
    value: "Serviços para Animais",
    text: "Serviços para Animais",
  },
  {
    key: "Serviços para Deficientes",
    value: "Serviços para Deficientes",
    text: "Serviços para Deficientes",
  },
  {
    key: "Serviços para Escritórios",
    value: "Serviços para Escritórios",
    text: "Serviços para Escritórios",
  },
  {
    key: "Serviços para Roupas",
    value: "Serviços para Roupas",
    text: "Serviços para Roupas",
  },
  {
    key: "Serviços Socias / Assistenciais",
    value: "Serviços Socias / Assistenciais",
    text: "Serviços Socias / Assistenciais",
  },
  {
    key: "Shopping Centers",
    value: "Shopping Centers",
    text: "Shopping Centers",
  },
  {
    key: "Tarifas Bancárias",
    value: "Tarifas Bancárias",
    text: "Tarifas Bancárias",
  },
  {
    key: "Tarifas Telefônicas",
    value: "Tarifas Telefônicas",
    text: "Tarifas Telefônicas",
  },
  {
    key: "Telefonia",
    value: "Telefonia",
    text: "Telefonia",
  },
  {
    key: "Turismo",
    value: "Turismo",
    text: "Turismo",
  },
];
