import React from "react";
import { Grid, Icon } from "semantic-ui-react";

export default () => (
  <Grid>
    <Grid.Row>
      <h2>Origem</h2>
      <p>Aqui mostra por onde o tícket foi aberto</p>
      <p>
        <Icon name="whatsapp" color="blue" /> Foi aberto pelo Monitchat
      </p>
      <p>
        <Icon name="whatsapp" color="black" /> Foi aberto pelo Whatsapp
      </p>
      <p>
        <Icon name="phone" rotated="clockwise" color="black" /> Foi aberto pelo
        Telefone
      </p>
    </Grid.Row>
  </Grid>
);
