import React from "react";
import { Grid } from "semantic-ui-react";

export default () => (
  <Grid>
    <Grid.Row>
      <h2>Tabela de Campanha</h2>
      <p>
        Aqui é onde verá todas as suas campanhas, poderá, criar, editar, e
        deletar camapnhas se tiver permissão para tais ações.
      </p>
    </Grid.Row>
  </Grid>
);
