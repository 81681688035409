import axios from "../services/api";

export default {
  conversations: {
    getStats: (conversationId) =>
      axios.get(`/conversation/${conversationId}/stats`),
    get: (conversationId, params = {}) => axios.get(`/conversation/${conversationId}`, { params }),
    read: (conversationId) => axios.get(`/conversation/${conversationId}/read`),
    count: () => axios.get(`/conversation/count`),
    checkTicket: (phoneNumber) => axios.get(`/conversation/${phoneNumber}/check-ticket`),
    fetchAll: (params) =>
      axios.get(`/conversation`, { params }).then((res) => res.data),
    fetchTickets: (conversationId, take = 30, skip = 0) =>
      axios
        .get(
          `/conversation/${conversationId}/tickets?take=${take}&skip=${skip}`
        )
        .then((res) => res.data),

    fetchTicketsTake: (conversationId, take = 20, skip = 0) =>
      axios.get(
        `/conversation/${conversationId}/tickets?take=${take}&skip=${skip}`
      ),
    resendMessage: (message) =>
      axios
        .post(
          `/conversation/${message.conversation_id}/resendMessage/${message.id}`
        )
        .then((res) => res.data),
    startConversation: (params) =>
      axios
        .post(`/conversation/startConversation`, params)
        .then((res) => res.data),
    sendTemplate: (conversationId, params) =>
      axios
        .post(`/conversation/${conversationId}/template`, params)
        .then((res) => res.data),
    deleteMessage: ({ phone_number, message_id, account_number, id }) =>
      axios
        .post(`/social/whatsapp/message/${id}/delete`, {
          phone_number,
          account_number,
          whatsapp_message_id: message_id,
        })
        .then((res) => res.data),
    fetchComments: (conversationId, take = 5, skip = 0, date = null) =>
      axios.get(
        `/conversation/${conversationId}/comments?take=${take}&skip=${skip}&date=${date}`
      ),
    archiveConversation: (id, conversations) =>
      axios
        .put(`/conversation/${id}/archive`, conversations)
        .then((res) => res.data),
    fetchMessages: (conversationId, take = 15, skip = 0) =>
      axios.get(
        `/conversation/${conversationId}/messages?take=${take}&skip=${skip}`
      ),
    update: (conversation) =>
      axios
        .put(`/conversation/${conversation.id}`, conversation)
        .then((res) => res.data),
    fetchMessagesTake: (conversationId, take = 15, skip = 0) =>
      axios.get(
        `/conversation/${conversationId}/messages?take=${take}&skip=${skip}`
      ),
    sendTextMessage: (conversationId, message) =>
      axios
        .post(`/conversation/${conversationId}/message`, message)
        .then((res) => res.data.data),
    uploadFile: (conversationId, data) =>
      axios.post(`/conversation-file/${conversationId}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((res) => res.data),
    sendImageMessage: (conversationId, message) =>
      axios
        .post(`/conversation/${conversationId}/imageMessage`, message)
        .then((res) => res.data.data),
    sendDocumentMessage: (conversationId, message) =>
      axios
        .post(`/conversation/${conversationId}/documentMessage`, message)
        .then((res) => res.data.data),
    sendVoiceMessage: (conversationId, message) =>
      axios
        .post(`/conversation/${conversationId}/voiceMessage`, message)
        .then((res) => res.data.data),
    sendComment: (comment) =>
      axios.post(`/comment`, comment).then((res) => res.data),

    enableAutoReply: (conversationId, autoReply) =>
      axios
        .post(`chat/conversation/${conversationId}/autoReply`, autoReply)
        .then((res) => res.data.data),
    sendNewConversation: (message) =>
      axios.post(`/chat/message`, message).then((res) => res.data.data),
  },
};
