import React from "react";
import { Grid } from "semantic-ui-react";

export default () => (
  <Grid>
    <Grid.Row>
      <h2>Status/Atividade</h2>
      <p>
        Nesta coluna você verá o status do ticket, de acordo com o que você
        cadastrou em <strong> configurações -> Status de atendimento </strong>
      </p>
    </Grid.Row>
  </Grid>
);
