import React from "react";
import { Grid, Icon } from "semantic-ui-react";

export default () => (
  <Grid>
    <h2>Nova Conversa</h2>
    <p>
      <Icon.Group className="add-comment" title="Nova Conversa">
        <Icon name="user" />
        <Icon corner name="add" />
      </Icon.Group>{" "}
      Este atalho abre um campo para abrir uma nova conversa. Basta digitar o
      número da pessoa e a mensagem e pronto se sua conta whatsapp estiver
      conectada irá enviar uma mensagem para o número informado!
      <strong>Simples não!!</strong>
    </p>
  </Grid>
);
