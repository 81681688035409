import jwt from "jsonwebtoken";
import isEmpty from "lodash/isEmpty";
import api from "../../api/user";

// Types declaration
export const Types = {
  USER_LOGGED_IN: "user/USER_LOGGED_IN",
  USER_LOGGED_OUT: "user/USER_LOGGED_OUT",
  SET_CURRENT_USER: "user/SET_CURRENT_USER",
  NEW_VERSION_RELEASED: "user/NEW_VERSION_RELEASED",
  SET_USER_INFO: "user/SET_USER_INFO",
  SET_USER_PAUSE: "user/SET_USER_PAUSE",
  SET_PERMISSIONS: "user/SET_PERMISSIONS",
  SET_USER_AVATAR: "user/SET_USER_AVATAR",
  ADD_TO_WALLET: "user/ADD_TO_WALLET",
  SET_PARAMETERS: "user/SET_PARAMETERS",
  SET_USERS: "user/SET_USERS",
};

const INITIAL_STATE = {
  isAuthenticated: false,
  currentPause: null,
  user: {
    info:{
      build: localStorage.build
    }
  },
  hasNewUpdate: false,
  permissions: [],
  pages: [],
  roles: {},
  users: [],
};

// Reducer
export default function auth(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case Types.USER_LOGGED_IN:
      return action.user;
    case Types.USER_LOGGED_OUT:
      return {
        isAuthenticated: false,
        user: {
          info:{
            build: localStorage.build
          }
        },
      };
    case Types.NEW_VERSION_RELEASED:
      return {
        ...state,
        hasNewUpdate: action.hasNewUpdate
      };
    case Types.SET_CURRENT_USER:
      return {
        ...state,
        hasSystemNotification: false,
        systemNotification: "",
        isAuthenticated: !isEmpty(action.user),
        currentPause: action.user.currentPause,
        user: action.user,
        roles: action.roles,
        avatar: action.avatar ? action.avatar : localStorage.avatar,
      };
    case Types.SET_USER_INFO: {
      let hasNewUpdate = action.info.build != process.env.REACT_APP_BUILD_VERSION
      localStorage.setItem("hasNewUpdate", hasNewUpdate)

      return {
        ...state,
        hasNewUpdate,
        user: { ...state.user, info: action.info },
      };
    }
    case Types.SET_USERS: {
      return {
        ...state,
        users: action.payload,
      };
    }
    case Types.SET_PARAMETERS: {
      return {
        ...state,
        user: { ...state.user, parameters: action.parameters },
      };
    }
    case Types.ADD_TO_WALLET: {
      const newState = {
        ...state,
        user: {
          ...state.user,
          contacts: action.payload,
        },
      };
      return newState;
    }
    case Types.SET_PERMISSIONS: {
      const newState = {
        ...state,
        user: {
          ...state.user,
          permissions: action.permissions,
          pages: action.pages,
        },
      };
      return newState;
    }
    case Types.SET_USER_PAUSE:
      return {
        ...state,
        currentPause: action.currentPause,
      };
    case Types.SET_USER_AVATAR:
      return {
        ...state,
        avatar: action.avatar,
      };
    default:
      return state;
  }
}

// Actions
export const Creators = {
  userLoggedIn: (user) => ({
    type: Types.USER_LOGGED_IN,
    user,
  }),

  userLoggedOut: () => ({
    type: Types.USER_LOGGED_OUT,
  }),

  setParameters: (parameters) => ({
    type: Types.SET_PARAMETERS,
    parameters,
  }),

  setUserInfos: (info) => ({
    type: Types.SET_USER_INFO,
    info,
  }),

  setCurrentUser: (user, roles, avatar) => ({
    type: Types.SET_CURRENT_USER,
    user,
    roles,
    avatar,
  }),

  setUserActive: (currentPause) => ({
    type: Types.SET_USER_PAUSE,
    currentPause,
  }),

  setUserAvatar: (avatar) => ({
    type: Types.SET_USER_AVATAR,
    avatar,
  }),

  setPermissions: (permissions, pages) => ({
    type: Types.SET_PERMISSIONS,
    permissions,
    pages,
  }),

  getPermissions: (user) => (dispatch) =>
    api.user.getPermissions(user).then((res) => {
      dispatch(Creators.setPermissions(res.data, res.pages));
    }),

  userPause: (user) => (dispatch) =>
    api.user.submitPause(user).then((res) => {
      dispatch(Creators.setUserActive(res.data.currentPause));
    }),

  removePause: (user) => (dispatch) => dispatch(Creators.setUserActive(null)),
  setUsers: (users) => (dispatch) => {
    dispatch({ type: Types.SET_USERS, payload: users });
  },
  getCurrentPause: () => (dispatch) =>
    api.user.getCurrentPause().then((pause) => {
      dispatch(Creators.setUserActive(pause));
    }),
  setHasNewUpdate:(hasNewUpdate) => (dispatch) => {
    localStorage.setItem("hasNewUpdate", hasNewUpdate)
    dispatch({ type: Types.NEW_VERSION_RELEASED, hasNewUpdate });
  },
  login: (credentials) => (dispatch) =>
    api.user.login(credentials).then(({ info, user }) => {
      const token = user.access_token;
      const roles = user.roles;
      const avatar = user.avatar;

      localStorage.setItem("token", token);
      localStorage.setItem("roles", roles);
      localStorage.setItem("avatar", avatar);
      dispatch(Creators.setUserInfos(info));
      dispatch(Creators.removePause());
      dispatch(Creators.setCurrentUser(jwt.decode(token), roles, avatar));
      window.location.reload();
    }),

  resetLogin: (credentials) => (dispatch) => {
    const token = credentials.access_token;
    const roles = credentials.roles;

    localStorage.setItem("token", token);
    localStorage.setItem("roles", roles);

    dispatch(Creators.setCurrentUser(jwt.decode(token), roles));
  },

  AddToWallet: (contacts) => (dispatch) => {
    dispatch({ type: Types.ADD_TO_WALLET, payload: contacts });
  },

  logout: (ignoreRevoke = false) => async (dispatch) => {
    await api.user.logout(ignoreRevoke);
    localStorage.removeItem("token");
    localStorage.removeItem("avatar");
    dispatch(Creators.userLoggedOut());
  },
};
