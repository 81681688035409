import React from "react";
import { Grid, Icon } from "semantic-ui-react";

export default () => (
  <Grid>
    <h2>Tutorial</h2>
    <p>
      {" "}
      <Icon name="rocket" className="add-comment" /> Neste ícone abre o tutorial
      da plataforma
    </p>
  </Grid>
);
