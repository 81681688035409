import React from "react";
import { Grid } from "semantic-ui-react";

export default () => (
  <Grid>
    <Grid.Row>
      <h2>Customização</h2>
      <p>
        Aqui você poderá customizar sua plataforma com novas funcionalidades,
        criando campos de formulário adicionais no cadastro e criando novos
        cadastros.
      </p>
    </Grid.Row>
  </Grid>
);
