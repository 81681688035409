import React, { Component } from "react";
import api from "../../api/social-media";
import { Dropdown } from "semantic-ui-react";
import { connect } from "react-redux";

class DropdownMedia extends Component {
  state = {
    media: {
      phone_number: "",
    },
    options: [],
    loading: false,
    search: "",
    openModal: false,
  };

  handleMediaAddition = (e) => { };

  onSearchChange = (e, { searchQuery }) => {
    clearTimeout(this.timer);
    this.setState({ search: searchQuery });
    this.timer = setTimeout(this.onSearchMedia, 300);
  };
  componentWillMount() {
    const { spamOnly, templateOnly } = this.props;

    var data = spamOnly
      ? this.props.records.filter((c) => c.can_spam)
      : this.props.records;

    if (templateOnly !== undefined) {
      data = templateOnly ? data.filter(c => c.whatsapp_official_account) : data.filter(c => !c.whatsapp_official_account)
    }

    this.setState({
      options: data.map((c) => ({
        key: c.id,
        value: c.id,
        text: c.phone_number,
      })),
      loading: false,
    });
  }

  onSearchMedia = async () => {
    this.setState({ loading: true });

    const { search } = this.state;
    const { spamOnly, templateOnly } = this.props;

    await api.media.fetchAll({ search }).then((media) => {
      var data = spamOnly ? media.data.filter((c) => c.can_spam) : media.data;
      data = templateOnly ? data.filter(c => c.whatsapp_official_account) : data.filter(c => !c.whatsapp_official_account)

      this.setState({
        options: data.map((c) => ({
          key: c.id,
          value: c.id,
          text: c.phone_number,
        })),
        loading: false,
      });
    });
  };

  onChange = (e, { name, value }) => {
    this.setState((state) => ({
      media: { ...state.media, [name]: value },
    }));
  };

  cleanErrors = () => {
    this.setState({ errors: "" });
  };
  submit = () => {
    const { media } = this.state;

    this.setState({
      loading: true,
    });

    return api.media
      .submit(media)
      .then((data) => {
        this.setState((state) => ({
          options: [...state.options].concat({
            key: data.data.id,
            value: data.data.id,
            text: data.data.phone_number,
          }),
          loading: false,
          media: {
            phone_number: "",
          },
          openModal: !state.openModal,
        }));

        this.props.onSelectMedia(data.data.id);
      })
      .catch((err) => {
        this.setState({
          loading: false,
          errors: err.response,
        });
      });
  };

  toggle = (e, { name, value }) => {
    this.setState((state) => ({
      openModal: !state.openModal,
      media: {
        phone_number: value,
      },
    }));
  };
  render() {

    const { options, loading } = this.state;
    const { multiple = true, name = "accounts" } = this.props
    return (
      <div>
        <Dropdown
          placeholder="Selecionar Número"
          fluid
          search
          selection
          scrolling
          selectOnBlur={false}
          multiple={multiple}
          additionLabel="Adicionar: "
          name={name}
          value={this.props.accounts}
          onSearchChange={this.onSearchChange}
          onChange={(e, { value, text }) =>
            this.props.onSelectMedia(value, text)
          }
          options={options}
          onAddItem={this.toggle}
          closeOnChange
          loading={loading}
          clearable
        />
      </div>
    );
  }
}

const mapStateToProps = ({ accounts }) => {
  return {
    records: accounts.records,
  };
};

export default connect(mapStateToProps)(DropdownMedia);
