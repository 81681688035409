import React from "react";
import { Grid } from "semantic-ui-react";

export default () => (
  <Grid>
    <Grid.Row>
      <h2>Permissões</h2>
      <p>
        Aqui é onde se cadastra as permissões disponíveis para acessar no perfis
        de acesso
      </p>
    </Grid.Row>
  </Grid>
);
