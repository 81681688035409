import axios from "../services/api";

export default {
	department: {
		fetchAll: params =>
			axios.get(`/department`, { params }).then(res => res.data),
		delete: id => axios.delete(`/department/${id}`).then(res => res.data),
		update: (id, department) =>
			axios.put(`/department/${id}`, department).then(res => res.data),
		submit: department =>
			axios.post(`/department`, department).then(res => res.data)
	}
};
