import React from "react";
import { Grid } from "semantic-ui-react";

export default () => (
  <Grid>
    <Grid.Row>
      <h2>Formulários</h2>
      <p>
        Aqui você irá cadastrar novos cadastros e criar um novo menu aqui em
        Administração para esta novo cadastro. Você pode criar um novo cadastro
        de produtos, estoque, entre outros que julgar necessário.
      </p>
    </Grid.Row>
  </Grid>
);
