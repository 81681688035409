import React from "react";
import { Grid } from "semantic-ui-react";

export default () => (
  <Grid>
    <Grid.Row>
      <h2>Atividades</h2>
    </Grid.Row>
    <Grid.Row>
      <p>
        Aqui nós apresentamos as todas as 10 últimas atividades principais e
        quem realizou as atividades, por exemplo:
        <br />
        Se um usuário efetuou login na plataforma, automáticamente terá um
        registro que tal usuário está logado na plataforma. <br />
        Outras atividades listadas aqui são criação, edição e exclusão:
        <br />
        <strong>
          Tarefas, contatos, Tickets, grupo de usuários, empresas.
        </strong>{" "}
        <br />
        No caso dos Tickets exibe também se foi atribuido para qual usuário!!
      </p>
    </Grid.Row>
  </Grid>
);
