import React from "react";

const AlertRed = ({
  message = "Seu registro foi excluído com sucesso",
  handleClick = () => {}
}) => (
  <div
    className="AlertSuccess AlertRed"
    style={{ padding: "5px 10px", marginTop: "-25px" }}
    onClick={handleClick}
  >
    <h3>{message}</h3>
  </div>
);

export default AlertRed;
