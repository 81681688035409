import React from "react";
import { Grid } from "semantic-ui-react";

export default () => (
  <Grid>
    <Grid.Row>
      <h2>Departamentos</h2>
      <p>
        Aqui é onde se cadastra os departamentos onde poderão cadastrar os
        agentes
      </p>
    </Grid.Row>
  </Grid>
);
