import React, { Component } from "react";
import axios from "../../services/api";
import whatsapp from "../../api/whatsapp";
import DataTable from "../table/DataTable";
import AlertSuccess from "../alerts/AlertSuccess";
import {
  Button,
  Checkbox,
  Dropdown,
  Form,
  Message,
  Modal,
} from "semantic-ui-react";
import InlineError from "../messages/InlineError";

const quotasType = [
  {
    id: "marketing",
    text: "Conversas para Marketing",
    value: "marketing"
  },
  {
    id: "service",
    text: "Conversas Service, para recebimento",
    value: "service"
  },
  {
    id: "utility",
    text: "Conversas Utility, para disparos informativos",
    value: "utility"
  }
];

const quotas = [
  {
    id: 100,
    text: "+100 Conversas por R$50,00",
    value: 100,
    amount: "R$50,00",
  },
  {
    id: 200,
    text: "+200 Conversas por R$80,00",
    value: 200,
    amount: "R$80,00",
  },
  {
    id: 300,
    text: "+300 Conversas por R$120,00",
    value: 300,
    amount: "R$120,00",
  },
  {
    id: 400,
    text: "+400 Conversas por R$150,00",
    value: 400,
    amount: "R$150,00",
  },
  {
    id: 500,
    text: "+500 Conversas por R$190,00",
    value: 500,
    amount: "R$190,00",
  },
  {
    id: 1000,
    text: "+1000 Conversas por R$360,00",
    value: 1000,
    amount: "R$360,00",
  },
  {
    id: 1500,
    text: "+1500 Conversas por R$540,00",
    value: 1500,
    amount: "R$540,00",
  },
  {
    id: 2000,
    text: "+2000 Conversas por R$720,00",
    value: 2000,
    amount: "R$720,00",
  },
  {
    id: 2500,
    text: "+2500 Conversas por R$900,00",
    value: 2500,
    amount: "R$900,00",
  },
  {
    id: 3000,
    text: "+3000 Conversas por R$1080,00",
    value: 3000,
    amount: "R$1080,00",
  },
  {
    id: 3500,
    text: "+3500 Conversas por R$1225,00",
    value: 3500,
    amount: "R$1225,00",
  },
  {
    id: 4000,
    text: "+4000 Conversas por R$1400,00",
    value: 4000,
    amount: "R$1400,00",
  },
  {
    id: 4500,
    text: "+4500 Conversas por R$1575,00",
    value: 4500,
    amount: "R$1575,00",
  },
  {
    id: 5000,
    text: "+5000 Conversas por R$1700,00",
    value: 5000,
    amount: "R$1700,00",
  },
  {
    id: 5500,
    text: "+5500 Conversas por R$1870,00",
    value: 5500,
    amount: "R$1870,00",
  },
  {
    id: 6000,
    text: "+6000 Conversas por R$2040,00",
    value: 6000,
    amount: "R$2040,00",
  },
  {
    id: 6500,
    text: "+6500 Conversas por R$2210,00",
    value: 6500,
    amount: "R$2210,00",
  },
  {
    id: 7000,
    text: "+7000 Conversas por R$2310,00",
    value: 7000,
    amount: "R$2310,00",
  },
  {
    id: 7500,
    text: "+7500 Conversas por R$2475,00",
    value: 7500,
    amount: "R$2475,00",
  },
  {
    id: 8000,
    text: "+8000 Conversas por R$2640,00",
    value: 8000,
    amount: "R$2640,00",
  },
  {
    id: 8500,
    text: "+8500 Conversas por R$2805,00",
    value: 8500,
    amount: "R$2805,00",
  },
  {
    id: 9000,
    text: "+9000 Conversas por R$2880,00",
    value: 9000,
    amount: "R$2880,00",
  },
  {
    id: 9500,
    text: "+9500 Conversas por R$3040,00",
    value: 9500,
    amount: "R$3040,00",
  },
  {
    id: 10000,
    text: "+10000 Conversas por R$3200,00",
    value: 10000,
    amount: "R$3200,00",
  },
];
class FranquiaMensagemSummay extends Component {
  state = {
    records: [],
    columns: {},
    quotas_columns: {},
    additional_quotas: [],
    order: {},
    errors: "",
    loading: false,
    total_records: 0,
    selectedDataIndex: -1,
    editModalOpen: false,
    save_alert: false,
    pageOptions: [],
    quotaType: null,
    quotaTypeError: "",
    menuIcons: [],
    quotaSuccess: false,
    addQuotaModalOpen: false,
    acceptedError: "",
    quotaError: "",
    quota: null,
    accepted: false,
  };

  select = (selectedDataId) => {
    const dataIndex = this.state.records.findIndex(
      (c) => c.id === selectedDataId
    );
    this.setState({
      selectedDataIndex: dataIndex,
      editModalOpen: true,
      pageOptions: this.state.records[dataIndex].options,
    });
  };

  newDataClick = () => {
    const { records } = this.state;

    const newData = records[0]
      ? Object.keys(records[0]).reduce((o, key) => {
        return Object.assign(o, {
          [key]: Array.isArray(records[0][key]) ? [] : "",
        });
      }, {})
      : {
        input_options: [],
      };

    this.setState(
      {
        records: [...records].concat(newData),
        editModalOpen: true,
      },
      () => {
        this.setState({
          selectedDataIndex: this.state.records.length - 1,
        });
      }
    );
  };

  nextRecord = () => {
    const selectedDataIndex =
      this.state.selectedDataIndex < this.state.records.length - 1
        ? this.state.selectedDataIndex + 1
        : this.state.records.length - 1;
    const selectedDataId = this.state.records[selectedDataIndex].id;

    this.select(selectedDataId);
  };

  previousRecord = () => {
    const selectedDataIndex =
      this.state.selectedDataIndex - 1 > 0
        ? this.state.selectedDataIndex - 1
        : 0;
    const selectedDataId = this.state.records[selectedDataIndex].id;

    this.select(selectedDataId);
  };

  addQuota = () => {
    this.setState({
      acceptedError: "",
      quotaError: "",
    });
    if (!this.state.quota) {
      this.setState({ quotaError: "Você precisa escolher uma franquia" });
      return;
    }
    if (!this.state.accepted) {
      this.setState({
        acceptedError: "Você precisa concordar com os termos da franquia",
      });
      return;
    }
    this.setState({ loading: true });
    axios
      .post("/additional-quota", {
        quota_id: this.state.quota.id,
        accepted: this.state.accepted,
      })
      .then((res) => {
        this.fetchRecords({ filter: { created: "month" } });
        this.setState({
          quota: null,
          accepted: false,
          quotaSuccess: true,
        });

        setTimeout(() => {
          this.setState({
            quotaSuccess: false,
          });
        }, 3000);
      });
  };

  fetchRecords = async (params) => {
    this.setState({ loading: true });

    return await whatsapp.whatsapp
      .billingMessages({ ...params, company_id: this.props.company.id })
      .then((res) => {
        this.setState({
          records: res.data,
          order: res.order,
          columns: res.quotas_columns,
          additional_quotas: res.additional_quotas,
          quotas_columns: res.quotas_columns,
          total_records: 0,
          total_quota: res.total_quota || 0,
          used_quota: res.used_quota || 0,
          loading: false,
          menuIcons: res.icons,
        });
      });
  };

  componentWillMount() {
    this.fetchRecords({ filter: { created: "month" } });
  }

  onClickAddQuota = () => {
    this.setState({ addQuotaModalOpen: !this.state.addQuotaModalOpen });
  };

  onAddItem = (e, { value }) => {
    this.setState((prevState) => ({
      pageOptions: [{ text: value, value }, ...prevState.pageOptions],
    }));
  };

  handleChange = (e, { name, value, checked }) => {
    const { selectedDataIndex: dataIndex, records } = this.state;

    this.setState({
      records: [
        ...records.slice(0, dataIndex),
        {
          ...records[dataIndex],
          [name]: checked !== undefined ? checked : value,
        },
        ...records.slice(dataIndex + 1),
      ],
    });
  };

  cleanErrors = () => {
    this.setState({ errors: "" });
  };

  handleCloseEditModal = () => {
    const { records } = this.state;
    this.setState({
      editModalOpen: false,
      selectedId: -1,
      selectedDataIndex: -1,
      records: [...records].filter((c) => c.id > 0),
    });
  };

  render() {
    const {
      records,
      loading,
      columns,
      quotas_columns,
      additional_quotas,
      total_records,
      total_quota = 0,
      used_quota = 0,
    } = this.state;

    return (
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            fontFamily: "Tahoma",
          }}
        >
          <div
            style={{
              display: "flex",
              fontSize: 18,
              width: 500,
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            <div style={{ padding: 10, width: "33.333%" }}>CONSUMIDAS</div>
            <div style={{ padding: 10, width: "33.333%" }}>FRANQUIA</div>
            <div style={{ padding: 10, width: "33.333%" }}>RESTAM</div>
          </div>
          <div
            style={{
              display: "flex",
              width: 500,
              fontSize: 15,
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            <div style={{ padding: 10, width: "33.333%" }}>{used_quota}</div>
            <div style={{ padding: 10, width: "33.333%" }}>{total_quota}</div>
            <div style={{ padding: 10, width: "33.333%" }}>
              {total_quota - used_quota}
            </div>
          </div>
        </div>
        {additional_quotas.length > 0 && (
          <div className="tabela-padrao">
            <div className="headingPage">
              <h1>Franquia Adicional Contratada</h1>
              {this.state.save_alert && <AlertSuccess />}
            </div>
            <DataTable
              loading={loading}
              showSelectAll={false}
              hide_actions={true}
              csv={additional_quotas}
              showFilterByDate={true}
              showAvailableLengths={false}
              showRefreshButton={false}
              showBackButton={false}
              showRightButtonsBar={false}
              showAddButton={false}
              showSearchInput={false}
              columns={quotas_columns}
              data={additional_quotas}
              totalRecords={total_records}
              fetchData={this.fetchRecords}
            />
          </div>
        )}
        {this.props.company.id && <div className="tabela-padrao">
          <div className="headingPage">
            <h1>Conversas Elegíveis para Cobrança</h1>
            {this.state.save_alert && <AlertSuccess />}
          </div>
          <DataTable
            loading={loading}
            addButtonLabel="Adicionar Franquia de Mensagem"
            showSelectAll={false}
            onAddClick={this.onClickAddQuota}
            csv={records}
            showAvailableLengths={false}
            showRefreshButton={false}
            showBackButton={false}
            showRightButtonsBar={false}
            showAddButton={false}
            showFilterByDate={true}
            showSearchInput={false}
            columns={columns}
            data={records.map((r) => {
              return {
                ...r,
                started_message: (
                  <span title={r.message}>
                    {r.started_message ? "Ativa" : "Passiva"}
                  </span>
                ),
              };
            })}
            totalRecords={total_records}
            fetchData={this.fetchRecords}
          />

          <Modal
            size="small"
            closeIcon
            open={this.state.addQuotaModalOpen}
            dimmer="blurring"
            closeOnEscape
            closeOnDocumentClick
            onClose={this.onClickAddQuota}
            closeOnDimmerClick={false}
          >
            <Modal.Header>Adicionar Franquia de Conversa</Modal.Header>
            <Modal.Content>
              {this.state.quotaSuccess && (
                <Message success>Franquia adicionada com sucesso</Message>
              )}
              <Modal.Description>
                <Form>
                <Form.Group>
                        <Dropdown
                          placeholder="Escolha o tipo de franquia"
                          fluid
                          search
                          selectOnBlur={false}
                          selection
                          scrolling
                          error={!!this.state.quotaTypeError}
                          name="quota_type"
                          value={this.state.quotaType}
                          onChange={(e, { value }) => {
                            this.setState({
                              quotaType: value,
                              quotaTypeError: "",
                            });
                          }}
                          options={quotasType}
                          closeOnChange
                          clearable
                        />
                      </Form.Group>
                      {this.state.quotaType & <Form.Group>
                    <Dropdown
                      placeholder="Escolha a Franquia"
                      fluid
                      search
                      selectOnBlur={false}
                      selection
                      scrolling
                      error={!!this.state.quotaError}
                      name="quota"
                      value={this.state.quota ? this.state.quota.id : ""}
                      onChange={(e, { value }) => {
                        this.setState({
                          quota: quotas.find((q) => q.id === value),
                          quotaError: "",
                        });
                      }}
                      options={quotas}
                      closeOnChange
                      clearable
                    />
                  </Form.Group>}
                  <Form.Group>
                    <Form.Field
                      control={Checkbox}
                      name="accepted"
                      onChange={(e, { checked }) => {
                        this.setState({ accepted: checked });
                      }}
                      error={!!this.state.acceptedError}
                      checked={this.state.accepted}
                      label={`Entendo que o valor de 
                                ${this.state.quota
                          ? this.state.quota.amount
                          : ""
                        } será adicionado ao valor total da minha fatura uma única vêz e que a franquia adicionada não é cumulativa`}
                    >
                      <InlineError text={this.state.acceptedError} />
                    </Form.Field>
                  </Form.Group>
                </Form>
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button.Group>
                <Button onClick={this.onClickAddQuota}>Cancelar</Button>
                <Button.Or />
                <Button
                  positive
                  onClick={this.addQuota}
                  loading={loading}
                  disabled={loading || !this.state.quotaType}
                >
                  Contratar
                </Button>
              </Button.Group>
            </Modal.Actions>
          </Modal>
        </div>}
      </div>
    );
  }
}

export default FranquiaMensagemSummay;
