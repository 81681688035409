import React from "react";
import { Grid } from "semantic-ui-react";

export default () => (
  <Grid>
    <Grid.Row>
      <h2>Lidas/Total</h2>
      <p>
        Nesta coluna você verá as mensagens que foram lidas e total que restam
        pra lê, bom observação é que se o seu contato não tiver confimação de
        leitura não será contabilizado!
      </p>
    </Grid.Row>
  </Grid>
);
