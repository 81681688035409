import axios from "../services/api";

export default {
	webhook: {
		fetchAll: params => axios.get(`/webhook`, { params }).then(res => res.data),
		delete: id => axios.delete(`/webhook/${id}`).then(res => res.data),
		update: (id, webhook) => axios.put(`/webhook/${id}`, webhook).then(res => res.data),
		submit: webhook => axios.post(`/webhook`, webhook).then(res => res.data)
	}
};
