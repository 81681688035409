export { default as Wellcome } from "./Wellcome";
export { default as Introduction } from "./Introduction";
export { default as MainMenu } from "./MainMenu";
export { default as TopMenu } from "./TopMenu";
export { default as Reports } from "./Reports";
export { default as Activities } from "./Activities";
export { default as Task } from "./Task";
export { default as Tickets } from "./Tickets";
export { default as DetailMainMenu } from "./DetailMainMenu";
export { default as TopMenuDetailStepOne } from "./TopMenuDetailStepOne";
export { default as TopMenuDetailStepTwo } from "./TopMenuDetailStepTwo";
export { default as TopMenuDetailStepThree } from "./TopMenuDetailStepThree";
export { default as TopMenuDetailStepFour } from "./TopMenuDetailStepFour";
export { default as TopMenuDetailStepFive } from "./TopMenuDetailStepFive";
