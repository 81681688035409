import api from "../../api/tasks";
import user from "../../api/users";

export const Types = {
  ON_ERROR: "task/ON_ERROR",
  IS_FETCHING: "task/IS_FETCHING",
  TASK_FETCH: "task/TASK_FETCH",
  EDIT_OPEN_MODAL: "task/EDIT_OPEN_MODAL",
  IS_UPDATING: "task/IS_UPDATING",
  UPDATED: "task/UPDATED",
  HANDLE_CHANGE: "task/HANDLE_CHANGE",
  ADD_TASK: "task/ADD_TASK",
  ADD_EVENT: "task/ADD_EVENT",
  REMOVE_EVENT: "task/REMOVE_EVENT",
  CLIENT_FETCHED: "task/CLIENT_FETCHED",
  ALTER_TASK: "task/ALTER_TASK",
  IS_DELETING: "task/IS_DELETING",
  DELETED: "task/DELETED",
  CREATE_USER_COLOR: "task/CREATE_USER_COLOR",
  ALTER_EVENT_TASK: "task/ALTER_EVENT_TASK",
  MESSAGE_EVENT: "task/MESSAGE_EVENT",
  MESSAGE_TASK: "task/MESSAGE_TASK",
  MESSAGE_EVENT_STATUS: "task/MESSAGE_EVENT_STATUS",
  MESSAGE_TASK_STATUS: "task/MESSAGE_TASK_STATUS",
  REMOVE_TASK: "task/REMOVE_TASK",
  ADD_TASK_ERROR: "task/ADD_TASK_ERROR",
};

const INITIAL_STATE = {
  total_records: 0,
  columns: {},
  order: {},
  records: [],
  errors: "",
  loading: false,
  selectedDataIndex: -1,
  editModalOpen: false,
  save_alert: false,
  event: [],
  usercolors: [],
};

// reducer
export default function task(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case Types.IS_FETCHING:
      return { ...state, loading: action.loading };
    case Types.TASK_FETCH:
      return {
        ...state,
        records: action.payload,
        total_records: action.total_records,
        order: action.order,
        columns: action.columns,
      };
    case Types.ADD_TASK:
      return {
        ...state,
        success: action.created_message,
        records: state.records.find(
          (element) => element.id === action.payload.id
        )
          ? [...state.records]
          : [...state.records, action.payload],
      };
    case Types.ADD_TASK_ERROR:
      return {
        ...state,
        errors: action.payload,
      };
    case Types.EDIT_OPEN_MODAL:
      return {
        ...state,
        editModalOpen: action.payload,
        selectedDataIndex: action.selectedDataIndex,
      };
    case Types.ADD_EVENT:
      return {
        ...state,
        event: state.event.find((element) => element.id === action.event.id)
          ? [...state.event]
          : [...state.event, action.event],
      };
    case Types.REMOVE_EVENT:
      return {
        ...state,
        event: state.event.filter((remove) => remove.id !== action.event.id),
      };
    case Types.REMOVE_TASK:
      return {
        ...state,
        records: state.records.filter(
          (remove) => remove.id !== action.records.id
        ),
      };

    case Types.ALTER_TASK:
      return {
        ...state,
        records: state.records.map((update) => {
          if (action.payload.id === update.id) {
            return action.payload;
          }
          return update;
        }),
        loading: false,
      };
    case Types.IS_UPDATING:
      return {
        ...state,
        loading: action.payload,
      };
    case Types.ALTER_EVENT_TASK:
      return {
        ...state,
        event: state.event.map((update) => {
          if (action.payload.id === update.id) {
            return action.payload;
          } else {
            return update;
          }
        }),
      };
    case Types.MESSAGE_EVENT:
      return {
        ...state,
        event: state.event.map((update) => {
          if (update.id === action.payload) {
            return { ...update, message: action.message };
          } else {
            return { ...update };
          }
        }),
      };
    case Types.MESSAGE_TASK:
      return {
        ...state,
        records: state.records.map((update) => {
          if (update.id === action.payload) {
            return { ...update, message: action.message };
          } else {
            return update;
          }
        }),
      };
    case Types.MESSAGE_TASK_STATUS:
      return {
        ...state,
        records: state.records.map((update) => {
          if (update.id === action.payload) {
            return { ...update, status_change: action.status_change };
          } else {
            return update;
          }
        }),
      };
    case Types.MESSAGE_EVENT_STATUS:
      return {
        ...state,
        records: state.records.map((update) => {
          if (update.id === action.payload) {
            return { ...update, status_change: action.status_change };
          } else {
            return update;
          }
        }),
      };
    case Types.UPDATED:
      return {
        ...state,
        records: state.records.map((update) => {
          if (action.payload.id === update.id) {
            return action.payload;
          } else {
            return update;
          }
        }),
      };
    case Types.CREATE_USER_COLOR:
      if (state.usercolors.find((element) => element.id === action.id)) {
        return state;
      } else {
        return {
          ...state,
          usercolors: [
            ...state.usercolors,
            { id: action.id, name: action.name, color: action.color },
          ],
        };
      }
    default:
      return state;
  }
}

// actions

export const Creators = {
  fetchRecords: (params) => (dispatch) => {
    dispatch({ type: Types.IS_FETCHING, loading: true });
    api.task.fetchAll({ take: 50 }).then((res) => {
      dispatch({
        type: Types.TASK_FETCH,
        payload: res.data,
        total_records: res.total_records,
        order: res.order,
        columns: res.columns,
      });

      res.data.forEach((elemt) => {
        dispatch({
          type: Types.CREATE_USER_COLOR,
          id: elemt.owner.id,
          name: elemt.owner.name,
          color: `#${Math.random()
            .toString(16)
            .substr(-6)}`,
        });
      });
    });
  },
  error: (e) => { },
  addTask: (params) => async (dispatch) => {
    dispatch({ type: Types.ADD_TASK_ERROR, payload: "" });
    return await api.task
      .submit(params)
      .then((element) => {
        dispatch({
          type: Types.ADD_TASK,
          payload: element.data,
          created_message: element,
        });
        setTimeout(() => {
          dispatch({
            type: Types.ADD_TASK,
            payload: element.data,
            created_message: "",
          });
        }, 3000);
      })
      .catch((err) => {
        dispatch({ type: Types.ADD_TASK_ERROR, payload: err.response.data.errors });
      });
  },
  editModal: (params) => (dispatch) => {
    dispatch({
      type: Types.EDIT_OPEN_MODAL,
      payload: params.payload,
      selectedDataIndex: params.selectedDataIndex,
    });
  },
  isEditing: (params) => (dispatch) => {
    dispatch({ type: Types.IS_UPDATING, payload: params.loading });
  },
  alterTask: (data) => (dispatch) => {
    dispatch({ type: Types.IS_UPDATING, payload: true });
    dispatch({ type: Types.ADD_TASK_ERROR, payload: "" });
    return api.task
      .update(data.id, data.payload)
      .then((datas) => {
        dispatch({
          type: Types.MESSAGE_EVENT_STATUS,
          payload: data.id,
          status_change: datas,
        });
        dispatch({
          type: Types.MESSAGE_TASK_STATUS,
          payload: data.id,
          status_change: datas,
        });
      })
      .catch((err) => {
        dispatch({
          type: Types.MESSAGE_EVENT_STATUS,
          payload: data.id,
          status_change: err,
        });
        dispatch({
          type: Types.MESSAGE_TASK_STATUS,
          payload: data.id,
          status_change: err,
        });
        dispatch({ type: Types.ADD_TASK_ERROR, payload: err.response.data.errors });
      });
  },
  deleteTask: (data) => (dispatch) => {
    dispatch({ type: Types.IS_DELETING });
    api.task
      .delete(data)
      .then((datas) => {
        dispatch({ type: Types.MESSAGE_EVENT, payload: data, message: datas });
        dispatch({ type: Types.MESSAGE_TASK, payload: data, message: datas });
        if (datas.status === "success") {
          dispatch({ type: Types.DELETED, payload: datas });
        }
      })
      .catch((err) => {
        dispatch({ type: Types.MESSAGE_EVENT, payload: data.id, message: err });
        dispatch({ type: Types.MESSAGE_TASK, payload: data.id, message: err });
      });
  },
  updatedTaskEvent: (data) => (dispatch) => {
    dispatch({ type: Types.UPDATED, payload: data });
  },
  addEventTask: (data) => (dispatch) => {
    dispatch({ type: Types.ADD_EVENT, event: data.list.task });
  },
  removeEventTask: (data) => (dispatch) => {
    dispatch({ type: Types.REMOVE_EVENT, event: data });
  },
  alterEventTask: (data) => (dispatch) => {
    dispatch({ type: Types.ALTER_EVENT_TASK, payload: data.payload });
  },
};
